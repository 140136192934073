import AddressLabel from "components/Common/AddressLabel";
import Tooltip from "components/Common/Tooltip";
import React from "react";
import { CarbonEVMTransaction } from "store/Wallet";
import "../BridgeHistoryCard.css";
import StatusBlock from "components/Common/StatusBlock";
import { ChainNames } from "constants/types";

interface Props {
  historyDataItem: CarbonEVMTransaction,
}

const CarbonEVMBridgeHistoryCard: React.FC<Props> = ({ historyDataItem }) => {
  const bridgeStatusToStatus = new Map<typeof historyDataItem.status, "Danger" | "Success">([
    ["Success", "Success"],
    ["Failed", "Danger"],
  ]);

  return <div className="bridge-history-card-inner-wrapper">
    <div className="bridge-history-card-top">
      <div className="date-time" style={{ textAlign: "left" }}>
        <span>Date</span>
        <span className="bridge-history-card-text-secondary">Time</span>
      </div>
      <div className="date-time" style={{ textAlign: "right" }}>
        <span>{historyDataItem.dateTime.format("DD MMM, YYYY")}</span>
        <span className="bridge-history-card-text-secondary">{historyDataItem.dateTime.format("HH:mm:ss")}</span>
      </div>
    </div>
    <div className="bridge-history-card-bottom">
      <div className="bridge-history-label-box">
        <div>
          <span>Sender </span>
          <AddressLabel
            text={historyDataItem.senderAddress}
            logoName={historyDataItem.senderChain}
            externalLinkData={historyDataItem.senderChain === ChainNames.CarbonCore ? { type: "address", chain: ChainNames.CarbonCore } : undefined}
          />
        </div>
      </div>
      <div className="bridge-history-label-box">
        <div>
          <span>Receiver</span>
          <AddressLabel
            text={historyDataItem.receiverAddress}
            logoName={historyDataItem.receiverChain}
            externalLinkData={historyDataItem.receiverChain === ChainNames.CarbonCore ? { type: "address", chain: ChainNames.CarbonCore } : undefined}
          />
        </div>
      </div>
      <div className="bridge-history-label-box">
        <div>
          <span>Amount</span>
          <span>{`${historyDataItem.amount} ${historyDataItem.token}`}</span>
        </div>
        <div className="bridge-history-card-text-secondary">
          <span>USD</span>
          <span>${historyDataItem.amountUSD}</span>
        </div>
      </div>
      <div className="bridge-history-card-status">
        <div className="bridge-history-card-status-label">
          <span>Status</span>
          <Tooltip>
            <span>The bridge process includes the following stages:</span>
            <span> 1. Success</span>
            <span> 2. Failed</span>
          </Tooltip>
        </div>
        <StatusBlock text={historyDataItem.status} status={bridgeStatusToStatus.get(historyDataItem.status)} />
      </div>
    </div>
  </div>;
}

export default CarbonEVMBridgeHistoryCard;
