"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTendermint37Client = exports.isTendermint34Client = void 0;
const tendermint34_1 = require("./tendermint34");
const tendermint37_1 = require("./tendermint37");
function isTendermint34Client(client) {
    return client instanceof tendermint34_1.Tendermint34Client;
}
exports.isTendermint34Client = isTendermint34Client;
function isTendermint37Client(client) {
    return client instanceof tendermint37_1.Tendermint37Client;
}
exports.isTendermint37Client = isTendermint37Client;
