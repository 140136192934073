import { CarbonSDK } from 'carbon-js-sdk'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import Avatar from 'components/Common/Avatar'
import { useAppSelector } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React from "react"
import { StakeTasks } from 'store/Stake/types'
import { DelegationLoading } from '../Common/DelegationLoading'
import { RedelegationEmpty } from '../Common/EmptyStates'
import { RedelegationsTableData } from "../StakeDelegationInfo"
import "./RedelegationMobile.css"
import BigNumber from 'bignumber.js'


interface Props {
  redelegations: RedelegationsTableData[]
}

const RedelegationMobile: React.FC<Props> = ({ redelegations }) => {
  const sdk = useAppSelector<CarbonSDK | null>(state => state.app.carbonSDK)
  const [redelegationsLoading] = useTaskSubscriber(StakeTasks.ValidatorRedelegations)

  return (
    <div className="stake-your-delegation-mobile-wrapper theme-color">
      {redelegationsLoading ?
      <DelegationLoading /> :
        redelegations.length === 0 ?
          <div className="empty-mobile-card" style={{ padding: "0px 16px" }}>
            <RedelegationEmpty />
          </div>
          :
          redelegations.sort((a,b) => a.completionTime > b.completionTime ? -1 : 1).map((redelegation, index) => {
            const symbol = sdk?.token.getSymbol(redelegation.denom) ?? ""
            const price = sdk?.token.getUSDValue(redelegation.denom) ?? BN_ZERO
            const amount = redelegation.amount

            const threshold = 0.001
            const isTooSmall: boolean = amount.lte(threshold)
            const amountWithDenom = `${isTooSmall ? `≥${threshold}` : amount.toFixed(3, BigNumber.ROUND_DOWN)} ${symbol}`

            return (
              <div className='stake-your-delegation-mobile-card-wrapper' key={index}>
                <div>
                  <p style={{ marginBottom: "8px" }}>Redelegate From</p>
                  <Avatar name={redelegation.validatorFrom} valAddr={redelegation.valFromAddr} icon="link" identity={redelegation.valFromID} />
                </div>
                <div style={{ marginTop: "13px", marginBottom: "16px" }}>
                  <p style={{ marginBottom: "8px" }}>Redelegate To</p>
                  <Avatar name={redelegation.validatorTo} valAddr={redelegation.valToAddr} icon="link" identity={redelegation.valToID} />
                </div>
                <div className='redelegate-mobile-divider' />
                <div className='stake-your-delegation-mobile-stats'>
                  <div>
                    <p>Redelegate Amount</p>
                    <p>{amountWithDenom}</p>
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "14px", lineHeight: "18px" }} className="reward-card-text-secondary">
                    <p>USD</p>
                    <p>{'$' + redelegation.amount.multipliedBy(price).toFormat(2)}</p>
                  </div>
                  <div>
                    <p>Start Block</p>
                    <p>{redelegation.startBlock}</p>
                  </div>
                  <div>
                    <p>Redelegated On</p>
                    <p>{redelegation.completionTime.subtract(30, "day").format("MMM DD, YYYY")}<span className="reward-card-text-secondary">{` ${redelegation.completionTime.subtract(30, "day").format("HH:mm:ss")}`}</span></p>
                  </div>
                </div>
              </div>
            )
          })
      }
    </div >
  )
}

export default RedelegationMobile
