import { CarbonSDK } from 'carbon-js-sdk'
export function getNetworkQueryParam(net: CarbonSDK.Network) {
    if (net === CarbonSDK.Network.LocalHost) {
        return `net=${net.toLowerCase().replace(/host$/, '')}`
    }
    return `net=${net.toLowerCase().replace(/net$/, '')}`
}

export function getExplorerHost(net: CarbonSDK.Network) {
    if (net === CarbonSDK.Network.MainNet) {
        return 'https://scan.carbon.network'
    }
    return 'https://beta-scan.carbon.network'
}

export function getAddressLink(address: string | null, net: CarbonSDK.Network, paramsStr: string = '') {
    const link = `${getExplorerHost(net)}/account/${address}?${getNetworkQueryParam(net)}`
    if (paramsStr.length > 0) {
        return `${link}&${paramsStr}`
    }
    return link
}

export function getValidatorAddressLink(address: string | null, net: CarbonSDK.Network, paramsStr: string = '') {
    const link = `${getExplorerHost(net)}/validator/${address}?${getNetworkQueryParam(net)}`
    if (paramsStr.length > 0) {
        return `${link}&${paramsStr}`
    }
    return link
}

export function getGovernanceAddressLink(net: CarbonSDK.Network, paramsStr: string = '') {
    const link = `${getExplorerHost(net)}/governance?${getNetworkQueryParam(net)}`
    if (paramsStr.length > 0) {
        return `${link}&${paramsStr}`
    }
    return link
}