import { ValidatorsResponse } from '@cosmjs/tendermint-rpc'
import { PayloadAction } from '@reduxjs/toolkit'
import { CarbonSDK, Models } from "carbon-js-sdk"
import { PageRequest } from 'carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination'
import { GetLatestValidatorSetResponse } from "carbon-js-sdk/lib/codec/cosmos/base/tendermint/v1beta1/query"
import Long from 'long'
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects"
import {
  StakeActionTypes,
  UpdateRewardAmount,
  UserDelegation,
  UserRedelegation,
  UserUnbonding,
  ValPair,
  updateAllianceAssets,
  updateAprStats,
  updateAvatarImagesMap,
  updateAvgBlockTime, updateDelegatorsMap, updateProposals, updateTotalStaked, updateTotalSupply, updateUserDelegationRewards, updateUserDelegations,
  updateUserRedelegations,
  updateUserUnbondingDelegations, updateValAddrMap,
  updateValidatorParticipation
} from 'store/Stake'
import { SimpleMap, reduxAction } from 'utils'
import { logger } from 'utils/logger'
import { waitforSDK } from "./Common"
import { defaultPagination, getUsd, runSagaTask } from "./helper"

import { Block } from '@cosmjs/stargate'
import { BlockResultsResponse } from '@cosmjs/tendermint-rpc/build/tendermint37/responses'
import BigNumber from 'bignumber.js'
import { BN_ZERO } from 'constants/math'
import { updateCarbonSDK } from 'store/App'
import { StakeTasks } from 'store/Stake/types'
import { SECONDS_PER_YEAR, bnOrZero } from 'utils'
import {
  getAverageBlockTime, getAverageRewards,
  getTotalBondedAmount
} from 'utils/stake'
import { selectState } from "./Common"
import dayjs from 'dayjs'
import { QueryProposalsRequest, QueryProposalsResponse } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/query'


export interface APRStats {
  totalBonded: BigNumber,
  avgBlockTime: BigNumber,
  avgReward: BigNumber,
}

// * Query APR and Avg. Block Time * //
async function reloadStakingStats(valMap: SimpleMap<ValPair>, sdk: CarbonSDK): Promise<APRStats> {
  const lastBlock: Block = await sdk!.query.chain.getBlock()
  // TODO: Fix unstable APY values
  // for (let i = 0; i < 10; i++) {
  //   const evts: BlockResultsResponse = await sdk!.tmClient.blockResults(
  //     lastHeight - i ?? 0,
  //   )
  //   const reward = getAverageRewards(evts, sdk)
  //   avgReward = avgReward.plus(reward)
  // }
  // avgReward = avgReward.div(new BigNumber(10))
  // const evts: BlockResultsResponse = await sdk!.tmClient.blockResults(
  //   lastHeight ?? 0,
  // )
  const evts: BlockResultsResponse = await sdk!.tmClient.blockResults(
    lastBlock.header.height ?? 0,
  )
  const totalBonded = getTotalBondedAmount(valMap)
  const avgReward = getAverageRewards(evts, sdk)
  const avgBlockTime = getAverageBlockTime(evts)

  return {
    totalBonded,
    avgBlockTime,
    avgReward,
  }
}

// also queries Avg. Block Time
function* handleFetchAprStats() {
  yield runSagaTask(StakeTasks.AprStats, function* () {
    const valMap = (yield selectState(state => state.stake.valAddrMap)) as SimpleMap<ValPair>
    const sdk: CarbonSDK = (yield call(waitforSDK)) as CarbonSDK

    if (Object.keys(valMap).length === 0) {
      return
    }

    const {
      totalBonded,
      avgBlockTime,
      avgReward,
    } = (yield call(reloadStakingStats, valMap, sdk)) as APRStats

    const blocksInYear = new BigNumber(SECONDS_PER_YEAR).div(avgBlockTime)
    const rewardsInYear = blocksInYear.times(avgReward)
    const aprPayload = {
      totalBonded,
      apr: totalBonded.isZero() ? BN_ZERO : rewardsInYear.div(totalBonded)
    }

    yield put(updateAvgBlockTime(avgBlockTime))
    yield put(updateAprStats(aprPayload))
  })
}

// * Query Total Staked amt. * //
function* handleFetchTotalStaked() {
  yield runSagaTask(StakeTasks.TotalStaked, function* () {
    const sdk = (yield call(waitforSDK)) as CarbonSDK
    const stakingQueryClient = sdk.query.staking

    const pool = (yield call([stakingQueryClient, stakingQueryClient.Pool], {})) as Models.Staking.QueryPoolResponse
    const totalStaked = sdk.token.toHuman('swth', bnOrZero(pool.pool?.bondedTokens).plus(bnOrZero(pool.pool?.notBondedTokens)))

    yield put(updateTotalStaked(totalStaked))
  })
}

function* handleFetchSwthSupply() {
  yield runSagaTask(StakeTasks.TotalSupply, function* () {
    const sdk = (yield call(waitforSDK)) as CarbonSDK
    const bankQueryClient = sdk.query.bank

    const totalSupply = (yield call([bankQueryClient, bankQueryClient.TotalSupply], {
      pagination: {
        limit: new Long(10000),
        offset: Long.UZERO,
        key: new Uint8Array(),
        countTotal: false,
        reverse: false,
      }
    })) as Models.Bank.QueryTotalSupplyResponse
    const swthSupply = totalSupply.supply.find((supply: any) => supply.denom === 'swth')
    yield put(updateTotalSupply(sdk.token.toHuman('swth', bnOrZero(swthSupply?.amount))))
  })
}

function* handleFetchAlliances() {
  yield runSagaTask(StakeTasks.Alliances, function* () {
    const sdk = (yield call(waitforSDK)) as CarbonSDK

    const { alliances } = (yield call([sdk.query.alliance, sdk.query.alliance.Alliances], {
      pagination: defaultPagination,
    })) as Models.Carbon.Alliance.QueryAlliancesResponse

    alliances.sort((lhs, rhs) => {
      return bnOrZero(lhs.totalValidatorShares).comparedTo(rhs.totalValidatorShares);
    })

    yield put(updateAllianceAssets(alliances))
  })
}

// * Query Staking Validators + Query All Delegators Delegations * //
function* handleFetchValidators(action?: PayloadAction) {
  yield runSagaTask(StakeTasks.Validators, function* () {
    const sdk = (yield call(waitforSDK)) as CarbonSDK
    const validatorsResponse = (yield call([sdk.query.staking, sdk.query.staking.Validators], {
      status: '',
    })) as Models.Staking.QueryValidatorsResponse
    const carbonValidators = validatorsResponse.validators

    const tmValidatorResponse = (yield call([sdk.tmClient, sdk.tmClient.validatorsAll])) as ValidatorsResponse
    const tmValidators = tmValidatorResponse.validators

    const valsetResponse = (yield call([sdk.query.cosmosTm, sdk.query.cosmosTm.GetLatestValidatorSet], {})) as GetLatestValidatorSetResponse
    const valsetValidators = valsetResponse.validators ?? []

    const keybaseImagesURL: { [id: string]: string } = (
      yield call(getAllPossibleKeybaseImages, validatorsResponse?.validators)
    ) as { [id: string]: string }

    const valAddrMap: SimpleMap<ValPair> = {}
    const delegatorsMap: SimpleMap<Models.Staking.DelegationResponse[]> = {}

    for (const validator of carbonValidators) {
      if (!validator.consensusPubkey) continue

      const validatorAddress = validator.operatorAddress
      const consensusPubkey = Buffer.from(validator.consensusPubkey.value).slice(2).toString('hex')
      const tmValidator = tmValidators.find((validator) => {
        if (!validator.pubkey?.data) return false
        return Buffer.from(validator.pubkey.data).toString("hex") === consensusPubkey
      })

      valAddrMap[validatorAddress] = {
        carbonValidator: validator,
        tmValidator,
        consAddress: valsetValidators.find((item) => Buffer.from(item.pubKey?.value ?? new Uint8Array()).slice(2).toString('hex') === consensusPubkey)?.address
      }
    }

    const delegatorsList = (yield all(carbonValidators.map((val: Models.Staking.Validator) => {
      return call([sdk.query.staking, sdk.query.staking.ValidatorDelegations], {
        validatorAddr: val.operatorAddress,
        pagination: PageRequest.fromPartial({
          limit: 1000
        })
      })
    }))) as Models.Staking.QueryValidatorDelegationsResponse[]

    carbonValidators.forEach((val, index) => {
      delegatorsMap[val.operatorAddress] = delegatorsList[index].delegationResponses
    })

    logger("stakeSaga", "delegators map", delegatorsMap)
    logger("stakeSaga", "validators addr map", valAddrMap)

    yield put(updateDelegatorsMap(delegatorsMap))
    yield put(updateValAddrMap(valAddrMap))
    yield put(updateAvatarImagesMap(keybaseImagesURL))
    yield put(reduxAction(StakeActionTypes.QUERY_APR_STATS))
  })
}

async function getAllPossibleKeybaseImages(data: ReadonlyArray<Models.Staking.Validator>): Promise<{ [id: string]: string }> {
  const keybaseFetchPromises = data.map((v) => {
    const identity = v.description?.identity
    if (identity && identity.length > 0) {
      return fetch(`https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${identity}&fields=public_keys,pictures`).then((r) => (r ? r.json() : null))
    }
    return null
  })

  const jsonResponses = await Promise.allSettled(keybaseFetchPromises) as any[]
  let validResponses = jsonResponses.filter(result => result.status === 'fulfilled') //filter for resolved Promises
  validResponses = validResponses.filter((r) => r?.value?.them?.[0]).map((r) => r.value?.them[0])

  const keybaseImagesURL: { [id: string]: string } = {}
  validResponses.forEach((r) => {
    const identity = r.public_keys?.primary?.key_fingerprint.toLowerCase()
    keybaseImagesURL[identity] = r.pictures?.primary?.url
  })
  return keybaseImagesURL
}

// * Query User Delegations * //
function* handleFetchUserDelegations(action?: PayloadAction): Generator {
  yield runSagaTask(StakeTasks.ValidatorDelegations, function* () {
    const sdk = (yield call(waitforSDK)) as CarbonSDK

    if (!sdk.wallet) return

    const delegatorAddr = sdk.wallet.bech32Address
    const { delegationResponses } = (
      yield call([sdk.query.staking, sdk.query.staking.DelegatorDelegations], { delegatorAddr })
    ) as Models.Staking.QueryDelegatorDelegationsResponse

    const { delegations } = (
      yield call([sdk.query.alliance, sdk.query.alliance.AlliancesDelegation], { delegatorAddr })
    ) as Models.Carbon.Alliance.QueryAlliancesDelegationsResponse

    const allDelegations: UserDelegation[] = delegationResponses.concat(delegations).map(delegation => ({
      balance: bnOrZero(delegation.balance?.amount),
      shares: bnOrZero(delegation.delegation?.shares),
      denom: delegation.balance!.denom,
      delegatorAddress: delegation.delegation!.delegatorAddress,
      validatorAddress: delegation.delegation!.validatorAddress,
      rewardsUsdValue: BN_ZERO,
      pendingRewards: [],
    }))

    allDelegations.sort((lhs, rhs) => {
      return rhs.shares.comparedTo(lhs.shares)
    })

    yield put(updateUserDelegations(allDelegations))

    logger("stakeSaga", "user delegations", allDelegations)
  })
}

function* handleFetchUserUnbondingDelegations(action?: PayloadAction): Generator {
  try {
    const sdk: CarbonSDK = (yield call(waitforSDK)) as CarbonSDK

    if (!sdk.wallet) return

    const delegatorAddr = sdk.wallet.bech32Address
    const result = (
      yield call([sdk.query.staking, sdk.query.staking.DelegatorUnbondingDelegations], { delegatorAddr })
    ) as Models.Staking.QueryDelegatorUnbondingDelegationsResponse

    const allianceResult: any = yield fetch(`${sdk.networkConfig.insightsUrl}/alliances/delegation/${delegatorAddr}?type=undelegate&limit=1000`).then(res => res.json());
    const unbondings = allianceResult?.result?.entries
    const allUnbondings: UserUnbonding[] = result.unbondingResponses.flatMap(d => {
      return d.entries.map(e => ({
        balance: bnOrZero(e.balance),
        initialBalance: bnOrZero(e.initialBalance),
        denom: "swth",
        validatorAddress: d.validatorAddress,
        delegatorAddress: d.delegatorAddress,
        completionTime: dayjs(e.completionTime),
        creationHeight: e.creationHeight.toNumber(),
      }))
    }).concat(unbondings.map((d: any) => ({
      balance: bnOrZero(d.amount),
      initialBalance: bnOrZero(d.amount),
      denom: d.denom,
      delegatorAddress: d.delegator,
      validatorAddress: d.validator,
      completionTime: dayjs(d.completionTime),
      creationHeight: d.blockHeight,
    })))

    yield put(updateUserUnbondingDelegations(allUnbondings))

    logger("stakeSaga", "user unbonding", allUnbondings)
  } catch (err) {
    console.error({ err })
  }
}

function* handleFetchUserRedelegations(action?: PayloadAction): Generator {
  yield runSagaTask(StakeTasks.ValidatorRedelegations, function* () {
    const sdk: CarbonSDK = (yield call(waitforSDK)) as CarbonSDK

    if (!sdk.wallet) return

    const delegatorAddr = sdk.wallet.bech32Address
    const { redelegationResponses } = (
      yield call([sdk.query.staking, sdk.query.staking.Redelegations], { delegatorAddr, srcValidatorAddr: '', dstValidatorAddr: '' })
    ) as Models.Staking.QueryRedelegationsResponse

    const allianceResult: any = yield fetch(`${sdk.networkConfig.insightsUrl}/alliances/delegation/${delegatorAddr}?type=redelegate&limit=1000`).then(res => res.json());
    const redelegations = allianceResult?.result?.entries
    const allRedelegations: UserRedelegation[] = redelegationResponses.flatMap((d) => {
      return d.entries.map(e => ({
        balance: bnOrZero(e.balance),
        initialBalance: bnOrZero(e.redelegationEntry?.initialBalance),
        newShares: bnOrZero(e.redelegationEntry?.sharesDst),
        denom: "swth",
        validatorSrcAddress: d.redelegation?.validatorSrcAddress ?? "",
        validatorDstAddress: d.redelegation?.validatorDstAddress ?? "",
        delegatorAddress: d.redelegation?.delegatorAddress ?? "",
        creationHeight: e.redelegationEntry?.creationHeight.toNumber() ?? 0,
        completionTime: dayjs(e.redelegationEntry?.completionTime),
      }))
    }).concat(redelegations.map((d: any) => ({
      balance: bnOrZero(d.amount),
      initialBalance: bnOrZero(d.amount),
      newShares: bnOrZero(d.newShares),
      denom: d.denom,
      delegatorAddress: d.delegator,
      validatorSrcAddress: d.validator,
      validatorDstAddress: d.newValidator,
      completionTime: dayjs(d.completionTime),
      creationHeight: d.blockHeight,
    } as UserRedelegation)))

    yield put(updateUserRedelegations(allRedelegations))

    logger("stakeSaga", "user redelegations", allRedelegations)
  })
}

// * Query User Rewards * //
function* handleFetchUserDelegationRewards(action?: PayloadAction): Generator {
  yield runSagaTask(StakeTasks.DelegationRewards, function* () {
    const sdk: CarbonSDK = (yield call(waitforSDK)) as CarbonSDK
    if (!sdk.wallet) return
    const delegatorAddress = sdk.wallet.bech32Address
    const distributionQueryClient = sdk.query.distribution

    const { rewards } = (
      yield call([distributionQueryClient, distributionQueryClient.DelegationTotalRewards], { delegatorAddress })
    ) as Models.Distribution.QueryDelegationTotalRewardsResponse

    const updates: UpdateRewardAmount[] = rewards.map(reward => {
      const bnRewards = reward.reward.map(coin => ({
        amount: bnOrZero(coin.amount).shiftedBy(-18),
        denom: coin.denom,
      }))
      return {
        delegatorAddress,
        validatorAddress: reward.validatorAddress,
        denom: "swth",
        usdValue: bnRewards.reduce((sum, r) => sum.plus(getUsd(sdk, r)), BN_ZERO),
        rewards: bnRewards,
      }
    })

    // alliance rewards
    const delegations = (yield select(state => state.stake.userDelegations)) as UserDelegation[]

    for (const allyDelegation of delegations) {
      if (allyDelegation.denom === "swth") continue;
      if (allyDelegation.balance.isZero() && allyDelegation.shares.isZero()) continue;
      const { rewards } = (
        yield call([sdk.query.alliance, sdk.query.alliance.AllianceDelegationRewards], {
          delegatorAddr: allyDelegation.delegatorAddress,
          validatorAddr: allyDelegation.validatorAddress,
          denom: allyDelegation.denom,
        })
      ) as Models.Carbon.Alliance.QueryAllianceDelegationRewardsResponse

      const bnRewards = rewards.map(coin => ({
        amount: bnOrZero(coin.amount),
        denom: coin.denom,
      }))
      updates.push({
        delegatorAddress: allyDelegation.delegatorAddress,
        validatorAddress: allyDelegation.validatorAddress,
        denom: allyDelegation.denom,
        usdValue: bnRewards.reduce((sum, r) => sum.plus(getUsd(sdk, r)), BN_ZERO),
        rewards: bnRewards,
      })
    }
    yield put(updateUserDelegationRewards(updates))
  })
}

function* handleFetchGovernanceStatus(action?: PayloadAction) {
  yield runSagaTask("voting-status", function* () {
    const sdk: CarbonSDK = (yield call(waitforSDK)) as CarbonSDK
    const govQueryClient = sdk.query.gov

    const proposalsResponse = (yield call([govQueryClient, govQueryClient.Proposals], QueryProposalsRequest.fromPartial({}))) as QueryProposalsResponse
    const proposals = proposalsResponse.proposals

    proposals.sort((lhs, rhs) => {
      return rhs.submitTime?.getTime()! - lhs.submitTime?.getTime()! || lhs.id.toInt() - rhs.id.toInt()
    })
    yield put(updateProposals(proposals))
  })
}

async function fetchValidatorsParticipation(sdk: CarbonSDK) {
  const networkURL = sdk.network === 'mainnet' ? '' : sdk.network === 'testnet' ? 'test-' : 'dev-'
  const totalProposalToCheck = 10
  return fetch(`https://${networkURL}api-insights.carbon.network/gov/proposal/participation?validator=true&last=${totalProposalToCheck}`).then((r) => (r ? r.json() : null))
}

function* handleFetchValidatorsParticipation(action?: PayloadAction) {
  yield runSagaTask("validators-participation", function* () {
    const sdk: CarbonSDK = (yield call(waitforSDK)) as CarbonSDK
    const participationRaw = (yield call(fetchValidatorsParticipation, sdk)) as any
    yield put(updateValidatorParticipation(participationRaw?.result?.entries))
  })
}


// ** Watchers ** // 

// * APR Stats * //
function* watchFetchAprStatsRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_APR_STATS], handleFetchAprStats)
}

// * Total Staked * //
function* watchFetchTotalStakedRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_TOTAL_STAKED, updateCarbonSDK.type], handleFetchTotalStaked)
}

// * Total Supply * //
function* watchFetchTotalSupplyRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_TOTAL_SUPPLY, updateCarbonSDK.type], handleFetchSwthSupply)
}

// * Alliances * //
function* watchFetchAlliancesRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_ALLIANCES, updateCarbonSDK.type], handleFetchAlliances)
}

// * Query Staking Validators + Query All Delegators Delegations * //
function* watchFetchValidatorsRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_VALIDATORS, updateCarbonSDK.type], handleFetchValidators)
}

// * Query User Delegations * //
function* watchFetchUserDelegationsRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_USER_DELEGATIONS, updateCarbonSDK.type], handleFetchUserDelegations)
}

function* watchFetchUserUnbondingDelegationsRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_USER_UNBONDING_DELEGATIONS, updateCarbonSDK.type], handleFetchUserUnbondingDelegations)
}

function* watchFetchUserRedelegationsRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_USER_REDELEGATIONS, updateCarbonSDK.type], handleFetchUserRedelegations)
}

function* watchFetchUserDelegationRewardsRequest(): Generator {
  yield takeLatest([StakeActionTypes.QUERY_USER_DELEGATION_REWARDS, updateUserDelegations.type], handleFetchUserDelegationRewards)
}

function* watchFetchValidatorsParticipation(): Generator {
  yield takeLatest([updateCarbonSDK.type], handleFetchValidatorsParticipation)
}

function* watchReloadUserAllDelegationsRequest(): Generator {
  yield takeLatest(StakeActionTypes.RELOAD_ALL_USER_DELEGATIONS, function* () {
    yield put(reduxAction(StakeActionTypes.QUERY_USER_DELEGATIONS))
    yield put(reduxAction(StakeActionTypes.QUERY_USER_UNBONDING_DELEGATIONS))
    yield put(reduxAction(StakeActionTypes.QUERY_USER_REDELEGATIONS))
    yield put(reduxAction(StakeActionTypes.QUERY_USER_DELEGATION_REWARDS))
  })
}

function* watchReloadAllStakingInfo(): Generator {
  yield takeLatest(StakeActionTypes.RELOAD_ALL_STAKING_INFO, function* () {
    yield put(reduxAction(StakeActionTypes.RELOAD_ALL_USER_DELEGATIONS))
    yield put(reduxAction(StakeActionTypes.QUERY_TOTAL_STAKED))
    yield put(reduxAction(StakeActionTypes.QUERY_TOTAL_SUPPLY))
  })
}

function* init() {
  yield handleFetchGovernanceStatus()
  yield handleFetchValidatorsParticipation()
}

export function* stakeSaga() {
  yield fork(watchFetchAprStatsRequest)
  yield fork(watchFetchTotalStakedRequest)
  yield fork(watchFetchTotalSupplyRequest)
  yield fork(watchFetchAlliancesRequest)

  yield fork(watchFetchValidatorsRequest)

  yield fork(watchFetchUserDelegationsRequest)
  yield fork(watchFetchUserUnbondingDelegationsRequest)
  yield fork(watchFetchUserRedelegationsRequest)

  yield fork(watchFetchUserDelegationRewardsRequest)

  yield fork(watchReloadUserAllDelegationsRequest)
  yield fork(watchReloadAllStakingInfo)
  yield fork(watchFetchValidatorsParticipation)

  yield fork(init)
}
