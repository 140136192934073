import { Models } from "carbon-js-sdk"
import { BondStatus } from "carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking"
import Checkbox from "components/Common/Checkbox"
import ExternalLink from "components/Common/ExternalLink"
import NumberHeader from "components/Common/NumberHeader"
import SearchBar from "components/Common/SearchBar"
import React, { useEffect, useState } from "react"
import { bnOrZero } from "utils/number"
import "./StakeValidatorsInfo.css"
import StakeValidatorsTable from "./StakeValidatorsTable"

type Props = {
  validators: Models.Staking.Validator[];
}

const StakeValidatorsInfo = React.forwardRef<HTMLDivElement, Props>(( { validators }, ref ) => {

  const [inputText, setInputText] = useState<string>("");
  const [poolSizeAscending, setPoolSizeAscending] = useState<boolean>(false);
  const [filteredValidators, setFilteredValidators] = useState<Models.Staking.Validator[]>([]);
  const [showInactiveVal, setShowInactiveVal] = useState<boolean>(false);

  const onInputHandler = (event: React.FormEvent<HTMLInputElement>) => {
    setInputText(event.currentTarget.value);
  }

  const onTogglePoolSize = () => {
    setPoolSizeAscending(!poolSizeAscending);
  }

  const onCheck = () => {
    setShowInactiveVal(!showInactiveVal);
  }

  useEffect(() => {
    let filteredValidators = validators;
    if (inputText.length > 0) {
      filteredValidators = validators.filter((validator) => {
        return validator.description?.moniker.toLowerCase().includes(inputText.toLowerCase()) || validator.operatorAddress === inputText;
      });
    }
    if (!poolSizeAscending) {
      filteredValidators.sort((lhs, rhs) => bnOrZero(rhs.tokens).comparedTo(lhs.tokens))
    } else {
      filteredValidators.sort((lhs, rhs) => bnOrZero(lhs.tokens).comparedTo(rhs.tokens))
    }
    if (!showInactiveVal) {
      filteredValidators = filteredValidators.filter((each) => each?.status === BondStatus.BOND_STATUS_BONDED || each?.status === BondStatus.BOND_STATUS_UNBONDING)
    }
    setFilteredValidators([...filteredValidators]);
  },[inputText, validators, poolSizeAscending, showInactiveVal]);

  return (
    <div className="stake-validators-info" ref={ref}>
      <div className="stake-validators-headers">
        <NumberHeader text="Validators" number={filteredValidators.length} bolded={true}/>
        <ExternalLink text="How to Become a Validator" link="https://docs.carbon.network/introduction/getting-started/carbon-node-setup/validator-guide" />
      </div>
      <div className="stake-validators-search-bar">
        <SearchBar onChange={onInputHandler} />
        <div className="stake-validators-show-inactive bolded-700">
          <Checkbox text="Show Inactive Validators" checkboxHandler={onCheck}/>
        </div>
      </div>
      <StakeValidatorsTable validators={filteredValidators} poolSizeAscending={poolSizeAscending} onTogglePoolSize={onTogglePoolSize} />
    </div>
  );
})

export default StakeValidatorsInfo;
