import React from "react"
import "./SWTH.css"
import CoinIcon from "./CoinIcon";

const SWTHNeo:React.FC = () => {
  return (<div className="SWTH-compound-token">
    <CoinIcon denom="SWTH"/>
    <div className="SWTH-chain-box">
      <CoinIcon denom="NEO"/>
    </div>
  </div>);
};

export default SWTHNeo;
