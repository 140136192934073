import PopupWindow from 'components/Common/Popups/PopupWindow'
import "./DelegationWarningPopup.css"

interface Props {
    setPopup: () => void
    popup: boolean
}

const DelegationWarningPopup: React.FC<Props> = ({ setPopup, popup }) => {
    return (
        <PopupWindow headerText='Your Stake Matters' closeWindow={() => setPopup()} bottomButtonText='Ok, I got it.' clickBottomButton={() => setPopup()}>
            <div className ='delegation-warning-popup-text bolded-400'>
            <p>The Carbon protocol relies on validators to verify transactions and secure the protocol. The greater the stake delegated to a validator, the higher the chance for them to be selected by network to propose new blocks.</p>
               <p>Cumulative stake with top validators can centralize voting power and compromise protocol security.</p> 
                <p>Help improve decentralization by <span className='bolded-700'>staking with validators that have a smaller pool size.</span></p></div>
        </PopupWindow>
    )
}

export default DelegationWarningPopup