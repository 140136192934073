import { useCallback, useState } from "react"

export type ErrorHandler = (error: any) => void
export type AsyncTaskOutput<T> = [(task: () => Promise<T>) => Promise<void>, boolean, Error | null, (e?: Error) => void]

const parseError = (original: any): Error => {
  let error = original
  if (typeof error === "string")
    error = new Error(error)
  return error
}

export const useAsyncTask = <T>(errorHandler?: (error: Error) => void): AsyncTaskOutput<T> => {
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)

  const cleanup = () => {
    setError(null)
  }

  const asyncTaskRunner = useCallback(async (task: () => Promise<T>): Promise<void> => {
    if (typeof cleanup === "function") cleanup()

    try {
      setLoading(true)
      await task()
    } catch (rawError) {
      console.error("async task error", rawError)
      const error = parseError(rawError)
      errorHandler?.(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [errorHandler])

  const setOrClearError = (error?: Error) => {
    error ? setError(error) : setError(null)
  }

  return [asyncTaskRunner, loading, error, setOrClearError]
}
