import React, { ChangeEventHandler } from "react";
import Search from "assets/Search.svg";
import "./SearchBar.css";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
}

const SearchBar: React.FC<Props> = ({ onChange, style }) => {
  return (
    <label htmlFor="search-input" className="search-bar" style={ style }>
      <img src={ Search } alt="Search" />
      <input type="text" placeholder="Search" id="search-input" onChange={ onChange } />
    </label>
  )
}

export default SearchBar;
