export enum Network {
  EthMainnet = "eth-mainnet",
  EthGoerli = "eth-goerli",
  EthRinkeby = "eth-rinkeby",
  BSCMainnet = "bsc-mainnet",
  BSCTestnet = "bsc-testnet",
  CarbonEVM = "carbonevm-mainnet",
  ArbitrumOne = "arbitrum-mainnet",
}

export enum ChainNames {
  Eth = "Ethereum",
  Bsc = "BSC",
  Zil = "Zilliqa",
  CarbonCore = "Carbon Core",
  CarbonEVM = "Carbon EVM",
  Neo = "Neo (NEP-5)",
  Neo3 = "Neo N3 (NEP-17)",
  Arbitrum = "Arbitrum One",
}

export enum WalletNames {
  Metamask = "Metamask",
  TrustWallet = "TrustWallet",
  Ledger = "Ledger",
  Keplr = "Keplr",
  ZilPay = "ZilPay",
  BoltX = "BoltX",
  NeoLine = "NeoLine",
  O3 = "O3",
  Leap = "Leap"
}
export interface MetamaskProvider {
  selectedAddress: string;
  isMetamask: boolean;
  chainId: string;
}

export interface TrustWalletProvider {
  isWalletConnect: boolean;
  accounts: string[];
}

export interface ZilRPCResponse {
  result: string;
}

declare global {
  interface Window {
    ethereum: any;
    keplr: any;
    leap: any;
    zilPay: any;
    NEOLine: any;
    boltX: any;
    web3: any;
  }
}
