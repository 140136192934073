import React from "react"
import "./SWTH.css"
import CoinIcon from "./CoinIcon"

const SWTHBSC:React.FC = () => {
  return (<div className="SWTH-compound-token">
    <CoinIcon denom="SWTH"/>
    <div className="SWTH-chain-box">
      <CoinIcon denom="BNB"/>
    </div>
  </div>);
};

export default SWTHBSC;
