import BigNumber from "bignumber.js";
import { BN_ZERO } from "constants/math";

export function parseNumber(number: BigNumber | string | null | number = '0', defaultValue?: BigNumber): BigNumber | null {
  const bnNumber = new BigNumber(number?.toString() ?? '0')
  if (bnNumber.isNaN() || !bnNumber.isFinite()) {
    return defaultValue || null
  }
  return bnNumber
}

export const bnOrZero = (input?: string | BigNumber | number, defaultValue: BigNumber = BN_ZERO) => {
  return parseBN(input, defaultValue)!;
};


export const parseBN = (input?: string | BigNumber | number, defaultValue?: BigNumber) => {
  if (!input && input !== 0) return defaultValue;
  const result = BigNumber.isBigNumber(input) ? input : new BigNumber(input);
  if (!result.isFinite() || result.isNaN())
    return defaultValue;

  return result;
};

export const formatNumberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const SHIFT_DECIMALS = 18

export const SECONDS_PER_DAY = 86400
export const SECONDS_PER_YEAR = 31536000

export const adjustHuman = (value: string): BigNumber => bnOrZero(value).shiftedBy(-SHIFT_DECIMALS);

export function adjustValueToGwei(value: string, precision: number): string {
  const valBN = new BigNumber(value)
  return valBN.shiftedBy(precision).toFixed(0, BigNumber.ROUND_DOWN)
}