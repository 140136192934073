import ChevronsLeft from "assets/ChevronsLeft.svg"
import ChevronsRight from "assets/ChevronsRight.svg"
import BigNumber from "bignumber.js"
import { CarbonSDK, Models } from "carbon-js-sdk"
import { QueryAllTransactionRequest } from "carbon-js-sdk/lib/codec/Switcheo/carbon/misc/query"
import { ChainNames } from "constants/types"
import dayjs from "dayjs"
import { useAppSelector, useAsyncTask } from "hooks"
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { CarbonEVMTransaction, ConvertCoinMessage, ConvertErc20Message } from "store/Wallet"
import { SimpleMap } from "utils"
import { CarbonEVMBridgeHistoryCards, CarbonEVMBridgeHistoryTable } from "."
import { generatePageNumbers } from "./utils"


interface Props {
  setShowBridge: Dispatch<SetStateAction<boolean>>
}

const convertMsgTypes = ['/Switcheo.carbon.erc20.MsgConvertERC20', '/Switcheo.carbon.erc20.MsgConvertCoin']
const itemsPerPage = 5

const parseTransactionMessage = (transaction: Models.Carbon.Misc.Transaction,
  tokenPairs: Models.Carbon.Erc20.TokenPair[],
  evmHexAddress: string,
  tokenInfo: { [x: string]: Models.Carbon.Coin.Token },
  tokenPrices: SimpleMap<number>
): CarbonEVMTransaction | null => {

  const { blockCreatedAt, code, messages } = transaction
  const { hash, message, messageType } = messages[0]
  if (messageType?.messageType === '/Switcheo.carbon.erc20.MsgConvertERC20') {
    const { amount, receiver, contract_address: contractAddress }: ConvertErc20Message = JSON.parse(message)
    const denom = tokenPairs.find((tokenPair) => tokenPair.erc20Address === contractAddress)?.denom
    const token = tokenInfo?.[denom ?? '']
    const tokenPrice = tokenPrices[denom ?? '']
    if (!denom || !token || !tokenPrice) return null
    const humanAmount = new BigNumber(amount).shiftedBy(-token.decimals)
    const amountUSD = humanAmount.multipliedBy(tokenPrice).toString()
    const convertTransaction: CarbonEVMTransaction = {
      dateTime: dayjs(blockCreatedAt),
      senderAddress: evmHexAddress,
      receiverAddress: receiver,
      amount: humanAmount.toString(),
      token: token.symbol,
      amountUSD,
      senderChain: ChainNames.CarbonEVM,
      receiverChain: ChainNames.CarbonCore,
      hash,
      status: code === 0 ? 'Success' : 'Failed',
    }
    return convertTransaction
  } else { // /Switcheo.carbon.erc20.MsgConvertCoin'
    const { coin, sender, receiver }: ConvertCoinMessage = JSON.parse(message)
    const { amount, denom } = coin
    const token = tokenInfo?.[denom]
    const tokenPrice = tokenPrices[denom]
    if (!token || !tokenPrice) return null
    const humanAmount = new BigNumber(amount).shiftedBy(-token.decimals)
    const amountUSD = humanAmount.multipliedBy(tokenPrice).toString()
    const convertTransaction: CarbonEVMTransaction = {
      dateTime: dayjs(blockCreatedAt),
      senderAddress: sender,
      receiverAddress: receiver,
      amount: humanAmount.toString(),
      token: token.symbol,
      amountUSD,
      senderChain: ChainNames.CarbonCore,
      receiverChain: ChainNames.CarbonEVM,
      hash,
      status: code === 0 ? 'Success' : 'Failed',
    }
    return convertTransaction
  }

}

const CarbonEVMBridgeHistory: React.FC<Props> = ({ setShowBridge }) => {
  const [runLoadTransactions, loadingTransactions] = useAsyncTask()
  const [allCarbonEVMTransfers, setAllCarbonEVMTransfers] = useState<CarbonEVMTransaction[]>([])
  const [page, setPage] = useState(1);
  const wallet = useAppSelector(state => state.wallet.senderCarbonWallet)
  const tokenInfo = useAppSelector(state => state.wallet.tokenInfo)
  const sdk = useAppSelector(state => state.app.carbonSDK) as CarbonSDK | null
  const tokenPairsMap = useAppSelector(state => state.wallet.tokenPairsMap)
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const bech32Address = wallet?.bech32Address
  const queryClient = sdk?.query
  const evmHexAddress = wallet?.evmHexAddress
  const maxPages = Math.ceil(allCarbonEVMTransfers.length / itemsPerPage)

  const decreasePageHandler = (): void => {
    setPage((currentPage) => {
      if (currentPage > 1) {
        window.scrollTo(0, 0)
        return currentPage - 1
      }
      return currentPage
    })
  }

  const increasePageHandler = (): void => {
    setPage((currentPage) => {
      if (currentPage < maxPages) {
        window.scrollTo(0, 0)
        return currentPage + 1
      }
      return currentPage
    })
  }

  const loadTransactions = useCallback((): void => {
    const tokenPairs = Object.values(tokenPairsMap ?? {})
    if (!bech32Address || !evmHexAddress || !queryClient || tokenPairs.length === 0 || !tokenInfo) {
      return
    }

    runLoadTransactions(async () => {
      const requestParams = QueryAllTransactionRequest.fromPartial({
        address: bech32Address,
        msgTypeFilters: convertMsgTypes,
      })

      // Refactor if conversion between different wallets becomes implemented
      // Currently, sender for ConvertERC20 (Carbon EVM -> Carbon Core) is hardcoded to the connected wallet's evm hex address
      const { transactions } = await queryClient.misc.TransactionAll(requestParams)
      const convertTransactions: CarbonEVMTransaction[] = []
      transactions.forEach((transaction) => {
        const convertTransaction = parseTransactionMessage(transaction, tokenPairs, evmHexAddress, tokenInfo, tokenPrices)
        if (convertTransaction) {
          convertTransactions.push(convertTransaction)
        }
      })
      setAllCarbonEVMTransfers(convertTransactions)
    })
  }, [bech32Address, evmHexAddress, queryClient, runLoadTransactions, tokenInfo, tokenPairsMap, tokenPrices])

  useEffect(() => {
    loadTransactions()
  }, [loadTransactions])

  const carbonEVMTransfers = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return allCarbonEVMTransfers.slice(startIndex, endIndex)
  }, [page, allCarbonEVMTransfers])

  return (
    <div>
      <div className="bridge-history-table-wrapper">
        <CarbonEVMBridgeHistoryTable
          bridgeHistoryData={carbonEVMTransfers}
          walletAddress={bech32Address ?? ""}
          setShowBridge={setShowBridge}
          loading={loadingTransactions}
        />
      </div>
      <div className="bridge-history-cards-wrapper">
        <CarbonEVMBridgeHistoryCards
          bridgeHistoryData={carbonEVMTransfers}
          walletAddress={bech32Address ?? ""}
          setShowBridge={setShowBridge}
          loading={loadingTransactions}
        />
      </div>
      {allCarbonEVMTransfers.length > 0 && (
        <div className="bridge-history-pagination-wrapper">
          <img
            src={ChevronsLeft}
            alt="Chevrons Left"
            onClick={decreasePageHandler}
          />
          {generatePageNumbers(page, maxPages).map((buttonContent, index) => {
            if (Number.isInteger(buttonContent)) {
              return (
                <span className={page === buttonContent ? "bridge-history-pagination-active" : ""} onClick={() => { setPage(buttonContent as number); window.scrollTo(0, 0) }} key={index}>
                  {buttonContent}
                </span>
              )
            } else {
              return (
                <span className="bridge-history-pagination-dots" key={index}>...</span>
              )
            }
          })}
          <img src={ChevronsRight} alt="Chevrons Right" onClick={increasePageHandler} />
        </div>
      )}
    </div>
  )
}

export default CarbonEVMBridgeHistory