import React from "react";
import PopupWindow from "./PopupWindow";
import InProgressDots from "components/Common/InProgressDots";
import { useAppSelector } from "hooks";
// import Click from "assets/Click.svg";

const AftSignPopupAllowance: React.FC = () => {
  const popupState = useAppSelector(state => state.ui.popup)
  return (
    <PopupWindow
      headerText="Approval In Progress"
      bottomButtonText={popupState.buttonText}
      message="Your tokens are being approved!"
      transactionLink={popupState.transactionLink}
    >
      <InProgressDots />
    </PopupWindow>
  );
};

export default AftSignPopupAllowance;
