import { CarbonSDK } from 'carbon-js-sdk';
import { PageRequest } from 'carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination';
import { BN_ZERO } from 'constants/math';
import Long from "long";
import { put } from 'redux-saga/effects';
import { addBackgroundLoading, removeBackgroundLoading } from 'store/LoadingTask';
import { TokenAmount, uuidv4 } from 'utils';

export function* runSagaTask<T>(taskname: string, task: () => Generator<unknown, T>, handleError?: (error: Error) => Generator<unknown, T>) {
  let taskUuid = uuidv4()
  try {
    yield put(addBackgroundLoading({ taskName: taskname, taskUuid: taskUuid }))
    const result: T = yield task();
    return result;
  } catch (error) {
    // TODO: throw error
    if (handleError) {
      yield handleError(error as Error)
    } else {
      console.error(error)
    }
  } finally {
    yield put(removeBackgroundLoading({ taskUuid: taskUuid }))
  }
}

export const defaultPagination = PageRequest.fromPartial({
  limit: new Long(10000),
  offset: Long.UZERO,
  key: new Uint8Array(),
  countTotal: false,
  reverse: false,
})

export const getUsd = (sdk: CarbonSDK | null, coin: TokenAmount) => {
  const tokenPrice = sdk?.token.getUSDValue(coin.denom) ?? BN_ZERO
  const decimals = sdk?.token.getDecimals(coin.denom) ?? 0
  return coin.amount.shiftedBy(-decimals).times(tokenPrice)
}
