import React, { useState } from "react";
import SWTH from "assets/SWTH-Blue-Small.svg";
import SWTHBranding from "assets/SWTH-Branding-Small.svg";
import "./ExternalLink.css"
import { NavLink } from "react-router-dom";

type Props = {
  text: string;
  link: string;
  style?: React.CSSProperties;
  gap?: number;
  address?: boolean;
}

const ExternalLink:React.FC<Props> = ({ text, link, style, gap, address = false }) => {
  const [hovered, setHovered] = useState<boolean>(false);

  let processedText = text;
  let processedTextEnd = "";
  if (address) {
    processedText = `${text.substring(0,5)}...`;
    processedTextEnd = text.substring(text.length-5);
  }

  const internalLink: boolean = link.startsWith("/");
  if (internalLink) {
    return (
      <NavLink className="external-link-component link" style={style} to={link} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <span>{processedText}</span>
        <span style={{ overflow: "hidden", direction: "rtl", whiteSpace: "nowrap" }}>{processedTextEnd}</span>
        <img src={hovered ? SWTHBranding : SWTH} style={{ marginLeft: `${gap ? gap : "6"}px` }}alt="Link"/>
      </NavLink>
    );
  } else {
    return (
      <a className="external-link-component link" style={style} href={link} target="_blank" rel="noopener noreferrer" onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <span>{processedText}</span>
        <span style={{ overflow: "hidden", direction: "rtl", whiteSpace: "nowrap" }}>{processedTextEnd}</span>
        <img src={hovered ? SWTHBranding : SWTH} style={{ marginLeft: `${gap ? gap : "6"}px` }}alt="Link"/>
      </a>
    );
  }
};

export default ExternalLink;
