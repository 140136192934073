import React, { Dispatch, SetStateAction } from "react";
import { CrosschainTransaction } from "store/Wallet";

import Loading_Light from "assets/Loading_light.gif";
import BridgeHistoryCard from "./CrosschainBridgeHistoryCard";
import BridgeHistoryError from "../../BridgeHistoryError";
import MobileWarningCard from "components/Common/MobileWarningCard";

import "../BridgeHistoryCards.css";

interface Props {
  walletAddress: string | null;
  loading: boolean;
  bridgeHistoryData: CrosschainTransaction[];
  setShowBridge: Dispatch<SetStateAction<boolean>>;
};

const CrosschainBridgeHistoryCards: React.FC<Props> = ({
  walletAddress,
  loading,
  bridgeHistoryData,
  setShowBridge,
}) => {
  return (
    <>
      {walletAddress ? (
        loading ? (
          <div className="bridge-history-card">
            <img src={Loading_Light} alt="Loading Light" />
          </div>
        ) : bridgeHistoryData.length ? <>
          <MobileWarningCard type="Neutral" text="If you've just completed a transaction, it may take a while to show up here." />
          {
          bridgeHistoryData.map((historyDataItem) => (
            <BridgeHistoryCard
              historyDataItem={historyDataItem}
              key={historyDataItem.id}
            />
          ))} </> : (
          <div className="bridge-history-card">
            <BridgeHistoryError
              width={310}
              setShowBridge={setShowBridge}
              mainText="There are no records at the moment. If you've just completed a transaction, it may take a while to show."
              buttonText="Bridge Now"
            />
          </div>
        )
      ) : (
        <div className="bridge-history-card">
          <BridgeHistoryError
            width={310}
            setShowBridge={setShowBridge}
            mainText="Connect wallet(s) on the Bridge page to view your bridge history."
            buttonText="Connect Wallets on Bridge"
          />
        </div>
      )}
    </>
  );
};

export default CrosschainBridgeHistoryCards;
