import Demex from 'assets/ExchangesIcons/Demex.svg';
import Zilswap from 'assets/ExchangesIcons/ZilSwap.svg';
// import Sushiswap from 'assets/ExchangesIcons/SushiSwap.svg';
import Osmosis from 'assets/ExchangesIcons/Osmosis.svg';
import Uniswap from 'assets/ExchangesIcons/Uniswap.svg';
import Loading_Light from "assets/Loading_light.gif";
import DropDown from 'assets/TableDropDown.svg';
import { BscCard, CarbonCard, EthCard, Neo2Card, Neo3Card, OsmosisCard, ZilCard } from 'components/Common/ChainCards';
import Tooltip from 'components/Common/Tooltip';
import { useAppSelector } from 'hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ExchangesTable.css';
import { bnOrZero } from 'utils';

type exchangesData = {
  name: string
  logo: string
  type: JSX.Element
  liquidity: number
  volume: number
  fees: number
  pair: string
  chain: JSX.Element
  nameLink?: string
  guideLink: string
  pairLink: string
  noApi: boolean
}

const DEX = <div className='exchange-card'>DEX</div>;

const ExchangesTable: React.FC = () => {

  const transformNumber = (input: number) => {
    if (bnOrZero(input).eq(0)) {
      return "-";
    }
    let prefix = "$";
    return prefix + bnOrZero(input).toFormat(2);
  }
  const tokenPrices = useAppSelector(state => state.tokenPrices.value);
  const marketStats = useAppSelector(state => state.marketStats);

  const {
    tableData
  } = useMemo(() => {
    const zilPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["zilliqa"];
    const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"];
    const ethPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["eth"];
    const usdPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["usd"];

    const demex_usd_volume = marketStats.demexStats.usd_volume;
    const demex_eth_volume = marketStats.demexStats.eth_volume;
    const demex_usd_liquidity = marketStats.demexStats.usd_liquidity_in * usdPrice + marketStats.demexStats.usd_liquidity_out * swthPrice;
    const demex_eth_liquidity = marketStats.demexStats.eth_liquidity_in * ethPrice + marketStats.demexStats.eth_liquidity_out * swthPrice;
    const zilVolume = marketStats.zilStats.volume * zilPrice;
    const zilLiquidity = marketStats.zilStats.liquidity * zilPrice;
    // const sushiVolume = marketStats.sushiStats.volume;
    // const sushiLiquidity = marketStats.sushiStats.liquidity;
    const uniVolume = marketStats.uniStats.volume;
    const uniLiquidity = marketStats.uniStats.liquidity;
    const osmosisVolume = marketStats.osmosisStats.volume;
    const osmosisLiquidity = marketStats.osmosisStats.liquidity;


    const tableData = [
      {
        name: "Demex", logo: Demex, nameLink: 'https://dem.exchange', type: DEX, liquidity: demex_usd_liquidity, volume: demex_usd_volume, pair: "SWTH / USD",
        pairLink: "https://app.dem.exchange/trade/SWTH%20/%20USD",
        chain: <div className='chain'>
          {BscCard} {CarbonCard} {EthCard} {ZilCard} {Neo2Card} {Neo3Card}
        </div>, fees: 0.25,
        guideLink: 'https://guide.dem.exchange/products/trading-spot-markets',
        noApi: false,
      },
      {
        name: "Demex", logo: Demex, nameLink: 'https://dem.exchange', type: DEX, liquidity: demex_eth_liquidity, volume: demex_eth_volume, pair: "SWTH / ETH",
        pairLink: 'https://app.dem.exchange/trade/SWTH%20/%20ETH',
        chain: <div className='chain'>
          {BscCard} {CarbonCard} {EthCard} {ZilCard} {Neo2Card} {Neo3Card}
        </div>, fees: 0.25,
        guideLink: 'https://guide.dem.exchange/products/trading-spot-markets',
        noApi: false,
      },
      {
        name: "ZilSwap", logo: Zilswap, nameLink: 'https://zilswap.io/', type: DEX, liquidity: zilLiquidity, volume: zilVolume, pair: "SWTH / ZIL",
        pairLink: 'https://zilswap.io/swap?tokenIn=zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz&tokenOut=zil1yk93f957fanapf0yszgm84p62xrxxfytj4d2tl',
        chain: <div className='chain'>
          {ZilCard}
        </div>, fees: 0.3,
        guideLink: 'https://docs.zilswap.io/how-to/swap',
        noApi: false,
      },
      // {
      //   name: "SushiSwap", logo: Sushiswap, nameLink: 'https://www.sushi.com', type: DEX, liquidity: sushiLiquidity, volume: sushiVolume, pair: "SWTH / WETH",
      //   pairLink: 'https://app.sushi.com/swap?inputCurrency=0xb4371da53140417cbb3362055374b10d97e420bb&outputCurrency=ETH',
      //   chain: <div className='chain'>
      //     {EthCard}
      //   </div>, fees: 0.3,
      //   guideLink: 'https://docs.sushi.com/how-to-get-started-on-sushi/how-to-swap'
      // },
      {
        name: "UniSwap", logo: Uniswap, nameLink: 'https://uniswap.org/', type: DEX, liquidity: uniLiquidity, volume: uniVolume, pair: "SWTH / ETH",
        pairLink: 'https://app.uniswap.org/#/swap?outputCurrency=0xb4371da53140417cbb3362055374b10d97e420bb&inputCurrency=ETH',
        chain: <div className='chain'>
          {EthCard}
        </div>, fees: 0.3,
        guideLink: 'https://support.uniswap.org/hc/en-us/articles/8370549680909-How-to-Swap-Tokens-',
        noApi: false,
      },
      {
        name: "Osmosis", logo: Osmosis, type: DEX, liquidity: osmosisLiquidity, volume: osmosisVolume, pair: "SWTH / OSMO",
        pairLink: 'https://app.osmosis.zone/?from=OSMO&to=SWTH',
        chain: <div className='chain'>
          {OsmosisCard}
        </div>, fees: 0.2,
        guideLink: 'https://docs.osmosis.zone/overview/osmosis-app/#swapping-tokens',
        noApi: false,
      }
    ];
    return { tableData };
  }, [marketStats, tokenPrices]);


  const [exchanges, setExchanges] = useState(tableData);
  const [order, setOrder] = useState(-1);
  const [orderType, setOrderType] = useState('liquidity');

  useEffect(() => {
    setExchanges(tableData);
  }, [tableData]);

  const sortByLiquidity = (tableData: exchangesData[], order: number) => {
    return tableData.sort((a: exchangesData, b: exchangesData) => {
      const liquidity_a = a.liquidity
      const liquidity_b = b.liquidity
      return order * (liquidity_a - liquidity_b)
    })
  }

  const sortByVolume = (tableData: exchangesData[], order: number) => {
    return tableData.sort((a: exchangesData, b: exchangesData) => {
      const volume_a = a.volume
      const volume_b = b.volume
      return order * (volume_a - volume_b)
    })
  }

  const sortByFees = (tableData: exchangesData[], order: number) => {
    return tableData.sort((a: exchangesData, b: exchangesData) => {
      const fees_a = a.fees
      const fees_b = b.fees
      return order * (fees_a - fees_b)
    })
  }

  const sortOrder = useCallback(
    (option: string, order: number) => {
      const results = tableData;

      switch (option) {
        case 'liquidity': {
          const sortedExchange = sortByLiquidity(results, order)
          setExchanges([...sortedExchange])
          break
        }
        case 'volume': {
          const sortedExchange = sortByVolume(results, order)
          setExchanges([...sortedExchange])
          break
        }
        case 'fees': {
          const sortedExchange = sortByFees(results, order)
          setExchanges([...sortedExchange])
          break
        }
        
        default: {
          setExchanges([...results]);
          break;
        }
      }
    }, [tableData]
  );

  useEffect(() => {
    sortOrder(orderType, order)
  }, [order, orderType, sortOrder])


  const changeOrder = (type: string) => {
    if (type !== orderType) {
      setOrder(-1); // descending order
      setOrderType(type);
      return;
    }
    if (order === 0) {
      setOrder(-1); // descending order
    } else if (order === -1) {
      setOrder(1); // ascending order
    } else {
      setOrder(0);
      setExchanges(tableData);
    }
  }

  return (
    <div className="exchanges-table-wrapper theme-color">
      <table className="exchanges-table-inner-wrapper">
        <thead className='table-head'>
          <tr>
            <th style={{ padding: '0 25px' }}>Exchange</th>
            <th align="right"> <div className='exchange-header-button' onClick={() => changeOrder("liquidity")}>Liquidity
              {orderType === 'liquidity' && order !== 0 ? <img src={DropDown} alt={"dropdown"}
                className={`dropdown-icon ${order === 1 ? 'reversed' : undefined}`}></img> : undefined}
            </div>  </th>
            <th align="right">
              <div className='exchange-header-button' onClick={() => changeOrder("volume")}>24H Volume
                {orderType === 'volume' && order !== 0 ? <img src={DropDown} alt={"dropdown"}
                  className={`dropdown-icon ${order === 1 ? 'reversed' : undefined}`}></img> : undefined}
              </div>
            </th>
            <th align="right"><div className='exchange-header-button' onClick={() => changeOrder("fees")}>Taker Fees
              {orderType === 'fees' && order !== 0 ? <img src={DropDown} alt={"dropdown"}
                className={`dropdown-icon ${order === 1 ? 'reversed' : undefined}`}></img> : undefined}
            </div>
            </th>
            <th>Pair</th>
            <th>Chain</th>
          </tr>
        </thead>
        <tbody>
          {exchanges.map((exchange: exchangesData) =>
          (
            <tr key={exchange.name + exchange.pair} style={{ border: 'none' }}>
              <td style={{ padding: '0 20px' }}>
                <div style={{ display: 'flex', color: 'inherit', fontWeight: 600, fontSize: '16px', fontFamily: 'Tyros Pro' }}>
                  <img src={exchange.logo} alt="logo" style={{ marginRight: '8px', marginLeft: '5px' }} />
                  <div>
                    <span style={{ fontSize: '14px' }}>{exchange.name}</span>  {exchange.type}
                  </div>
                </div>
              </td>
              <td>  {
                (!exchange.noApi && (exchange.liquidity !== 0 || exchange.volume !== 0)) ? transformNumber(exchange.liquidity) : <img src={Loading_Light} alt="Loading Light" height="15px" width="15px" />
              } </td>
              <td>  {
                (!exchange.noApi && (exchange.liquidity !== 0 || exchange.volume !== 0)) ? transformNumber(exchange.volume) : <img src={Loading_Light} alt="Loading Light" height="15px" width="15px" />
              } </td>
              <td>  {exchange.fees}% </td>
              <td> {exchange.pair}</td>
              <td> {exchange.chain}
                <div className='more-info'>
                  <a href={exchange.pairLink} target="_blank" rel="noopener noreferrer" style={{ fontFamily: 'tyros pro', fontSize: '14px', fontWeight: 'bold' }} className="link">Buy</a> 
                  <Tooltip hoverBoxStyle={{"height": "52px"}}>
                    <span>Need Some help?</span>
                    <span>Read the <a className='underline' style={{ color: '#3A78B1' }} href={exchange.guideLink} target="_blank" rel="noopener noreferrer">Step-by-step</a> Guide</span>
                  </Tooltip>
                </div>
              </td>
            </tr>
          )
          )}
        </tbody>
      </table>
    </div >);
};

export default ExchangesTable;
