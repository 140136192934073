import { Erc20ActionTypes } from "store/Erc20";
import {
  WalletActionTypes,
  ApproveAllowanceSchema,
  InitWalletSchema,
  BridgeEvmTokenSchema,
  BridgeCarbonTokenSchema,
  BridgeZilTokenSchema,
  BridgeNeoTokenSchema,
  ConnectEvmSchema,
  ConnectWalletSchema,
  ConnectNeoWalletSchema,
  DisconnectWalletSchema,
  ConvertTokenSchema,
  ConvertEVMTokenSchema
} from "./types";

export const approveAllowanceAction = (payload: ApproveAllowanceSchema) => ({
  type: WalletActionTypes.WALLET_APPROVE_ALLOWANCE,
  payload
});

export const initWalletAction = (payload: InitWalletSchema) => ({
  type: WalletActionTypes.WALLET_INIT_WALLET,
  payload
});

export const changeNetworkAction = () => ({
  type: WalletActionTypes.WALLET_CHANGE_NETWORK,
});

export const bridgeEvmTokenAction = (payload: BridgeEvmTokenSchema) => ({
  type: WalletActionTypes.WALLET_EVM_TOKENS,
  payload,
});

export const bridgeCarbonTokenAction = (payload: BridgeCarbonTokenSchema) => ({
  type: WalletActionTypes.WALLET_CARBON_TOKENS,
  payload,
});

export const bridgeZilTokenAction = (payload: BridgeZilTokenSchema) => ({
  type: WalletActionTypes.WALLET_ZIL_TOKENS,
  payload,
})

export const bridgeNeoTokenAction = (payload: BridgeNeoTokenSchema) => ({
  type: WalletActionTypes.WALLET_NEO_TOKENS,
  payload,
})

export const connectMetamaskAction = (payload: ConnectEvmSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_METAMASK,
  payload,
});

export const connectCarbonMetamaskAction = (payload: ConnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_CARBON_METAMASK,
  payload,
})

export const connectTrustWalletAction = (payload: ConnectEvmSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_TRUSTWALLET,
  payload,
});

export const connectEthLedgerAction = (payload: ConnectEvmSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_ETH_LEDGER,
  payload,
})

export const connectKeplrAction = (payload: ConnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_KEPLR,
  payload,
});

export const connectLeapAction = (payload: ConnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_LEAP,
  payload,
})

export const connectCosmosLedgerAction = (payload: ConnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_COSMOS_LEDGER,
  payload,
});

export const connectZilPayAction = (payload: ConnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_ZIL,
  payload,
});

export const connectBoltXAction = (payload: ConnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_BOLTX,
  payload,
});

export const connectNeolineAction = (payload: ConnectNeoWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_NEOLINE,
  payload,
});

export const connectO3Action = (payload: ConnectNeoWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_O3,
  payload,
});

export const connectNeoLedgerAction = (payload: ConnectNeoWalletSchema) => ({
  type: WalletActionTypes.WALLET_CONNECT_NEO_LEDGER,
  payload,
});

export const convertCarbonTokensAction = (payload: ConvertTokenSchema ) => ({
  type: WalletActionTypes.WALLET_CONVERT_CARBON_TOKENS,
  payload,
})

export const convertEVMTokensAction = (payload: ConvertEVMTokenSchema ) => ({
  type: WalletActionTypes.WALLET_CONVERT_EVM_TOKENS,
  payload,
})

export const disconnectWalletAction = (payload: DisconnectWalletSchema) => ({
  type: WalletActionTypes.WALLET_DISCONNECT_WALLET,
  payload,
})

export const fetchTokenPairs = () => ({
  type: Erc20ActionTypes.QUERY_TOKEN_PAIRS,
})
