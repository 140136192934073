import { Dispatch, SetStateAction } from "react";
import BridgeHistoryError from "../BridgeHistoryError"


interface Props {
  setShowBridge: Dispatch<SetStateAction<boolean>>
  mainText: string
  buttonText: string
}

const BridgeHistoryErrorCell: React.FC<Props> = ({ setShowBridge, mainText, buttonText }) => {
  return (<tr>
    <td colSpan={6} className="bridge-table-connect-wrapper">
      <BridgeHistoryError width={384} mainText={mainText} buttonText={buttonText} setShowBridge={setShowBridge} />
    </td>
  </tr>);
}

export default BridgeHistoryErrorCell