import { CarbonSDK } from 'carbon-js-sdk'
import { uuidv4 } from 'utils'
import { CustomNodeItem } from 'utils/nodes'


export const getLocalStorageNetwork = (): CarbonSDK.Network => {
    const storedNet = localStorage.getItem("network")
    if (storedNet) {
        try {
            const net = CarbonSDK.parseNetwork(storedNet, CarbonSDK.Network.MainNet)
            if (net && Object.values(CarbonSDK.Network).includes(net)) {
                return net as CarbonSDK.Network
            }
        } catch (error) {
            console.error(error)
        }
    }
    return CarbonSDK.Network.MainNet
}

export const safeParseStoredValue = <T = unknown>(key: string | null, backup?: T) => {
    if (!key) {
        return backup
    }

    try {
        return JSON.parse(key)
    } catch (error) {
        return backup
    }
}

export const processNodeArr = (nodeList: any): CustomNodeItem[] => {
    return nodeList.reduce((prev: CustomNodeItem[], node: any) => {
      const newPrev = prev
      const newNode = node
      // if not object, omit from array
      if (!(newNode instanceof Object)) {
        return prev
      }
      // if not old CustomNodeItem, omit from array
      if (!(
        Object.prototype.hasOwnProperty.call(newNode, 'appBuild')
        && Object.prototype.hasOwnProperty.call(newNode, 'moniker')
        && Object.prototype.hasOwnProperty.call(newNode, 'rpcUrl')
        && Object.prototype.hasOwnProperty.call(newNode, 'restUrl')
        && Object.prototype.hasOwnProperty.call(newNode, 'wsUrl')
        && Object.prototype.hasOwnProperty.call(newNode, 'faucetUrl')
        && Object.prototype.hasOwnProperty.call(newNode, 'insightsUrl')
      )) {
        return prev
      }
      // add nodeId and tmWsUrl if not added
      if (!newNode.nodeId) {
        newNode.nodeId = uuidv4()
      }
      if (!newNode.tmWsUrl) {
        newNode.tmWsUrl = ''
      }
      newPrev.push(newNode as CustomNodeItem)
      return newPrev
    }, [])
  }