import Loading_Light from "assets/Loading_light.gif"

const BridgeHistoryLoading: React.FC = () => {
  return (
    <tr>
      <td colSpan={6} className="bridge-history-loading-wrapper">
        <div className="bridge-history-loading-inner-wrapper">
          <img src={Loading_Light} alt="Loading Light" />
        </div>
      </td>
    </tr>
  )
}

export default BridgeHistoryLoading