import Loading_Light from "assets/Loading_light.gif"
import ExternalLink from "components/Common/ExternalLink"
import { useAppSelector } from "hooks"
import React from "react"
import "./StakeInfo.css"

import Tooltip from 'components/Common/Tooltip'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import { StakeTasks } from 'store/Stake/types'
import { SecondStakeInfoCard } from "./SecondStakeInfoCard"
import { BN_ZERO } from 'constants/math'

type Props = {
  onStakeNow: () => void
}

const StakeInfo: React.FC<Props> = ({ onStakeNow }) => {

  const aprState = useAppSelector(state => state.stake.aprStats)
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const sdk = useAppSelector(state => state.app.carbonSDK)
  const [aprLoading] = useTaskSubscriber(
    StakeTasks.AprStats,
  )
  const [validatorsLoading] = useTaskSubscriber(
    StakeTasks.Validators
  )
  const [totalStakedLoading] = useTaskSubscriber(
    StakeTasks.TotalStaked
  )
  const [totalSupplyLoading] = useTaskSubscriber(
    StakeTasks.TotalSupply
  )
  const totalStaked = useAppSelector(state => state.stake.totalStaked)
  const totalSupply = useAppSelector(state => state.stake.totalSupply)
  const bondedPercent = totalStaked.div(totalSupply).isFinite() ? totalStaked.div(totalSupply).multipliedBy(100) : BN_ZERO
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]

  let active = !!sdk?.wallet ? true : false


  return (
    <div className="stake-info">
      <div className="stake-info-headers">
        <p className="bolded-700">SWTH Staking</p>
        <div style={{ display: "flex", gap: "24px" }}>
          <ExternalLink text="SWTH Tokenomics" link="https://guide.carbon.network/swth/tokenomics" />
          <ExternalLink text="Staking FAQ" link="/stake/faq" />
        </div>
      </div>
      <div className="stake-info-cards">
        <div className="stake-info-card-1">
          <div className="stake-info-title">
            <p>APY</p>
            <Tooltip hoverBoxStyle={{ height: "max-content", width: "250px", transform: "translateX(-30%)" }}>
              <span>The APY for staking shown here only reflects SWTH emissions. For more information on staking rewards, please refer to the{" "}
                <a className='underline' style={{ color: '#3A78B1' }} href="https://guide.carbon.network/swth/stake-swth" target="_blank" rel="noopener noreferrer">
                  Staking Rewards
                </a>
                {" "}section.
              </span>

            </Tooltip>
          </div>
          <p className="stake-info-description-primary">{aprLoading || validatorsLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading_Dark" /> : `${aprState.apr.times(100).toFormat(2).toString()}%`}</p>

          <div className="stake-info-title" style={{ marginTop: "16px" }}>
            <p>Bonded</p>
            <Tooltip hoverBoxStyle={{ height: "max-content", width: "200px", transform: "translateX(-30%)", fontSize: "16px", lineHeight: "24px" }}>
              <span>Total SWTH Bonded: {totalStaked.toFormat(0)}</span>
            </Tooltip>
            </div>
          <p className="stake-info-description-primary">
            {totalStakedLoading || totalSupplyLoading
              ?
              <img src={Loading_Light} className="loading-icon" alt="Loading_Dark" />
              :
              `${bondedPercent.toFormat(2)}%`
            }
          </p>
          <p className="stake-info-description-secondary">{totalStakedLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> :  `$${totalStaked.times(swthPrice).toFormat(2)}`}</p>
        </div>
        <SecondStakeInfoCard onStakeNow={onStakeNow} active={active} />
      </div>
    </div>
  )
}

export default StakeInfo
