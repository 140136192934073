import { CarbonSDK } from "carbon-js-sdk"
import { ChainNames, Network } from "constants/types"

export const getNetworkMap = (network: CarbonSDK.Network) => {
  const mainnetMap: Map<ChainNames, Network> = new Map([
    [ChainNames.Eth, Network.EthMainnet],
    [ChainNames.Bsc, Network.BSCMainnet],
    [ChainNames.Arbitrum, Network.ArbitrumOne],
    [ChainNames.CarbonEVM, Network.CarbonEVM],
  ]);
  const testnetMap: Map<ChainNames, Network> = new Map([
    [ChainNames.Eth, Network.EthGoerli],
    [ChainNames.Bsc, Network.BSCTestnet],
  ])
  const devnetMap: Map<ChainNames, Network> = new Map([
    [ChainNames.Eth, Network.EthRinkeby],
    [ChainNames.Bsc, Network.BSCTestnet],
  ]);

  switch (network) {
    case CarbonSDK.Network.MainNet:
      return mainnetMap
    case CarbonSDK.Network.TestNet:
      return testnetMap
    case CarbonSDK.Network.DevNet:
      return devnetMap
    default:
      return mainnetMap
  }
}

export const getETHClient = (sdk: CarbonSDK, chain: ChainNames, network: CarbonSDK.Network) => {
  if (network === CarbonSDK.Network.DevNet) {
    return sdk.eth; // using SWC5 on rinkeby to simulate BSC for bridge feature
  } else if (chain === ChainNames.Bsc) {
    return sdk.bsc;
  } else if (chain === ChainNames.Arbitrum) {
    return sdk.arbitrum;
  } else {
    return sdk.eth
  }
}

export const isEvm = (...args: ChainNames[]) => {
  for (let chain of args) {
    if (chain !== ChainNames.Eth && chain !== ChainNames.Bsc && chain !== ChainNames.Arbitrum) {
      return false;
    }
  }
  return true;
}

export const isEthAddress = (...args: (string | null)[]) => {
  for (let address of args) {
    if (!address || !address.match(/^0x[a-zA-Z0-9]{40}$/)) {
      return false;
    }
  }
  return true;
}
