import React from 'react';
import { NavLink } from "react-router-dom";
import './Footer.css';
import Carbon from 'assets/Carbon_Footer_Desktop.svg';
import IconSet from './Common/IconSet';
const Footer: React.FC = () => {
  return (
    <div className="footer-wrapper">
      <div className='footer-nav'>
        <div className='footer-nav-1'>
          <NavLink to="/migrate" className="footer-link">Migrate</NavLink>
          <NavLink to="/buy" className="footer-link">Buy</NavLink>
          <NavLink to="/bridge" className="footer-link">Bridge</NavLink>
          <NavLink to="/stake" className="footer-link">Stake</NavLink></div>
        <div className='footer-nav-2'>
          <a className='footer-link' href={'https://carbon.network/'} target="_blank" rel="noopener noreferrer">
            About Carbon&nbsp;
          </a>
          <div className='icon-set-mobile'> <IconSet color='white' /></div></div>
      </div>
      <div className='footer-carbon'>
        <img src={Carbon} alt="Carbon" className='carbon-wrapper' />
      </div>
    </div>
  );
};

export default Footer;