import Success from "assets/Success.svg"
import SWTH from "assets/SWTH-Blue-Small.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import { Models } from 'carbon-js-sdk'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./RedelegatePopup.css"

type Props = {
  valTo: Models.Staking.Validator
  valFrom: Models.Staking.Validator
  delegateAmount: string
  delegateUSD: string
  setPopup: React.Dispatch<React.SetStateAction<boolean>>
  resetFormState: () => void
}


const RedelegatePopup: React.FC<Props> = (props) => {
  const { valFrom, valTo, delegateAmount, delegateUSD, setPopup, resetFormState } = props
  const [hovered, setHovered] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <div className="redelegate-popup-wrapper">
      <img src={Success} alt="success" className="success-img" />
      <div>Redelegation successful!</div>
      <div className="redelegate-details">
        <div><span>Validator To</span><a className="validator-name" onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} href={`https://scan.carbon.network/validator/${valTo.operatorAddress}`} target="_blank" rel="noopener noreferrer">{valTo.description?.moniker}<img src={hovered ? SWTHBranding : SWTH} alt="External Link" className="avatar-icon" /></a></div>
        <div><span>Validator From</span><a className="validator-name" onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} href={`https://scan.carbon.network/validator/${valFrom.operatorAddress}`} target="_blank" rel="noopener noreferrer">{valFrom.description?.moniker}<img src={hovered ? SWTHBranding : SWTH} alt="External Link" className="avatar-icon" /></a></div>
        <div><span>Amount</span><span>{delegateAmount} SWTH</span></div>
        <div><span id="usd">USD</span><span>${delegateUSD}</span></div>
      </div>
      <button className="button-theme button-theme-secondary popup-button" style={{ backgroundColor: "transparent", width: "100%", height: "48px", marginTop: "0px" }} onClick={() => { setPopup(false); resetFormState() }}>Redelegate Another</button>
      <button className="button-theme button-theme-primary popup-button" style={{ width: "100%", height: "48px", marginTop: "16px" }} onClick={() => { navigate("/stake") }}>Back to Stake</button>
    </div>
  )
}

export default RedelegatePopup
