import SWTH from "assets/SWTH-Blue-Small.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import Warning from 'assets/Warning_Error.svg'
import BigNumber from 'bignumber.js'
import { Models } from 'carbon-js-sdk'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import Avatar from 'components/Common/Avatar'
import Signal from 'components/Common/Signal'
import StatusBlock from 'components/Common/StatusBlock'
import { useAppDispatch, useAppSelector, useAsyncTask } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateStakeUI } from 'store/UI'
import { adjustHuman, bnOrZero, SECONDS_PER_DAY } from 'utils'
import { calculateRating, getBondStatus } from 'utils/stake'
import "./StakeValidatorsCard.css"

type Props = {
    validator: Models.Staking.Validator
    top: boolean
    validatorsLength: number
    startingStake: BigNumber
    cumulativeStake: BigNumber
    delegatorList: Models.Staking.DelegationResponse[]
    participation: number
    participationColor: string
    index: number
}

export const StakeValidatorsCard: React.FC<Props> = ({
    validator,
    top,
    validatorsLength,
    startingStake,
    cumulativeStake,
    delegatorList,
    participation,
    participationColor,
    index
}) => {
    const blockTime = useAppSelector(state => state.stake.avgBlockTime)
    const sdk = useAppSelector(state => state.app.carbonSDK)
    const valAddrMap = useAppSelector((state) => state.stake.valAddrMap)
    const tokenPrices = useAppSelector(state => state.tokenPrices.value)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [hovered, setHovered] = useState<boolean>(false)
    const [hover, setHover] = useState<boolean>(false)
    const stakedTokenBN = sdk?.token.toHuman("swth", bnOrZero(validator?.tokens ?? "")) ?? BN_ZERO
    const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]
    const commissionBN = adjustHuman(
        validator?.commission?.commissionRates?.rate ?? ""
    ).shiftedBy(2)

    const [bondStatus, status] = getBondStatus(validator.status)
    const [runFetchHealthDetails] = useAsyncTask()
    const [healthDetails, setHealthDetails] = useState({
        missedBlockCount: 0,
        oracleSlashCount: 0,
        timeSinceStarted: 0
    })

    const fetchHealthDetails = () => {
        runFetchHealthDetails(async () => {
            const oracleSlashCounter = await sdk?.query.oracle.SlashCounter({
                valoperAddress: validator?.operatorAddress,
            })
            const oracleSlashCount = oracleSlashCounter?.slashCounter?.slashCount.toInt() ?? 0

            const SigningInfo = await sdk?.query.slashing.SigningInfo({
                consAddress:
                    valAddrMap[validator?.operatorAddress ?? ""].consAddress ?? "",
            })
            const missedBlockCount =
                SigningInfo?.valSigningInfo?.missedBlocksCounter.toInt() ?? 0

            const startBlockHeight =
                SigningInfo?.valSigningInfo?.startHeight.toInt() ?? 0
            const currentBlock = await sdk!.query.chain.getBlock()
            const currentBlockHeight = currentBlock.header.height
            const timeSinceStarted = blockTime.toNumber() * (currentBlockHeight - startBlockHeight) / SECONDS_PER_DAY

            setHealthDetails({ missedBlockCount, oracleSlashCount, timeSinceStarted })
        })

    }

    useEffect(() => {
        if (!blockTime) return
        fetchHealthDetails()
    }, [validator, blockTime, sdk]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="stake-validator-card-wrapper">
            <div className="validator-card-identity">
                <div style={{ display: "flex", gap: "8px", alignItems: "center", maxWidth: "230px" }}>
                    <p>{index + 1}</p>
                    <Avatar
                        name={validator.description?.moniker ?? ""}
                        valAddr={validator?.operatorAddress}
                        identity={validator.description?.identity}
                        icon="arrow"
                    />
                </div>
                <div>
                    <Signal total={5} num={calculateRating(bondStatus, healthDetails, blockTime, cumulativeStake.toNumber())} />
                </div>
            </div>
            <div className="stake-divider" />
            <div style={{ padding: "16px 16px 24px 16px" }}>
                <div className="validator-card-stats">
                    <div style={{ marginTop: "0" }}>
                        <p>Delegators</p>
                        <p>{delegatorList?.length}</p>
                    </div>
                    <div>
                        <p>Pool Size</p>
                        <p>{stakedTokenBN.toFormat(0) + ' SWTH'}</p>
                    </div>
                    <div style={{ marginTop: "2px", fontSize: "14px", lineHeight: "18px" }} className="reward-card-text-secondary">
                        <p>USD</p>
                        <p>{`$${stakedTokenBN.multipliedBy(swthPrice).toFormat(0)}`}</p>
                    </div>
                    <div>
                        <p>Vote Size</p>
                        <p style={{ fontWeight: "700" }}>{cumulativeStake.toFormat(2)}%</p>
                    </div>
                    <div style={{ marginTop: "2px" }}>
                        <p style={{ marginTop: "2px", fontSize: "14px", lineHeight: "18px" }} className="reward-card-text-secondary">Cumulative Stake</p>
                        <div className="stake-validators-card-cumulative-chart">
                            <div
                                style={{
                                    width: `${startingStake.toNumber()}%`,
                                }}
                            />
                            <div style={{ width: `${cumulativeStake.toNumber()}%` }} />
                        </div>
                    </div>
                    <div>
                        <p>Participation</p>
                        <p style={{color: `${participationColor}`}}>{participation}/10</p>
                    </div>
                    <div>
                        <p>Commission</p>
                        <p>{commissionBN.toFormat(0)}%</p>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        <StatusBlock status={status} text={bondStatus} />
                        <div style={{display: "flex", alignItems: "center"}} >
                            {healthDetails.oracleSlashCount >= 1
                                ?
                                <>
                                    <img src={Warning} alt="High Slash Count" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ height: "18px", marginRight: "6px" }} />
                                    {hover ? <div className="stake-validators-table-slash-hover">
                                        <p>This validator has a high slash count, which may impact your delegation rewards.</p>
                                    </div> : ""}
                                </>
                                :
                                <></>}
                            <div
                                onClick={() => {
                                    navigate('/stake/delegate')
                                    dispatch(updateStakeUI({ chosenValidator: validator }))
                                }}
                                onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}
                                className="delegate-button-wrapper"
                            >
                                <div className="delegate-button bolded-700">Delegate</div>
                                <img src={hovered ? SWTHBranding : SWTH} alt="External Link" style={{ marginLeft: "6px" }} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
