import React from "react";
import MoreInfo from "assets/MoreInfo_NeutralThin.svg";

type Props = {
    type: "Neutral" | "Error" | "Success",
    text: string,
    style?: React.CSSProperties,
    className?: string
};

const MobileWarningCard: React.FC<Props> = ({ type, text, style, className }) => {
    const backgroundColor = type === "Neutral" ? "#F1F7FC" : type === "Error" ? "#FCF1EF" : "#EFFAEF";
    const textColor = type === "Neutral" ? "#3A78B1" : type === "Error" ? "#DC6D5E" : "#3C9D40";
    const mobileWarningCardStyles: React.CSSProperties = {
        width: "342px",
        height: "68px",
        display: "flex",
        alignItems: "center",
        padding: "14px 16px",
        gap: "8px",
        background: backgroundColor,
        color: textColor, 
        border: `1px solid ${textColor}`,
        fontSize: "12px",
        borderRadius: "12px",
        margin: "auto",
        marginBottom: "16px",
        lineHeight: "18px",
        fontFamily: "Heebo",
        fontWeight: "400",
        ...style
    }

    return (
        <div style={mobileWarningCardStyles} className={className}>
            <img src={MoreInfo} height="24px" alt="More Info"/>
            <span>{text}</span>
        </div>
    );
};

export default MobileWarningCard;
