import MoreInfo from "assets/MoreInfo_Neutral.svg"
import TableWarning from "components/Common/TableWarning"
import Tooltip from "components/Common/Tooltip"
import React, { Dispatch, SetStateAction } from "react"
import { CarbonEVMTransaction } from "store/Wallet"
import BridgeHistoryRow from "./CarbonEVMBridgeHistoryRow"
import "../BridgeHistoryTable.css"
import BridgeHistoryLoading from "../BridgeHistoryLoading"
import BridgeHistoryErrorCell from "../BridgeHistoryErrorCell"


interface Props {
  bridgeHistoryData: CarbonEVMTransaction[];
  walletAddress: string | null;
  setShowBridge: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}

const CarbonEVMBridgeHistoryTable: React.FC<Props> = ({ bridgeHistoryData, walletAddress, setShowBridge, loading }) => {
  return (
    <table className="bridge-table-inner-wrapper">
      <thead className="table-head">
        <tr>
          <th>Date</th>
          <th>Hash</th>
          <th>Sender</th>
          <th>Receiver</th>
          <th>Amount</th>
          <th>
            <div className="bridge-more-info">
              <span>Status</span>
              <Tooltip>
                <span>The bridge process includes the following stages:</span>
                <span> 1. Success</span>
                <span> 2. Failed</span>
              </Tooltip>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {(!!walletAddress ? (loading ? <BridgeHistoryLoading /> : (bridgeHistoryData.length ?
          <>
            <TableWarning type="Neutral" >
              <img src={MoreInfo} alt="More Info" />
              <span>If you've just completed a transaction, it may take a while to show up here.</span>
            </TableWarning>
            {bridgeHistoryData.map((carbonEVMTransaction: CarbonEVMTransaction) => (
              <BridgeHistoryRow historyDataItem={carbonEVMTransaction} key={carbonEVMTransaction.hash} />
            ))}
          </> : (<BridgeHistoryErrorCell setShowBridge={setShowBridge} mainText="There are no records at the moment. If you've just completed a transaction, it may take a while to show." buttonText="Bridge Now" />))
        ) : <BridgeHistoryErrorCell setShowBridge={setShowBridge} mainText="Connect wallet(s) on the Bridge page to view your bridge history." buttonText="Connect Wallets on Bridge" />)}
      </tbody>
    </table>
  );
};

export default CarbonEVMBridgeHistoryTable;
