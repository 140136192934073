import React from "react";
import PopupWindow from "./PopupWindow";
import { useAppSelector, useAppDispatch } from "hooks";
import Warning from "assets/Warning-popup.svg";
import { changeNetworkAction } from "store/Wallet";

const WrongNetworkPopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const changeNetwork = () => {
    dispatch(changeNetworkAction());
  };
  const bridgeCardImgWrapper = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center"
  }
  const popupState = useAppSelector(state => state.ui.popup);
  return (
    <PopupWindow
      bottomButtonText={popupState.buttonText}
      clickBottomButton={changeNetwork}
      message="You’re connected to the wrong network."
      style={{ marginTop: "8px" }}
    >
      <div style={bridgeCardImgWrapper}>
        <img src={Warning} alt="click" />
      </div>
    </PopupWindow>
  );
};

export default WrongNetworkPopup;
