
import Loading_Light from "assets/Loading_light.gif"
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import Avatar from "components/Common/Avatar"
import TableText from "components/Common/TableText"
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React from "react"
import { useNavigate } from 'react-router-dom'
import { ValPair } from 'store/Stake'
import { StakeTasks } from 'store/Stake/types'
import { updateStakeUI } from 'store/UI'
import { DelegationLoading } from '../Common/DelegationLoading'
import { DelegationEmpty } from '../Common/EmptyStates'
import { DelegationsTableData, RedelegationsTableData } from "../StakeDelegationInfo"
import "./StakeYourDelegation.css"


const StakeYourDelegationEmpty: React.FC = () => {
  return (
    <tr>
      <td colSpan={100}>
        <DelegationEmpty />
      </td>
    </tr>
  )
}
interface Props {
  delegations: DelegationsTableData[]
  redelegations: RedelegationsTableData[]
}

const StakeYourDelegation: React.FC<Props> = ({ delegations, redelegations }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const valAddrMap = useAppSelector(state => state.stake.valAddrMap) as SimpleMap<ValPair>
  const [delegationsLoading] = useTaskSubscriber(StakeTasks.ValidatorDelegations)
  const [rewardsLoading] = useTaskSubscriber(StakeTasks.DelegationRewards)
  const [validatorsLoading] = useTaskSubscriber(StakeTasks.Validators)

  return (
    <div className="stake-your-delegation-wrapper theme-color">
      <table className="stake-your-delegation-inner-wrapper" style={{marginBottom: "0px"}}>
        <thead className='delegation-table-head'>
          <tr>
            <th style={{ paddingLeft: "24px" }}>Validator</th>
            <th align="right">Staked Amount</th>
            <th align="right" style={{ paddingRight: "24px" }}>Pending Rewards</th>
          </tr>
        </thead>
        <tbody>
          {delegationsLoading ?
            <tr>
              <td colSpan={100} height="202px">
                <DelegationLoading />
              </td>
            </tr>
            :
            delegations.length === 0 ?
              <StakeYourDelegationEmpty /> :
              delegations.map((delegation, index) => {
                const allowRedelegate = redelegations.filter(redelegation => { return (redelegation.valToAddr === delegation.valAddr && dayjs().isBefore(redelegation.completionTime)) }).length === 0
                return (
                  <tr key={index}>
                    <td style={{ paddingLeft: "24px" }}>
                      <Avatar name={delegation.validator} valAddr={delegation.valAddr} icon="link" identity={delegation.valID} />
                    </td>
                    <td>
                      <TableText type="token" denom={delegation.denom} content={delegation.amount} />
                    </td>
                    <td style={{ paddingRight: "24px" }}>{rewardsLoading || validatorsLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> : `$${delegation.pendingRewards.toFormat(2)}`}</td>
                    <td style={{ padding: "0 20px 0 48px" }}>
                      <div className="stake-your-delegation-notes-buttons bolded-700">
                        <p
                          className="stake-row-button"
                          onClick={() => {
                            navigate(`/stake/undelegate`)
                            dispatch(updateStakeUI({ chosenValidator: valAddrMap[delegation.valAddr].carbonValidator }))
                          }}>Undelegate</p>
                        <p style={!allowRedelegate ? { color: "#94ABB4" } : { cursor: "pointer" }} className={allowRedelegate ? "stake-row-button" : ""} onClick={() => {
                          if (!allowRedelegate) return
                          navigate(`/stake/redelegate`)
                          dispatch(updateStakeUI({ chosenValidator: valAddrMap[delegation.valAddr].carbonValidator }))
                        }}>Redelegate</p>
                      </div>
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      </table>
    </div >
  )
}

export default StakeYourDelegation
