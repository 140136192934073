import { useEffect } from 'react'
import "./InputSlider.css"
interface Props {
  onChange: React.ChangeEventHandler<{}>
  disabled?: boolean
  value: number | undefined
  onFocus: React.FocusEventHandler<{}>
  numbersClickHandler: (number: number) => void
}

export const InputSlider = (props: Props) => {
  const {
    onChange, disabled, value, onFocus, numbersClickHandler
  } = props


  useEffect(() => {
    // for manipulating slider progress bar color
    const slider = document.getElementById("slider")
    if (slider !== null) {
      slider.style.background = `linear-gradient(to right, #0A3F52 0%, #0A3F52 ${value}%, #E7ECEE ${value}%, #E7ECEE 100%)`
      slider.oninput = function () {
        slider.style.background = `linear-gradient(to right, #0A3F52 0%, #0A3F52 ${value}%, #E7ECEE ${value}%, #E7ECEE 100%)`
      }
    }
  }, [value])


  return (
    <div>
      <div>
        <input type="range" className="slider" id="slider" value={value ?? 0} disabled={disabled} onChange={onChange} onFocus={onFocus} />
      </div>
      <div className="slider-numbers-bar">
        <span onClick={() => numbersClickHandler(0)}> 0% </span>
        <span onClick={() => numbersClickHandler(25)}> 25% </span>
        <span onClick={() => numbersClickHandler(50)}> 50% </span>
        <span onClick={() => numbersClickHandler(75)}> 75% </span>
        <span onClick={() => numbersClickHandler(100)}> Max </span>
      </div>
    </div>
  )
}