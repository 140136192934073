"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateDescriptor = exports.StateType = void 0;
const u_1 = require("../../u");
var StateType;
(function (StateType) {
    StateType[StateType["Account"] = 64] = "Account";
    StateType[StateType["Validator"] = 72] = "Validator";
})(StateType = exports.StateType || (exports.StateType = {}));
function toStateType(type) {
    if (typeof type === "string") {
        if (type in StateType) {
            return StateType[type];
        }
        throw new Error(`${type} not found in StateType!`);
    }
    return type;
}
class StateDescriptor {
    constructor(obj = {}) {
        this.type = obj.type ? toStateType(obj.type) : StateType.Account;
        this.key = obj.key || "";
        this.field = obj.field || "";
        this.value = obj.value || "";
    }
    static deserialize(hex) {
        const ss = new u_1.StringStream(hex);
        return this.fromStream(ss);
    }
    static fromStream(ss) {
        const type = parseInt(ss.read(), 16);
        const key = ss.readVarBytes();
        const field = u_1.hexstring2str(ss.readVarBytes());
        const value = ss.readVarBytes();
        return new StateDescriptor({ type, key, field, value });
    }
    get [Symbol.toStringTag]() {
        return "StateDescriptor";
    }
    serialize() {
        let out = u_1.num2hexstring(this.type);
        out += u_1.num2VarInt(this.key.length / 2);
        out += this.key;
        const hexField = u_1.str2hexstring(this.field);
        out += u_1.num2VarInt(hexField.length / 2);
        out += hexField;
        out += u_1.num2VarInt(this.value.length / 2);
        out += this.value;
        return out;
    }
    export() {
        return {
            type: this.type,
            key: this.key,
            field: this.field,
            value: this.value,
        };
    }
    equals(other) {
        return (this.type === toStateType(other.type) &&
            this.key === other.key &&
            this.field === other.field &&
            this.value === other.value);
    }
}
exports.StateDescriptor = StateDescriptor;
exports.default = StateDescriptor;
