import { SubmitDelegationParams, SubmitRedelegationParams } from './types'

export enum StakeActionTypes {
  QUERY_APR_STATS = '@stake/QUERY_APR_STATS',
  QUERY_AVG_BLOCK_TIME = '@stake/QUERY_AVG_BLOCK_TIME',
  QUERY_TOTAL_STAKED = '@stake/QUERY_TOTAL_STAKED',
  QUERY_TOTAL_SUPPLY = '@stake/QUERY_TOTAL_SUPPLY',

  QUERY_VALIDATORS = '@stake/QUERY_VALIDATORS',
  QUERY_ALLIANCES = '@stake/QUERY_ALLIANCES',

  QUERY_USER_BALANCE = '@stake/QUERY_USER_BALANCE',
  QUERY_USER_DELEGATIONS = '@stake/QUERY_USER_DELEGATIONS',
  QUERY_USER_UNBONDING_DELEGATIONS = '@stake/QUERY_USER_UNBONDING_DELEGATIONS',
  QUERY_USER_REDELEGATIONS = '@stake/QUERY_USER_REDELEGATIONS',

  QUERY_USER_DELEGATION_REWARDS = '@stake/QUERY_USER_DELEGATION_REWARDS',

  RELOAD_ALL_USER_DELEGATIONS = '@stake/RELOAD_ALL_USER_DELEGATIONS',
  RELOAD_ALL_STAKING_INFO = '@stake/RELOAD_ALL_STAKING_INFO',
  SUBMIT_DELEGATION = '@stake/SUBMIT_DELEGATION',
  SUBMIT_REDELEGATION = '@stake/SUBMIT_REDELEGATION'
}

export const queryAprStats = () => ({
  type: StakeActionTypes.QUERY_APR_STATS,
})

export const queryAvgBlockTime = () => ({
  type: StakeActionTypes.QUERY_AVG_BLOCK_TIME
})

export const reloadUserDelegations = () => ({
  type: StakeActionTypes.RELOAD_ALL_USER_DELEGATIONS,
});

export const queryValidators = () => ({
  type: StakeActionTypes.QUERY_VALIDATORS
})

export const submitDelegation = ( payload: SubmitDelegationParams ) => ({
  type: StakeActionTypes.SUBMIT_DELEGATION,
  payload,
})

export const submitRedelegation = ( payload: SubmitRedelegationParams ) => ({
  type: StakeActionTypes.SUBMIT_REDELEGATION,
  payload,
})

export interface FetchUserBalanceSchema {
  address: string; // user address
}

export const FetchUserBalance = ( payload : FetchUserBalanceSchema) => ({
  type: StakeActionTypes.QUERY_USER_BALANCE,
  payload,
});

export const fetchUserDelegations = () => ({
  type: StakeActionTypes.QUERY_USER_DELEGATIONS,
});

export const fetchUserDelegationRewards = () => ({
  type: StakeActionTypes.QUERY_USER_DELEGATION_REWARDS,
});
