import CircleDefault from 'assets/Circle_Default.svg'
import CircleHover from 'assets/Circle_Hover.svg'
import CircleSelected from 'assets/Circle_Selected.svg'
import Ellipsis from 'assets/Ellipsis.svg'
import { Insights } from 'carbon-js-sdk'
import { useAppDispatch, useAppSelector } from 'hooks'
import React, { useMemo, useState } from 'react'
import { updateShowNodeForm } from 'store/UI'
import { CustomNodeItem, NodeFormState } from 'utils/nodes'
import "./NodeRow.css"
import Signal from './Signal'
import Tooltip from './Tooltip'

interface Props {
    node: any
    isCustom: boolean
    recommendedNode: Insights.NodeItem
    handleSelectNode: (node: any) => void
}

const NodeRow: React.FC<Props> = (props: Props) => {
    const { node, recommendedNode, handleSelectNode } = props
    const dispatch = useAppDispatch()
    const ratingLatencies = useAppSelector(state => state.app.latencies)
    const indivRatingLatency = ratingLatencies[node.nodeId]
    const latency = indivRatingLatency?.latency ?? 0
    const rating = indivRatingLatency?.rating ?? 0
    const [hover, setHover] = useState<boolean>(false)
    const network = useAppSelector(state => state.app.network)
    const selectedNodes = useAppSelector(state => state.app.selectedNodes)
    const customNodes = useAppSelector(state => state.app.customNodes) as CustomNodeItem[]
    const selectedNodeId = useMemo(() => selectedNodes[network]?.nodeId, [selectedNodes, network])
    const selected = node.nodeId === selectedNodeId
    const isCustomNode = customNodes.filter(customNode => customNode.moniker === node.moniker).length > 0

    const calculateRating = (rating: any) => {
        if (rating >= 0.8) {
            return 5
        } else if (rating >= 0.6 && rating < 0.8) {
            return 4
        } else if (rating >= 0.4 && rating < 0.6) {
            return 3
        } else if (rating >= 0.2 && rating < 0.4) {
            return 2
        } else if (rating < 0.2) {
            return 1
        } else {
            return 0
        }
    }

    const selectRowNode = (node: any) => {
        handleSelectNode(node)
    }

    const nodeInfo: NodeFormState = {
        moniker: node.moniker,
        rpcUrl: node.rpcUrl,
        restUrl: node.restUrl,
        wsUrl: node.wsUrl,
        faucetUrl: node.faucetUrl,
        insightsUrl: node.insightsUrl,
        tmWsUrl: node.tmWsUrl,
    }

    const showNodeForm = () => {
        if (!isCustomNode) {
            dispatch(updateShowNodeForm({ viewOnly: true, showNodeForm: true, nodeInfo: nodeInfo }))
        } else {
            dispatch(updateShowNodeForm({ viewOnly: false, showNodeForm: true, nodeInfo: nodeInfo }))
        }
    }

    return (
        <div className='node-row-wrapper'>
            <img style={{ width: "24px", cursor: "pointer" }} src={hover ? CircleHover : (selected ? CircleSelected : CircleDefault)} onClick={() => selectRowNode(node)} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} alt="node button" />
            <div className={hover ? 'node-text-wrapper-hovered' : 'node-text-wrapper'}>
                <div className='node-name-wrapper'>
                    <p className={'node-moniker ' + (recommendedNode?.nodeId !== node.nodeId ? "normal-node-moniker" : "best-node-moniker")}>{node.moniker}</p>
                    {recommendedNode?.nodeId === node.nodeId && (
                        <div className='best-node'>Best</div>
                    )}
                </div>
                <p className="node-link">{node.rpcUrl}</p>
            </div>
            <div className='node-rating'>
                {!isCustomNode &&
                    <>
                        <Signal total={5} num={calculateRating(rating)} />
                        <Tooltip hoverBoxStyle={{ height: "max-content", width: "224px", transform: "translateX(-30%)" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>Latency</p>
                                <p className='node-tooltip-text'>{latency}ms</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>WS Uptime (24H)</p>
                                <p className='node-tooltip-text'>{node?.wsUptime}%</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>RPC Uptime (24H)</p>
                                <p className='node-tooltip-text'>{node?.rpcUptime}%</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>Insight Uptime (24H)</p>
                                <p className='node-tooltip-text'>{node?.insightUptime}%</p>
                            </div>
                        </Tooltip>
                    </>
                }
                <img style={{ cursor: "pointer", marginLeft: "auto"  }} src={Ellipsis} alt="More" onClick={() => showNodeForm()} />
            </div>
        </div>
    )
}

export default NodeRow