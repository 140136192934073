"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BaseContract_scriptHash, _BaseContract_abi;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseContract = void 0;
const ContractParam_1 = __importStar(require("../ContractParam"));
const CallFlags_1 = require("../CallFlags");
class BaseContract {
    constructor(scriptHash, methods = []) {
        _BaseContract_scriptHash.set(this, void 0);
        _BaseContract_abi.set(this, {});
        __classPrivateFieldSet(this, _BaseContract_scriptHash, scriptHash, "f");
        __classPrivateFieldSet(this, _BaseContract_abi, methods.reduce((map, method) => {
            map[method.name] = method;
            return map;
        }, {}), "f");
    }
    get scriptHash() {
        return __classPrivateFieldGet(this, _BaseContract_scriptHash, "f");
    }
    /**
     * Getter for retrieving the ABI for this contract.
     * This is made readonly with Typescript. This has no effect in Javascript.
     */
    get methods() {
        return __classPrivateFieldGet(this, _BaseContract_abi, "f");
    }
    call(method, ...inputArgs) {
        const methodDefinition = typeof method === "string" ? __classPrivateFieldGet(this, _BaseContract_abi, "f")[method] : method;
        if (methodDefinition === undefined) {
            throw new Error(`The method ${method} is not defined on this contract.`);
        }
        if (methodDefinition.parameters.length !== inputArgs.length) {
            throw new Error(`Invalid number of parameters provided. Method requires ${methodDefinition.parameters.length} parameters but got ${inputArgs.length}.`);
        }
        const args = inputArgs.map((arg, index) => convertParameter(arg, methodDefinition.parameters[index].type));
        return {
            scriptHash: this.scriptHash,
            operation: methodDefinition.name,
            callFlags: CallFlags_1.CallFlags.All,
            args: args,
        };
    }
}
exports.BaseContract = BaseContract;
_BaseContract_scriptHash = new WeakMap(), _BaseContract_abi = new WeakMap();
function convertParameter(arg, type) {
    if (typeof arg === "object") {
        const contractParamInstance = arg instanceof ContractParam_1.default ? arg : ContractParam_1.default.fromJson(arg);
        if (isCompatibleType(contractParamInstance.type, type)) {
            return contractParamInstance;
        }
        else {
            throw new Error(`Provided ${contractParamInstance.type} when trying to get ${type}`);
        }
    }
    return ContractParam_1.default.fromJson({
        type: ContractParam_1.ContractParamType[type],
        value: arg,
    });
}
function isCompatibleType(givenType, requiredType) {
    return (
    // same type
    requiredType === givenType ||
        // Hash160 & Hash256 are subsets of ByteArray
        (requiredType === ContractParam_1.ContractParamType.ByteArray &&
            (givenType === ContractParam_1.ContractParamType.Hash160 ||
                givenType === ContractParam_1.ContractParamType.Hash256)));
}
