import { useMemo } from "react"
import { ChainNames } from "constants/types"


interface BridgeOptions {
  [tokenDenom: string]: BridgeParams
}

interface BridgeParams {
  src: ChainNames[]
  dest: ChainNames[]
}

const bridgeOptions: BridgeOptions = {
  CarbonEVMBridge: {
    src: [
      ChainNames.CarbonCore,
      ChainNames.CarbonEVM,
    ],
    dest: [
      ChainNames.CarbonCore,
      ChainNames.CarbonEVM,
    ]
  },
  SWTHBridge: {
    src: [
      ChainNames.Eth,
      ChainNames.Bsc,
      ChainNames.CarbonCore,
      ChainNames.Arbitrum,
    ],
    dest: [
      ChainNames.Eth,
      ChainNames.Bsc,
      ChainNames.CarbonCore,
      ChainNames.Zil,
      ChainNames.Neo,
      ChainNames.Neo3,
      ChainNames.Arbitrum,
    ]
  },
}

// Bridge options are based on the selected token denom that is being bridged
export const useBridgeOptions = (tokenDenom: string) => {
  const isCarbonEvmBridge = tokenDenom !== 'swth'
  const key = isCarbonEvmBridge ? 'CarbonEVMBridge' : 'SWTHBridge'

  return useMemo(() => ([
    bridgeOptions[key].src,
    bridgeOptions[key].dest,
  ]), [key])
}
