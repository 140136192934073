import { CarbonSDK } from 'carbon-js-sdk'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import Avatar from 'components/Common/Avatar'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React from "react"
import { StakeTasks } from 'store/Stake/types'
import { DelegationLoading } from '../Common/DelegationLoading'
import { UndelegationEmpty } from '../Common/EmptyStates'
import { UndelegationsTableData } from "../StakeDelegationInfo"
import "./StakeYourDelegationMobile.css"
import BigNumber from 'bignumber.js'

interface Props {
  undelegations: UndelegationsTableData[]
}

const UndelegationMobile: React.FC<Props> = ({ undelegations }) => {
  const sdk = useAppSelector<CarbonSDK | null>(state => state.app.carbonSDK)
  const [undelegationsLoading] = useTaskSubscriber(StakeTasks.ValidatorUndelegations)

  return (
    <div className="stake-your-delegation-mobile-wrapper theme-color">
      {undelegationsLoading ?
        <DelegationLoading /> :
        undelegations.length === 0 ?
          <div className="empty-mobile-card" style={{ padding: "0px 16px" }}>
            <UndelegationEmpty />
          </div>
          :
          undelegations.sort((a, b) => a.completionTime > b.completionTime ? -1 : 1).map((undelegation, index) => {
            var now = dayjs()
            const timeLeft = dayjs(undelegation.completionTime).diff(now, 'days')
            const timeDiff = timeLeft > 0 ? (30 - timeLeft) / 30 * 100 : 100
            const symbol = sdk?.token.getSymbol(undelegation.denom) ?? ""
            const price = sdk?.token.getUSDValue(undelegation.denom) ?? BN_ZERO
            const amount = undelegation.undelegateAmount

            const threshold = 0.001
            const isTooSmall: boolean = amount.lte(threshold)
            const amountWithDenom = `${isTooSmall ? `≥${threshold}` : amount.toFixed(3, BigNumber.ROUND_DOWN)} ${symbol}`

            return (
              <div className='stake-your-delegation-mobile-card-wrapper' key={index}>
                <Avatar name={undelegation.validator} valAddr={undelegation.valAddr} icon="link" identity={undelegation.valID} />
                <div className='redelegate-mobile-divider' style={{ marginTop: "20px" }} />
                <div className='stake-your-delegation-mobile-stats'>
                  <div>
                    <p>Slashed Amount</p>
                    <p>{(undelegation.slashedAmount) + ' ' + undelegation.symbol}</p>
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "14px", lineHeight: "18px" }} className="reward-card-text-secondary">
                    <p>USD</p>
                    <p>{'$' + undelegation.slashedAmount.multipliedBy(price).toFormat(2)}</p>
                  </div>
                  <div>
                    <p>Undelegate Amount</p>
                    <p>{amountWithDenom}</p>
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "14px", lineHeight: "18px" }} className="reward-card-text-secondary">
                    <p>USD</p>
                    <p>{'$' + undelegation.undelegateAmount.multipliedBy(price).toFormat(2)}</p>
                  </div>
                  <div>
                    <p>Start Block</p>
                    <p>{undelegation.startBlock}</p>
                  </div>
                  <div>
                    <p>Initiated On</p>
                    <p>{undelegation.completionTime.subtract(30, "day").format("MMM DD, YYYY")}<span className="reward-card-text-secondary">{` ${undelegation.completionTime.subtract(30, "day").format("HH:mm:ss")}`}</span></p>
                  </div>
                  <div>Time Left</div>
                  <div className="stake-undelegating-time-box" style={{ marginTop: "6px" }}>
                    <div className="stake-undelegating-time-bar" style={{ width: "100%" }}>
                      <div className="stake-undelegating-time-left" style={{ width: `${timeDiff}%` }} />
                    </div>
                    <p className="stake-secondary">{timeLeft >= 0 ? `${timeLeft} days left` : "Complete"}</p>
                  </div>
                </div>
              </div>
            )
          })
      }
    </div >
  )
}

export default UndelegationMobile
