"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeout = exports.defaultCalculationStrategy = exports.networks = void 0;
const tx_1 = require("./tx");
Object.defineProperty(exports, "defaultCalculationStrategy", { enumerable: true, get: function () { return tx_1.defaultCalculationStrategy; } });
exports.networks = {};
exports.timeout = {
    ping: 2000,
    rpc: 30000,
};
