import { ChainNames } from "./types";

export enum MigrateChoice {
  BSC = 0,
  NeoInflationary = 1,
  NeoLegacy = 2
}

export const migrateChoiceToNames = new Map<MigrateChoice, ChainNames>([
  [MigrateChoice.BSC, ChainNames.Bsc],
  [MigrateChoice.NeoInflationary, ChainNames.Neo3],
  [MigrateChoice.NeoLegacy, ChainNames.Neo3]
]);
