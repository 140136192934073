import React from "react";
import "./StatusBlock.css";

type Props = {
  text: string;
  status: "Neutral" | "Success" | "Warning" | "Danger" | undefined;
}

const StatusBlock: React.FC<Props> = ({ status, text}) => {

  const statusColourMap =  new Map<typeof status, string>([
    ["Neutral", "#3A7BB1"],
    ["Warning", "#D0A200"],
    ["Success", "#3C9D40"],
    ["Danger", "#DC3545"],
  ]);

  return (
    <div className="status-block" style={{ color: statusColourMap.get(status) }}>
      <p>{text}</p>
    </div>
  );
}

export default StatusBlock;
