import { CarbonSDK, TypeUtils } from 'carbon-js-sdk'
import { TokenClient } from 'carbon-js-sdk/lib/clients'

// tslint:disable: no-bitwise
export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : ((r & 0x3) | 0x8)
        return v.toString(16)
    })
};

export const capitalise = (input: string) => {
    return input.replace(/^./, input[0].toUpperCase())
};


export const errorRegex = /\[(\d+)\] ([a-z\d\s]+); message index: (\d+): /i

export const cleanBackendErr = (error: string) => {
  if (error.match(errorRegex)) {
    return capitalise(error.replace(errorRegex, ''))
  }
  return error
}

export const parseError = (original: any): Error => {
    let error = original;
    if (typeof error === "string")
      error = new Error(error);
    return error;
};

export function getTokenName(
  denom: string = '',
  tokenCli?: TokenClient,
  network?: CarbonSDK.Network,
  symbolOverride?: TypeUtils.SimpleMap<string>,
): string {
  if (!tokenCli || !network) return denom
  return tokenCli.getTokenName(denom, symbolOverride)
}

export function getUnderlyingDenom(denom: string, sdk: CarbonSDK | undefined): string {
  if (!sdk) return ''

  let underlyingDenom: string = ''
  try {
    underlyingDenom = sdk?.cdp.getUnderlyingDenom(denom) ?? ''
  } catch (err) {
    console.error(err)
    underlyingDenom = ''
  }
  return underlyingDenom
}