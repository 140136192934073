import BoltX from "assets/Wallets/BoltX.svg"
import Keplr from "assets/Wallets/Keplr.svg"
import Leap from "assets/Wallets/Leap.svg"
import Ledger from "assets/Wallets/Ledger.svg"
import MetaMask from 'assets/Wallets/Metamask.svg'
import NeoLine from "assets/Wallets/NeoLine.svg"
import O3 from "assets/Wallets/O3.svg"
import TrustWallet from "assets/Wallets/TrustWallet.svg"
import ZilPay from "assets/Wallets/ZilPay.svg"
import WalletBranding from "assets/Wallet_Branding.svg"
import Wallet from "assets/Wallet_Outlined.svg"
import { useAppSelector } from "hooks"
import React, { useState } from "react"
import { ChainNames, WalletNames } from "../../constants/types"
import { addressToUrl, transactionToUrl } from "../../saga/Common"
import "./AddressLabel.css"
import ChainIcon from './ChainIcon'

const chainIconMap = new Map([
  [ChainNames.CarbonCore, <ChainIcon chain="Carbon Core" size={18}/>],
  [ChainNames.CarbonEVM, <ChainIcon chain="Carbon EVM" size={18}/>],
  [ChainNames.Zil, <ChainIcon chain="Zilliqa" size={18}/>],
  [ChainNames.Bsc, <ChainIcon chain="Binance Smart Chain" size={18}/>],
  [ChainNames.Eth, <ChainIcon chain="Ethereum" size={18}/>],
  [ChainNames.Neo, <ChainIcon chain="Neo" size={18}/>],
  [ChainNames.Neo3, <ChainIcon chain="Neo3" size={18}/>],
  [ChainNames.Arbitrum, <ChainIcon chain="Arbitrium" size={18}/>],
])

const walletSrcMap = new Map([
  [WalletNames.Metamask, MetaMask],
  [WalletNames.Keplr, Keplr],
  [WalletNames.Leap, Leap],
  [WalletNames.ZilPay, ZilPay],
  [WalletNames.BoltX, BoltX],
  [WalletNames.TrustWallet, TrustWallet],
  [WalletNames.Ledger, Ledger],
  [WalletNames.NeoLine, NeoLine],
  [WalletNames.O3, O3],
  ["wallet", Wallet]
])

export const ChainExplorerUrls = {
  [ChainNames.Bsc]: "https://bscscan.com/address",
  [ChainNames.Neo]: "https://neotube.io/address",
  [ChainNames.Eth]: "https://etherscan.io/address",
  [ChainNames.Neo3]: "https://neo3.neotube.io/address",
  [ChainNames.Zil]: "https://viewblock.io/zilliqa/address",
  [ChainNames.CarbonCore]: "https://scan.carbon.network/account",
  [ChainNames.Arbitrum]: "https://arbiscan.io/address"
}

interface Props {
  logoName?: ChainNames | WalletNames | "wallet"
  text: string;
  externalLinkData?: {type: "address" | "transaction", chain: ChainNames, isCarbonEVMBridge?: boolean};
  bolded?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  textOverflowParams?: number[];
};

const AddressLabel: React.FC<Props> = ({ logoName, text, externalLinkData, bolded, disabled, style, textOverflowParams }) => {
  const network = useAppSelector(state => state.app.network);
  const [hovered, setHovered] = useState<boolean>(false);
  const link = externalLinkData ? externalLinkData.type === "address" ? addressToUrl(text, externalLinkData.chain, network) : transactionToUrl(text, externalLinkData.chain, network, externalLinkData.isCarbonEVMBridge) : undefined;
  let displayedText = text;

  if (text.length >= 20 && (!textOverflowParams || textOverflowParams.length === 2)) {
    const overflowStart = textOverflowParams ? textOverflowParams[0] : 5;
    const overflowEnd = textOverflowParams ? textOverflowParams[1] : 5;
    displayedText = `${text.substring(0, overflowStart)}...${text.substring(text.length - overflowEnd)}`;
  }

  let icon;
  if (logoName === "wallet" && hovered) {
    icon = <img src={WalletBranding} height="14px" alt="Wallet"/>
  } else if (Object.values(WalletNames).includes(logoName as WalletNames)) {
    icon = <img src={walletSrcMap.get(logoName ?? '')} height="24px" alt={logoName}/>
  } else if (Object.values(ChainNames).includes(logoName as ChainNames)) {
    icon = chainIconMap.get(logoName as ChainNames)
  } else {
    icon = null
  }

  return (
    <div className={`address-label ${disabled ? "address-label-disabled" : ""} ${externalLinkData ? "address-label-external-link" : undefined}`} style={style} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <a href={link} style={disabled ? {pointerEvents: "none"} : {}} target="_blank" rel="noopener noreferrer">
        {icon}
        <span className={`${bolded ? "address-label-bolded" : "address-label-unbolded"}`}>
          {displayedText}
        </span>
      </a>
    </div>
  );
};

export default AddressLabel;
