import React, { ReactNode, useState } from "react";
import "./StakeFAQItem.css"
import SWTH from "assets/SWTH-Blue-Small.svg";
import SWTHBranding from "assets/SWTH-Branding-Small.svg";

type Props = {
  header: string;
  textBlocks: string[];
  extraIndex: number | undefined;
  children: ReactNode | undefined 
}

const StakeFAQItem: React.FC<Props> = ({ header, textBlocks, extraIndex, children }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <div className="stake-faq-item" style={{ marginBottom: expanded ? "40px" : "51px" }}>
      <div className="stake-faq-item-header bolded-700 link" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={() => {setExpanded(!expanded)}}>
        <p>{ header }</p>
        <img src={ hovered ? SWTHBranding : SWTH } style={ expanded ? undefined : { transform: "rotate(45deg)" } } alt="Arrow"/>
      </div>
      <div>
        { expanded && (textBlocks.length > 0 ?
          textBlocks.map((block, index) => {
            if (block.length === 0 && index === extraIndex) { 
              return <React.Fragment key={index}>
                {children}
                <br key={index} />
              </React.Fragment>
            } else if (block.length === 0 && index !== extraIndex) {
              return <br key={index} />
            } else {
              return <p key={index}>{block}</p>
            }
          }) :
          children)
        }
      </div>
    </div>
  );
}

export default StakeFAQItem;
