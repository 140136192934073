import { CarbonSDK } from "carbon-js-sdk"

export enum AppActionTypes {
  APP_UPDATE_NETWORK = "app/updateNetwork",
  SET_SELECTED_NODES = "SET_SELECTED_NODES",
  SET_CUSTOM_NODES = "SET_CUSTOM_NODES"
}
export interface UpdateNetworkSchema {
  network: CarbonSDK.Network;
}

export const UpdateNetworkAction = (payload: UpdateNetworkSchema) => ({
  type: AppActionTypes.APP_UPDATE_NETWORK,
  payload,
});

