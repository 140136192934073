import ArrowLeft from "assets/ArrowLeft.svg"
import ArrowLeftBranding from "assets/ArrowLeft_Branding.svg"
import Loading_Dark from "assets/Loading_Transparent.gif"
import Success from "assets/Success.svg"
import BigNumber from 'bignumber.js'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import PopupWindow from 'components/Common/Popups/PopupWindow'
import { ChainNames } from 'constants/types'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateStakeAsync } from 'store/Async'
import { StakeActionTypes } from 'store/Stake'
import { addToastItem } from 'store/Toast'
import { parseError, reduxAction, uuidv4 } from 'utils'
import { getAddressLink } from 'utils/externalLinks'
import RewardsTable from './RewardsTable'
import "./StakeRewardsCard.css"
import { AllianceModule } from "carbon-js-sdk"

const StakeRewardsCard: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [hoveredBackButton, setHoveredBackButton] = useState<boolean>(false)
    const [claimSuccess, setClaimSuccess] = useState<boolean>(false)
    const sdk = useAppSelector(state => state.app.carbonSDK)
    const network = useAppSelector(state => state.app.network)
    const userDelegations = useAppSelector(state => state.stake.userDelegations)
    const asyncState = useAppSelector(state => state.async)
    const tokenClient = sdk?.token

    const rewardsArray = useMemo(() => {
        const rewardsMap = userDelegations.reduce((accum, delegation) => {
            for (const reward of delegation.pendingRewards) {
                const amount = tokenClient?.toHuman(reward.denom, reward.amount) ?? BN_ZERO
                accum[reward.denom] = (accum[reward.denom] ?? BN_ZERO).plus(amount)
            }
            return accum
        }, {} as { [index: string]: BigNumber }) ?? {}
        return Object.keys(rewardsMap).map((denom) => ({
            amount: rewardsMap[denom],
            denom,
        }))
    }, [userDelegations, tokenClient]) // eslint-disable-line

    const totalRewardValue = rewardsArray.reduce((sum, reward) => {
        const usdValue = sdk?.token.getUSDValue(reward.denom) ?? BN_ZERO
        return sum.plus(reward.amount.times(usdValue))
    }, new BigNumber(0))

    const handleClaimAll = () => {
        if (!sdk?.wallet?.bech32Address) return
        if (!sdk.wallet) return

        dispatch(updateStakeAsync({ loading: true }))

        const params: AllianceModule.WithdrawAllRewardsParams = {
            delegatorAddress: sdk?.wallet?.bech32Address,
            delegations: userDelegations.flatMap(delegation => {
                if (!delegation.pendingRewards.length) return [];
                return [{
                    validatorAddress: delegation.validatorAddress,
                    denom: delegation.denom
                }]
            }),
        }
        sdk.alliance.withdrawAllRewards(params, { feeDenom: "swth" }).then(() => { setClaimSuccess(true) }).catch((err: any) => {
            const error = parseError(err)
            dispatch(updateStakeAsync({ error }))
            dispatch(addToastItem({
                id: uuidv4(),
                senderAddress: sdk.wallet?.bech32Address!,
                receiverAddress: null,
                senderChain: ChainNames.CarbonCore,
                receiverChain: null,
                transactionHash: null,
                error: error
            }))
        }).finally(() => {
            dispatch(updateStakeAsync({ loading: false }))
            dispatch(reduxAction(StakeActionTypes.QUERY_USER_DELEGATION_REWARDS))
        })
    }

    return (
        <div className="stake-card-wrapper theme-color">
            <div className="stake-card-container">
                <div className="reward-card-back-button-wrapper bolded-700">
                    <div className="reward-card-back-button" onClick={() => navigate("/stake")} onMouseOver={() => setHoveredBackButton(true)} onMouseLeave={() => setHoveredBackButton(false)}>
                        <img src={hoveredBackButton ? ArrowLeftBranding : ArrowLeft} alt="Back Arrow" />
                        <span>Back </span>
                    </div>
                </div>
                <div className='reward-card theme-color'>
                    <div className="reward-card-header">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <p className="reward-card-text-secondary bolded-700" style={{ marginBottom: "0px", fontSize: "16px", lineHeight: "20px" }}>Rewards</p>
                            <p className='reward-card-title theme-color bolded-700' style={{ marginTop: "8px", marginBottom: "0px" }}>{`$${totalRewardValue.toFormat(2)}`}</p>
                            <a href={getAddressLink(sdk?.wallet?.bech32Address, network) + "&tab=stakings"} target="_blank" rel="noopener noreferrer" className='underline reward-card-text-secondary' style={{ marginTop: "6px", fontSize: "14px", lineHeight: "18px", fontWeight: "400" }}>See Rewards Breakdown</a>
                        </div>
                        <button 
                            className="stake-table-button-theme button-theme-primary stake-reward-button" 
                            onClick={() => { handleClaimAll() }}
                            disabled={!totalRewardValue.gt(0)}
                        >
                                {asyncState.stake.loading ? <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" /> : "Claim All"}
                        </button>
                    </div>
                    <div className='reward-card-tokens-container'>
                        <div style={{ fontSize: "14px", lineHeight: "18px", height: "18px" }}>
                            <p>Token</p>
                            <p>Amount</p>
                        </div>
                        <RewardsTable />
                    </div>
                </div>
            </div>
            {claimSuccess ?
                <PopupWindow headerText="Rewards claimed successfully." style={{ fontSize: "14px" }} bottomButtonText="View Rewards History" topButtonText="Back to Stake" closeWindow={() => setClaimSuccess(false)}
                    clickBottomButton={() => { window.open(getAddressLink(sdk?.wallet?.bech32Address, sdk.network, 'message.action=withdraw_delegator_reward&tab=transactions'), "_blank") }}
                    clickTopButton={() => navigate("/stake")}
                    message={""}
                >
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img src={Success} alt="success" className="success-img" />
                        <div className='reward-card-popup-amount'>
                            <p>Rewards Claimed</p>
                            <p>{`$${totalRewardValue.toFormat(2)}`}</p>
                        </div>
                    </div>
                </PopupWindow>
                : <></>}
        </div>
    )
}

export default StakeRewardsCard
