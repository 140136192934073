import Loading_Light from "assets/Loading_light.gif"
import SWTH from "assets/SWTH-Blue-Small.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import SWTHDisabled from "assets/SWTH-Disabled-Small.svg"
import BigNumber from "bignumber.js"
import { CarbonSDK } from "carbon-js-sdk"
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import Avatar from 'components/Common/Avatar'
import { BN_ZERO } from "constants/math"
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React, { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { ValPair } from 'store/Stake'
import { StakeTasks } from 'store/Stake/types'
import { updateStakeUI } from 'store/UI'
import { DelegationLoading } from '../Common/DelegationLoading'
import { DelegationEmpty } from '../Common/EmptyStates'
import { DelegationsTableData, RedelegationsTableData } from "../StakeDelegationInfo"
import "./StakeYourDelegationMobile.css"

interface Props {
  delegations: DelegationsTableData[]
  redelegations: RedelegationsTableData[]
}

const StakeYourDelegationMobile: React.FC<Props> = ({ delegations, redelegations }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const sdk = useAppSelector<CarbonSDK | null>(state => state.app.carbonSDK)
  const valAddrMap = useAppSelector(state => state.stake.valAddrMap) as SimpleMap<ValPair>
  const [hovered1, setHovered1] = useState<boolean>(false)
  const [hovered2, setHovered2] = useState<boolean>(false)
  const [delegationsLoading] = useTaskSubscriber(StakeTasks.ValidatorDelegations)
  const [rewardsLoading] = useTaskSubscriber(StakeTasks.DelegationRewards)

  return (
    <div className="stake-your-delegation-mobile-wrapper">
      {delegationsLoading ?
      <DelegationLoading /> :
        delegations.length === 0 ?
          <div className="empty-mobile-card" style={{ padding: "0px 16px" }}>
            <DelegationEmpty />
          </div>
          :
          delegations.map((delegation, index) => {
            const allowRedelegate = redelegations.filter(redelegation => {return (redelegation.valToAddr === delegation.valAddr && dayjs().isBefore(redelegation.completionTime))}).length === 0
            const symbol = sdk?.token.getSymbol(delegation.denom) ?? ""
            const price = sdk?.token.getUSDValue(delegation.denom) ?? BN_ZERO
            const amount = delegation.amount

            const threshold = 0.001
            const isTooSmall: boolean = amount.lte(threshold)
            const amountWithDenom = `${isTooSmall ? `≥${threshold}` : amount.toFixed(3, BigNumber.ROUND_DOWN)} ${symbol}`

            return (
              <div className='stake-your-delegation-mobile-card-wrapper' key={index}>
                <Avatar name={delegation.validator} valAddr={delegation.valAddr} icon="link" identity={delegation.valID} />
                <div className='stake-your-delegation-mobile-stats'>
                  <div>
                    <p>Staked Amount</p>
                    <p>{amountWithDenom}</p>
                  </div>
                  <div style={{ marginTop: "2px", fontSize: "14px", lineHeight: "18px" }} className="reward-card-text-secondary">
                    <p>USD</p>
                    <p>{'$' + delegation.amount.multipliedBy(price).toFormat(2)}</p>
                  </div>
                  <div>
                    <p>Pending Rewards</p>
                    <p>{rewardsLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" />  : `$${delegation.pendingRewards.toFormat(2) ?? '-'}`}</p>
                  </div>
                </div>
                <div className='stake-your-delegation-mobile-buttons'>
                  <div
                    className="delegate-button-wrapper bolded-700"
                    onMouseOver={() => setHovered1(true)}
                    onMouseLeave={() => setHovered1(false)}
                    onClick={() => {
                      navigate(`/stake/undelegate`)
                      dispatch(updateStakeUI({ chosenValidator: valAddrMap[delegation.valAddr].carbonValidator }))
                    }}>
                    <div>Undelegate</div>
                    <img src={hovered1 ? SWTHBranding : SWTH} alt="External Link" style={{ marginLeft: "6px" }} />
                  </div>
                  <div
                    style={!allowRedelegate ? { color: "#6A8A96", display: "flex" } : { cursor: "pointer" }}
                    onMouseOver={() => allowRedelegate ? setHovered2(true) : ""}
                    onMouseLeave={() => allowRedelegate ? setHovered2(false) : ""}
                    className={allowRedelegate ? "delegate-button-wrapper bolded-700" : "bolded-700"} onClick={() => {
                      if (!allowRedelegate) return
                      navigate(`/stake/redelegate`)
                      dispatch(updateStakeUI({ chosenValidator: valAddrMap[delegation.valAddr].carbonValidator }))
                    }}>
                    <div>Redelegate</div>
                    <img src={!allowRedelegate ? SWTHDisabled : (hovered2 ? SWTHBranding : SWTH)} alt="External Link" style={{ marginLeft: "6px" }} />
                  </div>
                </div>
              </div>
            )
          })
      }
    </div >
  )
}

export default StakeYourDelegationMobile
