import MoreInfo from "assets/MoreInfo.svg"
import MoreInfoHover from "assets/MoreInfo_hover.svg"
import React, { ReactNode } from "react"
import "./Tooltip.css"

type Props = {
  children: ReactNode;
  hoverBoxStyle?: React.CSSProperties;
}

const Tooltip: React.FC<Props> = ({ children, hoverBoxStyle }) => {
  return (
    <div className="tooltip">
      <img src={MoreInfo} alt="More Info" className="tooltip-more-info-icon" onMouseOver={(e) => e.currentTarget.src = MoreInfoHover} onMouseLeave={(e) => e.currentTarget.src = MoreInfo} />
      <div className="tooltip-hover-box" style={hoverBoxStyle}>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
