import React from 'react';
import './BuyPage.css';
import SWTH from 'assets/SWTH-white.svg';
import Callout from "assets/Callout_Transaction.svg";
import Table from './ExchangesTable';

const BuyPage: React.FC = () => {
  const Learn_More = 'https://guide.carbon.network/swth/liquidity-pools';

  return (
    <div className="buyPage-wrapper theme-color">
      <div className="buyPage-inner-wrapper">
        <div className="top-text">
          <p className='section-header section-header-mobile'>One token, many chains</p>
          <p className='section-subheader'>
            SWTH is the governance token of Carbon, the Layer-2 DeFi protocol powering interconnectivity in the multi-chain world. </p>
          <p className='section-text'>
            SWTH exists as a transferrable and fluid token across multiple blockchains, bridging Carbon to wider ecosystems. As more chains are integrated to Carbon, expect to see more SWTH token standards being introduced!
          </p>
        </div>
        <p className='section-subpoint-header'> Get SWTH from different exchanges </p>
        <p className='section-text'> Buy SWTH on your preferred decentralized or centralized exchange.</p>
        <img src={Callout} alt="callout" className='callout-wrapper' style={{ margin: '0 0 40px 0' }} />
        <Table />
        <p className='section-subpoint-header' style={{ margin: '50px 0 20px 0' }}> Earn more with your SWTH </p>
        <p className='section-text'>Add liquidity, earn rewards. With SWTH, you can choose to earn more by being a liquidity provider to earn maker rebates and yield farming rewards!</p>
        <button className='button-theme button-theme-primary button-getSWTH'>
          <a style={{color: 'white'}} href={Learn_More} target="_blank" rel="noopener noreferrer"> Learn More <img src={SWTH} alt="SWTH" className='SWTH' /> </a>
        </button>
      </div>
    </div>);
};

export default BuyPage;
