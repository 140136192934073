import { createSlice } from '@reduxjs/toolkit';
import { SimpleMap } from 'utils';

const usdValues: SimpleMap<number> = {};

interface PoolsState {
    value: SimpleMap<number>
}

const initialState: PoolsState = {
    value: usdValues,
}

export const tokenPricesSlice = createSlice({
  name: 'token_prices',
  initialState,
  reducers: {
    updateTokenPrices: (state, action) => {
        state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateTokenPrices } = tokenPricesSlice.actions

export default tokenPricesSlice.reducer;
