import React, { Dispatch, SetStateAction } from "react";
import "./BridgeHistoryError.css";
import ConnectWallet from "assets/ConnectWallet.svg";

interface Props {
  width: number;
  setShowBridge: Dispatch<SetStateAction<boolean>>;
  mainText: string;
  buttonText: string;
}

const BridgeHistoryError: React.FC<Props> = ({ width, setShowBridge, mainText, buttonText }) => {
  const handleClick = () => {
    setShowBridge(true)
  }
  return (
    <div className="bridge-table-connect-inner-wrapper" style={{ width: `${width}px` }}>
      <img src={ConnectWallet} alt="logo" />
      <span style={{ fontSize: "16px" }}>{mainText}</span>
      <button className="button-theme button-theme-primary" style={{ width: "100%", fontSize: "14px", height: "48px" }} onClick={handleClick}>{buttonText}</button>
    </div>
  );
};

export default BridgeHistoryError;
