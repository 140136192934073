import { statusColourMap } from 'constants/stake'
import React from "react";
import "./Signal.css";

type Props = {
  total: number;
  num: number;
  style?: React.CSSProperties;
}

const Signal: React.FC<Props> = ({ total, num, style }) => {
  const statusMap = new Map<typeof num, number>([
    [1, 1],
    [2, 2],
    [3, 2],
    [4, 3],
    [5, 3],
  ])
  const status = statusMap.get(num) ?? 1;

  return (
    <div className="signal" style={style}>
      {
        Array.apply(null, Array(total)).map((e, i) => {
          return (
            <div key={i} className="signal-bar" style={{ background: i < num ? statusColourMap.get(status) : "#E7ECEE" }} />
          )
        })
      }
    </div>
  );
}

export default Signal;
