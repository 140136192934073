import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AsyncEventSchema {
  error: Error | null;
  loading: boolean;
}

interface AsyncEventInputSchema {
  error?: Error | null;
  loading?: boolean;
}

interface AsyncSliceSchema {
  initWallet: AsyncEventSchema;
  approveAllowance: AsyncEventSchema;
  bridge: AsyncEventSchema;
  stake: AsyncEventSchema;
  changeNetwork: AsyncEventSchema;
}

const initialAsyncState: AsyncEventSchema = {
  error: null,
  loading: false,
}

const initialState: AsyncSliceSchema = {
  initWallet: initialAsyncState,
  approveAllowance: initialAsyncState,
  bridge: initialAsyncState,
  stake: initialAsyncState,
  changeNetwork: initialAsyncState,
}

export const asyncSlice = createSlice({
  name: 'async',
  initialState,
  reducers: {
    updateInitWalletAsync: (state, action: PayloadAction<AsyncEventInputSchema>) => {
      if (action.payload.error !== undefined) { state.initWallet.error = action.payload.error }
      if (action.payload.loading !== undefined) { state.initWallet.loading = action.payload.loading }
    },
    updateApproveAllowanceAsync: (state, action: PayloadAction<AsyncEventInputSchema>) => {
      if (action.payload.error !== undefined) { state.approveAllowance.error = action.payload.error }
      if (action.payload.loading !== undefined) { state.approveAllowance.loading = action.payload.loading }
    },
    updateBridgeAsync: (state, action: PayloadAction<AsyncEventInputSchema>) => {
      if (action.payload.error !== undefined) { state.bridge.error = action.payload.error }
      if (action.payload.loading !== undefined) { state.bridge.loading = action.payload.loading }
    },
    updateStakeAsync: (state, action: PayloadAction<AsyncEventInputSchema>) => {
      if (action.payload.error !== undefined) { state.stake.error = action.payload.error }
      if (action.payload.loading !== undefined) { state.stake.loading = action.payload.loading }
    },
    updateChangeNetworkAsync: (state, action: PayloadAction<AsyncEventInputSchema>) => {
      if (action.payload.error !== undefined) { state.changeNetwork.error = action.payload.error }
      if (action.payload.loading !== undefined) { state.changeNetwork.loading = action.payload.loading }
    },
  }
})

export const { updateApproveAllowanceAsync, updateBridgeAsync, updateStakeAsync, updateChangeNetworkAsync, updateInitWalletAsync } = asyncSlice.actions

export default asyncSlice.reducer;
