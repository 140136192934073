import { useAppDispatch, useAppSelector } from "hooks";
import { updateEth, updateAddress, initWalletAction } from "store/Wallet";
import { ethers } from "ethers";
import { WalletNames } from "constants/types";
import { isEvm } from "utils/evm";
import React, { useEffect } from "react";

const Listener:React.FC = () => {
  const walletState = useAppSelector(state => state.wallet);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (walletState.eth.provider) {
      const provider = walletState.eth.provider.provider as any;

      const handleAccountsChanged = (account: string[]) => {
        if (account.length === 0) {
          dispatch(updateEth({ provider: null }));
          if (isEvm(walletState.senderChain, walletState.receiverChain)) {
            dispatch(updateAddress({ sender: null, senderWallet: null, receiver: null, receiverWallet: null }));
          } else if (isEvm(walletState.senderChain)) {
            dispatch(updateAddress({ sender: null, senderWallet: null }));
          } else if (isEvm(walletState.receiverChain)) {
            dispatch(updateAddress({ receiver: null, receiverWallet: null }));
          }
          return;
        }
        let address = account[0];
        if (isEvm(walletState.senderChain)) {
          dispatch(updateAddress({ sender: address, senderWallet: WalletNames.Metamask }));
        }
        if (isEvm(walletState.receiverChain)) {
          dispatch(updateAddress({ receiver: address, receiverWallet: WalletNames.Metamask }));
        }
      }

      const handleChainChanged = () => {
        const newWalletProvider = new ethers.providers.Web3Provider(window.ethereum);
        dispatch(updateEth({ provider: newWalletProvider }));
        dispatch(initWalletAction({ side: "sender", chain: walletState.senderChain }));
        dispatch(initWalletAction({ side: "receiver", chain: walletState.receiverChain }));
      }

      const handleDisconnect = () => {
        dispatch(updateEth({ provider: null }));
        if (isEvm(walletState.senderChain, walletState.receiverChain)) {
          dispatch(updateAddress({ sender: null, senderWallet: null, receiver: null, receiverWallet: null }));
        } else if (isEvm(walletState.senderChain)) {
          dispatch(updateAddress({ sender: null, senderWallet: null }));
        } else if (isEvm(walletState.receiverChain)) {
          dispatch(updateAddress({ receiver: null, receiverWallet: null }));
        }
      }
      if ((provider as any).isWalletConnect) {
        provider.on("disconnect", handleDisconnect);
      }
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);

      return () => {
        provider.removeListener("accountsChanged", handleAccountsChanged);
        provider.removeListener("chainChanged", handleChainChanged);
        provider.removeListener("disconnect", handleDisconnect);
      }
    }
  },[walletState.eth.provider, walletState.senderChain, walletState.receiverChain, dispatch]);
  return null;
};

export default Listener;
