export const generatePageNumbers = (
  currentPage: number,
  pageLength: number
): (number | string)[] => {
  if (pageLength <= 1) {
    return [1]
  }
  let pages: number[] = [1, pageLength]
  if (currentPage === 1) {
    currentPage++
  } else if (currentPage === pageLength) {
    currentPage--
  }
  const middlePages = [currentPage - 1, currentPage, currentPage + 1]
  middlePages.forEach((page) => {
    if (page > 1 && page < pageLength) {
      pages.splice(pages.length - 1, 0, page)
    }
  })
  let pageButtons: (number | string)[] = pages
  if (pages[1] - 1 !== 1) {
    pageButtons.splice(1, 0, "...")
  }
  if (pages[pages.length - 2] + 1 !== pageLength) {
    pageButtons.splice(pageButtons.length - 1, 0, "...")
  }
  return pageButtons
}