import { createSlice } from '@reduxjs/toolkit';

export type stats = {
    liquidity: number,
    volume: number
}

export type demexStats = {
    usd_liquidity_in: number,
    usd_liquidity_out: number,
    usd_volume: number,

    eth_liquidity_in: number,
    eth_liquidity_out: number,
    eth_volume: number
}

const market_stats: stats = {
    liquidity: 0,
    volume: 0
}

const demex_stats: demexStats = {
    usd_liquidity_in: 0,
    usd_liquidity_out: 0,
    usd_volume: 0,
    eth_liquidity_in: 0,
    eth_liquidity_out: 0,
    eth_volume: 0
}

interface marketStats {
    demexStats: demexStats,
    zilStats: stats
    sushiStats: stats
    uniStats: stats
    osmosisStats: stats
    marketStatsLoaded: boolean
}

const initialState: marketStats = {
    demexStats: demex_stats,
    zilStats: market_stats,
    sushiStats: market_stats,
    uniStats: market_stats,
    osmosisStats: market_stats,
    marketStatsLoaded: false
}

export const marketSlice = createSlice({
    name: 'marketStats',
    initialState,
    reducers: {
        updateMarketStats: (state, action) => {
            state.demexStats = action.payload.demexStats;
            state.zilStats = action.payload.zilStats;
            state.sushiStats = action.payload.sushiStats;
            state.uniStats = action.payload.uniStats;
            state.osmosisStats = action.payload.osmosisStats;
            state.marketStatsLoaded = true;
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateMarketStats } = marketSlice.actions

export default marketSlice.reducer;
