import { fromBech32Address } from "@zilliqa-js/zilliqa"
import Exchange from "assets/Exchange.svg"
import ExchangeBranding from "assets/Exchange_Branding.svg"
import Loading_Dark from "assets/Loading_Transparent.gif"
import Refresh from "assets/Refresh.svg"
import RefreshBranding from "assets/Refresh_Branding.svg"
import BigNumber from "bignumber.js"
import { AddressUtils, CarbonSDK, CarbonTx, Models, Zilpay } from "carbon-js-sdk"
import { ETHClient, ZILClient } from "carbon-js-sdk/lib/clients"
import { TokensWithExternalBalance } from "carbon-js-sdk/lib/util/external"
import { appendHexPrefix } from "carbon-js-sdk/lib/util/generic"
import AddressLabel from "components/Common/AddressLabel"
import ChainIcon from "components/Common/ChainIcon"
import DropDown, { DropdownItem } from "components/Common/DropDown"
import Notification from "components/Common/Notification"
import Popup from "components/Common/Popups/Popup"
import CoinIcon from "components/Common/Tokens/CoinIcon"
import { warningContent } from "constants/bridge"
import { contractABIs, RpcUrl } from "constants/evm"
import { BN_ZERO } from "constants/math"
import { ChainNames, Network } from "constants/types"
import { ethers } from "ethers"
import { useAppDispatch, useAppSelector, useAsyncTask } from "hooks"
import { useBridgeOptions } from "hooks/useBridgeOptions"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { addToastItem } from "store/Toast"
import { updateBridgeUI, updatePopup } from "store/UI"
import { approveAllowanceAction, bridgeCarbonTokenAction, bridgeEvmTokenAction, bridgeNeoTokenAction, bridgeZilTokenAction, convertCarbonTokensAction, convertEVMTokensAction, disconnectWalletAction, fetchTokenPairs, initWalletAction, updateBalance, updateCarbonWallet, updateChain, updateTokenDenom } from "store/Wallet"
import { EthLedgerAccount, getETHClient, getNetworkFeeBN, getNetworkMap, getRecoveryAddress, getTokenDenoms, isEvm, shiftDecimals, SimpleMap, uuidv4 } from "utils"
import BridgeProgressPopup from "../../Common/Popups/BridgeProgressPopup"
import ProviderPopup from "../../Common/Popups/ProviderPopup"
import WalletInfoPopup from "../../Common/Popups/WalletInfoPopup"
import "./BridgeCard.css"

interface Props {
  viewHistoryHandler: () => void
}

const BridgeCard: React.FC<Props> = ({ viewHistoryHandler }) => {
  const dispatch = useAppDispatch()
  const walletState = useAppSelector(state => state.wallet)
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const uiState = useAppSelector(state => state.ui.bridge)
  const popupState = useAppSelector(state => state.ui.popup)
  const asyncState = useAppSelector(state => state.async)
  const tokenPairsMap = useAppSelector(state => state.wallet.tokenPairsMap)
  const tokenDenom = useAppSelector(state => state.wallet.tokenDenom)
  const senderCarbonWallet = useAppSelector(state => state.wallet.senderCarbonWallet)
  const sdk = useAppSelector(state => state.app.carbonSDK) as CarbonSDK
  const network = useAppSelector(state => state.app.network)
  const [bridgeSrc, bridgeDest] = useBridgeOptions(tokenDenom)
  const [runLoadBalance, , loadBalanceError] = useAsyncTask()
  const [runLoadAllowance, , loadAllowanceError] = useAsyncTask()
  const [runLoadFee, , loadFeeError] = useAsyncTask()
  const [allowance, setAllowance] = useState<BigNumber>(BN_ZERO)
  const [providerPopupChain, setProviderPopupChain] = useState<ChainNames | null>(null)
  const [providerSide, setProviderSide] = useState<"sender" | "receiver">("sender")
  const [walletInfoPopupSide, setWalletInfoPopupSide] = useState<"sender" | "receiver" | null>(null)
  const [estimatedFee, setEstimatedFee] = useState<BigNumber>(BN_ZERO)
  const [hoverRefreshBalance, setHoverRefreshBalance] = useState<boolean>(false)
  const [clickedRefreshBalance, setClickedRefreshBalance] = useState<boolean>(false)
  const [hoverExchange, setHoverExchange] = useState<boolean>(false)
  const isCarbonEvmBridge = tokenDenom !== "swth"
  const tokensInfoMap = walletState.tokenInfo

  const networkFee = getNetworkFeeBN("swth", sdk, walletState.senderCarbonWallet ?? undefined, CarbonTx.Types.MsgConvertCoin)

  const [bridgeSrcOptions, bridgeDestOptions]: [DropdownItem[], DropdownItem[]] = useMemo(() => ([
    createBridgeList(bridgeSrc), createBridgeList(bridgeDest)
  ]), [bridgeDest, bridgeSrc])

  const { defaultBridgeFromChoiceId, defaultBridgeToChoiceId } = useMemo(() => {
    const senderChainId = bridgeSrc.indexOf(walletState.senderChain)
    const receiverChainId = bridgeDest.indexOf(walletState.receiverChain)

    const senderChainExists = senderChainId > -1
    const receiverChainExists = receiverChainId > -1

    // check if option is selected on other side
    const defaultSrcId = bridgeSrc[0] === bridgeDest[receiverChainId] ? 1 : 0
    const defaultDestId = bridgeDest[0] === bridgeSrc[senderChainId] ? 1 : 0

    const defaultBridgeFromChoiceId = senderChainExists ? senderChainId : defaultSrcId
    const defaultBridgeToChoiceId = receiverChainExists ? receiverChainId : defaultDestId

    if (!senderChainExists) {
      dispatch(disconnectWalletAction({ side: "sender" }))
      dispatch(updateChain({ senderChain: bridgeSrc[defaultBridgeFromChoiceId] }))
    }
    else if (!receiverChainExists) {
      dispatch(disconnectWalletAction({ side: "receiver" }))
      dispatch(updateChain({ receiverChain: bridgeDest[defaultBridgeToChoiceId] }))
    }

    return {
      defaultBridgeFromChoiceId,
      defaultBridgeToChoiceId,
    }
  }, [bridgeDest, bridgeSrc, dispatch, walletState.receiverChain, walletState.senderChain])

  useEffect(() => {
    dispatch(fetchTokenPairs())
  }, [dispatch])

  const fromTokenInfo = useMemo(() => {
    const fromTokenDenom = isCarbonEvmBridge ? tokenDenom : getTokenDenoms(network, walletState.senderChain)
    if (walletState.tokenInfo) {
      return walletState.tokenInfo[fromTokenDenom]
    } else {
      return null
    }
  }, [walletState.tokenInfo, walletState.senderChain, network, tokenDenom, isCarbonEvmBridge])

  const toTokenInfo = useMemo(() => {
    let toTokenDenom = isCarbonEvmBridge ? tokenDenom : getTokenDenoms(network, walletState.receiverChain)
    if (walletState.tokenInfo) {
      return walletState.tokenInfo[toTokenDenom]
    } else {
      return null
    }
  }, [isCarbonEvmBridge, tokenDenom, network, walletState.receiverChain, walletState.tokenInfo])

  const shiftedSenderBalance = useMemo(() => {
    return shiftDecimals(walletState.senderBalance, fromTokenInfo)
  }, [walletState.senderBalance, fromTokenInfo])
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]

  const bnAmount = useMemo(() => {
    const tokenDecimals = parseInt(toTokenInfo?.decimals.toString() ?? '0', 10)
    const parsedAmount = uiState.input.amount.replace(/,/g, "")
    return new BigNumber(parsedAmount).decimalPlaces(tokenDecimals)
  }, [uiState.input.amount, toTokenInfo?.decimals])

  const tokenOptions = useMemo(() => {
    const tokenOptions = [
      { img: <CoinIcon denom="SWTH" />, content: "SWTH", denom: "swth" },
    ]

    if (!tokensInfoMap || !tokenPairsMap) {
      return tokenOptions
    }

    Object.values(tokenPairsMap).forEach((tokenPair) => {
      tokenOptions.push({
        img: <CoinIcon denom={tokenPair.denom} />,
        content: tokensInfoMap[tokenPair.denom]?.symbol ?? tokenPair.denom,
        denom: tokenPair.denom,
      })
    })

    return tokenOptions
  }, [tokenPairsMap, tokensInfoMap])

  const createAssetContract = useCallback((chain: ChainNames, token: Models.Carbon.Coin.Token | null): ethers.Contract | undefined => {
    const networkMap = getNetworkMap(network)
    if ((isEvm(chain) && toTokenInfo?.tokenAddress) || isCarbonEvmBridge) {
      const tokenAddress = isCarbonEvmBridge ? tokenPairsMap?.[tokenDenom]?.erc20Address : appendHexPrefix(token?.tokenAddress!)
      const network = networkMap.get(chain) as Network
      const provider = new ethers.providers.JsonRpcProvider(RpcUrl[network])
      return new ethers.Contract(tokenAddress ?? "", contractABIs[network] ?? [], provider)
    }
  }, [isCarbonEvmBridge, network, toTokenInfo, tokenDenom, tokenPairsMap])

  const [sendAssetContract, receiveAssetContract] = useMemo(() => ([
    createAssetContract(walletState.senderChain, fromTokenInfo),
    createAssetContract(walletState.receiverChain, toTokenInfo),
  ]), [createAssetContract, walletState.senderChain, walletState.receiverChain, fromTokenInfo, toTokenInfo])

  const changeDropdownHandler = (optionId: number, dropdownId: number | undefined = 0) => {
    const senderDropdown = dropdownId === 0
    const chainName: ChainNames = senderDropdown ? bridgeSrc[optionId] : bridgeDest[optionId]

    if (senderDropdown) { // sender dropdown
      if (chainName === walletState.receiverChain) {
        swapWalletProvider()
      } else {
        dispatch(updateChain({ senderChain: chainName }))
        dispatch(initWalletAction({ side: "sender", chain: chainName }))
      }
    } else { // receive dropdown
      if (chainName === walletState.senderChain) {
        swapWalletProvider()
      } else {
        dispatch(updateChain({ receiverChain: chainName }))
        dispatch(initWalletAction({ side: "receiver", chain: chainName }))
      }
    }
  }

  const handleTokenDropdown = (optionId: number, id: number | undefined) => {
    dispatch(updateBalance({ senderBalance: BN_ZERO, receiverBalance: BN_ZERO }))
    dispatch(updateTokenDenom(tokenOptions[optionId].denom))
  }

  const connectWallet = (side: "sender" | "receiver") => {
    if (walletState.receiverChain === walletState.senderChain) {
      return
    }
    setProviderSide(side)
    const chain = side === "sender" ? walletState.senderChain : walletState.receiverChain
    setProviderPopupChain(chain)
  }

  const reloadFeeInfo = () => {
    runLoadFee(async () => {
      let feeAmount: BigNumber = BN_ZERO
      if (sdk && toTokenInfo && !isCarbonEvmBridge && toTokenInfo.denom !== "swth") {
        const feeResult = await sdk.hydrogen.getFeeQuote({ token_denom: toTokenInfo.denom })
        feeAmount = new BigNumber(feeResult.withdrawal_fee)
      }
      setEstimatedFee(feeAmount)
    })
  }

  const reloadBalance = (side: "sender" | "receiver") => {
    runLoadBalance(async () => {
      let balance: BigNumber
      const address = side === "sender" ? walletState.senderAddress : walletState.receiverAddress
      const token = side === "sender" ? fromTokenInfo : toTokenInfo
      const chain = side === "sender" ? walletState.senderChain : walletState.receiverChain
      const assetContract = side === "sender" ? sendAssetContract : receiveAssetContract
      const balanceSide = side === "sender" ? "senderBalance" : "receiverBalance"

      // TODO: change to a switch (pls)
      if (!address || !token || !sdk) {
        balance = BN_ZERO
      } else if (isEvm(chain) || chain === ChainNames.CarbonEVM) {
        if (!assetContract) throw new Error(`No asset contract for ${side}`)
        const rawBalance = await assetContract.balanceOf(address)
        balance = new BigNumber(rawBalance.toString())
      } else if (chain === ChainNames.CarbonCore) {
        balance = BN_ZERO
        const rawBalance = await sdk?.query.bank.Balance({
          address,
          denom: token.denom,
        })
        if (rawBalance && rawBalance.balance) {
          balance = new BigNumber(rawBalance.balance.amount)
        }
      } else if (chain === ChainNames.Zil) {
        balance = BN_ZERO
        const zilHexAddress = fromBech32Address(address).toLowerCase()
        const tokenList = await sdk.zil.getExternalBalances(sdk, zilHexAddress, [token.denom]).catch((err) => console.error(err))
        if (tokenList && tokenList.length >= 0) {
          tokenList.forEach((queriedToken: TokensWithExternalBalance) => {
            if (token.denom === queriedToken.denom) {
              balance = new BigNumber(queriedToken.externalBalance)
            }
          })
        }
      } else if (chain === ChainNames.Neo) {
        balance = BN_ZERO
        const rpcUrl = await sdk.neo.getProviderUrl()
        const tokenList = await sdk.neo.getExternalBalances(sdk, address, rpcUrl, [token.denom])
        tokenList.forEach((queriedToken: TokensWithExternalBalance) => {
          if (token.denom === queriedToken.denom) {
            balance = new BigNumber(queriedToken.externalBalance)
          }
        })
      } else if (chain === ChainNames.Neo3) {
        balance = BN_ZERO
        const tokenList = await sdk.n3.getExternalBalances(sdk, address, [token.denom])
        tokenList.forEach((queriedToken: TokensWithExternalBalance) => {
          if (token.denom === queriedToken.denom) {
            balance = new BigNumber(queriedToken.externalBalance)
          }
        })
      } else {
        balance = BN_ZERO
      }

      const balanceObj: SimpleMap<BigNumber> = {}
      balanceObj[balanceSide] = balance
      dispatch(updateBalance(balanceObj))
    })
  }

  function getEthLockProxyAddress() {
    switch (walletState.senderChain) {
      case ChainNames.Bsc: {
        return sdk.networkConfig.bsc.lockProxyAddr
      }
      case ChainNames.Arbitrum: {
        return sdk.networkConfig.arbitrum.lockProxyAddr
      }
      default: {
        return sdk.networkConfig.eth.lockProxyAddr
      }
    }
  }


  const reloadAllowance = () => {
    runLoadAllowance(async () => {
      if (!walletState.senderAddress || !sdk) {
        setAllowance(BN_ZERO)
        return
      }
      if (isEvm(walletState.senderChain) && sendAssetContract) {
        const ethClient: ETHClient = getETHClient(sdk, walletState.senderChain, network)
        let contractAddress: string = ""
        if (walletState.receiverChain === ChainNames.CarbonCore) {
          contractAddress = getEthLockProxyAddress()
        } else {
          contractAddress = ethClient.getBridgeEntranceAddr()
        }
        if (!contractAddress) {
          setAllowance(BN_ZERO)
          return
        }
        const allowance = await sendAssetContract.allowance(walletState.senderAddress, contractAddress)
        const bnAllowance = new BigNumber(allowance.toString())
        setAllowance(bnAllowance)
      } else if (walletState.senderChain === ChainNames.CarbonCore) {
        // set allowance equal to balance for carbon wallets (no need for allowance step)
        let balance = BN_ZERO
        if (fromTokenInfo) {
          const sendBalance = await sdk?.query.bank.Balance({
            address: walletState.senderAddress,
            denom: fromTokenInfo.denom,
          })
          if (sendBalance && sendBalance.balance) {
            balance = new BigNumber(sendBalance.balance.amount)
          }
        }
        setAllowance(balance)
      } else if (walletState.senderChain === ChainNames.Zil && (walletState.zil.zilPay || walletState.zil.boltX) && fromTokenInfo) {
        let bnAllowance: BigNumber = BN_ZERO
        const zilHexAddress = fromBech32Address(walletState.senderAddress).toLowerCase()
        let contractAddress: string = ""
        if (walletState.receiverChain === ChainNames.CarbonCore) {
          contractAddress = sdk.zil.getLockProxyAddress()
        } else {
          contractAddress = sdk.zil.getBridgeEntranceAddr()
        }
        bnAllowance = await sdk.zil.checkAllowanceZRC2(
          fromTokenInfo,
          zilHexAddress,
          contractAddress.toLowerCase(),
        )
        setAllowance(bnAllowance)
      } else {
        setAllowance(BN_ZERO)
      }
    })
  }

  const approveAllowance = async () => {
    if (!fromTokenInfo || !sdk || asyncState.approveAllowance.loading) return
    let contractAddr: string = ""
    let walletProviderSender: ethers.providers.Web3Provider | EthLedgerAccount | Zilpay
    let client: ETHClient | ZILClient
    if (isEvm(walletState.senderChain)) {
      client = getETHClient(sdk, walletState.senderChain, network)

      if (walletState.eth.provider) {
        walletProviderSender = walletState.eth.provider
      } else if (walletState.eth.ledger) {
        walletProviderSender = walletState.eth.ledger
      } else return

    } else if (walletState.senderChain === ChainNames.Zil) {
      if (walletState.zil.zilPay) {
        walletProviderSender = walletState.zil.zilPay
        client = sdk.zil
      } else return
    } else {
      return
    }

    if (walletState.receiverChain === ChainNames.CarbonCore) {
      if (isEvm(walletState.senderChain)) {
        contractAddr = getEthLockProxyAddress()
      } else {
        contractAddr = client.getLockProxyAddress()
      }
    } else {
      contractAddr = client.getBridgeEntranceAddr()
    }

    dispatch(approveAllowanceAction({
      senderChain: walletState.senderChain,
      token: fromTokenInfo,
      purpose: "bridge",
      walletProviderSender,
      contractAddr,
      reloadAllowance,
    }))
  }

  const bridgeTokens = () => {
    if (asyncState.bridge.loading || !sdk || !fromTokenInfo || !walletState.receiverAddress) {
      return
    }

    if (isCarbonEvmBridge && sendAssetContract && tokenPairsMap) {
      const decimals = fromTokenInfo.decimals
      if (walletState.senderChain === ChainNames.CarbonEVM) {
        dispatch(convertEVMTokensAction({
          contractAddress: sendAssetContract.address,
          receiverAddress: walletState.receiverAddress,
          senderAddress: walletState.senderAddress ?? "",
          amount: bnAmount.shiftedBy(decimals.toNumber()).toString(10),
          senderWalletProviderAgent: senderCarbonWallet?.providerAgent ?? "",
          reloadBalance,
        }))
      } else if (walletState.senderChain === ChainNames.CarbonCore) {
        dispatch(convertCarbonTokensAction({
          receiverAddress: walletState.receiverAddress,
          senderAddress: walletState.senderAddress ?? "",
          denom: tokenDenom,
          amount: bnAmount.shiftedBy(decimals.toNumber()).toString(10),
          senderWalletProviderAgent: senderCarbonWallet?.providerAgent ?? "",
          reloadBalance,
        }))
      }
      return
    }

    if (!toTokenInfo) {
      return
    }

    if (isEvm(walletState.senderChain)) { // From EVM
      let walletProviderSender: ethers.providers.Web3Provider | EthLedgerAccount
      if (walletState.eth.provider) {
        walletProviderSender = walletState.eth.provider
      } else if (walletState.eth.ledger) {
        walletProviderSender = walletState.eth.ledger
      } else return
      dispatch(bridgeEvmTokenAction({
        senderChain: walletState.senderChain,
        receiverChain: walletState.receiverChain,
        fromToken: fromTokenInfo,
        toToken: toTokenInfo,
        amount: bnAmount,
        recoveryAddress: getRecoveryAddress(network),
        receiverAddress: walletState.receiverAddress,
        walletProviderSender,
        feeAmount: estimatedFee,
        reloadBalance,
        reloadAllowance,
        setAllowance,
        signCompleteCallback: () => {
          dispatch(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
        }
      }))
    } else if (walletState.senderChain === ChainNames.CarbonCore) { // From Carbon
      dispatch(bridgeCarbonTokenAction({
        receiverChain: walletState.receiverChain,
        receiverAddress: walletState.receiverAddress,
        toToken: toTokenInfo,
        amount: bnAmount,
        feeAddress: AddressUtils.SWTHAddress.encode(sdk.networkConfig.feeAddress, { bech32Prefix: sdk.networkConfig.Bech32Prefix }),
        feeAmount: estimatedFee,
        reloadBalance,
        reloadAllowance,
      }))
    } else if (walletState.senderChain === ChainNames.Zil) { // From Zil
      if (!walletState.zil.zilPay) return
      dispatch(bridgeZilTokenAction({
        receiverChain: walletState.receiverChain,
        fromToken: fromTokenInfo,
        toToken: toTokenInfo,
        amount: bnAmount,
        recoveryAddress: getRecoveryAddress(network),
        receiverAddress: walletState.receiverAddress,
        walletProviderSender: walletState.zil.zilPay,
        feeAmount: estimatedFee,
        reloadAllowance,
        reloadBalance,
        setAllowance,
        signCompleteCallback: () => {
          dispatch(updatePopup({ purpose: null, buttonText: "", transactionLink: "" }))
        }
      }))
    } else if (walletState.senderChain === ChainNames.Neo) { // From Neo
      if (!walletState.neo.o3Wallet) return
      dispatch(bridgeNeoTokenAction({
        receiverChain: walletState.receiverChain,
        fromToken: fromTokenInfo,
        toToken: toTokenInfo,
        amount: bnAmount,
        recoveryAddress: getRecoveryAddress(network),
        receiverAddress: walletState.receiverAddress,
        o3Wallet: walletState.neo.o3Wallet,
        reloadAllowance,
        reloadBalance,
        setAllowance,
      }))
    }
  }

  useEffect(() => {
    if (network !== CarbonSDK.Network.DevNet) {
      reloadFeeInfo() // set fee to 0 for devnet
    }
  }, [sdk, toTokenInfo, network]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!walletState.senderAddress || !walletState.receiverAddress) {
      dispatch(updateBridgeUI({ button: { text: "Please connect wallet" } }))
      return
    };

    let allowanceAmount = shiftDecimals(allowance, fromTokenInfo)
    if (bnAmount.gt(allowanceAmount) && !isCarbonEvmBridge) {
      dispatch(updateBridgeUI({ button: { text: "Approve" } }))
    } else if (allowance.gte(BN_ZERO)) {
      dispatch(updateBridgeUI({ button: { text: "Bridge" } }))
    }
  }, [walletState.senderAddress, walletState.receiverAddress, allowance, bnAmount, fromTokenInfo, dispatch, isCarbonEvmBridge])

  useEffect(() => {
    if ((uiState.button.text === "Approve" || uiState.button.text === "Bridge") && (!uiState.statusMessage) && !uiState.input.error && bnAmount && bnAmount.gt(BN_ZERO)) {
      dispatch(updateBridgeUI({ button: { enabled: true } }))
      return
    }
    dispatch(updateBridgeUI({ button: { enabled: false } }))
  }, [uiState.statusMessage, uiState.button.text, uiState.input.error, bnAmount, asyncState.approveAllowance.error, dispatch])

  useEffect(() => {
    const sendBalanceTimeout = setTimeout(() => {
      reloadBalance("sender")
      reloadAllowance()
    }, 1000)
    return () => clearTimeout(sendBalanceTimeout)
  }, [walletState.senderAddress, fromTokenInfo]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const receiveBalanceTimeout = setTimeout(() => {
      reloadBalance("receiver")
    }, 1000)
    return () => clearTimeout(receiveBalanceTimeout)
  }, [walletState.receiverAddress, toTokenInfo]) // eslint-disable-line react-hooks/exhaustive-deps

  // error handling
  useEffect(() => {
    let error: string | undefined
    let timeout: NodeJS.Timeout | undefined
    if (walletState.senderChain === walletState.receiverChain) { // same network on both sides
      dispatch(updateBridgeUI({ statusMessage: warningContent.sameNetwork }))
      dispatch(disconnectWalletAction({ side: "sender" }))
      dispatch(disconnectWalletAction({ side: "receiver" }))
      return
    }
    if (asyncState.initWallet.error) {
      timeout = setTimeout(() => {
        dispatch(addToastItem({
          id: uuidv4(),
          senderAddress: null,
          receiverAddress: null,
          senderChain: null,
          receiverChain: null,
          transactionHash: null,
          error: asyncState.initWallet.error
        }))
      }, 500)
    }
    if (asyncState.changeNetwork.error) {
      error = asyncState.changeNetwork.error.message
    }
    if (loadBalanceError) {
      error = "Errors with loading balance"
    }
    if (loadAllowanceError) {
      error = loadAllowanceError.message
    }

    if (loadFeeError) {
      error = "Failed to fetch fee"
    }

    if (uiState.input.error) {
      error = "Please make the relevant changes to proceed"
    }

    if (error) {
      dispatch(updateBridgeUI({ statusMessage: { category: "error", message: error } }))
    } else {
      dispatch(updateBridgeUI({ statusMessage: null }))
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }

  }, [dispatch, walletState.senderChain, walletState.receiverChain, asyncState.initWallet.error, asyncState.changeNetwork.error, loadBalanceError, loadAllowanceError, loadFeeError, uiState.input.error])

  const inputChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
    slider?: string
  ) => {
    const targetValue: string = e.currentTarget.value
    const filteredTargetValue: string = targetValue.replace(/[A-Za-z!@#$%^&*(), ]/g, "")
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    if (slider) {
      dispatch(updateBridgeUI({ input: { amount: shiftedSenderBalance.times(parsedTargetValue / 100).toFormat(), percentage: parsedTargetValue } }))
    } else if (Number.isNaN(parsedTargetValue)) {
      dispatch(updateBridgeUI({ input: { amount: "", percentage: 0 } }))
    } else if (filteredTargetValue.split(".").length === 2) {
      const [int, decimals] = filteredTargetValue.split(".")
      let percentage = new BigNumber(parsedTargetValue).dividedBy(shiftedSenderBalance).times(100).toNumber()
      percentage = isFinite(percentage) ? percentage : 100
      dispatch(updateBridgeUI({ input: { amount: new BigNumber(int).toFormat() + `.${decimals}`, percentage } }))
    } else {
      let percentage = new BigNumber(parsedTargetValue).dividedBy(shiftedSenderBalance).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(100, percentage) : 100
      dispatch(updateBridgeUI({ input: { amount: new BigNumber(parsedTargetValue).toFormat(), percentage } }))
    }
  }

  const numbersClickHandler = (percentage: number) => {
    if (walletState.senderBalance !== BN_ZERO) {
      dispatch(updateBridgeUI({ input: { percentage, amount: shiftedSenderBalance.times(percentage / 100).toFormat() } }))
    }
  }

  useEffect(() => {
    // for manipulating slider progress bar color
    const slider = document.getElementById("slider")
    if (slider !== null) {
      slider.style.background = `linear-gradient(to right, #0A3F52 0%, #0A3F52 ${uiState.input.percentage}%, #E7ECEE ${uiState.input.percentage}%, #E7ECEE 100%)`
      slider.oninput = function () {
        slider.style.background = `linear-gradient(to right, #0A3F52 0%, #0A3F52 ${uiState.input.percentage}%, #E7ECEE ${uiState.input.percentage}%, #E7ECEE 100%)`
      }
    }
  }, [uiState.input.percentage])

  // for input box border colors
  let rangeInput = document.getElementById("amount")

  rangeInput?.addEventListener("focus", () => {
    dispatch(updateBridgeUI({ input: { focus: true } }))
  })
  rangeInput?.addEventListener("blur", () => {
    dispatch(updateBridgeUI({ input: { focus: false } }))
  })

  useEffect(() => {
    if (bnAmount.lt(shiftDecimals(estimatedFee, toTokenInfo))) {
      dispatch(updateBridgeUI({ input: { error: "Enter an amount that exceeds the estimated fee." } }))
    } else if (bnAmount.gt(shiftedSenderBalance) || (bnAmount.isEqualTo(shiftedSenderBalance) && bnAmount.isEqualTo(BN_ZERO))) {
      dispatch(updateBridgeUI({ input: { error: "The amount entered exceeds your wallet balance." } }))
    } else {
      dispatch(updateBridgeUI({ input: { error: null } }))
    }
  }, [bnAmount, shiftedSenderBalance, toTokenInfo, estimatedFee, dispatch])

  const swapWalletProvider = () => {
    if (!asyncState.approveAllowance.loading && !asyncState.bridge.loading) {
      if (!bridgeSrc.includes(walletState.receiverChain))
        return;

      dispatch(updateBridgeUI({ input: { amount: "", percentage: 0 } }))
      // disconnect both wallets if swap trustwallet / ledger bsc to sender
      if (walletState.receiverChain === ChainNames.Bsc && walletState.receiverAddress && (walletState.eth.ledger || !walletState.eth.provider?.provider.isMetaMask)) {
        dispatch(disconnectWalletAction({ side: "sender" }))
      }

      dispatch(updateChain({
        senderChain: walletState.receiverChain,
        receiverChain: walletState.senderChain,
      }))

      dispatch(updateCarbonWallet({ side: "sender", carbonWallet: walletState.receiverCarbonWallet }))
      dispatch(updateCarbonWallet({ side: "receiver", carbonWallet: walletState.senderCarbonWallet }))
      dispatch(initWalletAction({ side: "sender", chain: walletState.receiverChain }))
      dispatch(initWalletAction({ side: "receiver", chain: walletState.senderChain }))
    }
  }

  const selectedTokenId = tokenOptions.map(token => token.denom).indexOf(tokenDenom)
  const estimatedTimeText = isCarbonEvmBridge ? "2 Seconds" : "15 Minutes"

  return (
    <div className="bridge-card-inner-wrapper">
      <div className="bridge-card theme-color bolded-700">
        <span className="bridge-card-header">
          Bridge
        </span>
        <div className="from-to">
          <div className="dropdown-wrapper">
            <span> From </span>
            <div className="bridge-card-dropdown">
              <DropDown selectOption={changeDropdownHandler} defaultOption={defaultBridgeFromChoiceId} options={bridgeSrcOptions} id={0} />
            </div>
            {(!asyncState.initWallet.loading && !asyncState.changeNetwork.loading)
              ? (!walletState.senderAddress || !walletState.senderWallet) ? <button className="button-theme button-theme-primary" onClick={() => connectWallet("sender")}> Connect Wallet </button>
                : <div className="wallet-address-holder" onClick={() => { setWalletInfoPopupSide("sender") }}>
                  <AddressLabel logoName={walletState.senderWallet} text={walletState.senderAddress ?? ""} bolded={true} />
                </div>
              : <button className="button-theme button-theme-primary"> <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" />  </button>}
          </div>
          <img src={hoverExchange ? ExchangeBranding : Exchange} id="bridge-icon" alt="bridge-icon" onClick={swapWalletProvider} onMouseOver={() => setHoverExchange(true)} onMouseLeave={() => setHoverExchange(false)} />
          <div id="to" className="dropdown-wrapper">
            <span> To </span>
            <div className="bridge-card-dropdown">
              <DropDown selectOption={changeDropdownHandler} defaultOption={defaultBridgeToChoiceId} options={bridgeDestOptions} id={1} />
            </div>
            {(!asyncState.initWallet.loading && !asyncState.changeNetwork.loading)
              ? (!walletState.receiverAddress || !walletState.receiverWallet) ? <button className="button-theme button-theme-primary" onClick={() => connectWallet("receiver")}> Connect Wallet </button>
                : <div className="wallet-address-holder" onClick={() => { setWalletInfoPopupSide("receiver") }}>
                  <AddressLabel logoName={walletState.receiverWallet} text={walletState.receiverAddress ?? ""} bolded={true} />
                </div>
              : <button className="button-theme button-theme-primary"> <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" />  </button>}
          </div>
        </div>
        <div className={`bridge-amount ${walletState.senderBalance === BN_ZERO ? "bridge-amount-disabled" : undefined}`}>
          <span> Bridge Amount</span>
          <label htmlFor="amount" className={`bridge-amount-input ${uiState.input.focus ? "active-border" : "default-border"}`} style={uiState.input.error ? { border: "1px solid #DC6D5E" } : undefined}>
            <input type="text" placeholder="0" min="0" id="amount" value={uiState.input.amount} onChange={inputChangeHandler} style={uiState.input.error ? { color: "#DC6D5E" } : undefined} disabled={walletState.senderBalance === BN_ZERO} onFocus={() => { dispatch(updateBridgeUI({ input: { focus: true } })) }} />
            <div className="tokens-container">
              <DropDown className="token-dropdown" selectOption={handleTokenDropdown} defaultOption={selectedTokenId} options={tokenOptions} id={2} />
            </div>
          </label>
          <input type="range" className="slider" id="slider" value={uiState.input.percentage} disabled={walletState.senderBalance === BN_ZERO} onChange={(e: React.FormEvent<HTMLInputElement>) => inputChangeHandler(e, "slider")} onFocus={() =>
            dispatch(updateBridgeUI({ input: { focus: false, amount: shiftedSenderBalance.times(Math.round(uiState.input.percentage) / 100).toFormat(), percentage: Math.round(uiState.input.percentage) } }))
          } />
          <div className="bridge-numbers-bar">
            <span onClick={() => numbersClickHandler(0)}> 0% </span>
            <span onClick={() => numbersClickHandler(25)}> 25% </span>
            <span onClick={() => numbersClickHandler(50)}> 50% </span>
            <span onClick={() => numbersClickHandler(75)}> 75% </span>
            <span onClick={() => numbersClickHandler(100)}> Max </span>
          </div>
          <div className="bridge-balance-box">
            {shiftedSenderBalance.isEqualTo(BN_ZERO) &&
              <img
                src={(hoverRefreshBalance && walletState.senderBalance !== BN_ZERO) ? RefreshBranding : Refresh}
                alt="Refresh"
                onClick={() => {
                  if (walletState.senderBalance !== BN_ZERO) {
                    reloadBalance("sender")
                    reloadBalance("receiver")
                    setClickedRefreshBalance(true)
                  }
                }}
                onMouseOver={() => {
                  setHoverRefreshBalance(true)
                }}
                onMouseLeave={() => setHoverRefreshBalance(false)}
                onAnimationEnd={() => {
                  setClickedRefreshBalance(false)
                }}
                className={`${clickedRefreshBalance ? "bridge-refresh-rotate" : ""} ${!(walletState.senderBalance === BN_ZERO) ? "bridge-refresh-pointer" : ""}`} />}
            <div className="bridge-balance">
              <span className="input-error">{uiState.input.error}</span>
              <span style={{ whiteSpace: "nowrap" }}>Balance:{" "}{loadBalanceError ? 0 : shiftedSenderBalance.toFormat()}</span>
            </div>
          </div>
        </div>
        <div className="bridge-estimations">
          <div><span>Estimated Time</span><span>~ {estimatedTimeText}</span></div>
          {isCarbonEvmBridge ?
            <React.Fragment>
              <div><span>Carbon EVM Network Fee</span><span>{networkFee.toString()} SWTH</span></div>
              <div><span id="usd">USD</span><span>${networkFee.multipliedBy(swthPrice).toFormat(4)}</span></div>
            </React.Fragment>
            :
            <React.Fragment>
              <div><span>Estimated Fee</span><span>{shiftDecimals(estimatedFee, toTokenInfo).toFormat(2)} SWTH</span></div>
              <div><span id="usd">USD</span><span>${shiftDecimals(estimatedFee, toTokenInfo).multipliedBy(swthPrice).toFormat(2)}</span></div>
            </React.Fragment>
          }
        </div>
        <div className="bridge-warning">
          {(!!uiState.statusMessage) && (
            <Notification category={uiState.statusMessage.category} message={(
              <React.Fragment>
                {uiState.statusMessage.message}
              </React.Fragment>
            )} />
          )}
        </div>
        <button id="bottom-button" className="button-theme button-theme-primary" disabled={!uiState.button.enabled} onClick={() => { uiState.button.text === "Approve" ? approveAllowance() : bridgeTokens() }}>
          {asyncState.approveAllowance.loading || asyncState.bridge.loading ? <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" /> : uiState.button.text}
        </button>
        {walletInfoPopupSide &&
          <WalletInfoPopup
            setWalletInfoPopupSide={setWalletInfoPopupSide}
            walletInfoPopupSide={walletInfoPopupSide}
            tokenDenom={tokenDenom}
            balance={walletInfoPopupSide === "sender" ? shiftDecimals(walletState.senderBalance, fromTokenInfo) : shiftDecimals(walletState.receiverBalance, toTokenInfo)}
            onDisconnect={() => dispatch(disconnectWalletAction({ side: walletInfoPopupSide }))} />}
        {uiState.bridgeProcess.popup && <BridgeProgressPopup viewHistoryHandler={viewHistoryHandler} />}
        {popupState.purpose && <Popup purpose={popupState.purpose} />}
        {providerPopupChain && <ProviderPopup providerPopupChain={providerPopupChain} setProviderPopupChain={setProviderPopupChain} providerSide={providerSide} />}
      </div>
    </div>
  )
}

const createBridgeList = (bridges: string[]) => (
  bridges.map((chain: string) => ({ img: <ChainIcon chain={chain} />, content: chain }))
)

export default BridgeCard
