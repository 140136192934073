import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { LoadingTasks } from './types'

export interface TaskSliceSchema {
    loadingTasks: LoadingTasks
    tasksRegistry: { [index: string]: any }
}

const initialState: TaskSliceSchema = {
    loadingTasks: {},
    tasksRegistry: {},
}

const updateTaskViewer = (loadingTasks: LoadingTasks) => {
    if (typeof window === 'undefined') return
    (window as any).loadingTasks = loadingTasks
}

export interface AddBackgroundLoadingAction {
    taskName: string
    taskUuid: string
}

export interface RemoveBackgroundLoadingAction {
    taskUuid: string
}

export const loadingSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        addBackgroundLoading: (state, action: PayloadAction<AddBackgroundLoadingAction>) => {
            const { taskName, taskUuid } = action.payload
            const loadingTask = state.loadingTasks[taskName] || {}
            loadingTask[taskUuid] = dayjs()
            state.tasksRegistry[taskUuid] = taskName

            updateTaskViewer(state.loadingTasks)
            state.loadingTasks = {
                ...state.loadingTasks,
                [taskName]: loadingTask,
            }
            state.tasksRegistry = {
                ...state.tasksRegistry
            }
        },
        removeBackgroundLoading: (state, action: PayloadAction<RemoveBackgroundLoadingAction>) => {
            const { taskUuid } = action.payload
            const taskName = state.tasksRegistry[taskUuid]
            
            if (!taskName) {
                return
            }
            const loadingTask = state.loadingTasks[taskName!]
            if (!loadingTask || !loadingTask[taskUuid]) {
                return
            }

            delete loadingTask[taskUuid]
            if (!Object.keys(loadingTask).length) {
                delete state.loadingTasks[taskName!]
            }
            delete state.tasksRegistry[taskUuid]

            updateTaskViewer(state.loadingTasks)
            state.loadingTasks = {
                ...state.loadingTasks
            }
            state.tasksRegistry = {
                ...state.tasksRegistry
            }
        },
    }
})

export const { addBackgroundLoading, removeBackgroundLoading } = loadingSlice.actions

export default loadingSlice.reducer