import { AddressUtils, CarbonSDK, CosmosLedger } from 'carbon-js-sdk'

export const connectLedger = async (network: CarbonSDK.Network) => {
    const ledger = await new CosmosLedger(
        {},
        AddressUtils.SWTHAddress.keyDerivationPath(), // HDPATH
        AddressUtils.SWTHAddress.getBech32Prefix(network), // BECH32PREFIX
    ).connect()

    return ledger
}