import ChevronsLeft from "assets/ChevronsLeft_Primary.svg"
import Loading_Light from "assets/Loading_light.gif"
import Toggle_Off from "assets/Toggle_Off.svg"
import Toggle_Off_Hover from "assets/Toggle_Off_Hover.svg"
import Toggle_On from "assets/Toggle_On.svg"
import Toggle_On_Hover from "assets/Toggle_On_Hover.svg"
import { CarbonSDK, Insights } from "carbon-js-sdk"
import { NetworkConfigs } from 'carbon-js-sdk/lib/constant'
import { ChainNames, WalletNames } from 'constants/types'
import { useAppDispatch, useAppSelector } from "hooks"
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React, { useEffect, useMemo, useRef, useState } from "react"
import { AppActionTypes, updateCarbonSDK, updateNetwork, updateSelectedNodes } from "store/App"
import { AppTasks } from 'store/App/types'
import { getLocalStorageNetwork } from 'store/localStorage'
import { StakeActionTypes } from 'store/Stake'
import { updateShowNodeForm } from 'store/UI'
import { connectKeplrAction, connectLeapAction, disconnectWalletAction, WalletActionTypes } from 'store/Wallet'
import { reduxAction } from 'utils'
import { CustomNodeItem, sortByRatingLatencies } from 'utils/nodes'
import { capitalise } from "utils/strings"
import { choiceToNetwork, networkToChoice } from "../../constants/stake"
import DropDown from "./DropDown"
import NodeForm from './NodeForm'
import NodeRow from './NodeRow'
import "./NodeSelection.css"

const NodeSelection: React.FC = () => {
  const network = getLocalStorageNetwork()
  const walletState = useAppSelector(state => state.wallet)
  const dispatch = useAppDispatch()
  const nodeSelectionRef = useRef<HTMLDivElement>(null)
  const [showCard, setShowCard] = useState(false)
  const autoSelectInitialState = localStorage.getItem("autoselect-node") ? true : false
  const [autoSelect, setAutoSelect] = useState(autoSelectInitialState)
  const [autoSelectHover, setAutoSelectHover] = useState(false)
  const [connectNodeLoading, setConnectNodeLoading] = useState(false)
  const wallet = useAppSelector(state => state.wallet)
  const nodeList = useAppSelector(state => state.app.nodes)
  const selectedNodes = useAppSelector(state => state.app.selectedNodes)
  const customNodes = useAppSelector(state => state.app.customNodes) as CustomNodeItem[]
  const nodeFormInfo = useAppSelector(state => state.ui.node)
  const localhostNode = NetworkConfigs[CarbonSDK.Network.LocalHost]
  const nodes: Insights.NodeItem[] = useMemo(() => {
    if (network !== CarbonSDK.Network.LocalHost) {
      return nodeList
    } else {
      return [{
        nodeId: '',
        rpcUrl: localhostNode.tmRpcUrl,
        restUrl: localhostNode.restUrl,
        wsUrl: localhostNode.wsUrl,
        tmWsUrl: localhostNode.tmWsUrl,
        faucetUrl: localhostNode.faucetUrl,
        insightsUrl: localhostNode.insightsUrl,
        moniker: `${network} default node`,
        appBuild: network,
        lastupdated: '',
        rpcUptime: '100',
        wsUptime: '100',
        insightUptime: '100',
        appVersion: '',
        appCommit: '',
        latestBlockHeight: 0,
        creator: {
          name: '',
          telegram: '',
        },
      }]
    }
  }, [localhostNode, network, nodeList])
  const ratingLatencies = useAppSelector(state => state.app.latencies)
  const [nodesLoading] = useTaskSubscriber(AppTasks.FetchNodes)

  const selectedNodeId = useMemo(() => selectedNodes[network]?.nodeId, [selectedNodes, network])

  const networkChoices = [
    { content: `Carbon ${capitalise(CarbonSDK.Network.MainNet)}` },
    { content: `Carbon ${capitalise(CarbonSDK.Network.TestNet)}` },
    { content: `Carbon ${capitalise(CarbonSDK.Network.DevNet)}` },
    { content: `Carbon ${capitalise(CarbonSDK.Network.LocalHost)}` }
  ]

  const walletSide = useMemo(() => {
    if (walletState.receiverChain === ChainNames.CarbonCore) {
      return "receiver"
    } else if (walletState.senderChain === ChainNames.CarbonCore) {
      return "sender"
    } else {
      return null
    }
  }, [walletState])

  const setupNetwork = async (network: CarbonSDK.Network) => {
    const sdk = await CarbonSDK.instance({
      network
    })
    dispatch(updateCarbonSDK({ carbonSDK: sdk }))
  }

  const changeNetworkHandler = async (choice: number) => {
    const network = choiceToNetwork.get(choice)
    if (network) {
      await setupNetwork(network)
      dispatch(updateNetwork({ network }))
      dispatch(reduxAction(StakeActionTypes.RELOAD_ALL_STAKING_INFO))

      if (walletSide === "receiver") {
        if (wallet.receiverWallet === WalletNames.Keplr) {
          dispatch(connectKeplrAction({ side: walletSide }))
          return
        }
        if (wallet.receiverWallet === WalletNames.Leap) {
          dispatch(connectLeapAction({ side: walletSide }))
        }
        if (wallet.receiverWallet === WalletNames.Ledger) {
          dispatch(disconnectWalletAction({ side: walletSide }))
          window.location.reload()
        }
      }

      if (walletSide === "sender") {
        if (wallet.senderWallet === WalletNames.Keplr) {
          dispatch(connectKeplrAction({ side: walletSide }))
        }
        if (wallet.senderWallet === WalletNames.Leap) {
          dispatch(connectLeapAction({ side: walletSide }))
        }
        if (wallet.senderWallet === WalletNames.Ledger) {
          dispatch(disconnectWalletAction({ side: walletSide }))
          window.location.reload()
        }
      }
    }
  }
  const showNodeForm = () => {
    dispatch(updateShowNodeForm({ viewOnly: false, showNodeForm: true }))
  }

  const setupSelectNode = async (node: any) => {
    if (network !== node.appBuild) return
    setConnectNodeLoading(true)
    try {

      const newSDK = await CarbonSDK.instance({
        network,
        config: {
          tmRpcUrl: node.rpcUrl,
          restUrl: node.restUrl,
          wsUrl: node.wsUrl,
          faucetUrl: node.faucetUrl,
          insightsUrl: node.insightsUrl,
        },
      })
      dispatch(updateCarbonSDK({ carbonSDK: newSDK }))
      dispatch(updateSelectedNodes({
        ...selectedNodes,
        [network]: node,
      }))
      dispatch(reduxAction(WalletActionTypes.WALLET_CHECK_LOCAL_STORAGE))
    } catch (err) {
      throw new Error((err as Error).message)
    } finally {
      setConnectNodeLoading(false)
    }
  }

  const handleSelectNode = async (node: any) => {
    if (!node) return

    try {
      await setupSelectNode(node)
      localStorage.removeItem("autoselect-node")
      setAutoSelect(false)
    } catch (error) {
      //TODO: add error toast
      // customToast(
      //   `Node connection error: ${(error as Error).message}`,
      //   'Please check the console for more details, or try switching to another node.',
      //   { type: 'error' },
      // )

      if (node.nodeId === selectedNodeId) {
        try {
          await setupSelectNode(recommendedNode)
        } catch (err) {
          console.error(err)
        }
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem("autoselect-node")) {
      setAutoSelect(true)
    }
  }, [])

  const setAutoSelectNode = (bool: boolean) => {
    if (bool) {
      localStorage.setItem("autoselect-node", "autoselect")
      localStorage.removeItem(AppActionTypes.SET_SELECTED_NODES)
      setAutoSelect(true)
    } else {
      localStorage.removeItem("autoselect-node")
      setAutoSelect(false)
    }
  }
  //TODO: sort by rating
  // const sortRating = (nodes: Insights.NodeItem[], direction: string, ratingLats: RatingLatencyObj) => {
  //   return nodes.sort((nodeA: Insights.NodeItem, nodeB: Insights.NodeItem) => {
  //     const ratingA = ratingLats[nodeA.nodeId]?.rating ?? 0
  //     const ratingB = ratingLats[nodeB.nodeId]?.rating ?? 0
  //     return direction === 'desc' ? ratingB - ratingA : ratingA - ratingB
  //   })
  // }

  const recommendedNode = useMemo(() => {
    return nodes.slice().sort(sortByRatingLatencies(ratingLatencies))[0]
  }, [nodes, ratingLatencies])

  //TODO: sort default and custom nodes
  // const {
  //   sortedNodes,
  //   sortedCustomNodes,
  // } = useMemo(() => {
  //   const filteredCustomNodes = customNodes.filter((node: CustomNodeItem) => node.appBuild === network)
  //   switch (sort.prop) {
  //     case 'Name':
  //       return {
  //         sortedNodes: sortName(nodes, sort.direction),
  //         sortedCustomNodes: sortName(filteredCustomNodes, sort.direction),
  //       }
  //     case 'Rating':
  //       return {
  //         sortedNodes: sortRating(nodes, sort.direction, ratingLatencies),
  //         sortedCustomNodes: filteredCustomNodes,
  //       }
  //     default:
  //       return {
  //         sortedNodes: nodes,
  //         sortedCustomNodes: filteredCustomNodes,
  //       }
  //   }
  // }, [nodes, customNodes, sort, network, ratingLatencies])

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      if (nodeSelectionRef.current && showCard && !(nodeSelectionRef.current.contains(e.target as Node))) {
        setShowCard(false)
      }
    }
    document.addEventListener("mousedown", onOutsideClick)
    return () => document.removeEventListener("mousedown", onOutsideClick)
  }, [showCard])

  return (
    <div className="node-selection" ref={nodeSelectionRef}>
      <div className="node-button" onClick={() => setShowCard(!showCard)}>
        <img src={ChevronsLeft} alt="Chevrons Left" className="stake-left-chevron" />
        <img src={ChevronsLeft} alt="Chevrons Left" className="reversed stake-right-chevron" />
      </div>
      <div className="node-tooltip">
        {capitalise(network)}
      </div>
      {showCard &&
        <div className="node-card">
          {nodeFormInfo.showNodeForm
            ?
            <NodeForm handleSelectNode={handleSelectNode} />
            :
            <>
              <div className="node-card-selection">
                <div style={{ width: "180px" }}>
                  <DropDown options={networkChoices} selectOption={changeNetworkHandler} defaultOption={networkToChoice.get(network)} />
                </div>
                <div className={"bolded-700 node-auto-select " + (autoSelectHover ? "auto-select-hover" : "")}>
                  <div>Auto-select</div>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <div>{autoSelect ? "On" : "Off"}</div>
                    <div style={{ display: "flex", alignItems: "center" }} onMouseEnter={() => setAutoSelectHover(true)} onMouseLeave={() => setAutoSelectHover(false)}>
                      {autoSelect
                        ?
                        (autoSelectHover ? <img src={Toggle_On_Hover} alt="toggle on" onClick={() => setAutoSelectNode(false)} /> : <img src={Toggle_On} alt="toggle on" />)
                        :
                        (autoSelectHover ? <img src={Toggle_Off_Hover} alt="toggle off" onClick={() => setAutoSelectNode(true)} /> : <img src={Toggle_Off} alt="toggle off" />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="node-table">
                <div className="node-table-header">
                  <p>Node Name</p>
                  <p>Node Rating</p>
                </div>
                <div className="node-rows">
                  {nodesLoading || connectNodeLoading
                    ?
                    <img src={Loading_Light} className="loading-icon" alt="Loading" />
                    :
                    <>
                      {nodes.map(node => <NodeRow
                        node={node as any}
                        isCustom={false}
                        recommendedNode={recommendedNode}
                        handleSelectNode={handleSelectNode}
                        key={node.nodeId}
                      />)}
                      {customNodes.length > 0 && customNodes.map(node => <NodeRow
                        node={node as any}
                        isCustom={false}
                        recommendedNode={recommendedNode}
                        handleSelectNode={handleSelectNode}
                        key={node.nodeId}
                      />)}
                    </>
                  }
                </div>
                <div className="add-custom-node bolded-700" onClick={() => showNodeForm()}><p>+ Add Custom Node</p></div>
                <p className="host-node">
                  Want to host a new node on Carbon? <br />
                  <a className='underline' href="https://github.com/Switcheo/carbon-bootstrap" target="_blank" rel="noopener noreferrer">Visit Github</a>
                </p>
              </div>
            </>
          }
        </div>
      }
    </div>
  )
}

export default NodeSelection
