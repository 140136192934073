import ChevronsLeft from "assets/ChevronsLeft.svg"
import ChevronsRight from "assets/ChevronsRight.svg"
import BigNumber from "bignumber.js"
import { ChainNames } from "constants/types"
import dayjs from "dayjs"
import { useAppSelector, useAsyncTask } from "hooks"
import queryString from "query-string"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { fetchData } from "saga/Common"
import {
  ChainToEnumMap, CrosschainTransaction, CrosschainTransactionSchema, HydrogenAPIDataSchema, HydrogenAPISchema
} from "store/Wallet"
import "./BridgeHistory.css"
import { generatePageNumbers } from "./utils"
import { CrosschainBridgeHistoryCards, CrosschainBridgeHistoryTable } from "."

interface Props {
  setShowBridge: Dispatch<SetStateAction<boolean>>
};

const processStatus = (transaction: HydrogenAPIDataSchema): "Complete" | "Confirming" | "Receiving" => {
  if (transaction.status === "completed") {
    return "Complete"
  } else if (transaction.bridging_event) {
    return "Confirming"
  } else {
    return "Receiving"
  }
}

const processChain = (chain: keyof ChainToEnumMap): ChainNames => {
  const chainToEnum: ChainToEnumMap = {
    zil: ChainNames.Zil,
    eth: ChainNames.Eth,
    carbon: ChainNames.CarbonCore,
    neo: ChainNames.Neo,
    neo3: ChainNames.Neo3,
    bsc: ChainNames.Bsc,
    arbitrum: ChainNames.Arbitrum,
  }
  return chainToEnum[chain]
}

const processTransactions = (transactions: HydrogenAPISchema, swthPrice: number): CrosschainTransaction[] => {
  const processedTransactions: CrosschainTransaction[] = []
  transactions.data.forEach((transaction: HydrogenAPIDataSchema) => {
    if (transaction.carbon_token_id.includes("swth")) { //only want SWTH token transfers between chains
      const amount: BigNumber = new BigNumber(transaction.amount).shiftedBy(
        -8
      )
      const fee: BigNumber = new BigNumber(
        transaction.fee_amount
      ).shiftedBy(-8)
      processedTransactions.push({
        id: transaction.id,
        dateTime: dayjs(transaction.source_event.created_at),
        fromAddress: transaction.from_address,
        sendHash: transaction.source_event.tx_hash,
        sendChain: processChain(transaction.source_blockchain),
        toAddress: transaction.destination_event?.sender ?? "-",
        receiveHash: transaction.destination_event?.tx_hash,
        receiveChain: processChain(transaction.destination_blockchain),
        amount: amount.decimalPlaces(1).toFormat(),
        amountUSD: amount.multipliedBy(swthPrice).toFormat(2),
        fee: fee.decimalPlaces(1).toFormat(),
        feeUSD: fee.multipliedBy(swthPrice).toFormat(2),
        status: processStatus(transaction),
      })

    }
  })
  return processedTransactions
}


interface Props {
  setShowBridge: Dispatch<SetStateAction<boolean>>
}

const CrosschainBridgeHistory: React.FC<Props> = ({ setShowBridge }) => {
  const [runLoadTransactions, loadingTransactions] = useAsyncTask()
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]
  const walletAddress = useAppSelector(state => state.wallet.senderAddress)
  const [page, setPage] = useState<number>(1)
  const [bridgeHistoryData, setbridgeHistoryData] = useState<CrosschainTransactionSchema>({ data: [], pageLength: 0 })

  const decreasePageHandler = (): void => {
    setPage((currentPage) => {
      if (currentPage > 1) {
        window.scrollTo(0, 0)
        return currentPage - 1
      }
      return currentPage
    })
  }

  const increasePageHandler = (): void => {
    setPage((currentPage) => {
      if (currentPage < bridgeHistoryData.pageLength) {
        window.scrollTo(0, 0)
        return currentPage + 1
      }
      return currentPage
    })
  }

  const loadTransactions = useCallback((): void => {
    runLoadTransactions(async () => {
      const offset = (page - 1) * 10
      const assets = ["SWTH Token (BEP-20)", "SWTH Token (NEP-17)", "Switcheo", "Switcheo Token", "Switcheo (BEP-20)", "Switcheo (ERC-20)", "Switcheo (NEP-5)", "Carbon Token (Arbitrum)"]

      const queryParams = { include_tx: "true", bridging_blockchain: "polynetwork", offset, limit: 10, address: walletAddress, asset_names: assets, blockchains: "carbon" }
      const endpoint = queryString.stringifyUrl({ url: "https://hydrogen-api.carbon.network/transfer_payloads", query: queryParams }, { arrayFormat: 'comma' })
      const rawTransactions: HydrogenAPISchema = await fetchData(endpoint)

      const pageLengthBN = new BigNumber(rawTransactions.pagination.total).dividedBy(10).integerValue(BigNumber.ROUND_CEIL)
      const pageLength = pageLengthBN.isGreaterThan(10) ? 10 : pageLengthBN.toNumber()
      var processedTransactions: CrosschainTransaction[] = processTransactions(rawTransactions, swthPrice)

      if (processedTransactions.length) {
        processedTransactions.sort((a, b) => (a.dateTime.isBefore(b.dateTime) ? 1 : -1))
      }
      setbridgeHistoryData({ data: processedTransactions, pageLength: pageLength })
    })
  }, [page, runLoadTransactions, swthPrice, walletAddress])

  useEffect(() => {
    if (walletAddress) {
      loadTransactions()
    }
  }, [loadTransactions, walletAddress]) 

  return (
    <div>
      <div className="bridge-history-table-wrapper">
        <CrosschainBridgeHistoryTable
          bridgeHistoryData={bridgeHistoryData.data}
          walletAddress={walletAddress}
          setShowBridge={setShowBridge}
          loading={loadingTransactions}
        />
      </div>
      <div className="bridge-history-cards-wrapper">
        <CrosschainBridgeHistoryCards
          bridgeHistoryData={bridgeHistoryData.data}
          walletAddress={walletAddress}
          setShowBridge={setShowBridge}
          loading={loadingTransactions}
        />
      </div>
      {!!bridgeHistoryData.data.length && (
        <div className="bridge-history-pagination-wrapper">
          <img
            src={ChevronsLeft}
            alt="Chevrons Left"
            onClick={decreasePageHandler}
          />
          {generatePageNumbers(page, bridgeHistoryData.pageLength).map((buttonContent, index) => {
            if (Number.isInteger(buttonContent)) {
              return (
                <span className={page === buttonContent ? "bridge-history-pagination-active" : ""} onClick={() => { setPage(buttonContent as number); window.scrollTo(0, 0) }} key={index}>
                  {buttonContent}
                </span>
              )
            } else {
              return (
                <span className="bridge-history-pagination-dots" key={index}>...</span>
              )
            }
          })}
          <img src={ChevronsRight} alt="Chevrons Right" onClick={increasePageHandler} />
        </div>
      )}
    </div>
  )
}

export default CrosschainBridgeHistory