import { BlockResultsResponse, Event, EventAttribute } from '@cosmjs/tendermint-rpc/build/tendermint37/responses'
import BigNumber from 'bignumber.js'
import { CarbonSDK } from "carbon-js-sdk"
import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import { BN_ZERO } from "constants/math"
import { ValPair } from 'store/Stake'
import { SimpleMap } from 'utils'
import { parseNumber } from "utils/number"

export const parseEventAttr = (a: string) => {
  return window.atob(a)
}

// * for APR + Staking Stats * //
export const getTotalBondedAmount = (valMap: SimpleMap<ValPair>): BigNumber => {
  let totalBonded: BigNumber = BN_ZERO

  for (const valKey in valMap) {
    const validator = valMap[valKey].carbonValidator

    if (validator.status === BondStatus.BOND_STATUS_BONDED) {
      const tokens = new BigNumber(validator.tokens).shiftedBy(-8)
      totalBonded = totalBonded.plus(tokens)
    }
  }
  return totalBonded
}

// avg rewards = reward from latest block
export const getAverageRewards = (
  evts: BlockResultsResponse,
  sdk?: CarbonSDK
) => {
  let totalRewards = BN_ZERO

  evts.beginBlockEvents.forEach((evt: Event) => {
    if (evt.type !== "rewards") {
      return
    }

    evt.attributes.forEach((c: EventAttribute) => {
      const key = parseEventAttr(c.key)
      const value = parseEventAttr(c.value)
      if (key === "amount" && value.substring(value.length - 4) === "swth") {
        const rewardsBN = parseNumber(value.substring(0, value.length - 4))!
        const adjustedRewards =
          sdk?.token.toHuman("swth", rewardsBN) ?? BN_ZERO
        totalRewards = totalRewards.plus(adjustedRewards)
      }
    })
  })
  return totalRewards
}

export const getAverageBlockTime = (evts: BlockResultsResponse) => {
  const evidenceParams = evts.consensusUpdates?.evidence
  const maxAgeNumBlocks = parseNumber(
    evidenceParams?.maxAgeNumBlocks ?? 0,
    BN_ZERO,
  )!
  const maxAgeDuration = parseNumber(evidenceParams?.maxAgeDuration, BN_ZERO)!
  const avgBlockTime = maxAgeNumBlocks.isZero()
    ? BN_ZERO
    : maxAgeDuration.dividedBy(maxAgeNumBlocks) // in nanoseconds
  return avgBlockTime.shiftedBy(-9) // convert to seconds
}

export const SWTH_DECIMALS = 8

export interface healthDetailsInterface {
  missedBlockCount: number
  oracleSlashCount: number
  timeSinceStarted: number // in days
}

export const calculateRating = (bondStatus: "Bonded" | "Unbonded" | "Unbonding" | "Unknown", health: healthDetailsInterface, blockTime: BigNumber, cumulativeStake: number): number => {
  if (bondStatus !== "Bonded") return 1
  
  if (!blockTime) return 5
  var rating: number = 5

  const { missedBlockCount, oracleSlashCount } = health

  rating -= Math.floor(missedBlockCount / (72000 * 0.02))
  rating -= Math.ceil(oracleSlashCount / 10)
  rating -= Math.floor(cumulativeStake / 5)

  rating = Math.max(1, rating)

  return rating
}

export const getBondStatus = (
  status: number
): [
    "Bonded" | "Unbonded" | "Unbonding" | "Unknown",
    "Success" | "Neutral" | "Warning" | undefined
  ] => {
  switch (status) {
    case BondStatus.BOND_STATUS_BONDED:
      return ["Bonded", "Success"]
    case BondStatus.BOND_STATUS_UNBONDED:
      return ["Unbonded", "Neutral"]
    case BondStatus.BOND_STATUS_UNBONDING:
      return ["Unbonding", "Warning"]
    case BondStatus.BOND_STATUS_UNSPECIFIED:
    case BondStatus.UNRECOGNIZED:
    default:
      return ["Unknown", undefined]
  }
}
