import { delay, fork } from "redux-saga/effects";
import { appSaga } from "./App";
import { erc20Saga } from "./Erc20";
import { queryMarketStats } from "./MarketStats";
import { stakeSaga } from "./Stake";
import { queryPrices } from "./TokenPrices";
import { bridgeSaga } from "./Wallet";

const STATS_REFRESH_RATE = 300000;

function* watchStats() {
    while (true) {
        try {
            yield fork(queryMarketStats);
            yield fork(queryPrices);
        } catch (error) {
            console.error(error);
        } finally {
            yield delay(STATS_REFRESH_RATE);
        }
    }
}

export function* rootSaga() {
    yield fork(watchStats);
    yield fork(appSaga);
    yield fork(stakeSaga);
    yield fork(bridgeSaga);
    yield fork(erc20Saga);
}
