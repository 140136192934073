import DropDownIcon from "assets/DropDownIcon.svg";
import DropDownIconBranding from "assets/DropDownIcon_Branding.svg";
import React, { useEffect, useRef, useState } from "react";
import "./DropDown.css";

interface Props {
  options: DropdownItem[];
  defaultOption: number | any;
  selectOption: (optionId: number, dropdownId?: number) => void;
  chosenOption?: boolean  //for manipulating the chosen option from outside the dropdown component
  id?: number //different ids for different dropdown menus
  className?: string
};

export type DropdownItem = {
  img?: JSX.Element;
  tag?: JSX.Element;
  content: string;
}

const DropDown: React.FC<Props> = ({ className, options, defaultOption, selectOption, chosenOption, id }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = useRef<HTMLDivElement>(null);
  const [dropdown, setDropDown] = useState<boolean>(false);
  const [chosen, setChosen] = useState(defaultOption);
  const [hoveredDropdown, setHoveredDropdown] = useState(false);

  const handleChoose = (index: number) => {
    setChosen(index);
    selectOption(index, id);
    setDropDown(false);
    setHoveredDropdown(false);
  }

  useEffect(() => {
    setChosen(defaultOption)
  }, [defaultOption])

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      if (dropdownRef.current && dropdown && !(dropdownRef.current.contains(e.target as Node) || dropdownOptionsRef.current?.contains(e.target as Node))) {
        setDropDown(false);
      }
    }
    document.addEventListener("mousedown", onOutsideClick);
    return () => document.removeEventListener("mousedown", onOutsideClick);
  },[dropdown]);


  const classNames = className?.split(" ") ?? [];

  return (
    <div className={["dropdown", ...classNames].join(" ")}>
      <div onClick={() => setDropDown(!dropdown)} ref={dropdownRef} className={`dropdown-header ${(hoveredDropdown || dropdown) ? "dropdown-hover" : undefined}`} onMouseOver={() => setHoveredDropdown(true)} onMouseLeave={() => setHoveredDropdown(false)}>
        <div className="dropdown-list-item">
          {options[chosen]?.img &&
            <span className="dropdown-list-item-img">{options[chosen].img}</span>
          }
          <span>
            {options[chosen]?.content}
          </span>
        </div>
        <div>
          <img src={(hoveredDropdown || dropdown) ? DropDownIconBranding : DropDownIcon} alt={"drop down"} className={`dropdown-icon ${!dropdown && "down"}`}></img>
        </div>
      </div>
      {dropdown && 
      <div className="dropdown-list-wrapper dropdown-hover">
        <div className="dropdown-list" ref={dropdownOptionsRef} onMouseOver={() => setHoveredDropdown(true)} onMouseLeave={() => setHoveredDropdown(false)}>
        {
          options.map((option, index) => (
            <div key={index} className={`${chosen === index && "selected"}`} onClick={() => handleChoose(index)}>
              <span className="dropdown-list-item" style={index === 0 ? { borderRadius: "12px 12px 0 0" } : index === options.length - 1 ? { borderRadius: " 0 0 12px 12px" } : undefined} >
                {option.img} 
                <div style={option.img && { marginLeft: "8px" }} className="dropdown-content">
                  <span>{option.content}</span>
                  {option.tag}
                </div>
              </span>
            </div>
          ))
        }
        </div>
      </div>
      }
    </div >
  )
};

export default DropDown;
