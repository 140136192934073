import BondedSWTH from 'assets/BondedSWTH.svg'
import DebtPosition from 'assets/DebtPosition.svg'
import Rocket from 'assets/Rocket.svg'
import SWTH from 'assets/SWTH-blue.svg'
import React from 'react'
import { useInView } from "react-intersection-observer"
import './Upcoming.css'


const Upcoming: React.FC = () => {
    let LastestLink = 'https://twitter.com/0xcarbon';
    const { ref, inView } = useInView({
        /* Optional options */
        triggerOnce: true,
    });
    return (
        <div ref={ref} className={`upcoming-wrapper ${inView ? 'show' : undefined}`}>
            <div className='upcoming-inner-wrapper'>
                <div className="upcoming-header">
                    <p className='section-header'>Upcoming Next&nbsp;<img src={Rocket} alt="Rocket" className='title-image-holder' /></p>
                    <p className='section-subheader'> There's so much more on the horizon for SWTH! </p>
                </div>
                <br></br>
                <div className='upcoming-section'>
                    <img src={DebtPosition} alt="DebtPosition" className='upcoming-image-holder' />
                    <div>
                        <p className='section-subpoint-header'>Liquid Staked SWTH</p>
                        <p className='section-text'>Carbon is set to introduce liquid staked SWTH. This gives you greater liquidity, where you get to use liquid staked SWTH for other DeFi purposes to earn more yield (e.g. as collateral for borrowing, by lending, LPing, and more) whilst still earning rewards on your staked SWTH!</p>
                    </div>
                </div>
                <br></br>
                <div className='upcoming-section'>
                    <img src={BondedSWTH} alt="BondedSWTH" className='upcoming-image-holder' />
                    <div> <p className='section-subpoint-header'>Collateralized Debt Position</p>
                        <p className='section-text'>Carbon’s collateralized debt position (CDP) module is currently live and will soon enable SWTH as collateral, along with SWTH lending/borrowing pools. This will drive the demand for SWTH whilst simultaneously providing liquidity. SWTH holders can also choose to lock their tokens in a CDP and mint USC, Carbon’s native decentralized and over-collateralized stablecoin, and deposit the minted USC into liquidity pools to earn rewards.</p>
                    </div>
                </div>
                <br></br>
                <div className='stay-tuned-wrapper'> <a className='button-text link hover-underline-blue' href={LastestLink} target="_blank" rel="noopener noreferrer"> Stay tuned to latest updates&nbsp;<img src={SWTH} alt="SWTH" className='SWTH' /> </a></div>
            </div>
        </div>
    );
};

export default Upcoming;