import React, { Dispatch, SetStateAction } from "react"
import "./BridgeHistory.css"
import { BridgeHistoryTab } from "./types"
import CrosschainBridgeHistory from "./CrosschainBridgeHistory"
import CarbonEVMBridgeHistory from "./CarbonEVMBridgeHistory"
import { useAppSelector } from "hooks"
import { useDispatch } from "react-redux"
import { updateBridgeUI } from "store/UI"

interface Props {
  setShowBridge: Dispatch<SetStateAction<boolean>>
};

const BridgeHistory: React.FC<Props> = ({ setShowBridge }) => {
  const bridgeHistoryTab = useAppSelector(state => state.ui.bridge.historyTab)
  const dispatch = useDispatch()

  const setBridgeHistoryTab = (bridgeHistoryTab: BridgeHistoryTab) => {
    dispatch(updateBridgeUI({ historyTab: bridgeHistoryTab }))
  }

  return (
    <div className="bridge-history-wrapper">
      <div className="bridge-history-tabs-wrapper">
        <div className="bridge-history-tabs">
          <span
            className={`bridge-history-tab-button ${bridgeHistoryTab === BridgeHistoryTab.CrosschainTransfers ? "active" : ""}`}
            onClick={() => setBridgeHistoryTab(BridgeHistoryTab.CrosschainTransfers)}
          >
            Cross-chain <span className="mobile-hidden">Transfers</span>
          </span>
          <span
            className={`bridge-history-tab-button ${bridgeHistoryTab === BridgeHistoryTab.CarbonEVMTranfers ? "active" : ""}`}
            onClick={() => setBridgeHistoryTab(BridgeHistoryTab.CarbonEVMTranfers)}
          >
            Carbon EVM <span className="mobile-hidden">Transfers</span>
          </span>
        </div>
      </div>
      {bridgeHistoryTab === BridgeHistoryTab.CrosschainTransfers && <CrosschainBridgeHistory setShowBridge={setShowBridge} />}
      {bridgeHistoryTab === BridgeHistoryTab.CarbonEVMTranfers && <CarbonEVMBridgeHistory setShowBridge={setShowBridge} />}
    </div>
  )
}

export default BridgeHistory
