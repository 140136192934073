import Cross from "assets/Cross.svg"
import Branding from "assets/SWTH-Blue-Small.svg"
import React, { ReactNode } from "react"
import "./PopupWindow.css"

interface Props {
  bottomButtonText?: string;
  headerText?: string;
  topButtonText?: string;
  message?: string;
  style?: React.CSSProperties;
  clickTopButton? : () => void; 
  clickBottomButton?: (() => void) | undefined;
  closeWindow?: () => void;
  children: ReactNode;
  type?: string;
  transactionLink?: string;
};

const PopupWindow: React.FC<Props> = ({ type, bottomButtonText, headerText, topButtonText, message, transactionLink, clickTopButton, clickBottomButton, closeWindow, children, style }) => {
  return (
    <div className="popup-screen">
      <div className="popup">
        <div className="pop-up-header bolded-700" style={closeWindow ? undefined : {justifyContent: "center"}}>
          <span>{headerText}</span>
          {closeWindow && <div className="close-button-wrapper">
            <img src={Cross} alt="close-window" onClick={closeWindow}></img>
          </div>}
        </div>
        {children}
        {message &&
          <div className="popup-msg-wrapper" style={style}>
            <span>{message}</span>
          </div>
        }
        {topButtonText && <button className="button-theme button-theme-secondary popup-button" style={{ backgroundColor: "transparent", width: "100%" }} onClick={clickTopButton}>{topButtonText}</button>}
        {bottomButtonText && <button className="button-theme button-theme-primary popup-button" style={{ width: "100%" }} onClick={clickBottomButton}>{bottomButtonText}</button>}
        {transactionLink && <a className="popup-transaction-link bolded-700 dull-link" href={transactionLink} target="_blank" rel="noopener noreferrer">
          <p>View Transaction</p>
          <img src={Branding} alt="Branding" />
        </a>}
      </div>
    </div>
  )
};

export default PopupWindow;
