import Deposit from "assets/Stake_Deposit.svg";
import React, { ChangeEventHandler } from "react";
import "./StakeFAQHeader.css";
import SearchBar from "components/Common/SearchBar";

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const StakeFAQHeader: React.FC<Props> = ({ onChange }) => {
  return (
    <div className="stake-faq-header bolded-700">
      <img src={Deposit} alt="Deposit" height="74px" />
      <p className="stake-faq-header-title">Staking FAQ</p>
      <p className="bolded-400 stake-faq-header-secondary">Answers to all your questions on staking</p>
      <SearchBar onChange={onChange} />
    </div>
  );
}

export default StakeFAQHeader;
