import { toBech32Address, Zilliqa } from "@zilliqa-js/zilliqa"
import BigNumber from "bignumber.js"
import { CarbonSDK } from "carbon-js-sdk"
import { call, put } from "redux-saga/effects"
import { demexStats, stats, updateMarketStats } from "store/MarketStats/MarketStats"
import { MarketStatsTasks } from 'store/MarketStats/types'
import { bnOrZero } from "utils"
import { fetchData, getRefTime, waitforSDK } from './Common'
import { runSagaTask } from './helper'

const ADDR_ZRC_SWTH = '0x258b14969e4f67d0a5e48091b3d43a518663248b'
const ADDR_ZILSWAP_DEX = "0x459cb2d3baf7e61cfbd5fe362f289ae92b2babb0"
const zil_volume_api = "https://stats.zilswap.org/volume"
const carbon_pool_routes = "https://api.carbon.network/carbon/liquiditypool/v1/pool_routes"
const demex_volume_api = "https://api-insights.carbon.network/market/volume?interval=hour"
const demex_liquidity_api = "https://api.carbon.network/carbon/liquiditypool/v1/pools?pagination.limit=1000"
const osmosis_api = "https://api-osmosis.imperator.co/pools/v2/651"
const uniswap_token_api = "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3"
// const flamingo_volume_api = "https://api.flamingo.finance/analytics/pool-stats-24h?mainnet=true";
// const gate_api_liquidity = "https://www.gate.io/market_pools/pool_situation?pair=SWTH_USDT";
// const gate_api_volume = "https://data.gateapi.io/api2/1/ticker/swth_usdt";
// const sushi_swth_weth_pair_id = '0x6a30d6c9408c84fafdc8b0b00580f78f61feb56f';
// const mexc_api = 'https://api.mexc.com/api/v3/ticker/24hr?symbol=SWTHUSDT';

type pool_route = {
  market: string,
  pool_ids: string[]
}

type demex_pool = {
  creator: string,
  id: string,
  name: string,
  denom: string,
  denom_a: string,
  amount_a: string,
  weight_a: string,
  denom_b: string,
  amount_b: string,
  weight_b: string,
  swap_fee: string,
  num_quotes: string,
  shares_amount: string,
  market: string
}

const fetchDemexStats = async (sdk: CarbonSDK) => {
  const ref_time_now = getRefTime(0)
  const ref_time_one_day_ago = getRefTime(-1)
  let api = demex_volume_api + "&from=" + ref_time_one_day_ago + "&until=" + ref_time_now
  let usd_volume = 0
  let eth_volume = 0
  let in_usd_liquidity_amount = 0
  let out_usd_liquidity_amount = 0
  let in_eth_liquidity_amount = 0
  let out_eth_liquidity_amount = 0

  try {
    // fetch volume
    const volume_data = await fetchData(api)
    let length = volume_data.result.entries.length
    const entries = volume_data.result.entries

    for (let i = 0; i < length; i++) {
      const markets = entries[i].markets
      eth_volume += markets.find((e: { market: string }) => e.market === "swth_eth1")?.volumeValue ?? 0
      usd_volume += markets.find((e: { market: string }) => e.market === "cmkt/109")?.volumeValue ?? 0
    }
  } catch (error) {
    console.error(error)
  }

  try {
    // fetch liquidity
    const liquidity_data = await fetchData(demex_liquidity_api)
    const pool_routes_data = await fetchData(carbon_pool_routes)

    // swth markets
    const eth_pool_routes = pool_routes_data.pool_routes.filter((pool: pool_route) => pool.market === "swth_eth1")
    eth_pool_routes.forEach((poolRoutes: pool_route) => {
      poolRoutes.pool_ids.forEach((id: string) => {
        const poolData = liquidity_data.pools.find((e: { pool: demex_pool }) => e.pool.id === id)
        if (poolData) {
          const { denom_a, denom_b, amount_a, amount_b } = poolData.pool
          const decimal_a = sdk?.token.getDecimals(denom_a) ?? 0
          const decimal_b = sdk?.token.getDecimals(denom_b) ?? 0
          in_eth_liquidity_amount += bnOrZero(amount_a).shiftedBy(-decimal_a).toNumber()
          out_eth_liquidity_amount += bnOrZero(amount_b).shiftedBy(-decimal_b).toNumber()
        }
      })
    })

    const usd_pool_routes = pool_routes_data.pool_routes.filter((pool: pool_route) => pool.market === "cmkt/109")
    usd_pool_routes.forEach((poolRoutes: pool_route) => {
      poolRoutes.pool_ids.forEach((id: string) => {
        const poolData = liquidity_data.pools.find((e: { pool: demex_pool }) => e.pool.id === id)
        if (poolData) {
          const { denom_a, denom_b, amount_a, amount_b } = poolData.pool
          const decimal_a = sdk?.token.getDecimals(denom_a) ?? 0
          const decimal_b = sdk?.token.getDecimals(denom_b) ?? 0
          in_usd_liquidity_amount += bnOrZero(amount_a).shiftedBy(-decimal_a).toNumber()
          out_usd_liquidity_amount += bnOrZero(amount_b).shiftedBy(-decimal_b).toNumber()
        }
      })
    })
  } catch (error) {
    console.error(error)
  }

  const demexStats: demexStats = {
    usd_liquidity_in: in_usd_liquidity_amount,
    usd_liquidity_out: out_usd_liquidity_amount,
    usd_volume: usd_volume,

    eth_liquidity_in: in_eth_liquidity_amount,
    eth_liquidity_out: out_eth_liquidity_amount,
    eth_volume: eth_volume,
  }
  return demexStats
}

const fetchZilStats = async () => {
  let liquidity_amount = 0
  let volume_amount = 0
  try {
    // fetch liquidity
    const zilliqa = new Zilliqa("https://api.zilliqa.com")
    const dexContract = zilliqa.contracts.at(ADDR_ZILSWAP_DEX)
    const { pools } = await dexContract.getSubState("pools", [ADDR_ZRC_SWTH]) ?? {}
    const [zilAmount] = pools?.[ADDR_ZRC_SWTH]?.arguments ?? []
    liquidity_amount = new BigNumber(zilAmount ?? "0").shiftedBy(-12).times(2).toNumber()
  } catch (error) {
    console.error(error)
  }

  try {
    // fetch volume
    const bech32SwthAddr = toBech32Address(ADDR_ZRC_SWTH)
    const ref_time_now = getRefTime(0)
    const ref_time_one_day_ago = getRefTime(-1)
    let api = zil_volume_api + "?from=" + ref_time_one_day_ago + "&until=" + ref_time_now
    const volume_data = await fetchData(api)
    const volume_result = volume_data.filter((e: { pool: string }) => e.pool === bech32SwthAddr)[0]

    const in_zil_amount = new BigNumber(volume_result.in_zil_amount)
    const out_zil_amount = new BigNumber(volume_result.out_zil_amount)
    volume_amount = in_zil_amount.plus(out_zil_amount).shiftedBy(-12).toNumber()
  } catch (error) {
    // console.error(error);
  }

  const zilStats: stats = {
    liquidity: liquidity_amount,
    volume: volume_amount
  }
  return zilStats
}



const fetchSushiStats = async () => {
  let sushi_volume = 0
  let sushi_liquidity = 0

  // let api = require('sushiswap-api');
  // const ref_time_now = getRefTime(0);
  // const ref_time_one_week = getRefTime(-7);

  // try {
  //     const volumeResponse = await api.getHistoricalVolume(1, sushi_swth_weth_pair_id, ref_time_one_week, ref_time_now)
  //     const liquidityResponse = await api.getHistoricalLiquidity(1, sushi_swth_weth_pair_id, ref_time_one_week, ref_time_now)
  //     sushi_volume = volumeResponse[1][volumeResponse[1].length - 1].USD_total_volume
  //     sushi_liquidity = liquidityResponse[1][liquidityResponse[1].length - 1].USD_total_liquidity
  // } catch (error) {
  //     console.error(error);
  // }
  const sushiStats: stats = {
    liquidity: sushi_liquidity,
    volume: sushi_volume
  }
  return sushiStats
}

const fetchUniStats = async () => {
  const result = await fetch(uniswap_token_api, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
            query {
                pool(id: "0xc995da109796385524f630ceecd34b6e928b9158") {
                  token0 {
                    tokenDayData(
                      where: {token: "0xb4371da53140417cbb3362055374b10d97e420bb"}
                      orderBy: date
                      orderDirection: desc
                    ) {
                      id,
                      volumeUSD
                    }
                  }
                  totalValueLockedUSD
                }
              }
          `
    }
    )
  }).then(res => res.json())
  const uni_volume = result.data.pool.token0.tokenDayData[0].volumeUSD ?? "0"
  const uni_liquidity = result.data.pool.totalValueLockedUSD ?? "0"

  const uniStats: stats = {
    liquidity: parseInt(uni_liquidity),
    volume: parseInt(uni_volume)
  }
  return uniStats
}

const fetchOsmosisStats = async () => {
  let liquidity = 0
  let volume = 0
  try {
    const liquidity_data = await fetchData(osmosis_api)
    if (liquidity_data && liquidity_data.length > 0) {
      liquidity = liquidity_data[0].liquidity
      volume = liquidity_data[0].volume_24h
    }
  } catch (error) {
    console.error(error)
  }
  const osmosisStats: stats = {
    liquidity: liquidity,
    volume: volume
  }
  return osmosisStats
}


export function* queryMarketStats() {
  const sdk: CarbonSDK = yield call(waitforSDK)
  yield runSagaTask(MarketStatsTasks.MarketStats, function* () {
    const demex_stats: demexStats = (yield call(fetchDemexStats, sdk)) as demexStats
    const zil_stats: stats = (yield call(fetchZilStats)) as stats
    const sushi_stats: stats = (yield call(fetchSushiStats)) as stats
    const uni_stats: stats = (yield call(fetchUniStats)) as stats
    const osmosis_stats: stats = (yield call(fetchOsmosisStats)) as stats

    yield put(updateMarketStats({
      demexStats: demex_stats,
      zilStats: zil_stats,
      sushiStats: sushi_stats,
      uniStats: uni_stats,
      osmosisStats: osmosis_stats,
    }))
  })
};
