import React from "react";

type Props = {
    text: string;
    number: number;
    bolded: boolean;
    style?: React.CSSProperties;
}

const NumberHeader: React.FC<Props> = ({ text, number, bolded, style }) => {
    const numberHeaderStyles = {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        fontSize: "20px",
        lineHeight: "24px",
        ...style
    }
    const numberHolderStyles = { 
        borderRadius: "8px", 
        backgroundColor: "#E7ECEE", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center",
        padding: "2px 4px"
    }
    return (
        <div className={bolded ? "bolded-700" : "bolded-400"} style={numberHeaderStyles}>
            <span>{text}</span>
            <div style={numberHolderStyles}>
                {number}
            </div>
        </div>
    );
}

export default NumberHeader;
