import AddressLabel from "components/Common/AddressLabel"
import StatusBlock from "components/Common/StatusBlock"
import React from "react"
import {
  CrosschainTransaction
} from "store/Wallet"
import "../BridgeHistoryRow.css"

interface Props {
  historyDataItem: CrosschainTransaction;
};

const CrosschainBridgeHistoryRow: React.FC<Props> = ({ historyDataItem }) => {

  const bridgeStatusToStatus = new Map<typeof historyDataItem.status, "Neutral" | "Warning" | "Success">([
    ["Receiving", "Neutral"],
    ["Confirming", "Warning"],
    ["Complete", "Success"]
  ]);

  return (
    <tr key={historyDataItem.id} className="bridge-table-row">
      <td>
        <p>{historyDataItem.dateTime.format("DD MMM, YYYY")}</p>
        <p className="bridge-table-text-secondary">
          {historyDataItem.dateTime.format("HH:mm:ss")}
        </p>
      </td>
      <td>
        <AddressLabel
          text={historyDataItem.sendHash}
          logoName={historyDataItem.sendChain}
          externalLinkData={{ type: "transaction", chain: historyDataItem.sendChain }}
          textOverflowParams={[5,3]}
          style={{ marginBottom:"4px" }}
        />
        <AddressLabel
          text={historyDataItem.fromAddress}
          logoName="wallet"
          externalLinkData={{type: "address", chain: historyDataItem.sendChain}}
          style={{ "color":"#6A8A96", "fontSize": "12px" }}
        />
      </td>
      <td>
        <AddressLabel text={historyDataItem.status === 'Complete' ? historyDataItem.receiveHash! : `${historyDataItem.status}...`}
          logoName={historyDataItem.receiveChain}
          externalLinkData={historyDataItem.status === "Complete" ? {type: "transaction", chain: historyDataItem.receiveChain} : undefined}
          disabled={!(historyDataItem.status === "Complete")}
          textOverflowParams={[5,3]}
          style={{marginBottom:"4px"}}
        />
        <AddressLabel
          text={historyDataItem.toAddress}
          logoName="wallet"
          externalLinkData={{ type: "address", chain: historyDataItem.receiveChain }}
          style={{ "color":"#6A8A96", "fontSize": "12px" }}
          disabled={!(historyDataItem.status === "Complete")}
        />
      </td>
      <td>
        <p>{`${historyDataItem.amount} SWTH`}</p>
        <p className="bridge-table-text-secondary">
          ${historyDataItem.amountUSD}
        </p>
      </td>
      <td>
        <p>{`${historyDataItem.fee} SWTH`}</p>
        <p className="bridge-table-text-secondary">${historyDataItem.feeUSD}</p>
      </td>
      <td>
        <div>
          <StatusBlock text={historyDataItem.status} status={bridgeStatusToStatus.get(historyDataItem.status)} />
        </div>
      </td>
    </tr>
  );
};

export default CrosschainBridgeHistoryRow;
