import Avatar from "components/Common/Avatar"
import TableText from "components/Common/TableText"
import Tooltip from "components/Common/Tooltip"
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React from "react"
import { StakeTasks } from 'store/Stake/types'
import { DelegationLoading } from '../Common/DelegationLoading'
import { RedelegationEmpty } from '../Common/EmptyStates'
import { RedelegationsTableData } from '../StakeDelegationInfo'
import "./StakeRedelegation.css"

export const StakeRedelegationEmpty: React.FC = () => {
  return (
    <tr>
      <td colSpan={100}>
        <RedelegationEmpty />
      </td>
    </tr>
  )
}

interface Props {
  redelegations: RedelegationsTableData[]
}

const StakeRedelegation: React.FC<Props> = ({ redelegations }) => {
  const [redelegationsLoading] = useTaskSubscriber(StakeTasks.ValidatorRedelegations)
  return (
    <div className="stake-redelegation-wrapper theme-color">
      <table className="stake-redelegation-inner-wrapper" style={{marginBottom: "0px"}}>
        <thead className='table-head'>
          <tr>
            <th style={{ padding: "0 24px" }}>
              <p>Redelegate From</p>
            </th>
            <th style={{paddingLeft: "24px"}}>
              <div className="stake-more-info">
                <p>Redelegate To</p>
                <Tooltip>
                  <span>After you redelegate to this validator, you cannot redelegate to another for 30 days. Learn more about redelegation.</span>
                </Tooltip>
              </div>
            </th>
            <th align="right">
              <p>Redelegate Amount</p>
            </th>
            <th align="right">
              <p>Start Block</p>
            </th>
            <th style={{ paddingLeft: "141px", paddingRight: "24px" }}>
              <p>Redelegated On</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {redelegationsLoading ?
            <tr>
              <td colSpan={100} height="202px">
                <DelegationLoading />
              </td>
            </tr>
            :
            redelegations.length === 0 ?
              <StakeRedelegationEmpty /> :
              redelegations.sort((a, b) => a.completionTime > b.completionTime ? -1 : 1).map((redelegation, index) => {
                const validatorFrom = redelegation.validatorFrom
                const validatorTo = redelegation.validatorTo
                return (
                  <tr key={index}>
                    <td style={{ paddingLeft: "24px" }}>
                      <Avatar name={validatorFrom} valAddr={redelegation.valFromAddr} icon="link" identity={redelegation.valFromID} />
                    </td>
                    <td style={{ paddingLeft: "24px" }}>
                      <Avatar name={validatorTo} valAddr={redelegation.valToAddr} icon="link" identity={redelegation.valToID} />
                    </td>
                    <td>
                      <TableText type="token" denom={redelegation.denom} content={redelegation.amount} />
                    </td>
                    <td>
                      {redelegation.startBlock}
                    </td>
                    <td style={{ paddingLeft: "141px" }}>
                      <TableText content={redelegation.completionTime.subtract(30, "day")} type="date" />
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      </table>
    </div>
  )
}

export default StakeRedelegation
