import React, { useEffect, useRef } from "react";
import "./Banner.css";
import { NavLink } from "react-router-dom";
import Cross from "assets/Cross_White.svg";

type Props = {
  showBanner: boolean;
  hideBannerHandler: () => void;
  bannerHeightHandler: (height: number) => void;
}

const Banner:React.FC<Props> = ({ showBanner, hideBannerHandler, bannerHeightHandler }) => {
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bannerRef.current?.clientHeight) {
      bannerHeightHandler(bannerRef.current?.clientHeight);
    } else {
      bannerHeightHandler(0);
    }
  }, [bannerRef.current?.clientHeight, bannerHeightHandler]);

  return (
    <div className={showBanner ? "banner bolded-400" : "banner-hidden"} ref={bannerRef}>
      <p>
        <span className="banner-front-text">SWTH Token migration is now available. </span>
        <NavLink className="bolded-700" to="/migrate">Migrate</NavLink>
        <span> your SWTH tokens to BSC V2 and Neo N3 now!</span>
      </p>
      <img src={Cross} alt="Cross" className="banner-cross" height="24px" onClick={hideBannerHandler}/>
    </div>
  );
};

export default Banner;
