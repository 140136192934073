import BigNumber from "bignumber.js"
import { CarbonSDK, CarbonWallet, Models } from "carbon-js-sdk"
import { BN_ZERO } from "constants/math"
import { ChainNames } from "constants/types"
import { SimpleMap } from "utils"

export const shiftDecimals = (input: BigNumber, token: Models.Carbon.Coin.Token | null) => {
  const decimals = token ? token.decimals.toNumber() : 0
  return input.shiftedBy(-decimals).dp(decimals)
}

export const getRecoveryAddress = (network: CarbonSDK.Network) => {
  const mainDevRecoveryAddress = 'swth1cuekk8en9zgnuv0eh4hk7xtr2kghn69x0x6u7r'
  const localTestRecoveryAddress = 'tswth1cuekk8en9zgnuv0eh4hk7xtr2kghn69xt3tv8x'
  if (network === CarbonSDK.Network.MainNet || network === CarbonSDK.Network.DevNet) {
    return mainDevRecoveryAddress
  } else {
    return localTestRecoveryAddress
  }
}

export const getTokenDenomList = (network: CarbonSDK.Network) => {
  const mainTokenDenoms: SimpleMap<string> = {
    [ChainNames.Eth]: "swthe.1.2.683ddd",
    [ChainNames.Bsc]: "swth.1.6.5bc06b",
    [ChainNames.CarbonCore]: "swth",
    [ChainNames.Zil]: "swth.1.18.4ef38b",
    [ChainNames.Neo]: "swthn.1.4.2624e1",
    [ChainNames.Neo3]: "swth.1.14.fffdbf",
    [ChainNames.Arbitrum]: "swth.1.19.6f83d0",
  }
  const devTokenDenoms: SimpleMap<string> = {
    [ChainNames.Eth]: "swth1.1.350.9d90c3",
    [ChainNames.Bsc]: "swth2.1.350.6da2b8",
    [ChainNames.CarbonCore]: "swth.1.111.7742c9",
    [ChainNames.Zil]: "zil1.1.111.f0354c",
    [ChainNames.Neo]: "",
    [ChainNames.Neo3]: "",
    [ChainNames.Arbitrum]: "",
  }
  if (network === CarbonSDK.Network.MainNet) {
    return mainTokenDenoms
  } else {
    return devTokenDenoms
  }
}

export const getTokenDenoms = (network: CarbonSDK.Network, chain: ChainNames) => {
  return getTokenDenomList(network)[chain]
}

export function getEvmGasLimit(chain: ChainNames, action: "approve" | "bridge" | "deposit") {
  if (action === "approve") {
    switch (chain) {
      case ChainNames.Eth:
        return new BigNumber(100000)
      case ChainNames.Bsc:
        return new BigNumber(100000)
      case ChainNames.Arbitrum:
        return new BigNumber(500000)
      default:
        return new BigNumber(100000)
    }
  } else if (action === "deposit") {
    switch (chain) {
      case ChainNames.Eth:
        return new BigNumber(250000)
      case ChainNames.Bsc:
        return new BigNumber(200000)
      case ChainNames.Arbitrum:
        return new BigNumber(2000000)
      default:
        return new BigNumber(900000)
    }
  } else if (action === "bridge") {
    switch (chain) {
      case ChainNames.Eth:
        return new BigNumber(200000)
      case ChainNames.Bsc:
        return new BigNumber(200000)
      case ChainNames.Arbitrum:
        return new BigNumber(2000000)
      default:
        return new BigNumber(1000000)
    }
  }
}

export function getNetworkFeeBN(
  feeDenom: string,
  sdk: CarbonSDK | undefined,
  wallet: CarbonWallet | undefined,
  actionType: string,
): BigNumber {
  const feeDp = sdk?.token?.getDecimals(feeDenom) ?? 0
  return (sdk?.gasFee?.getFee(actionType, feeDenom) ?? BN_ZERO).shiftedBy(-feeDp)
}