import ChevronsDown from "assets/ChevronsDown.svg"
import Loading_Light from "assets/Loading_light.gif"
import NoResults from "assets/NoResults.svg"
import DropDown from "assets/TableDropDown.svg"
import Warning from 'assets/Warning_Error.svg'
import BigNumber from "bignumber.js"
import { CarbonSDK, Models } from "carbon-js-sdk"
import Avatar from "components/Common/Avatar"
import Signal from "components/Common/Signal"
import StatusBlock from "components/Common/StatusBlock"
import TableText from "components/Common/TableText"
import TableWarning from "components/Common/TableWarning"
import { BN_ZERO } from "constants/math"
import { statusColourMap } from 'constants/stake'
import { useAppDispatch, useAppSelector, useAsyncTask } from "hooks"
import useMediaQuery from 'hooks/useMediaQuery'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { StakeTasks } from 'store/Stake/types'
import { updateStakeUI } from 'store/UI'
import { adjustHuman, bnOrZero, SECONDS_PER_DAY } from "utils/number"
import { calculateRating, getBondStatus, healthDetailsInterface } from 'utils/stake'
import { DelegationLoading } from '../DelegationInfo/Common/DelegationLoading'
import DelegationWarningPopup from './DelegationWarningPopup'
import { StakeValidatorsCard } from './StakeValidatorsCard'
import "./StakeValidatorsTable.css"

type Props = {
  validators: Models.Staking.Validator[]
  poolSizeAscending: boolean
  onTogglePoolSize: () => void
}

type RowProps = {
  validator: Models.Staking.Validator
  top: boolean
  delegatorList: Models.Staking.DelegationResponse[]
  startingStake: BigNumber
  cumulativeStake: BigNumber
  index: number
  poolSizeAscending: boolean
  validatorsLength: number
  participation: number
  participationColor: string
}

const StakeValidatorsRow: React.FC<RowProps> = ({
  validator,
  top,
  startingStake,
  delegatorList,
  cumulativeStake,
  index,
  validatorsLength,
  poolSizeAscending,
  participation,
  participationColor,
}) => {
  const sdk: CarbonSDK = useAppSelector((state) => state.app.carbonSDK)
  const valAddrMap = useAppSelector((state) => state.stake.valAddrMap)
  const blockTime = useAppSelector(state => state.stake.avgBlockTime)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()


  const stakedTokenBN =
    sdk?.token.toHuman("swth", bnOrZero(validator?.tokens ?? "")) ?? BN_ZERO
  const commissionBN = adjustHuman(
    validator?.commission?.commissionRates?.rate ?? ""
  ).shiftedBy(2)

  const [hover, setHover] = useState<boolean>(false)
  const [popup, setPopup] = useState<boolean>(false)
  const [bondStatus, status] = getBondStatus(validator.status)
  const [runFetchHealthDetails] = useAsyncTask()
  const [healthDetails, setHealthDetails] = useState({
    missedBlockCount: 0,
    oracleSlashCount: 0,
    timeSinceStarted: 0
  })
  const [aprLoading] = useTaskSubscriber(StakeTasks.AprStats)
  const [validatorsLoading] = useTaskSubscriber(StakeTasks.Validators)
  const [participationLoading] = useTaskSubscriber(StakeTasks.ValidatorsParticipation)

  const fetchHealthDetails = () => {
    runFetchHealthDetails(async () => {
      const oracleSlashCounter = await sdk?.query.oracle.SlashCounter({
        valoperAddress: validator?.operatorAddress,
      })
      const oracleSlashCount = oracleSlashCounter?.slashCounter?.slashCount.toInt() ?? 0
      
      const consAddress = valAddrMap[validator?.operatorAddress ?? ""].consAddress ?? ""

      const SigningInfo = consAddress 
        ? await sdk?.query.slashing.SigningInfo({ consAddress })
        : null

      const missedBlockCount =
        SigningInfo?.valSigningInfo?.missedBlocksCounter.toInt() ?? 0

      const startBlockHeight =
        SigningInfo?.valSigningInfo?.startHeight.toInt() ?? 0
      const currentBlock = await sdk!.query.chain.getBlock()
      const currentBlockHeight = currentBlock.header.height
      const timeSinceStarted = blockTime.toNumber() * (currentBlockHeight - startBlockHeight) / SECONDS_PER_DAY

      setHealthDetails({ missedBlockCount, oracleSlashCount, timeSinceStarted })
    })
  }

  useEffect(() => {
    if (!blockTime) return
    fetchHealthDetails()
  }, [validator, blockTime, sdk]) // eslint-disable-line react-hooks/exhaustive-deps

  const statusHoverText = (health: healthDetailsInterface) => {
    const { missedBlockCount, oracleSlashCount, timeSinceStarted } = health
    let votingPower :string
    if (cumulativeStake.toNumber() >= 10) {
      votingPower = "Very High"
    } else if (cumulativeStake.toNumber() >= 5) {
      votingPower = "High"
    } else {
      votingPower = "Normal"
    }
    return (
      <p>
        Recent Missed Blocks: {missedBlockCount}
        <br />
        Recent Oracle Slashes: {oracleSlashCount}
        <br />
        Voting Power: {votingPower}
        <br />
        Active Duration: {timeSinceStarted.toFixed(0)} days
        <br />
        Note: The lower the slash count,
        <br />
        the higher the staking rewards.
      </p>
    )
  }

  return (
    <>
      {popup ? <DelegationWarningPopup popup={popup} setPopup={() => setPopup(!popup)} /> : <></>}
      {!poolSizeAscending && index === 0 && (
        <TableWarning type="Error">
          <img src={Warning} alt="Warning" height="18px" />
          <div className="delegation-warning">
            <span>
              Delegating to top validators may centralize voting power and
              halt the network.{" "}
            </span>
            <span className="dull-link underline" style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
              Learn More
            </span>
          </div>
        </TableWarning>
      )}
      {!poolSizeAscending && index === 5 && (
        <TableWarning type="Success">
          <img src={ChevronsDown} alt="Chevrons Down" />
          <span>
            Improve decentralization by delegating to validators below.
          </span>
          <img src={ChevronsDown} alt="Chevrons Down" />
        </TableWarning>
      )}
      {poolSizeAscending && index === validatorsLength - 5 && (
        <TableWarning type="Success">
          <img src={ChevronsDown} alt="Chevrons Down" className="reversed" />
          <span>
            Improve decentralization by delegating to validators above.
          </span>
          <img src={ChevronsDown} alt="Chevrons Down" className="reversed" />
        </TableWarning>
      )}
      <tr className="stake-validators-table-row">
        <td style={{ paddingLeft: "20.5px" }}>{index + 1}</td>
        <td>
          <Avatar
            name={validator.description?.moniker ?? ""}
            valAddr={validator?.operatorAddress}
            identity={validator.description?.identity}
            icon="arrow"
          />
        </td>
        <td align="right">{delegatorList?.length}</td>
        <td align="right">
          <TableText type="token" denom="swth" content={stakedTokenBN} />
        </td>
        <td style={{ paddingLeft: "45px" }}>
          <div className="stake-validators-table-cumulative">
            <div className="stake-validators-table-cumulative-chart">
              <div
                style={{
                  width: `${startingStake.multipliedBy(125).dividedBy(100)}px`,
                }}
              />
              <div style={{ width: `${cumulativeStake.toNumber()}%` }} />
            </div>
            <div className="stake-validators-table-cumulative-text">
              {aprLoading || validatorsLoading
                ?
                <img src={Loading_Light} className="loading-icon" alt="Loading" />
                :
                <div>
                  {cumulativeStake.toFormat(2)}%
                </div>
              }
            </div>
          </div>
        </td>
        <td align="right">
          {participationLoading
            ?
            <img src={Loading_Light} className="loading-icon" alt="Loading" />
            :
            <div style={{'color': `${participationColor}`}}>
              {participation}/10
          </div>
          }
        </td>
        <td align="right">{commissionBN.toFormat(0)}%</td>
        <td style={{ paddingLeft: "34px" }}>
          <div className="stake-validators-table-status">
            <div style={{ width: "100px" }}>
              <StatusBlock status={status} text={bondStatus} />
            </div>
            <div
              style={{ marginRight: "18px" }}
              className="stake-validators-table-status-signal"
            >
              <Signal total={5} num={calculateRating(bondStatus, healthDetails, blockTime, cumulativeStake.toNumber())} />
              <div className="stake-validators-table-status-hover">
                {statusHoverText(healthDetails)}
              </div>
            </div>
          </div>
        </td>
        <td className="stake-validators-table-delegate-wrapper">
          {healthDetails.oracleSlashCount >= 1
            ?
            <>
              <img src={Warning} alt="High Slash Count" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ height: "18px", marginRight: "6px", cursor: "pointer" }} />
              {hover ? <div className="stake-validators-table-slash-hover">
                <p>This validator has a high slash count, which may impact your delegation rewards.</p>
              </div> : ""}
            </>
            :
            <></>}
          <p className="stake-row-button bolded-700" onClick={() => {
            navigate('/stake/delegate')
            dispatch(updateStakeUI({ chosenValidator: validator }))
          }}>Delegate</p>
        </td>
      </tr>
    </>
  )
}

const ValidatorsArrayEmpty: React.FC = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        flexDirection: "column",
        gap: "24px",
      }}
      className="centralize-vertical"
    >
      <img src={NoResults} alt="No Results" />
      <p style={{ fontSize: "16px" }}>No validators found.</p>
    </div>
  )
}

const StakeValidatorsTable: React.FC<Props> = ({
  validators,
  poolSizeAscending,
  onTogglePoolSize,
}) => {
  let accumulatedStake = BN_ZERO
  const totalTokens = useAppSelector(
    (state) => state.stake.aprStats.totalBonded
  )
  const delegatorsMap = useAppSelector((state) => state.stake.delegatorsMap)
  const validatorsParticipationRaw = useAppSelector(state => state.stake.validatorParticipations)
  const sdk = useAppSelector((state) => state.app.carbonSDK)
  const isDesktop = useMediaQuery('(min-width: 750px)')
  const [validatorsLoading] = useTaskSubscriber(StakeTasks.Validators)
  const [popup, setPopup] = useState<boolean>(false)

  // determine if validator is in top 33%
  let minTokens: BigNumber
  if (validators.length !== 0) {
    const minTokenIndex = poolSizeAscending ? Math.floor(validators.length) * 2 / 3 : Math.floor(validators.length / 3)
    minTokens = new BigNumber(validators[minTokenIndex]?.tokens)
  } else minTokens = BN_ZERO

  function participationStatus(participation: number): number {
    if (participation < 5) {
      return 1
    } else if (participation >= 5 && participation < 8) {
      return 2
    } else {
      return 3
    }
  }

  const calculateCumulativeStake = (validator: Models.Staking.Validator) => {
    const stakedTokenBN = sdk?.token.toHuman("swth", bnOrZero(validator?.tokens ?? "")) ?? BN_ZERO
    return stakedTokenBN.dividedBy(totalTokens).multipliedBy(100)
  }

  const memoizedCalculateCumulativeStake = useCallback(calculateCumulativeStake, [totalTokens]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isDesktop ?
        <div className="stake-validators-table-wrapper theme-color">
          <table className="stake-validators-table-inner-wrapper">
            <thead className="stake-table-head">
              <tr>
                <th style={{ paddingLeft: "16px" }}>#</th>
                <th>Validator</th>
                <th align="right">Delegators</th>
                <th align="right">
                  <div
                    className="stake-validators-table-poolsize-header"
                    onClick={onTogglePoolSize}
                  >
                    <p>Pool Size</p>
                    <img
                      src={DropDown}
                      alt="Table Dropdown"
                      className={poolSizeAscending ? "reversed" : undefined}
                    />
                  </div>
                </th>
                <th style={{ paddingLeft: "48px" }}>Cumulative Stake</th>
                <th align="right">Participation</th>
                <th align="right">Commission</th>
                <th style={{ paddingLeft: "48px" }}>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {validatorsLoading ?
                <tr>
                  <td colSpan={100} height="202px">
                    <DelegationLoading />
                  </td>
                </tr>
                : <>
                  {validators.length === 0 ? (
                    <tr>
                      <td colSpan={100} height="202px" style={{ padding: "40px 0" }}>
                        <ValidatorsArrayEmpty />
                      </td>
                    </tr>
                  ) : (
                    validators.map((validator, index) => {
                      const startingStake = accumulatedStake
                      const cumulativeStake = memoizedCalculateCumulativeStake(validator)
                      const delegatorList = delegatorsMap[validator.operatorAddress]
                      accumulatedStake = accumulatedStake.plus(cumulativeStake)
                      const participationInfo = validatorsParticipationRaw.find((o: any) => o.validatorAddress === validator.operatorAddress)
                      const participationColor = statusColourMap.get(participationStatus(participationInfo?.participated ?? 0)) ?? "#000000"

                      const currentTokens = new BigNumber(validator.tokens)
                      return (
                        <StakeValidatorsRow
                          validator={validator}
                          top={currentTokens.comparedTo(minTokens) >= 0}
                          poolSizeAscending={poolSizeAscending}
                          validatorsLength={validators.length}
                          startingStake={startingStake}
                          cumulativeStake={cumulativeStake}
                          delegatorList={delegatorList}
                          participation={participationInfo?.participated ?? 0}
                          participationColor={participationColor}
                          key={index}
                          index={index}
                        />
                      )
                    })
                  )}</>}
            </tbody>
          </table>
        </div>
        :
        <div className="stake-validators-mobile-container">
          {validatorsLoading ?
            <DelegationLoading />
            :
            validators.length === 0 ? (
              <div className="stake-validator-card-wrapper" style={{ padding: "32px 0px" }} >
                <ValidatorsArrayEmpty />
              </div>
            ) : (
              <>
                {popup ? <DelegationWarningPopup popup={popup} setPopup={() => setPopup(!popup)} /> : <></>}
                <div className="delegation-warning-mobile">
                  <img src={Warning} alt="Warning" height="18px" />
                  <div className="delegation-warning">
                    <span>
                      Delegating to top validators may centralize voting power and
                      halt the network.{" "}
                    </span>
                    <span className="dull-link underline" style={{ cursor: "pointer" }} onClick={() => setPopup(!popup)}>
                      Learn More
                    </span>
                  </div>
                </div>
                {validators.filter((item, index) => index < 5).map((validator, index) => {
                  const startingStake = accumulatedStake
                  const cumulativeStake = memoizedCalculateCumulativeStake(validator)
                  const delegatorList = delegatorsMap[validator.operatorAddress]
                  accumulatedStake = accumulatedStake.plus(cumulativeStake)
                  const participationInfo = validatorsParticipationRaw.find((o: any) => o.validatorAddress === validator.operatorAddress)
                  const participationColor = statusColourMap.get(participationStatus(participationInfo?.participated ?? 0)) ?? "#000000"

                  const currentTokens = new BigNumber(validator.tokens)
                  return (
                    <StakeValidatorsCard
                      validator={validator}
                      top={currentTokens.comparedTo(minTokens) >= 0}
                      validatorsLength={validators.length}
                      startingStake={startingStake}
                      cumulativeStake={cumulativeStake}
                      delegatorList={delegatorList}
                      participation={participationInfo?.participated ?? 0}
                      participationColor={participationColor}
                      key={index}
                      index={index}
                    />
                  )
                })}
                {validators.length > 5 && <div className="delegation-success-mobile">
                  <img src={ChevronsDown} alt="Chevrons Down" />
                  <span>
                    Improve decentralization by delegating to validators below.
                  </span>
                </div>}
                {validators.filter((item, index) => index >= 5).map((validator, index) => {
                  const startingStake = accumulatedStake
                  const cumulativeStake = memoizedCalculateCumulativeStake(validator)
                  const delegatorList = delegatorsMap[validator.operatorAddress]
                  accumulatedStake = accumulatedStake.plus(cumulativeStake)
                  const participationInfo = validatorsParticipationRaw.find((o: any) => o.validatorAddress === validator.operatorAddress)
                  const participationColor = statusColourMap.get(participationStatus(participationInfo?.participated ?? 0)) ?? "#000000"

                  const currentTokens = new BigNumber(validator.tokens)
                  return (
                    <StakeValidatorsCard
                      validator={validator}
                      top={currentTokens.comparedTo(minTokens) >= 0}
                      validatorsLength={validators.length}
                      startingStake={startingStake}
                      cumulativeStake={cumulativeStake}
                      delegatorList={delegatorList}
                      participation={participationInfo?.participated ?? 0}
                      participationColor={participationColor}
                      key={index + 5}
                      index={index + 5}
                    />
                  )
                })}
              </>
            )}
        </div>
      }
    </>
  )
}

export default StakeValidatorsTable
