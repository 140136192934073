import { WarningMessage } from "../components/Common/Notification"
import { ChainNames } from "./types"

export enum BridgeLocalStorageKeys {
  senderChain = "wallet:sender-chain",
  receiverChain = "wallet:receiver-chain",
  senderWallet = "wallet:sender-wallet",
  receiverWallet = "wallet:receiver-wallet"
}

export const neoChainMap = new Map<ChainNames, string>([
  [ChainNames.Neo, "MainNet"],
  [ChainNames.Neo3, "N3MainNet"]
]);

export const warningContent: { [index: string]: WarningMessage } = {
  noMetaMask: {
    category: "error",
    message: "Please install Metamask extension.",
  },
  noKeplr: {
    category: "error",
    message: "Please install Keplr extension.",
  },
  noLeap: {
    category: "error",
    message: "Please install Leap extension.",
  },
  noZilpay: {
    category: "error",
    message: "Please install Zilpay extension.",
  },
  noBoltX: {
    category: "error",
    message: "Please install BoltX extension."
  },
  noNeoline: {
    category: "error",
    message: "Please install NeoLine extension."
  },
  wrongNetwork: {
    message: "Select the correct network in your wallet of choice.",
    category: "error",
  },
  sameNetwork: {
    message: "Please select two different networks for bridging.",
    category: "error",
  },
  noSDK: {
    message: "CarbonSDK Initialisation failed.",
    category: "error",
  },
};

export enum PopupPurposes {
  WRONG_CHAIN = "WRONG_CHAIN",
  BEF_APPROVE = "BEF_APPROVE",
  AFT_APPROVE_APPROVAL = "AFT_APPROVE_APPROVAL",
}
