import { CarbonSDK, Models, } from "carbon-js-sdk"
import { call, put, takeLatest } from "redux-saga/effects"
import { waitforSDK } from "./Common"
import { PageRequest } from "carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination"
import Long from "long"
import { TokenPairsMap, Erc20ActionTypes } from "store/Erc20"
import { updateTokenPairsMap } from "store/Wallet"

function* fetchTokenPairs() {
  const sdk = (yield call(waitforSDK)) as CarbonSDK
  const tokenPairQuery = sdk.query.erc20.TokenPairs
  const tokenPairParams = {
    pagination: PageRequest.fromPartial({
      limit: Long.UZERO,
    }),
  }
  const tokenPairsResponse = (yield call(tokenPairQuery, tokenPairParams)) as Models.Carbon.Erc20.QueryTokenPairsResponse
  const tokenPairs = tokenPairsResponse.tokenPairs

  const tokenPairsMap: TokenPairsMap = {}
  tokenPairs.forEach((tokenPair: Models.Carbon.Erc20.TokenPair) => {
    tokenPairsMap[tokenPair.denom] = tokenPair
  })

  yield put(updateTokenPairsMap(tokenPairsMap))
}

export function* erc20Saga() {
  yield takeLatest(Erc20ActionTypes.QUERY_TOKEN_PAIRS, fetchTokenPairs)
}