import React, { useMemo } from "react";
import { useAppSelector } from "hooks";
import BigNumber from "bignumber.js";
import "./TableText.css";
import { Dayjs } from "dayjs";
import { CarbonSDK } from "carbon-js-sdk";
import { BN_ZERO } from "constants/math";

type TokenProps = {
  content: BigNumber;
  denom: string;
  type: "token"
}

type DateProps = {
  content: Dayjs;
  denom?: undefined;
  type: "date";
}

const TableText: React.FC<TokenProps | DateProps> = (prop) => {
  const sdk = useAppSelector<CarbonSDK | null>(state => state.app.carbonSDK);

  const {
    primaryText,
    secondaryText,
  } = useMemo(() => {
    if (prop.type === "date") {
      return {
        primaryText: prop.content.format("MMM DD, YYYY"),
        secondaryText: prop.content.format("HH:mm:ss"),
      };
    }

    const tokenPrice = sdk?.token.getUSDValue(prop.denom) ?? BN_ZERO;
    const tokenSymbol = sdk?.token.tokenForDenom(prop.denom)?.symbol ?? "";
    const tokenAmount = prop.content

    const threshold = 0.001
    const isTooSmall: boolean = tokenAmount.lte(threshold)
    const amountWithDenom = `${isTooSmall ? `≥${threshold}` : tokenAmount.toFixed(3, BigNumber.ROUND_DOWN)} ${tokenSymbol}`

    return {
      primaryText: amountWithDenom,
      secondaryText: "$" + prop.content.multipliedBy(tokenPrice).toFormat(2),
    }
  }, [sdk?.token, prop])

  return (
    <div className="swth-display-box">
      <p>{primaryText}</p>
      <p className="swth-display-secondary">{secondaryText}</p>
    </div>
  );
}

export default TableText;
