import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import createSagaMiddleware from "redux-saga"
import { rootSaga } from "../saga"
import appReducer from "./App"
import asyncReducer from "./Async"
import loadingReducer from "./LoadingTask"
import marketStatsReducer from "./MarketStats/MarketStats"
import stakeReducer from "./Stake"
import toastReducer from "./Toast"
import tokenPricesReducer from "./TokenPrices"
import uiReducer from "./UI"
import walletReducer from "./Wallet"

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
  // here we will be adding reducers
  marketStats: marketStatsReducer,
  tokenPrices: tokenPricesReducer,
  app: appReducer,
  wallet: walletReducer,
  toast: toastReducer,
  stake: stakeReducer,
  ui: uiReducer,
  async: asyncReducer,
  loading: loadingReducer,
});

export const store = configureStore({
  reducer,
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
