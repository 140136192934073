"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Params = exports.ModuleCredential = exports.ModuleAccount = exports.BaseAccount = exports.protobufPackage = void 0;
/* eslint-disable */
const any_1 = require("../../../google/protobuf/any");
const helpers_1 = require("../../../helpers");
const _m0 = __importStar(require("protobufjs/minimal"));
exports.protobufPackage = "cosmos.auth.v1beta1";
function createBaseBaseAccount() {
    return {
        address: "",
        pubKey: undefined,
        accountNumber: helpers_1.Long.UZERO,
        sequence: helpers_1.Long.UZERO,
    };
}
exports.BaseAccount = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.address !== "") {
            writer.uint32(10).string(message.address);
        }
        if (message.pubKey !== undefined) {
            any_1.Any.encode(message.pubKey, writer.uint32(18).fork()).ldelim();
        }
        if (!message.accountNumber.isZero()) {
            writer.uint32(24).uint64(message.accountNumber);
        }
        if (!message.sequence.isZero()) {
            writer.uint32(32).uint64(message.sequence);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBaseAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.pubKey = any_1.Any.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.accountNumber = reader.uint64();
                    break;
                case 4:
                    message.sequence = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            address: (0, helpers_1.isSet)(object.address) ? String(object.address) : "",
            pubKey: (0, helpers_1.isSet)(object.pubKey) ? any_1.Any.fromJSON(object.pubKey) : undefined,
            accountNumber: (0, helpers_1.isSet)(object.accountNumber) ? helpers_1.Long.fromValue(object.accountNumber) : helpers_1.Long.UZERO,
            sequence: (0, helpers_1.isSet)(object.sequence) ? helpers_1.Long.fromValue(object.sequence) : helpers_1.Long.UZERO,
        };
    },
    toJSON(message) {
        const obj = {};
        message.address !== undefined && (obj.address = message.address);
        message.pubKey !== undefined && (obj.pubKey = message.pubKey ? any_1.Any.toJSON(message.pubKey) : undefined);
        message.accountNumber !== undefined &&
            (obj.accountNumber = (message.accountNumber || helpers_1.Long.UZERO).toString());
        message.sequence !== undefined && (obj.sequence = (message.sequence || helpers_1.Long.UZERO).toString());
        return obj;
    },
    fromPartial(object) {
        const message = createBaseBaseAccount();
        message.address = object.address ?? "";
        message.pubKey =
            object.pubKey !== undefined && object.pubKey !== null ? any_1.Any.fromPartial(object.pubKey) : undefined;
        message.accountNumber =
            object.accountNumber !== undefined && object.accountNumber !== null
                ? helpers_1.Long.fromValue(object.accountNumber)
                : helpers_1.Long.UZERO;
        message.sequence =
            object.sequence !== undefined && object.sequence !== null
                ? helpers_1.Long.fromValue(object.sequence)
                : helpers_1.Long.UZERO;
        return message;
    },
};
function createBaseModuleAccount() {
    return {
        baseAccount: undefined,
        name: "",
        permissions: [],
    };
}
exports.ModuleAccount = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.baseAccount !== undefined) {
            exports.BaseAccount.encode(message.baseAccount, writer.uint32(10).fork()).ldelim();
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        for (const v of message.permissions) {
            writer.uint32(26).string(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseModuleAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.baseAccount = exports.BaseAccount.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.permissions.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            baseAccount: (0, helpers_1.isSet)(object.baseAccount) ? exports.BaseAccount.fromJSON(object.baseAccount) : undefined,
            name: (0, helpers_1.isSet)(object.name) ? String(object.name) : "",
            permissions: Array.isArray(object?.permissions) ? object.permissions.map((e) => String(e)) : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.baseAccount !== undefined &&
            (obj.baseAccount = message.baseAccount ? exports.BaseAccount.toJSON(message.baseAccount) : undefined);
        message.name !== undefined && (obj.name = message.name);
        if (message.permissions) {
            obj.permissions = message.permissions.map((e) => e);
        }
        else {
            obj.permissions = [];
        }
        return obj;
    },
    fromPartial(object) {
        const message = createBaseModuleAccount();
        message.baseAccount =
            object.baseAccount !== undefined && object.baseAccount !== null
                ? exports.BaseAccount.fromPartial(object.baseAccount)
                : undefined;
        message.name = object.name ?? "";
        message.permissions = object.permissions?.map((e) => e) || [];
        return message;
    },
};
function createBaseModuleCredential() {
    return {
        moduleName: "",
        derivationKeys: [],
    };
}
exports.ModuleCredential = {
    encode(message, writer = _m0.Writer.create()) {
        if (message.moduleName !== "") {
            writer.uint32(10).string(message.moduleName);
        }
        for (const v of message.derivationKeys) {
            writer.uint32(18).bytes(v);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseModuleCredential();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.moduleName = reader.string();
                    break;
                case 2:
                    message.derivationKeys.push(reader.bytes());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            moduleName: (0, helpers_1.isSet)(object.moduleName) ? String(object.moduleName) : "",
            derivationKeys: Array.isArray(object?.derivationKeys)
                ? object.derivationKeys.map((e) => (0, helpers_1.bytesFromBase64)(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        message.moduleName !== undefined && (obj.moduleName = message.moduleName);
        if (message.derivationKeys) {
            obj.derivationKeys = message.derivationKeys.map((e) => (0, helpers_1.base64FromBytes)(e !== undefined ? e : new Uint8Array()));
        }
        else {
            obj.derivationKeys = [];
        }
        return obj;
    },
    fromPartial(object) {
        const message = createBaseModuleCredential();
        message.moduleName = object.moduleName ?? "";
        message.derivationKeys = object.derivationKeys?.map((e) => e) || [];
        return message;
    },
};
function createBaseParams() {
    return {
        maxMemoCharacters: helpers_1.Long.UZERO,
        txSigLimit: helpers_1.Long.UZERO,
        txSizeCostPerByte: helpers_1.Long.UZERO,
        sigVerifyCostEd25519: helpers_1.Long.UZERO,
        sigVerifyCostSecp256k1: helpers_1.Long.UZERO,
    };
}
exports.Params = {
    encode(message, writer = _m0.Writer.create()) {
        if (!message.maxMemoCharacters.isZero()) {
            writer.uint32(8).uint64(message.maxMemoCharacters);
        }
        if (!message.txSigLimit.isZero()) {
            writer.uint32(16).uint64(message.txSigLimit);
        }
        if (!message.txSizeCostPerByte.isZero()) {
            writer.uint32(24).uint64(message.txSizeCostPerByte);
        }
        if (!message.sigVerifyCostEd25519.isZero()) {
            writer.uint32(32).uint64(message.sigVerifyCostEd25519);
        }
        if (!message.sigVerifyCostSecp256k1.isZero()) {
            writer.uint32(40).uint64(message.sigVerifyCostSecp256k1);
        }
        return writer;
    },
    decode(input, length) {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseParams();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.maxMemoCharacters = reader.uint64();
                    break;
                case 2:
                    message.txSigLimit = reader.uint64();
                    break;
                case 3:
                    message.txSizeCostPerByte = reader.uint64();
                    break;
                case 4:
                    message.sigVerifyCostEd25519 = reader.uint64();
                    break;
                case 5:
                    message.sigVerifyCostSecp256k1 = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromJSON(object) {
        return {
            maxMemoCharacters: (0, helpers_1.isSet)(object.maxMemoCharacters)
                ? helpers_1.Long.fromValue(object.maxMemoCharacters)
                : helpers_1.Long.UZERO,
            txSigLimit: (0, helpers_1.isSet)(object.txSigLimit) ? helpers_1.Long.fromValue(object.txSigLimit) : helpers_1.Long.UZERO,
            txSizeCostPerByte: (0, helpers_1.isSet)(object.txSizeCostPerByte)
                ? helpers_1.Long.fromValue(object.txSizeCostPerByte)
                : helpers_1.Long.UZERO,
            sigVerifyCostEd25519: (0, helpers_1.isSet)(object.sigVerifyCostEd25519)
                ? helpers_1.Long.fromValue(object.sigVerifyCostEd25519)
                : helpers_1.Long.UZERO,
            sigVerifyCostSecp256k1: (0, helpers_1.isSet)(object.sigVerifyCostSecp256k1)
                ? helpers_1.Long.fromValue(object.sigVerifyCostSecp256k1)
                : helpers_1.Long.UZERO,
        };
    },
    toJSON(message) {
        const obj = {};
        message.maxMemoCharacters !== undefined &&
            (obj.maxMemoCharacters = (message.maxMemoCharacters || helpers_1.Long.UZERO).toString());
        message.txSigLimit !== undefined && (obj.txSigLimit = (message.txSigLimit || helpers_1.Long.UZERO).toString());
        message.txSizeCostPerByte !== undefined &&
            (obj.txSizeCostPerByte = (message.txSizeCostPerByte || helpers_1.Long.UZERO).toString());
        message.sigVerifyCostEd25519 !== undefined &&
            (obj.sigVerifyCostEd25519 = (message.sigVerifyCostEd25519 || helpers_1.Long.UZERO).toString());
        message.sigVerifyCostSecp256k1 !== undefined &&
            (obj.sigVerifyCostSecp256k1 = (message.sigVerifyCostSecp256k1 || helpers_1.Long.UZERO).toString());
        return obj;
    },
    fromPartial(object) {
        const message = createBaseParams();
        message.maxMemoCharacters =
            object.maxMemoCharacters !== undefined && object.maxMemoCharacters !== null
                ? helpers_1.Long.fromValue(object.maxMemoCharacters)
                : helpers_1.Long.UZERO;
        message.txSigLimit =
            object.txSigLimit !== undefined && object.txSigLimit !== null
                ? helpers_1.Long.fromValue(object.txSigLimit)
                : helpers_1.Long.UZERO;
        message.txSizeCostPerByte =
            object.txSizeCostPerByte !== undefined && object.txSizeCostPerByte !== null
                ? helpers_1.Long.fromValue(object.txSizeCostPerByte)
                : helpers_1.Long.UZERO;
        message.sigVerifyCostEd25519 =
            object.sigVerifyCostEd25519 !== undefined && object.sigVerifyCostEd25519 !== null
                ? helpers_1.Long.fromValue(object.sigVerifyCostEd25519)
                : helpers_1.Long.UZERO;
        message.sigVerifyCostSecp256k1 =
            object.sigVerifyCostSecp256k1 !== undefined && object.sigVerifyCostSecp256k1 !== null
                ? helpers_1.Long.fromValue(object.sigVerifyCostSecp256k1)
                : helpers_1.Long.UZERO;
        return message;
    },
};
