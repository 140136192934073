import { Network } from "./types"
export const MigrationContractAddress = {
  [Network.BSCMainnet]: {
    SWTHTokenV1: "0x250b211ee44459dad5cd3bca803dd6a7ecb5d46c",
    SWTHTokenV2: "0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468",
  },
  [Network.BSCTestnet]: {
    SWTHTokenV1: "0x23AfFECB55e49ff2C973f3C27ff63f61e8D3C2B4",
    SWTHTokenV2: "0x509344AcA56e701e05Ace63CffB9707Fc3373156",
  },
  [Network.EthMainnet]: {
    SWTHTokenV1: "",
    SWTHTokenV2: "",
  }, 
  [Network.EthRinkeby]: {
    SWTHTokenV1: "",
    SWTHTokenV2: "",
  },
  [Network.EthGoerli]: {
    SWTHTokenV1: "",
    SWTHTokenV2: "",
  }
} as const;

export const RpcUrl = {
  [Network.EthMainnet]: "https://eth-mainnet.alchemyapi.io/v2/RWHcfoaBKzRpXnLONcEDnVqtUp7StNYl",
  [Network.EthRinkeby]: "https://eth-rinkeby.alchemyapi.io/v2/2KD9F3mFPNMfflSqZsPuTKmK_w7fFfut",
  [Network.EthGoerli]: "https://eth-goerli.alchemyapi.io/v2/Rog1kuZQf1R8X7EAmsXs7oFyQXyzIH-4",
  [Network.BSCMainnet]: "https://bsc-dataseed.binance.org/",
  [Network.BSCTestnet]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  [Network.CarbonEVM]: "https://evm-api.carbon.network/",
  [Network.ArbitrumOne]: "https://arb1.arbitrum.io/rpc",
} as const;

export const ChainID = {
  [Network.EthMainnet]: 1,
  [Network.EthRinkeby]: 4,
  [Network.EthGoerli]: 5, 
  [Network.BSCMainnet]: 56,
  [Network.BSCTestnet]: 97,
  [Network.CarbonEVM]: 9791,
  [Network.ArbitrumOne]: 42161,
} as const;

export const ChainParams = {
  [Network.EthMainnet]: {
    //TODO: sub with the correct values
    chainId: `0x${ChainID[Network.EthMainnet].toString(16)}`,
    chainName: "Ethereum Main Network",
    rpcUrls: ["https://eth-mainnet.alchemyapi.io/v2/RWHcfoaBKzRpXnLONcEDnVqtUp7StNYl"],
    blockExplorerUrls: ["https://etherscan.io"]
  },
  [Network.EthRinkeby]: {
    chainId: `0x${ChainID[Network.EthRinkeby].toString(16)}`,
    chainName: "Rinkeby Test Network",
    rpcUrls: ["https://eth-rinkeby.alchemyapi.io/v2/2KD9F3mFPNMfflSqZsPuTKmK_w7fFfut"],
    blockExplorerUrls: ["https://rinkeby.etherscan.io"]
  },
  [Network.EthGoerli]: {
    chainId: `0x${ChainID[Network.EthGoerli].toString(16)}`,
    chainName: "Goerli Test Network",
    RpcUrls: ["https://eth-goerli.alchemyapi.io/v2/Rog1kuZQf1R8X7EAmsXs7oFyQXyzIH-4"],
    blockExplorerUrls: ["https://goerli.etherscan.io"]
  },
  [Network.BSCMainnet]: {
    chainId: `0x${ChainID[Network.BSCMainnet].toString(16)}`,
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org"
    ],
    blockExplorerUrls: ["https://bscscan.com"]
  },
  [Network.BSCTestnet]: {
    chainId: `0x${ChainID[Network.BSCTestnet].toString(16)}`,
    chainName: "Binance Smart Chain (Testnet)",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545/",
      "https://data-seed-prebsc-2-s1.binance.org:8545/",
      "https://data-seed-prebsc-1-s2.binance.org:8545/",
      "https://data-seed-prebsc-2-s2.binance.org:8545/",
      "https://data-seed-prebsc-1-s3.binance.org:8545/",
      "https://data-seed-prebsc-2-s3.binance.org:8545/",
    ],
    blockExplorerUrls: ["https://testnet.bscscan.com"]
  },
  [Network.ArbitrumOne]: {
    chainId: `0x${ChainID[Network.ArbitrumOne].toString(16)}`,
    chainName: "Arbitrum One (Mainnet)",
    nativeCurrency: {
      name: "Ethereum (Arbitrum)",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: [
      "https://arb1.arbitrum.io/rpc",
    ],
    blockExplorerUrls: ["https://explorer.arbitrum.io"]
  },
  [Network.CarbonEVM]: {
    chainId: ChainID[Network.CarbonEVM],
    chainName: "Carbon EVM",
  }
} as const;

const ERC20_ABI = [
  // Some details about the token
  "function name() view returns (string)",
  "function symbol() view returns (string)",

  // Get the account balance
  "function balanceOf(address) view returns (uint)",

  "function allowance(address owner, address spender) view returns (uint)",

  // Send some of your tokens to someone else
  "function transfer(address to, uint amount)",

  "function approve(address spender, uint amount)",

  // An event triggered whenever anyone transfers to someone else
  "event Transfer(address indexed from, address indexed to, uint amount)"
];

export const contractABIs : {[key: string]: string[] | null} = {
  [Network.EthMainnet]: ERC20_ABI,
  [Network.EthRinkeby]: ERC20_ABI,
  [Network.BSCMainnet]: ERC20_ABI,
  [Network.ArbitrumOne]: ERC20_ABI,
  [Network.CarbonEVM]: ERC20_ABI,
  [Network.BSCTestnet]: null
} as const;
