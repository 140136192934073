import { CarbonSDK, Insights } from "carbon-js-sdk"
import { InsightsQueryClient } from 'carbon-js-sdk/lib/clients'
import { NetworkConfig, NetworkConfigs } from 'carbon-js-sdk/lib/constant'
import { NodeItem } from 'carbon-js-sdk/lib/insights'
import { all, call, delay, fork, put, race, take, takeLatest } from "redux-saga/effects"
import { AppActionTypes, updateCarbonSDK, updateCustomNodes, updateLatency, updateNetwork, updateNodes, updateSelectedNodes } from "store/App"
import { AppTasks } from 'store/App/types'
import { safeParseStoredValue } from 'store/localStorage'
import { uuidv4 } from 'utils'
import { CustomNodeItem, getNodesRating, RatingLatencyObj } from 'utils/nodes'
import { selectState } from "./Common"
import { runSagaTask } from './helper'

export function* watchCarbonSDK() {
  while (true) {
    const network = (yield selectState(state => state.app.network)) as CarbonSDK.Network
    const sdk = (yield selectState(state => state.app.carbonSDK)) as CarbonSDK | null
    const selectedNodes = safeParseStoredValue(localStorage.getItem(AppActionTypes.SET_SELECTED_NODES), {})
    try {
      const selectedNode = selectedNodes[network]
      const indivNode = NetworkConfigs[network]
      const fallbackNode: Partial<NetworkConfig> = {
        tmRpcUrl: indivNode.tmRpcUrl,
        restUrl: indivNode.restUrl,
        wsUrl: indivNode.wsUrl,
        faucetUrl: indivNode.faucetUrl,
        insightsUrl: indivNode.insightsUrl,
      }
      var config: Partial<NetworkConfig>
      if (localStorage.getItem("autoselect-node")) {
        config = {
          tmRpcUrl: fallbackNode.tmRpcUrl,
          restUrl: fallbackNode.restUrl,
          wsUrl: fallbackNode.wsUrl,
          faucetUrl: fallbackNode.faucetUrl,
          insightsUrl: fallbackNode.insightsUrl,
        }
      } else {
        config = {
          tmRpcUrl: selectedNode?.rpcUrl ?? fallbackNode.tmRpcUrl,
          restUrl: selectedNode?.restUrl ?? fallbackNode.restUrl,
          wsUrl: selectedNode?.wsUrl ?? fallbackNode.wsUrl,
          faucetUrl: selectedNode?.faucetUrl ?? fallbackNode.faucetUrl,
          insightsUrl: selectedNode?.insightsUrl ?? fallbackNode.insightsUrl,
        }
      }

      if (!sdk) {
        const carbonSDK = (yield CarbonSDK.instance({ network, config })) as CarbonSDK
        yield put(updateCarbonSDK({ carbonSDK: carbonSDK }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      yield race({
        sdkUpdated: take(updateCarbonSDK.type),
        delay: delay(1000)
      })
    }
  }
}

function* handleQueryNodes() {
  yield runSagaTask(AppTasks.FetchNodes, function* () {
    const network = (yield selectState(state => state.app.network)) as CarbonSDK.Network
    const selectedNodes = safeParseStoredValue(localStorage.getItem(AppActionTypes.SET_SELECTED_NODES), {})
    const currentNode = selectedNodes[network]
    const defaultConfig = NetworkConfigs[network]
    const insightsClient = new InsightsQueryClient(defaultConfig)
    const nodesResponse = (yield call([insightsClient, insightsClient.Nodes])) as Insights.InsightsQueryResponse<Insights.QueryGetNodesResponse>
    const nodesList = nodesResponse.result.models
    const fallBackNode: Insights.NodeItem = {
      nodeId: uuidv4(),
      rpcUrl: defaultConfig.tmRpcUrl,
      restUrl: defaultConfig.restUrl,
      wsUrl: defaultConfig.wsUrl,
      tmWsUrl: defaultConfig.tmWsUrl,
      faucetUrl: defaultConfig.faucetUrl,
      insightsUrl: defaultConfig.insightsUrl,
      moniker: `${network} default node`,
      appBuild: network,
      lastupdated: '',
      rpcUptime: '100',
      wsUptime: '100',
      insightUptime: '100',
      appVersion: '',
      appCommit: '',
      creator: { name: '', telegram: '' },
      latestBlockHeight: 0,
    }
    const defaultNodes = nodesList.length > 0 ? nodesList : [fallBackNode]

    try {
      const nodes = defaultNodes
      yield put(updateNodes(nodes))
      const nodeFromLocal = safeParseStoredValue(localStorage.getItem(AppActionTypes.SET_CUSTOM_NODES), [])
      const customNodes: CustomNodeItem[] = Object.values(nodeFromLocal)
      const filteredCustomNodes = customNodes.filter((node: CustomNodeItem) => node.appBuild === network).map((custom: CustomNodeItem) => {
        if (custom.nodeId === '') {
          custom.nodeId = uuidv4() // assign uuidv4 to old custom nodes
        }
        return custom
      })

      let selectedNode = nodes.find((node: Insights.NodeItem) => node.nodeId === currentNode?.nodeId)
        ?? filteredCustomNodes.find((node: CustomNodeItem) => node.nodeId === currentNode?.nodeId)
      if (!selectedNode) {
        selectedNode = nodes[0]
      }

      yield put(updateCustomNodes(filteredCustomNodes))

      yield put(updateSelectedNodes({
        ...selectedNodes,
        [network]: selectedNode,
      }))
    } catch {
      // if both sdk and insights query client failed, set default node
      yield put(updateNodes(defaultNodes))
      const newNetworkNodes = currentNode && currentNode.appBuild === network
        ? (currentNode ?? defaultNodes[0])
        : defaultNodes[0]
      yield put(updateSelectedNodes({
        ...selectedNodes,
        [network]: newNetworkNodes,
      }))
    }
  })
}

function* handleQueryLatencies(): any {
  yield runSagaTask(AppTasks.NodeLatencies, function* () {
    const nodes = (yield selectState(state => state.app.nodes)) as NodeItem[]
    if (nodes.length === 0) {
      return
    }

    try {
      const latency = (yield call(getNodesRating, nodes)) as RatingLatencyObj
      yield put(updateLatency(latency))
    } catch (err) {
      console.error(err)
    }
  })
}

function* watchFetchNodes(): Generator {
  yield takeLatest(updateNetwork.type, handleQueryNodes)
}

function* watchFetchNodesRatings(): Generator {
  yield takeLatest(updateNodes.type, handleQueryLatencies)
}

function* init() {
  yield handleQueryNodes()
  yield handleQueryLatencies()
}

export function* appSaga() {
  yield all([
    fork(watchCarbonSDK),
    fork(watchFetchNodes),
    fork(watchFetchNodesRatings),
    fork(init)
  ])
}
