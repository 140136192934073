import Loading_Dark from "assets/Loading_Transparent.gif"
import { ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1beta1/gov'
import AddressLabel from "components/Common/AddressLabel"
import NodeSelection from "components/Common/NodeSelection"
import ProviderPopup from "components/Common/Popups/ProviderPopup"
import WalletInfoPopup from "components/Common/Popups/WalletInfoPopup"
import CoinIcon from "components/Common/Tokens/CoinIcon"
import { ChainNames } from "constants/types"
import { useAppDispatch, useAppSelector } from "hooks"
import React, { useMemo, useState } from "react"
import { StakeActionTypes } from 'store/Stake'
import { disconnectWalletAction, updateChain } from "store/Wallet"
import { reduxAction } from 'utils'
import { getGovernanceAddressLink } from 'utils/externalLinks'
import { AddressSchema } from "./StakePage"
import "./StakeTopRow.css"

const StakeTopRow: React.FC<AddressSchema> = ({ carbonAddress, carbonWallet, walletSide }) => {
  const dispatch = useAppDispatch()
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]
  const asyncState = useAppSelector(state => state.async)
  const walletState = useAppSelector(state => state.wallet)
  const network = useAppSelector(state => state.app.network)
  const proposals = useAppSelector(state => state.stake.proposals)
  const [walletInfoPopupSide, setWalletInfoPopupSide] = useState<"sender" | "receiver" | null>(null)
  const [connectProviderSide, setConnectProviderSide] = useState<"sender" | "receiver">("sender")
  const [providerPopupChain, setProviderPopupChain] = useState<ChainNames | null>(null)
  const connectWallet = () => {
    if (walletState.receiverChain === ChainNames.CarbonCore) {
      setConnectProviderSide("receiver")
    } else if (walletState.senderChain === ChainNames.CarbonCore) {
      setConnectProviderSide("sender")
    } else {
      dispatch(updateChain({ senderChain: ChainNames.CarbonCore }))
    }
    setProviderPopupChain(ChainNames.CarbonCore)
  }

  let status = useMemo(() => {
    if (!proposals) return
    switch (proposals[0]?.status) {
      case ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
        return "Deposit"
      case ProposalStatus.PROPOSAL_STATUS_PASSED:
        return "Passed"
      case ProposalStatus.PROPOSAL_STATUS_REJECTED:
        return "Rejected"
      case ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
        return "Voting"
    }
  }, [proposals])


  const title = proposals[0]?.title

  return (
    <>
      <div className="stake-top-row">
        <div className="stake-top-row-proposal">
          <div className="stake-top-row-proposal-text">
            <p className="stake-secondary">
              Proposal
            </p>
            <a className="underline text-ellipsis stake-primary" style={{ flex: "1", width: "0" }} href={getGovernanceAddressLink(network)} target="_blank" rel="noopener noreferrer" >
              {title}
            </a>
          </div>
          {status && <div className={"stake-top-row-proposal-tag bolded-600 " + status.toLowerCase()}>
            <p>
              {status}
            </p>
          </div>}
        </div>
        <div className="stake-top-row-functional-components">
          <div className="stake-top-row-price">
            <CoinIcon denom="SWTH" />
            <p style={{ whiteSpace: "nowrap", width: "max-content" }} className="stake-secondary swth-price">SWTH Price</p>
            <p>${swthPrice.toFixed(5)}</p>
          </div>
          <div className="stake-top-row-connection-buttons">
            <NodeSelection />
            {(!asyncState.initWallet.loading && !asyncState.changeNetwork.loading)
              ? (!carbonAddress || !carbonWallet) ? <button className="stake-connect-button-theme button-theme-primary" onClick={() => connectWallet()}> Connect Wallet </button>
                : <div className="stake-top-row-wallet" onClick={() => { setWalletInfoPopupSide(walletSide) }}>
                  <AddressLabel logoName={carbonWallet} text={carbonAddress} bolded={true} />
                </div>
              : <button className="stake-connect-button-theme button-theme-primary"> <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" />  </button>}
          </div>
        </div>
      </div>

      {walletInfoPopupSide &&
        <WalletInfoPopup
          setWalletInfoPopupSide={setWalletInfoPopupSide}
          onDisconnect={() => { dispatch(disconnectWalletAction({ side: walletInfoPopupSide })); dispatch(reduxAction(StakeActionTypes.RELOAD_ALL_USER_DELEGATIONS)) }}
          walletInfoPopupSide={walletInfoPopupSide}
        />}
      {providerPopupChain && <ProviderPopup providerPopupChain={providerPopupChain} setProviderPopupChain={setProviderPopupChain} providerSide={connectProviderSide} />}
    </>

  )
}

export default StakeTopRow
