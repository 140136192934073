import React from 'react';
import './Banner.css';
import { useInView } from "react-intersection-observer";
import SWTH from 'assets/SWTH-white.svg';
import { useNavigate } from 'react-router-dom';

const Banner: React.FC = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
  });
  return (
    <div ref={ref} className={`banner-wrapper`}>
      <div className={`banner-inner-wrapper ${inView ? 'show' : undefined}`}>
        <p className='section-subpoint-header'>Token Migration is now live!</p>
        <p className='section-subheader'> Migrate your BSC SWTH tokens to V2 today.</p>
        <div className='migrate-now-wrapper'>
          <p className='button-text link white hover-underline-white' onClick={() => navigate('migrate')}>
            Migrate Now&nbsp;<img src={SWTH} alt="SWTH" className='SWTH' /> </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;