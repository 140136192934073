import React from 'react';
import WavingHand from 'assets/WavingHand.svg';
import TokenAnimation from 'assets/Token-Animation.gif';
import SWTH from 'assets/SWTH-white.svg';
import './Introduction.css';
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";

const Introduction: React.FC = () => {
    const navigate = useNavigate();
    const { ref, inView } = useInView({
        /* Optional options */
        triggerOnce: true,
      });
    return (
        <div ref={ref} className="introduction-wrapper">
            <div className='introduction-inner-wrapper'>
            <div className='welcome-message-wrapper'>
                <p className='welcome-header'>Say <img src={WavingHand} alt="waving-hand" className={`waving-hand ${inView ? 'wave' : undefined}`} /> to SWTH</p>
                <p className='section-subheader'> Carbon (SWTH) is the native cryptocurrency and governance token of the Carbon blockchain, a Layer 2 protocol powering cross-chain financial markets and infrastructures.  </p>
                <button className='button-theme button-theme-primary button-getSWTH' onClick={() => navigate('buy')}> Get SWTH <img src={SWTH} alt="SWTH" className='SWTH'/> </button>
            </div>
            <div className='welcome-image-display'>
                <img src={TokenAnimation} alt="token-animation" className='welcome-image' />
            </div>
            </div>
        </div>
    );
};

export default Introduction;
