import React from 'react';
import './LiquidityPool.css';
import MoneyBag from 'assets/MoneyBag.svg';
import SWTH from 'assets/SWTH-blue.svg';
import { useInView } from "react-intersection-observer";

const LiquidityPool: React.FC = () => {
  let DemexLink = 'https://guide.dem.exchange/products/what-is-liquidity-pooling/providing-liquidity-on-demex';
  let LPLink = 'https://guide.carbon.network/swth/liquidity-pools';
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
  });
  return (
    <div ref={ref} className={`liquidity-wrapper ${inView ? 'liquidity-show' : undefined}`}>
      <div className={`liquidity-inner-wrapper`}>
        <div className='liquidity-content-wrapper'>
          <div className='title-holder'>
            <p className='section-header'>Liquidity Pool Rewards&nbsp;<img src={MoneyBag} alt="Flash" className='title-image-holder' /></p>
            <p className='section-subheader'> Add liquidity and start earning! </p>
          </div>
          <div className='section-holder-outer'>
            <div className='section-holder-inner'>
              <div className='section'>
                <p className='section-subpoint-header'>Native LP Rewards</p>
                <p className='section-text flexible'> Provide liquidity to SWTH pools on Demex and start earning from fees! You may also choose to commit LP tokens to earn additional rewards. What’s sweeter is that the longer you commit, the more you stand to earn!  </p>
                <div className='external-link'>
                  <a className='button-text link hover-underline-blue' href={DemexLink} target="_blank" rel="noopener noreferrer"> Add Liquidity Now&nbsp;<img src={SWTH} alt="SWTH" className='SWTH' /> </a>
                </div>
              </div>
              <div className='section'>
                <p className='section-subpoint-header'>External LP Rewards</p>
                <p className='section-text flexible'> Become a liquidity provider on other leading exchanges to earn from swap fees and farming incentives on SWTH pools housed across a multitude of chains!</p>
                <div className='external-link'>
                  <a className='button-text link hover-underline-blue' href={LPLink} target="_blank" rel="noopener noreferrer"> Check Them Out&nbsp;<img src={SWTH} alt="SWTH" className='SWTH' /> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiquidityPool;