import ArrowLeft from "assets/ArrowLeft.svg"
import ArrowLeftBranding from "assets/ArrowLeft_Branding.svg"
import Loading_Dark from "assets/Loading_Transparent.gif"
import Loading_Light from "assets/Loading_light.gif";
import Refresh from "assets/Refresh.svg"
import RefreshBranding from "assets/Refresh_Branding.svg"
import BigNumber from 'bignumber.js'
import { CarbonSDK, CarbonTx } from "carbon-js-sdk"
import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import AddressLabel from 'components/Common/AddressLabel'
import AvatarImage from 'components/Common/AvatarImage'
import DropDown, { DropdownItem } from 'components/Common/DropDown'
import { InputSlider } from 'components/Common/InputSlider'
import Notification from "components/Common/Notification"
import ProviderPopup from 'components/Common/Popups/ProviderPopup'
import StakeProcessPopup from 'components/Common/Popups/StakeProgressPopup'
import WalletInfoPopup from 'components/Common/Popups/WalletInfoPopup'
import CoinIcon from "components/Common/Tokens/CoinIcon"
import { AddressSchema } from 'components/StakePage/StakePage'
import { BN_ZERO } from "constants/math"
import { ChainNames } from 'constants/types'
import { useAppDispatch, useAppSelector, useAsyncTask } from "hooks"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateStakeAsync } from 'store/Async'
import { StakeActionTypes } from "store/Stake"
import { addToastItem } from 'store/Toast'
import { disconnectWalletAction, updateChain } from 'store/Wallet'
import { SHIFT_DECIMALS, adjustHuman, bnOrZero, parseError, parseNumber, reduxAction, uuidv4 } from "utils"
import { ButtonState, FormState } from '../utils/createConfig'
import './DelegateCard.css'
import ExternalLink from "components/Common/ExternalLink"
import dayjs from "dayjs"

interface APYMap {
  [denom: string]: BigNumber
}

const initialFormState: FormState = {
  delegateAsset: "swth",
  delegateTo: null,

  delegateToAddr: null,

  input: '0',
  percentage: 0,
}

const initialButtonState: ButtonState = {
  enabled: true,
  text: "",
}

const DelegateCard: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [hoveredBackButton, setHoveredBackButton] = useState<boolean>(false)
  const [assetBalance, setAssetBalance] = useState<BigNumber>(BN_ZERO)
  const [hoverRefreshBalance, setHoverRefreshBalance] = useState<boolean>(false)
  const [clickedRefreshBalance, setClickedRefreshBalance] = useState<boolean>(false)
  const [popup, setPopup] = useState<boolean>(false)
  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [buttonState, setButtonState] = useState<ButtonState>(initialButtonState)
  const [inputError, setInputError] = useState<string>()
  const [reloadBal, setReloadBal] = useState<boolean>(true)
  const [runLoadBalance, , loadBalanceError,] = useAsyncTask(console.error)
  const [runStake, loadingStake] = useAsyncTask(console.error)
  const valAddrMap = useAppSelector(state => state.stake.valAddrMap)
  const allianceAssets = useAppSelector(state => state.stake.allianceAssets)
  const sdk = useAppSelector<CarbonSDK | null>(state => state.app.carbonSDK)
  const network = useAppSelector(state => state.app.network)
  const walletState = useAppSelector(state => state.wallet)
  const asyncState = useAppSelector(state => state.async)
  const selectedValidator = useAppSelector(state => state.ui.stake.chosenValidator)
  const aprStats = useAppSelector(state => state.stake.aprStats)
  const [apyMap, setApyMap] = useState<APYMap>({})
  const [apyLoading, setApyLoading] = useState<boolean>(true)

  const [walletInfoPopupSide, setWalletInfoPopupSide] = useState<"sender" | "receiver" | null>(null)
  const [connectProviderSide, setConnectProviderSide] = useState<"sender" | "receiver">("sender")
  const [providerPopupChain, setProviderPopupChain] = useState<ChainNames | null>(null)

  const resetFormState = () => setFormState(initialFormState)

  const updateDenomApyMap = useCallback(async () => {
    setApyLoading(true)
    const stakingRewardsPercentage = await sdk?.query.distribution.Params({})
      .then(res => {
        let result = bnOrZero(1)
        if (!res.params) return result
        Object.values(res.params).forEach((param) => {
          if ((typeof param !== 'string') && (typeof param !== 'number')) return
          result = result.minus(bnOrZero(param).shiftedBy(-SHIFT_DECIMALS))
        })
        return result
      })

    const takeRateInstancesPerYear = await sdk?.query.alliance.Params({})
      .then(res => {
        if (!res.params?.takeRateClaimInterval) return 0
        const numberOfSecondsPerYear = 365 * 24 * 60 * 60
        return Math.floor(numberOfSecondsPerYear / res.params?.takeRateClaimInterval?.seconds.toNumber())
      })

    const averageRewardsDistributedPerYear = await sdk?.insights.BalanceDistribution({
      // extrapolate annual staking rewards from weekly rewards
      from: dayjs().subtract(7, 'days').toISOString(),
      until: dayjs().subtract(1, 'days').toISOString(),
    }).then(res =>
      res.result.entries
        .map(entry => bnOrZero(entry.amountValue))
        .reduce((prev, curr) => prev.plus(curr), BN_ZERO)
        .dividedBy(res.result.entries.length) // per day avg
        .times(365) // annualized
        .times(stakingRewardsPercentage ?? 1) // percentage of rewards given to stakers
    )

    const allianceRewardInfo = allianceAssets.map((asset) => {
      return {
        denom: asset.denom,
        rewardWeight: bnOrZero(asset.rewardWeight).shiftedBy(-SHIFT_DECIMALS),
        takeRate: bnOrZero(asset.takeRate).shiftedBy(-SHIFT_DECIMALS),
        totalTokens: bnOrZero(asset.totalTokens).shiftedBy(-(sdk?.token.getDecimals(asset.denom) ?? 0)),
        price: sdk?.token.getUSDValue(asset.denom) ?? BN_ZERO
      }
    })

    // reward weights including swth
    const rewardWeightsSum = allianceRewardInfo.reduce((prev, curr) => prev.plus(curr.rewardWeight), BN_ZERO).plus(1)

    const updatedApy: APYMap = {}

    allianceRewardInfo.forEach((asset) => {
      const { denom, rewardWeight, totalTokens, price, takeRate } = asset
      const totalTokensPrice = totalTokens.times(price)
      const proportionOfRewards = rewardWeight.dividedBy(rewardWeightsSum)
      const rewardsInUSD = proportionOfRewards.times(averageRewardsDistributedPerYear!)
      const portionLeftPerTake = bnOrZero(1).minus(takeRate)
      const proportionAfterTake = Math.exp(Math.log(portionLeftPerTake.toNumber()) * (takeRateInstancesPerYear ?? 0))
      const apy = rewardsInUSD.dividedBy(totalTokensPrice).times(proportionAfterTake)
      updatedApy[denom] = apy
    })

    updatedApy['swth'] = aprStats.apr

    setApyMap(updatedApy)
    setApyLoading(false)
  }, [sdk, allianceAssets, aprStats])

  useEffect(() => {
    updateDenomApyMap()
  }, [updateDenomApyMap])

  const isLedgerDisabled = useMemo(() => {
    const isLedger = sdk?.wallet?.isLedgerSigner()
    return formState.delegateAsset !== "swth" && isLedger
  }, [sdk?.wallet, formState.delegateAsset]);

  const connectWallet = () => {
    if (walletState.receiverChain === ChainNames.CarbonCore) {
      setConnectProviderSide("receiver")
    } else if (walletState.senderChain === ChainNames.CarbonCore) {
      setConnectProviderSide("sender")
    } else {
      dispatch(updateChain({ senderChain: ChainNames.CarbonCore }))
    }
    setProviderPopupChain(ChainNames.CarbonCore)
  }
  const notificationList = useMemo(() => {
    const list = [
      <React.Fragment>Estimated rewards are based on historical rates of return.</React.Fragment>,
      <React.Fragment>Staked tokens will be locked with validators. Tokens have to be unstaked first for trading.</React.Fragment>,
      <React.Fragment>Rewards on staked tokens will be calculated on a per block basis.</React.Fragment>,
      <React.Fragment>If you choose to redelegate to another validator, redelegation is immediate - however, you cannot redelegate again for 30 days.</React.Fragment>,
    ];
    if (formState.delegateAsset !== "swth") {
      list.push((
        <React.Fragment>
          Delegating Alliance assets does not give you any voting power.
          {" "}
          <a className="dull-link underline" style={{ cursor: "pointer" }} href="https://commonwealth.im/carbon-protocol/discussion/11525-cip51-enable-ampluna-and-stluna-as-alliance-assets-on-carbon">
            Learn More
          </a>
        </React.Fragment>
      ))
    }

    return list
  }, [formState.delegateAsset])

  const recomputeState = (newFormState: Partial<FormState>) => {
    const newState = {
      ...formState,
      ...newFormState,
    }

    setFormState(newState)
  }

  useEffect(() => {
    recomputeState({
      ...formState,
      delegateFrom: selectedValidator,
      delegateFromAddr: selectedValidator?.operatorAddress
    })
    // eslint-disable-next-line
  }, [selectedValidator])

  const { carbonAddress, carbonWallet, walletSide }: AddressSchema = useMemo(() => {
    if (walletState.senderChain === ChainNames.CarbonCore) {
      return {
        carbonAddress: walletState.senderAddress,
        carbonWallet: walletState.senderWallet,
        walletSide: "sender",
      }
    } else if (walletState.receiverChain === ChainNames.CarbonCore) {
      return {
        carbonAddress: walletState.receiverAddress,
        carbonWallet: walletState.receiverWallet,
        walletSide: "receiver",
      }
    } else {
      return { carbonAddress: null, carbonWallet: null, walletSide: null }
    }
  }, [walletState])


  const validators = useMemo(() => {
    let results = Object.values(valAddrMap).map(pair => pair.carbonValidator).sort((lhs, rhs) => bnOrZero(rhs.tokens).comparedTo(lhs.tokens))
    results = results.filter((each) => each?.status === BondStatus.BOND_STATUS_BONDED)
    return results
  }, [valAddrMap])

  const valOptions: DropdownItem[] = useMemo(() => {
    var valList: DropdownItem[] = []
    validators.forEach((validator) => {
      valList.push({ img: <AvatarImage identity={validator?.description?.identity} />, content: validator?.description?.moniker! })
    })
    return valList
  }, [validators])

  const { assetOptions, assets } = useMemo(() => {
    const options: DropdownItem[] = [{
      content: "SWTH",
      img: <CoinIcon denom="SWTH" />
    }];
    const assets = ["swth"];

    if (sdk === null)
      return {
        assetOptions: options,
        assets,
      }

    for (const asset of allianceAssets) {
      const token = sdk.token.tokenForDenom(asset.denom)
      if (!token) continue

      assets.push(token.denom);
      options.push({
        content: token.symbol,
        tag: <span className="dropdown-tag">Alliance</span>,
        img: <CoinIcon denom={token.symbol} />
      })
    }

    return {
      assetOptions: options,
      assets,
    }
  }, [allianceAssets, sdk])

  const adjustedFee = useMemo(() => {
    const feeDenom = "swth"
    const txFee = (sdk?.gasFee?.getFee(CarbonTx.TxGasCostTypeDefaultKey, feeDenom) ?? BN_ZERO)
    const feeDenomPrice = sdk?.token.getUSDValue(feeDenom) ?? BN_ZERO
    return {
      denom: feeDenom,
      symbol: sdk?.token.tokenForDenom(feeDenom)?.symbol ?? "",
      amount: sdk?.token.toHuman("swth", txFee) ?? BN_ZERO,
      price: feeDenomPrice,
    }
  }, [sdk?.gasFee, sdk?.token])

  const assetInfo = useMemo(() => {
    const denom = formState.delegateAsset
    const token = sdk?.token.tokenForDenom(denom)
    return {
      decimals: token?.decimals.toNumber() ?? 0,
      symbol: token?.symbol ?? "",
      price: sdk?.token.getUSDValue(denom) ?? BN_ZERO,
    }
  }, [sdk?.token, formState.delegateAsset]);
  const walletAddress = sdk?.wallet?.bech32Address

  const reloadBalance = useCallback((asset = formState.delegateAsset) => {
    runLoadBalance(async () => {
      if (walletAddress && sdk) {
        const rawBalance = await sdk?.query.bank.Balance({
          address: walletAddress,
          denom: asset
        })
        if (rawBalance.balance) {
          setAssetBalance(new BigNumber(rawBalance.balance.amount))
        }

      } else {
        setAssetBalance(BN_ZERO)
      }

    })
  }, [runLoadBalance, sdk, walletAddress, formState.delegateAsset])

  useEffect(() => {
    reloadBalance(formState.delegateAsset)
  }, [sdk, network, walletAddress, reloadBal, formState.delegateAsset]) // eslint-disable-line react-hooks/exhaustive-deps

  const commissionBN = useMemo(() => {
    return adjustHuman(
      formState.delegateFrom?.commission?.commissionRates?.rate ?? ""
    ).shiftedBy(2)
  }, [formState.delegateFrom])

  const bnAmount = useMemo(() => {
    const parsedAmount = formState.input.replace(/,/g, "")
    return new BigNumber(parsedAmount)
  }, [formState.input])

  useEffect(() => {
    let amount = formState.delegateAsset === "swth" ? bnAmount.plus(adjustedFee.amount) : bnAmount;
    if (amount.gt(assetBalance.shiftedBy(-assetInfo.decimals))) {
      setInputError("The amount entered exceeds your wallet balance.")
    } else {
      setInputError("")
    }
  }, [setInputError, assetInfo.decimals, formState.delegateAsset, bnAmount, assetBalance, adjustedFee.amount])

  useEffect(() => {
    if (!walletAddress) {
      setButtonState({ enabled: false, text: "Please Connect Wallet" })
      return
    } else if (formState.input === "0" || formState.input === "") {
      setButtonState({ enabled: false, text: "Enter Amount" })
      return
    } else if (!formState.delegateFromAddr) {
      setButtonState({ enabled: false, text: "Select Validator" })
    } else {
      setButtonState({ enabled: true, text: "Delegate" })
      return
    }
  }, [walletAddress, formState, sdk, network])

  const numbersClickHandler = (percentage: number) => {
    if (assetBalance !== BN_ZERO) {
      recomputeState({
        ...formState,
        input: assetBalance.shiftedBy(-assetInfo.decimals).times(percentage / 100).toString(),
        percentage: percentage,
      })
    }
  }

  const handleStakeSubmit = () => {
    if (!formState.delegateFromAddr || formState.input === "0" || formState.input === "") return
    // eslint-disable-next-line no-alert
    if (window.confirm(
      'Are you sure? There is a 30-day waiting time if you wish to unstake in the future.',
    )) {

      runStake(async () => {
        if (!sdk?.wallet) return

        const params = {
          delegatorAddress: sdk.wallet.bech32Address,
          validatorAddress: formState.delegateFromAddr!,
          amount: bnOrZero(formState.input).shiftedBy(assetInfo.decimals),
        };
        try {
          if (sdk.token.isNativeToken(formState.delegateAsset)) {
            await sdk.staking.delegateTokens(params, { feeDenom: "swth" })
          } else {
            await sdk.alliance.delegateTokens({ ...params, denom: formState.delegateAsset }, { feeDenom: "swth" });
          }
          setPopup(true)
        } catch (err) {
          const error = parseError(err)
          dispatch(updateStakeAsync({ error }))
          dispatch(addToastItem({
            id: uuidv4(),
            senderAddress: walletAddress!,
            receiverAddress: null,
            senderChain: ChainNames.CarbonCore,
            receiverChain: null,
            transactionHash: null,
            error: error
          }))
        } finally {
          dispatch(reduxAction(StakeActionTypes.RELOAD_ALL_STAKING_INFO))
          setReloadBal(!reloadBal)
        }
      })
    }
  }

  const inputChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const filteredTargetValue: string = e.currentTarget.value
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    if (Number.isNaN(parsedTargetValue)) {
      recomputeState({
        ...formState,
        input: "",
        percentage: 0,
      })
    } else if (filteredTargetValue.split(".").length === 2) {
      const [int, decimals] = filteredTargetValue.split(".")
      let percentage = new BigNumber(parsedTargetValue).dividedBy(assetBalance.shiftedBy(-assetInfo.decimals)).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(percentage, 100) : 100
      recomputeState({
        ...formState,
        input: new BigNumber(int).toNumber() + `.${decimals}`,
        percentage: percentage,
      })
    } else {
      let percentage = new BigNumber(parsedTargetValue).dividedBy(assetBalance.shiftedBy(-assetInfo.decimals)).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(percentage, 100) : 100
      recomputeState({
        ...formState,
        input: new BigNumber(parsedTargetValue).toString(),
        percentage: percentage,
      })
    }
  }

  const inputSliderHandler = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const filteredTargetValue: string = e.currentTarget.value
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    recomputeState({
      ...formState,
      input: assetBalance.shiftedBy(-assetInfo.decimals).multipliedBy(parsedTargetValue / 100).toString(),
      percentage: parsedTargetValue,
    })
  }

  const changeDropdownHandler = (valID: number) => {
    const validator = validators[valID]
    recomputeState({
      ...formState,
      delegateFrom: validator,
      delegateFromAddr: validator.operatorAddress,
    })
  }

  const changeAssetHandler = (assetIdx: number) => {
    const asset = assets[assetIdx]
    recomputeState({
      ...formState,
      delegateAsset: asset,
    })
  }

  return (
    <div className="stake-card-wrapper theme-color">
      <div className={`stake-card-container + ${popup ? "no-padding" : ""}`}>
        <div className={`stake-card-back-button-wrapper bolded-700" + ${popup ? "active" : ""}`}>
          <div className="stake-card-back-button" onClick={() => navigate("/stake")} onMouseOver={() => setHoveredBackButton(true)} onMouseLeave={() => setHoveredBackButton(false)}>
            <img src={hoveredBackButton ? ArrowLeftBranding : ArrowLeft} alt="Back Arrow" />
            <span>Back </span>
          </div>
        </div>
        {!popup ?
          <div className='stake-card theme-color'>
            <div className="stake-card-header theme-color">
              <p>Delegate</p>
              <div style={{ display: "flex", gap: "6px" }}>
                {(!asyncState.initWallet.loading && !asyncState.changeNetwork.loading)
                  ? (!carbonAddress || !carbonWallet) ? <button className="stake-connect-button-theme button-theme-primary" onClick={() => connectWallet()}> Connect Wallet </button>
                    : <div className="stake-top-row-wallet" onClick={() => { setWalletInfoPopupSide(walletSide) }}>
                      <AddressLabel logoName={carbonWallet} text={carbonAddress} bolded={true} />
                    </div>
                  : <button className="stake-connect-button-theme button-theme-primary"> <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" />  </button>}
              </div>
            </div>
            <div className="validator-info-wrapper">
              <div className="validator-name-wrapper">
                <p>Validator</p>
                <div className="validator-dropdown-wrapper" style={{ height: "48px" }}>
                  <DropDown selectOption={changeDropdownHandler} defaultOption={selectedValidator ? validators.indexOf(selectedValidator) : ""} options={valOptions} />
                </div>
              </div>
              <div className="validator-commission-wrapper">
                <p>Commission</p>
                <div className="validator-commission">
                  <p>{commissionBN.toFormat(0)}%</p>
                </div>
              </div>
            </div>
            <div className="stake-input-wrapper">
              <p>Amount</p>
              {formState.delegateAsset !== "swth" && (
                <div className="stake-alliance-info">
                  <ExternalLink style={{ position: "absolute", bottom: 0 }} text="Staking Alliance Asset" link="https://commonwealth.im/carbon-protocol/discussion/11525-cip51-enable-ampluna-and-stluna-as-alliance-assets-on-carbon" />
                </div>
              )}
              <label className="stake-amount-input" style={inputError ? { border: "1px solid #DC6D5E" } : undefined}>
                <input type="number" placeholder="0" min="0" id="amount" value={formState.input} onChange={inputChangeHandler} style={inputError ? { color: "#DC6D5E" } : undefined} disabled={assetBalance === BN_ZERO} onFocus={() => { }} />
                <DropDown className="stake-asset-dropdown" selectOption={changeAssetHandler} defaultOption={formState.delegateAsset ? assets.findIndex(asset => asset === formState.delegateAsset) : ""} options={assetOptions} />
              </label>
              <InputSlider value={formState.percentage} disabled={assetBalance === BN_ZERO} numbersClickHandler={numbersClickHandler} onChange={(e: React.FormEvent<HTMLInputElement>) => inputSliderHandler(e)} onFocus={() => { }}
              />
            </div>
            <div className="stake-balance-box">
              <img
                src={(hoverRefreshBalance && assetBalance !== BN_ZERO) ? RefreshBranding : Refresh}
                alt="Refresh"
                onClick={() => {
                  reloadBalance()
                  setClickedRefreshBalance(true)
                }}
                onMouseOver={() => {
                  setHoverRefreshBalance(true)
                }}
                onMouseLeave={() => setHoverRefreshBalance(false)}
                onAnimationEnd={() => {
                  setClickedRefreshBalance(false)
                }}
                className={`${clickedRefreshBalance ? "stake-refresh-rotate" : ""} ${!(assetBalance.isZero()) ? "stake-refresh-pointer" : ""}`} />
              <div className="stake-balance">
                <span className="input-error">{inputError}</span>
                <span style={{ whiteSpace: "nowrap" }}>Balance:{" "}{loadBalanceError ? 0 : (assetBalance.shiftedBy(-assetInfo.decimals)).toFormat()}</span>
              </div>
            </div>
            <div className="stake-details">
              <div><span>APY</span>
                {
                  apyLoading
                    ? <img src={Loading_Light} className="loading-icon" alt="Loading_Light" />
                    : <span>{`${apyMap[formState.delegateAsset] ? apyMap[formState.delegateAsset].times(100).toFormat(2).toString() : 0.00}%`}</span>
                }
              </div>
              <div><span>Lock Period</span><span>30 Days</span></div>
              <div><span>Estimated Fee</span><span>{adjustedFee.amount.toString(10)} {adjustedFee.symbol}</span></div>
              <div><span id="usd">USD</span><span>${adjustedFee.amount.multipliedBy(adjustedFee.price).toString(10)}</span></div>
            </div>
            {!isLedgerDisabled && (
              <>
                <div className="stake-warning">
                  <Notification category="warning" message={<React.Fragment>Unstaking takes <b>&nbsp;30 days&nbsp;</b> to complete.</React.Fragment>} />
                </div>
                <div style={{ marginTop: "16px" }}>
                  <Notification category="neutral" header="Notice" list={notificationList} />
                </div>
              </>
            )}
            {isLedgerDisabled && (
              <div style={{ marginTop: "16px" }}>
                <Notification category="warning" header="Staking Alliance assets with Ledger is currently not supported, please connect with Keplr or Leap wallet. Alternatively, you can still stake SWTH with ledger." />
              </div>
            )}
            <button id="bottom-button" className="button-theme button-theme-primary" disabled={!buttonState.enabled || !!inputError || isLedgerDisabled} onClick={handleStakeSubmit}>
              {loadingStake ? <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" /> : buttonState.text}
            </button>
          </div>
          : (
            <StakeProcessPopup
              val={formState.delegateFrom!}
              setPopup={setPopup}
              resetFormState={resetFormState}
              delegateAmount={parseNumber(formState.input)!.toFormat()}
              delegateUSD={parseNumber(formState.input)!.multipliedBy(assetInfo.price).toFormat(2)}
            />
          )}
      </div>
      {walletInfoPopupSide &&
        <WalletInfoPopup
          setWalletInfoPopupSide={setWalletInfoPopupSide}
          onDisconnect={() => {
            dispatch(disconnectWalletAction({ side: walletInfoPopupSide }))
            setReloadBal(!reloadBal)
          }}
          walletInfoPopupSide={walletInfoPopupSide}
        />}
      {providerPopupChain && <ProviderPopup providerPopupChain={providerPopupChain} setProviderPopupChain={setProviderPopupChain} providerSide={connectProviderSide} />}
    </div>
  )
}

export default DelegateCard
