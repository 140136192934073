import AddressLabel from "components/Common/AddressLabel"
import { ChainNames, WalletNames } from "constants/types"
import { useAppDispatch, useAppSelector } from "hooks"
import React, { Dispatch, SetStateAction } from "react"
import { connectBoltXAction, connectCarbonMetamaskAction, connectCosmosLedgerAction, connectEthLedgerAction, connectKeplrAction, connectLeapAction, connectMetamaskAction, connectNeoLedgerAction, connectNeolineAction, connectO3Action, connectTrustWalletAction, connectZilPayAction } from "store/Wallet"
import PopupWindow from "./PopupWindow"
import "./ProviderPopup.css"

type Props = {
  providerPopupChain: ChainNames
  setProviderPopupChain: Dispatch<SetStateAction<ChainNames | null>>
  providerSide: "sender" | "receiver"
}

const ProviderPopup: React.FC<Props> = ({ providerPopupChain, setProviderPopupChain, providerSide }) => {
  const dispatch = useAppDispatch()
  const walletState = useAppSelector(state => state.wallet)
  let providerPopupInfo = {
    [ChainNames.Eth]: [
      { name: WalletNames.Metamask, connectFunction: () => { dispatch(connectMetamaskAction({ side: providerSide, senderChain: walletState.senderChain, receiverChain: walletState.receiverChain })) }, text: "Metamask" },
      { name: WalletNames.TrustWallet, connectFunction: () => { dispatch(connectTrustWalletAction({ side: providerSide, senderChain: walletState.senderChain, receiverChain: walletState.receiverChain })) }, text: "Trustwallet" },
      { name: WalletNames.Ledger, connectFunction: () => dispatch(connectEthLedgerAction({ side: providerSide, senderChain: walletState.senderChain, receiverChain: walletState.receiverChain })), text: "Ledger" }
    ],
    [ChainNames.Bsc]: [
      { name: WalletNames.Metamask, connectFunction: () => { dispatch(connectMetamaskAction({ side: providerSide, senderChain: walletState.senderChain, receiverChain: walletState.receiverChain })) }, text: "Metamask" },
    ],
    [ChainNames.CarbonCore]: [
      { name: WalletNames.Metamask, connectFunction: () => { dispatch(connectCarbonMetamaskAction({ side: providerSide })) }, text: "Metamask" },
      { name: WalletNames.Keplr, connectFunction: () => { dispatch(connectKeplrAction({ side: providerSide })) }, text: "Keplr Wallet" },
      { name: WalletNames.Leap, connectFunction: () => { dispatch(connectLeapAction({ side: providerSide })) }, text: "Leap Wallet" },
      { name: WalletNames.Ledger, connectFunction: () => { dispatch(connectCosmosLedgerAction({ side: providerSide })) }, text: "Ledger" },
    ],
    [ChainNames.CarbonEVM]: [
      { name: WalletNames.Metamask, connectFunction: () => { dispatch(connectCarbonMetamaskAction({ side: providerSide, isCarbonEvm: true })) }, text: "Metamask" },
      { name: WalletNames.Keplr, connectFunction: () => { dispatch(connectKeplrAction({ side: providerSide, isCarbonEvm: true })) }, text: "Keplr Wallet" },
      { name: WalletNames.Leap, connectFunction: () => { dispatch(connectLeapAction({ side: providerSide, isCarbonEvm: true })) }, text: "Leap Wallet" },
      { name: WalletNames.Ledger, connectFunction: () => { dispatch(connectCosmosLedgerAction({ side: providerSide, isCarbonEvm: true })) }, text: "Ledger" },
    ],
    [ChainNames.Neo]: [
      { name: WalletNames.NeoLine, connectFunction: () => { dispatch(connectNeolineAction({ side: providerSide, chain: ChainNames.Neo })) }, text: "NeoLine" },
      { name: WalletNames.O3, connectFunction: () => { dispatch(connectO3Action({ side: providerSide, chain: ChainNames.Neo })) }, text: "O3" },
      { name: WalletNames.Ledger, connectFunction: () => { dispatch(connectNeoLedgerAction({ side: providerSide, chain: ChainNames.Neo })) }, text: "Ledger" }
    ],
    [ChainNames.Neo3]: [
      { name: WalletNames.NeoLine, connectFunction: () => { dispatch(connectNeolineAction({ side: providerSide, chain: ChainNames.Neo3 })) }, text: "NeoLine" },
      { name: WalletNames.O3, connectFunction: () => { dispatch(connectO3Action({ side: providerSide, chain: ChainNames.Neo3 })) }, text: "O3" },
      { name: WalletNames.Ledger, connectFunction: () => { dispatch(connectNeoLedgerAction({ side: providerSide, chain: ChainNames.Neo3 })) }, text: "Ledger" }
    ],
    [ChainNames.Zil]: [
      { name: WalletNames.ZilPay, connectFunction: () => { dispatch(connectZilPayAction({ side: providerSide })) }, text: "Zilpay" },
      { name: WalletNames.BoltX, connectFunction: () => { dispatch(connectBoltXAction({ side: providerSide })) }, text: "BoltX" },
    ],
    [ChainNames.Arbitrum]: [
      { name: WalletNames.Metamask, connectFunction: () => { dispatch(connectMetamaskAction({ side: providerSide, senderChain: walletState.senderChain, receiverChain: walletState.receiverChain })) }, text: "Metamask" },
    ],
  }

  if (providerSide === "receiver" && (walletState.senderChain === ChainNames.Bsc || walletState.senderChain === ChainNames.Arbitrum)) {
    providerPopupInfo[ChainNames.Eth].splice(1)
  }


  return (
    <PopupWindow
      closeWindow={() => setProviderPopupChain(null)}
      headerText="Connect Wallet"
    >
      <div className="popup-provider-wrapper">
        {providerPopupInfo[providerPopupChain].map((provider, id) => {
          return (
            <div key={id} className="popup-provider-box" onClick={() => {
              setProviderPopupChain(null)
              if (provider.connectFunction) {
                provider.connectFunction()
              }
            }}>
              <AddressLabel logoName={provider.name} text={provider.text} bolded={true} />
            </div>
          )
        })}
        {providerPopupChain === ChainNames.CarbonCore && <div className="popup-provider-bottom-text">
          <p>We recommend using
            {" "}
            <a href="https://www.keplr.app/download" target="_blank" rel="noopener noreferrer">Keplr</a>
            {" "}
            or
            {" "}
            <a href="https://www.leapwallet.io/download" target="_blank" rel="noopener noreferrer">Leap</a>
            {" "}
            to connect to Carbon.
          </p>
          <br />
          <p>Using <b>Encrypted Key</b>?</p>
          <p>
            <a href="https://guide.dem.exchange/getting-started/creating-a-demex-account/keplr-wallet/import-existing-demex-account" target="_blank" rel="noopener noreferrer">
              Import
            </a>
            {" "}your existing Carbon Account into Keplr or{" "}
            <a href="https://app.dem.exchange/legacy/stake" target="_blank" rel="noopener noreferrer">
              use the legacy app
            </a>
            .
          </p>
        </div>}
      </div>
    </PopupWindow>
  )
}

export default ProviderPopup
