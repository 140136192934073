import { useEffect, useState } from 'react'
import { useAppSelector } from './useAppSelector'

export const useTaskSubscriber = (...tasks: string[]) => {
    const loadingTasks = useAppSelector(state => state.loading.loadingTasks)
    const [loading, setLoading] = useState<boolean>(false)
    let network = useAppSelector(state => state.app.network)
    useEffect(() => {
      for (const key of tasks) {
        if (loadingTasks[key]) {
          return setLoading(true)
        }
      }
      return setLoading(false)
    }, [loadingTasks, tasks, network])
  
    return [loading]
  }
  