import React, { useState } from "react";
import NumberHeader from "./NumberHeader";
import "./TabBar.css";

type Props = {
  tabs: {
    text: string,
    number: number,
    gap?: number
  }[]
  onChoose: (index: number) => void;
  style?: React.CSSProperties;
}

const TabBar: React.FC<Props> = ({ tabs, onChoose, style }) => {
  const [chosen, setChosen] = useState(0);

  const chooseTabHandler = (index: number) => {
    setChosen(index);
    onChoose(index);
  }
  return (
    <div className="tabbar" style={style}>
      {tabs.map((tab, index) => {
        return (
          <div key={index} className={chosen === index ? "tabbar-tab-focus" : "tabbar-tab-blur"} style={{ marginRight: tab.gap }} onClick={() => chooseTabHandler(index)}>
            <NumberHeader number={tab.number} text={tab.text} bolded={chosen === index} />
          </div>
        )
      })}
    </div>
  );
}

export default TabBar;
