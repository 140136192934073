import ExternalLink from "assets/ExternalLink_Disabled.svg"
import SWTH from "assets/SWTH-Blue-Small.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import { useAppSelector } from 'hooks'
import React, { useState } from "react"
import { getValidatorAddressLink } from 'utils/externalLinks'
import "./Avatar.css"
import AvatarImage from './AvatarImage'

type Props = {
  name: string
  valAddr: string
  identity: string | undefined
  icon: "link" | "arrow"
}

const Avatar: React.FC<Props> = ({ name, valAddr, icon, identity }) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const network = useAppSelector(state => state.app.network)

  return (
    <div className="avatar-wrapper">
      <AvatarImage identity={identity} />
      <a className="avatar-name" onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} href={getValidatorAddressLink(valAddr, network)} target="_blank" rel="noopener noreferrer">
        <p>{name}</p>
        <img src={icon === "link" ? ExternalLink : hovered ? SWTHBranding : SWTH} alt="External Link" className="avatar-icon" />
      </a>
    </div>
  )
}

export default Avatar
