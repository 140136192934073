import React, { ReactNode } from "react";

type Props = {
  type: "Neutral" | "Error" | "Success",
  children: ReactNode
}

const TableWarning: React.FC<Props> = ({ type, children }) => {

  const tableWarningStyles: React.CSSProperties = {
    background: type === "Neutral" ? "#F1F7FC" : type === "Error" ? "#FCF1EF" : "#EFFAEF",
    color: type === "Neutral" ? "#3A78B1" : type === "Error" ? "#DC6D5E" : "#3C9D40",
    height: "48px",
  };

  const tableWarningContentStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  }

  return (
    <tr>
      <td colSpan={100} style={tableWarningStyles}>
        <div style={tableWarningContentStyles}>
          {children}
        </div>
      </td>
    </tr>
  );
};

export default TableWarning;
