"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinerTransaction = void 0;
const consts_1 = require("../../consts");
const u_1 = require("../../u");
const BaseTransaction_1 = require("./BaseTransaction");
class MinerTransaction extends BaseTransaction_1.BaseTransaction {
    constructor(obj = {}) {
        super(Object.assign({ version: consts_1.TX_VERSION.MINER }, obj));
        this.type = 0x00;
        this.nonce = obj.nonce || 0;
    }
    static deserializeExclusive(ss, tx) {
        // read Uint32 from StringStream
        const nonce = parseInt(u_1.reverseHex(ss.read(4)), 16);
        return Object.assign(tx, { nonce });
    }
    get exclusiveData() {
        return { nonce: this.nonce };
    }
    get fees() {
        return 0;
    }
    serializeExclusive() {
        return u_1.num2hexstring(this.nonce, 4, true);
    }
    export() {
        return Object.assign(super.export(), {
            nonce: this.nonce,
        });
    }
    equals(other) {
        if (this.type !== other.type) {
            return false;
        }
        if (other instanceof MinerTransaction) {
            return this.hash === other.hash;
        }
        return this.hash === new MinerTransaction(other).hash;
    }
}
exports.MinerTransaction = MinerTransaction;
exports.default = MinerTransaction;
