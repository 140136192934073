import AddressLabel from "components/Common/AddressLabel";
import Tooltip from "components/Common/Tooltip";
import React from "react";
import { CrosschainTransaction } from "store/Wallet";
import "../BridgeHistoryCard.css";
import StatusBlock from "components/Common/StatusBlock";

interface Props {
  historyDataItem: CrosschainTransaction,
}

const CrosschainBridgeHistoryCard: React.FC<Props> = ({ historyDataItem }) => {
  const bridgeStatusToStatus = new Map<typeof historyDataItem.status, "Neutral" | "Warning" | "Success">([
    ["Receiving", "Neutral"],
    ["Confirming", "Warning"],
    ["Complete", "Success"]
  ]);
  return <div className="bridge-history-card-inner-wrapper">
    <div className="bridge-history-card-top">
      <div className="date-time" style={{ textAlign: "left" }}>
        <span>Date</span>
        <span className="bridge-history-card-text-secondary">Time</span>
      </div>
      <div className="date-time" style={{ textAlign: "right" }}>
        <span>{historyDataItem.dateTime.format("DD MMM, YYYY")}</span>
        <span className="bridge-history-card-text-secondary">{historyDataItem.dateTime.format("HH:mm:ss")}</span>
      </div>
    </div>
    <div className="bridge-history-card-bottom">
      <div className="bridge-history-label-box">
        <div>
          <span>Send Hash</span>
          <AddressLabel text={historyDataItem.sendHash} logoName={historyDataItem.sendChain} externalLinkData={{ type: "transaction", chain: historyDataItem.sendChain }} />
        </div>
        <div className="bridge-history-card-text-secondary">
          <span>Wallet</span>
          <AddressLabel 
            text={historyDataItem.fromAddress}
            logoName="wallet"
            externalLinkData={{ type: "address", chain: historyDataItem.sendChain }}
            style={{ "color":"#6A8A96", "fontSize": "12px" }}
          />
        </div>
      </div>
      <div className="bridge-history-label-box">
        <div>
          <span>Receive Hash</span>
          <AddressLabel 
            text={historyDataItem.receiveHash ? historyDataItem.receiveHash : `${historyDataItem.status}...`} 
            logoName={historyDataItem.receiveChain} 
            externalLinkData={historyDataItem.status === "Complete" ? {type: "transaction", chain: historyDataItem.receiveChain} : undefined} 
            disabled={!historyDataItem.receiveHash} 
          />
        </div>
        <div className="bridge-history-card-text-secondary">
          <span>Wallet</span>
          <AddressLabel 
            text={historyDataItem.toAddress}
            logoName="wallet"
            externalLinkData={{ type: "address", chain: historyDataItem.receiveChain }}
            style={{ "color":"#6A8A96", "fontSize": "12px" }}
          />
        </div>
      </div>
      <div className="bridge-history-label-box">
        <div>
          <span>Amount</span>
          <span>{`${historyDataItem.amount} SWTH`}</span>
        </div>
        <div className="bridge-history-card-text-secondary">
          <span>USD</span>
          <span>${historyDataItem.amountUSD}</span>
        </div>
      </div>
      <div className="bridge-history-label-box">
        <div>
          <span>Fee</span>
          <span>{`${historyDataItem.fee} SWTH`}</span>
        </div>
        <div className="bridge-history-card-text-secondary">
          <span>USD</span>
          <span>${historyDataItem.feeUSD}</span>
        </div>
      </div>
      <div className="bridge-history-card-status">
        <div className="bridge-history-card-status-label">
          <span>Status</span>
          <Tooltip>
            <span>The bridge process includes the following stages:</span>
            <span> 1. Confirming</span>
            <span> 2. Receiving</span>
            <span> 3. Complete</span>
          </Tooltip>
        </div>
        <StatusBlock text={historyDataItem.status} status={bridgeStatusToStatus.get(historyDataItem.status)} />
      </div>
    </div>
  </div>;
}

export default CrosschainBridgeHistoryCard;
