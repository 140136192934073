import AddressLabel from "components/Common/AddressLabel"
import StatusBlock from "components/Common/StatusBlock"
import React from "react"
import {
  CarbonEVMTransaction
} from "store/Wallet"
import "../BridgeHistoryRow.css"
import { ChainNames } from "constants/types"

interface Props {
  historyDataItem: CarbonEVMTransaction;
};

const CarbonEVMBridgeHistoryRow: React.FC<Props> = ({ historyDataItem }) => {

  const bridgeStatusToStatus = new Map<typeof historyDataItem.status, "Danger" | "Success">([
    ["Success", "Success"],
    ["Failed", "Danger"],
  ]);

  return (
    <tr key={historyDataItem.hash} className="bridge-table-row">
      <td>
        <p>{historyDataItem.dateTime.format("DD MMM, YYYY")}</p>
        <p className="bridge-table-text-secondary">
          {historyDataItem.dateTime.format("HH:mm:ss")}
        </p>
      </td>
      <td>
        <AddressLabel text={historyDataItem.hash}
          externalLinkData={historyDataItem.status === "Success" ? { type: "transaction", chain: ChainNames.CarbonCore, isCarbonEVMBridge: true } : undefined}
          textOverflowParams={[5, 3]}
          style={{ marginBottom: "4px" }}
        />
      </td>
      <td>
        <AddressLabel
          text={historyDataItem.senderAddress}
          logoName={historyDataItem.senderChain}
          externalLinkData={{ type: "address", chain: historyDataItem.senderChain }}
        />
      </td>
      <td>
        <AddressLabel
          text={historyDataItem.receiverAddress}
          logoName={historyDataItem.receiverChain}
          externalLinkData={{ type: "address", chain: historyDataItem.receiverChain }}
        />
      </td>
      <td>
        <p>{`${historyDataItem.amount} ${historyDataItem.token}`}</p>
        <p className="bridge-table-text-secondary">
          ${historyDataItem.amountUSD}
        </p>
      </td>
      <td>
        <div>
          <StatusBlock text={historyDataItem.status} status={bridgeStatusToStatus.get(historyDataItem.status)} />
        </div>
      </td>
    </tr>
  );
};

export default CarbonEVMBridgeHistoryRow;
