import React, { useState } from "react"
import BridgeCard from "./BridgeCard/BridgeCard"
import BridgeHistory from "./BridgeHistory"
import "./BridgePage.css"
import { useDispatch } from "react-redux";
import { updateBridgeUI } from "store/UI";
import { BridgeHistoryTab } from "./types";
import { useAppSelector } from "hooks";

const BridgePage: React.FC = () => {
  const [showBridge, setShowBridge] = useState(true);
  const dispatch = useDispatch()
  const tokenDenom = useAppSelector(state => state.wallet.tokenDenom)
  const isCarbonEvmBridge = tokenDenom !== "swth"

  const bridgeConnectHandler = () => {
    setShowBridge(true);
  };

  const viewHistoryHandler = () => {
    dispatch(updateBridgeUI({ historyTab: isCarbonEvmBridge ? BridgeHistoryTab.CarbonEVMTranfers : BridgeHistoryTab.CrosschainTransfers }))
    setShowBridge(false);
  };

  return (
    <div className="bridge-card-wrapper">
      <div className="bridge-toggle-card">
        <span className={`bridge-toggle-button ${showBridge && "bridge-toggle-active"}`} onClick={() => setShowBridge(true)}>Bridge</span>
        <span className={`bridge-toggle-button ${!showBridge && "bridge-toggle-active"}`} onClick={() => setShowBridge(false)}>History</span>
      </div>
      {showBridge ? <BridgeCard viewHistoryHandler={viewHistoryHandler} /> : <BridgeHistory setShowBridge={bridgeConnectHandler} />}
    </div>
  );
};

export default BridgePage;
