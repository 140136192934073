import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChainNames } from "constants/types";
interface ToastItemSchema {
    id: string;
    senderAddress: string | null;
    senderChain: ChainNames | null;
    receiverAddress: string | null;
    receiverChain: ChainNames | null;
    transactionHash: string | null;
    error: Error | null;
}

interface ToastSliceSchema {
    toastItems: ToastItemSchema[];
}

const initialState: ToastSliceSchema = {
    toastItems: []
};

export const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        addToastItem: (state, action: PayloadAction<ToastItemSchema>) => {
            const newItem: ToastItemSchema = {
                id: action.payload.id,
                senderAddress: action.payload.senderAddress,
                senderChain: action.payload.senderChain,
                receiverAddress: action.payload.receiverAddress,
                receiverChain: action.payload.receiverChain,
                transactionHash: action.payload.transactionHash,
                error: action.payload.error,
            };
            state.toastItems = [...state.toastItems, newItem];
        },
        removeToastItem: (state, action) => {
            state.toastItems = state.toastItems.filter((item) => item.id !== action.payload)
        }
    },
});

export const { addToastItem, removeToastItem } = toastSlice.actions;

export default toastSlice.reducer;
