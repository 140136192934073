import Loading_Light from "assets/Loading_light.gif"
import BigNumber from 'bignumber.js'
import { BN_ZERO } from 'carbon-js-sdk/lib/util/number'
import CoinIcon from 'components/Common/Tokens/CoinIcon'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React, { useEffect, useMemo } from 'react'
import { fetchUserDelegationRewards } from 'store/Stake'
import { StakeTasks } from 'store/Stake/types'
import { getTokenName } from 'utils'
import "./RewardsTable.css"

interface RowData {
    tokenName: string
    denom: string
    amountBN: BigNumber
    usdValue: BigNumber
}

const RewardsTable: React.FC = () => {
    const dispatch = useAppDispatch()
    const sdk = useAppSelector(state => state.app.carbonSDK)
    const net = useAppSelector(state => state.app.network)
    const wallet = sdk?.wallet
    const userDelegations = useAppSelector(state => state.stake.userDelegations)
    const [rewardsLoading] = useTaskSubscriber(StakeTasks.DelegationRewards)

    const tokenClient = sdk?.token

    // code to run on component mount
    useEffect(() => {

        // dispatch query everytime rewards table is loaded
        // workaround to fix rewards only loaded on site land,
        // and if wallet is not initialised, its never loaded.
        dispatch(fetchUserDelegationRewards())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, wallet?.bech32Address])

    const rewardData = useMemo(() => {
        const result: {
            [denom: string]: RowData
        } = {}
        for (const delegation of userDelegations) {
            for (const reward of delegation.pendingRewards) {
                const { amount, denom } = reward
                const tokenName = getTokenName(denom, tokenClient, net)
                const usdPrice = tokenClient?.getUSDValue(denom)
                const amountBN = tokenClient?.toHuman(denom, amount) ?? BN_ZERO

                if (!result[denom]) {
                    const usdValue = usdPrice?.times(amountBN) ?? BN_ZERO
                    result[denom] = {
                        tokenName,
                        denom,
                        amountBN,
                        usdValue,
                    }
                } else {
                    const newAmountBN = result[denom].amountBN.plus(amountBN)
                    const usdValue = usdPrice?.times(newAmountBN) ?? BN_ZERO
                    result[denom] = {
                        tokenName,
                        denom,
                        amountBN: newAmountBN,
                        usdValue,
                    }
                }
            }
        }

        return Object.values(result)
    }, [userDelegations, tokenClient, net])
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {rewardsLoading ?
                <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src={Loading_Light} className="loading-icon" alt="Loading" />
                </div>
                :
                rewardData.sort((a, b) => { return Number(b.usdValue.minus(a.usdValue)) }).map((denom, index) => {
                    return (
                        <div key={index} className="token-reward-container">
                            <div>{denom.tokenName}</div>
                            <div className='token-reward-amount-wrapper'>
                                <CoinIcon denom={denom.tokenName} />
                                <p style={{ marginLeft: "6px", marginRight: "4px" }}>{denom.amountBN.toFormat(10)}</p>
                                <p className='reward-table-text-secondary'>{`$${denom.usdValue.toFormat(2)}`}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RewardsTable
