"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInteropServicePrice = void 0;
const InteropServiceCode_1 = require("./InteropServiceCode");
const fixedPrices = {
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_CALL]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_CALLNATIVE]: 0,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_CREATEMULTISIGACCOUNT]: 256,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_CREATESTANDARDACCOUNT]: 256,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_GETCALLFLAGS]: 1024,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_NATIVEONPERSIST]: 0,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CONTRACT_NATIVEPOSTPERSIST]: 0,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_CRYPTO_CHECKSIG]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETINVOCATIONCOUNTER]: 16,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETNETWORK]: 8,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETNOTIFICATIONS]: 256,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETRANDOM]: 16,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETSCRIPTCONTAINER]: 8,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETTIME]: 8,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_GETTRIGGER]: 8,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_LOG]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_NOTIFY]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_RUNTIME_PLATFORM]: 8,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_ASREADONLY]: 16,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_DELETE]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_FIND]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_GET]: 32768,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_GETCONTEXT]: 16,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_GETREADONLYCONTEXT]: 16,
    [InteropServiceCode_1.InteropServiceCode.SYSTEM_STORAGE_PUT]: 32768,
};
function isFixedPrice(code) {
    return code in fixedPrices;
}
function getInteropServicePrice(service) {
    if (isFixedPrice(service)) {
        return fixedPrices[service];
    }
    throw new Error(`InteropServiceCode ${service} not supported as it is dynamically priced.`);
}
exports.getInteropServicePrice = getInteropServicePrice;
