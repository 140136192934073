import Loading_Light from "assets/Loading_light.gif"
import BigNumber from 'bignumber.js'
import { BN_ZERO } from 'constants/math'
import { useAppSelector, useAsyncTask } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StakeTasks } from 'store/Stake/types'
import "./SecondStakeInfoCard.css"
type Props = {
  onStakeNow: () => void
  active: boolean
}

export const SecondStakeInfoCard: React.FC<Props> = ({
  onStakeNow,
  active,
}) => {
  const sdk = useAppSelector(state => state.app.carbonSDK)
  const network = useAppSelector(state => state.app.network)
  const userDelegations = useAppSelector(state => state.stake.userDelegations)
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]
  const [runLoadBalance] = useAsyncTask()
  const [swthBalance, setSwthBalance] = useState<BigNumber>(BN_ZERO)
  const [balanceLoading, setBalanceLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [delegationLoading] = useTaskSubscriber(StakeTasks.ValidatorDelegations)
  const [rewardsLoading] = useTaskSubscriber(StakeTasks.DelegationRewards)

  const totalAmountStaked = useMemo(() => {
    const stakedBN = userDelegations.reduce((sum, delegation) => {
      if (delegation.denom !== "swth") return sum
      return sum.plus(delegation.balance.shiftedBy(-8) ?? BN_ZERO)
    }, BN_ZERO)
    return stakedBN
  }, [userDelegations])

  const tokenClient = sdk?.token

  const rewardsArray = useMemo(() => {
    const rewardsMap = userDelegations.reduce((accum, delegation) => {
      for (const reward of delegation.pendingRewards) {
        const amount = tokenClient?.toHuman(reward.denom, reward.amount) ?? BN_ZERO
        accum[reward.denom] = (accum[reward.denom] ?? BN_ZERO).plus(amount)
      }
      return accum
    }, {} as { [index: string]: BigNumber }) ?? {}
    return Object.keys(rewardsMap).map((denom) => ({
      amount: rewardsMap[denom],
      denom,
    }))
  }, [userDelegations, tokenClient])

  const totalRewardValue = rewardsArray.reduce((sum, reward) => {
    const usdValue = sdk?.token.getUSDValue(reward.denom) ?? BN_ZERO
    return sum.plus(reward.amount.times(usdValue))
  }, new BigNumber(0))

  const wallet = sdk?.wallet
  const walletAddress = wallet?.bech32Address ?? ''

  const reloadBalance = useCallback(() => {
    runLoadBalance(async () => {
      if (walletAddress && sdk) {
        setBalanceLoading(true)
        const rawBalance = await sdk?.query.bank.Balance({
          address: walletAddress,
          denom: "swth"
        })
        if (rawBalance.balance) {
          setSwthBalance(new BigNumber(rawBalance.balance.amount))
        }
        setBalanceLoading(false)
      } else {
        setSwthBalance(BN_ZERO)
      }
    })
  }, [runLoadBalance, sdk, walletAddress])

  useEffect(() => {
    reloadBalance()
  }, [sdk, network, walletAddress, reloadBalance])

  const shiftedSwthBalance = swthBalance.shiftedBy(-8)

  return (
    <div className={active ? `stake-info-card-active stake-info-card-2` : `stake-info-card-inactive stake-info-card-2`}>
      <div className="stake-info-card-2-section">
        <div>
          <p className="stake-info-title">My Staked SWTH</p>
          <p className="stake-info-description-primary">{delegationLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> : `${totalAmountStaked.toFormat(0)} SWTH`}</p>
          <p className="stake-info-description-secondary">{delegationLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> : `$${totalAmountStaked.multipliedBy(swthPrice).toFormat(2)}`}</p>
        </div>
        <div className="stake-claim-rewards">
          <button disabled={!active} className="stake-table-button-theme button-theme-primary stake-button" onClick={() => { navigate('/stake/rewards') }}>Claim Rewards</button>
          <p>{rewardsLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> : `$${totalRewardValue.toFormat(2)}`}</p>
        </div>
      </div>
      <div className="stake-divider" />
      <div className="stake-info-card-2-section">
        <div>
          <p className="stake-info-title">My Available SWTH</p>
          <p className="stake-info-description-primary">{balanceLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> : `${shiftedSwthBalance.toFormat(0)} SWTH`}</p>
          <p className="stake-info-description-secondary">{balanceLoading ? <img src={Loading_Light} className="loading-icon" alt="Loading" /> : `$${shiftedSwthBalance.multipliedBy(swthPrice).toFormat(2)}`}</p>
        </div>
        <button disabled={!active}
          className="stake-table-button-theme button-theme-primary stake-button"
          style={{ alignSelf: "flex-end" }}
          onClick={onStakeNow}
        >
          Stake Now
        </button>
      </div>
    </div>
  )
}
