import { Coin } from "@keplr-wallet/types"
import BigNumber from "bignumber.js"
import { ERC20Module, O3Wallet, Zilpay } from 'carbon-js-sdk'
import { Token } from "carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token"
import { Dayjs } from 'dayjs'
import { ethers } from "ethers"
import { Dispatch, SetStateAction } from "react"
import { EthLedgerAccount } from "utils"
import { ChainNames } from '../../constants/types'

export interface CrosschainTransaction {
    id: string;
    dateTime: Dayjs;
    fromAddress: string;
    sendHash: string;
    sendChain: ChainNames;
    toAddress: string;
    receiveHash: string | undefined;
    receiveChain: ChainNames;
    amount: string;
    amountUSD: string;
    fee: string;
    feeUSD: string;
    status: "Receiving" | "Confirming" | "Complete";
}

export interface CrosschainTransactionSchema {
    data: CrosschainTransaction[],
    pageLength: number;
}

export interface HydrogenAPISchema {
    data: HydrogenAPIDataSchema[];
    pagination: Pagination;
}

export interface CarbonEVMTransaction {
    dateTime: Dayjs;
    senderAddress: string;
    receiverAddress: string;
    amount: string;
    amountUSD: string;
    token: string;
    status: string;
    hash: string;
    senderChain: ChainNames;
    receiverChain: ChainNames;
}

interface ConvertMessage {
    receiver: string
    sender: string
    memo: string
}

export interface ConvertErc20Message extends ConvertMessage {
    amount: string
    contract_address: string
}

export interface ConvertCoinMessage extends ConvertMessage {
    coin: Coin
}

export interface HydrogenAPIDataSchema {
    id: string;
    cross_chain_flow_id: string;
    asset_name: string;
    from_address: string;
    from_address_hash: string;
    from_asset: string;
    from_asset_hash: string;
    to_address: string;
    to_address_hash: string;
    to_asset: string;
    to_asset_hash: string;
    amount: string;
    carbon_token_id: string;
    fee_address: string;
    fee_address_hash: string;
    fee_amount: string;
    nonce: string;
    created_at: string;
    updated_at: string;
    source_blockchain: string;
    bridging_blockchain: string;
    destination_blockchain: string;
    status: string;
    source_event: Event;
    bridging_event: Event | null;
    destination_event: Event | null;
}

interface Event {
    id: string;
    blockchain: string;
    contract: string;
    block_height: number;
    tx_hash: string;
    tx_fee: string;
    sender: string;
    sender_hash: string;
    index: number;
    name: string;
    event_params: any;
    processing_status: string;
    confirmation_status: string;
    confirmed_at: null;
    created_at: Date;
    updated_at: Date;
    block_time: number;
    link_status: string;
    cross_chain_flow_id: string;
}

interface Pagination {
    currentOffset: number;
    total: number;
    limit: number;
}

export interface ChainToEnumMap {
    [key: string]: ChainNames;
}

export enum WalletActionTypes {
    WALLET_APPROVE_ALLOWANCE = "WALLET_APPROVE_ALLOWANCE",
    WALLET_INIT_WALLET = "WALLET_INIT_WALLET",
    WALLET_CHECK_LOCAL_STORAGE = "WALLET_CHECK_LOCAL_STORAGE",
    WALLET_CHANGE_NETWORK = "WALLET_CHANGE_NETWORK",
    WALLET_EVM_TOKENS = "WALLET_EVM_TOKENS",
    WALLET_CARBON_TOKENS = "WALLET_CARBON_TOKENS",
    WALLET_ZIL_TOKENS = "WALLET_ZIL_TOKENS",
    WALLET_NEO_TOKENS = "WALLET_NEO_TOKENS",
    WALLET_CHECK_METAMASK_NETWORK = "WALLET_CHECK_METAMASK_NETWORK",
    WALLET_CONNECT_METAMASK = "WALLET_CONNECT_METAMASK",
    WALLET_CONNECT_CARBON_METAMASK = "WALLET_CONNECT_CARBON_METAMASK",
    WALLET_CONNECT_TRUSTWALLET = "WALLET_CONNECT_TRUSTWALLET",
    WALLET_CONNECT_KEPLR = "WALLET_CONNECT_KEPLR",
    WALLET_CONNECT_LEAP = "WALLET_CONNECT_LEAP",
    WALLET_CONNECT_ZIL = "WALLET_CONNECT_ZIL",
    WALLET_CONNECT_BOLTX = "WALLET_CONNECT_BOLX",
    WALLET_CONNECT_NEOLINE = "WALLET_CONNECT_NEOLINE",
    WALLET_CONNECT_O3 = "WALLET_CONNECT_O3",
    WALLET_CONNECT_ETH_LEDGER = "WALLET_CONNECT_ETH_LEDGER",
    WALLET_CONNECT_COSMOS_LEDGER = "WALLET_CONNECT_COSMOS_LEDGER",
    WALLET_CONNECT_NEO_LEDGER = "WALLET_CONNECT_NEO_LEDGER",
    WALLET_CONNECT_N3_LEDGER = "WALLET_CONNECT_N3_LEDGER",
    WALLET_DISCONNECT_WALLET = "WALLET_DISCONNECT_WALLET",
    WALLET_SET_TOKEN_DENOM = "WALLET_SET_TOKEN_DENOM",
    WALLET_CONVERT_CARBON_TOKENS = "WALLET_CONVERT_CARBON_TOKENS",
    WALLET_CONVERT_EVM_TOKENS = "WALLET_CONVERT_EVM_TOKENS",
}

export interface ApproveAllowanceSchema {
    senderChain: ChainNames;
    token: Token;
    walletProviderSender: ethers.providers.Web3Provider | EthLedgerAccount | Zilpay;
    contractAddr: string;
    purpose: "bridge" | "migrate";
    reloadAllowance: () => void;
}

export interface InitWalletSchema {
    side: "sender" | "receiver";
    chain: ChainNames;
}

export interface BridgeEvmTokenSchema {
    senderChain: ChainNames;
    receiverChain: ChainNames;
    fromToken: Token;
    toToken: Token;
    amount: BigNumber;
    recoveryAddress: string;
    receiverAddress: string;
    walletProviderSender: ethers.providers.Web3Provider | EthLedgerAccount;
    feeAmount: BigNumber;
    reloadBalance: (side: "sender" | "receiver") => void;
    reloadAllowance: () => void;
    setAllowance: Dispatch<SetStateAction<BigNumber>>;
    signCompleteCallback: () => void;
}
export interface BridgeCarbonTokenSchema {
    receiverChain: ChainNames;
    receiverAddress: string;
    toToken: Token;
    amount: BigNumber;
    feeAddress: string;
    feeAmount: BigNumber;
    reloadBalance: (side: "sender" | "receiver") => void;
    reloadAllowance: () => void;
}

export interface BridgeZilTokenSchema {
    receiverChain: ChainNames;
    fromToken: Token;
    toToken: Token;
    amount: BigNumber;
    recoveryAddress: string;
    receiverAddress: string;
    walletProviderSender: Zilpay;
    feeAmount: BigNumber;
    reloadBalance: (side: "sender" | "receiver") => void;
    reloadAllowance: () => void;
    setAllowance: Dispatch<SetStateAction<BigNumber>>;
    signCompleteCallback: () => void;
}

export interface BridgeNeoTokenSchema {
    receiverChain: ChainNames;
    fromToken: Token;
    toToken: Token;
    amount: BigNumber;
    recoveryAddress: string;
    receiverAddress: string;
    o3Wallet: O3Wallet;
    reloadBalance: (side: "sender" | "receiver") => void;
    reloadAllowance: () => void;
    setAllowance: Dispatch<SetStateAction<BigNumber>>;
}

export interface CheckMetamaskNetworkSchema {
    walletProvider: ethers.providers.Web3Provider;
    chain: ChainNames;
}

export interface ConnectWalletSchema {
    side: "sender" | "receiver";
    isCarbonEvm?: boolean;
}

export interface ConnectEvmSchema extends ConnectWalletSchema {
    senderChain: ChainNames;
    receiverChain: ChainNames;
}

export interface ConnectNeoWalletSchema extends ConnectWalletSchema {
    chain: ChainNames;
}

export interface DisconnectWalletSchema {
    side: "sender" | "receiver"
}

interface TasksType {
    [index: string]: string
}

export const WalletTasks: TasksType = {
    Transactions: 'transactions-loading'
}

export interface ConvertTokenSchema extends ERC20Module.ConvertCoinParams {
    reloadBalance: (side: "sender" | "receiver") => void;
    senderWalletProviderAgent: string;
}

export interface ConvertEVMTokenSchema extends ERC20Module.ConvertERC20Params {
    reloadBalance: (side: "sender" | "receiver") => void;
    senderWalletProviderAgent: string;
}