import { put } from "redux-saga/effects";
import { updateTokenPrices } from "store/TokenPrices";
import { SimpleMap } from "utils";
import { bnOrZero } from 'utils';
import { fetchData } from './Common';

const coingecko_price_api = 'https://coingecko-proxy.dem.exchange/api/v3/simple/price?ids=';
const usd_currency = "&vs_currencies=usd";

const fetchPrices = async (tokens: string | string[]) => {
    if (typeof tokens === "string") tokens = [tokens];
    const price_endpoint = `${coingecko_price_api}${tokens.join(",")}${usd_currency}`;
    const price_result = await fetchData(price_endpoint);

    return tokens.reduce((accum, token) => {
        accum[token] = bnOrZero(price_result[token].usd).toNumber();
        return accum;
    }, {} as SimpleMap<number>);
}

export function* queryPrices() {
    try {
        const result = (yield fetchPrices(["zilliqa", "switcheo", "ethereum", "usd", "binance-usd"])) as SimpleMap<number>;
        const usdValues: SimpleMap<number> = {};
        usdValues["zilliqa"] = result.zilliqa;
        usdValues["swth"] = result.switcheo;
        usdValues["eth"] = result.ethereum;
        usdValues["usd"] = result.usd;
        usdValues["binance-usd"] = result['binance-usd'];
        usdValues["cgt/1"] = 1
        yield put(updateTokenPrices(usdValues));
    } catch (error) {
        console.error(error);
    }
};
