import ArrowLeft from "assets/ArrowLeft.svg"
import ArrowLeftBranding from "assets/ArrowLeft_Branding.svg"
import { Insights } from 'carbon-js-sdk'
import { useAppDispatch, useAppSelector, useAsyncTask } from 'hooks'
import { ChangeEvent, useState } from 'react'
import { updateCustomNodes } from 'store/App'
import { updateShowNodeForm } from 'store/UI'
import { uuidv4 } from 'utils'
import { CustomNodeItem, NodeFormState } from 'utils/nodes'
import "./NodeForm.css"


type NodeFormKey = keyof NodeFormState

interface Props {
    handleSelectNode: (node: any) => void
}

const NodeForm: React.FC<Props> = ({ handleSelectNode }) => {
    const [hoveredBackButton, setHoveredBackButton] = useState<boolean>(false)
    const nodeFormInfo = useAppSelector(state => state.ui.node)
    const initialFormState: NodeFormState = {
        moniker: nodeFormInfo.nodeInfo?.moniker ?? "",
        rpcUrl: nodeFormInfo.nodeInfo?.rpcUrl ?? "",
        restUrl: nodeFormInfo.nodeInfo?.restUrl ?? "",
        wsUrl: nodeFormInfo.nodeInfo?.wsUrl ?? "",
        faucetUrl: nodeFormInfo.nodeInfo?.faucetUrl ?? "",
        insightsUrl: nodeFormInfo.nodeInfo?.insightsUrl ?? "",
        tmWsUrl: nodeFormInfo.nodeInfo?.tmWsUrl ?? "",
    }
    const [formState, setFormState] = useState<NodeFormState>(initialFormState)
    const dispatch = useAppDispatch()
    const network = useAppSelector(state => state.app.network)
    const nodes = useAppSelector(state => state.app.nodes)
    const customNodes = useAppSelector(state => state.app.customNodes)
    const selectedNodes = useAppSelector(state => state.app.selectedNodes)
    const filteredCustomNodes = customNodes.filter((node: CustomNodeItem) => node.appBuild === network)
    const customIndex = customNodes.findIndex((customNode: CustomNodeItem) => customNode.moniker === nodeFormInfo.nodeInfo?.moniker)
    //TODO: add error and loading for useAsyncTask
    const [runSaveNode] = useAsyncTask()

    const showNodeView = () => {
        dispatch(updateShowNodeForm({ viewOnly: false, showNodeForm: false }))
    }

    const recomputeState = (newFormState: Partial<NodeFormState>) => {
        const newState = {
            ...formState,
            ...newFormState,
        }

        setFormState(newState)
    }

    const handleFormChange = (key: NodeFormKey) => (event: ChangeEvent<{ value: unknown }>) => {
        recomputeState({
            ...formState,
            [key]: event.target.value as string,
        })
    }

    const validateForm = (formState: NodeFormState) => {
        // setFormErrors([])
        const errors = []
        const moniker = formState.moniker as string // formState don't have nodeId, so check against moniker
        const nodeMatch = nodes.find((node: Insights.NodeItem) => node.moniker === moniker)
        const filteredNodeMatch = filteredCustomNodes.find((node: CustomNodeItem) => node.moniker === moniker)
        if (nodeMatch || (filteredNodeMatch && filteredNodeMatch.moniker !== nodeFormInfo.nodeInfo?.moniker)) {
            errors.push('moniker')
        }
        const rpcUrl = formState.rpcUrl as string
        if (!rpcUrl.startsWith('http://') && !rpcUrl.startsWith('https://')) {
            errors.push('rpcUrl')
        }
        const restUrl = formState.restUrl as string
        if (!restUrl.startsWith('http://') && !restUrl.startsWith('https://')) {
            errors.push('restUrl')
        }
        const wsUrl = formState.wsUrl as string
        if (!wsUrl.startsWith('ws://') && !wsUrl.startsWith('wss://')) {
            errors.push('wsUrl')
        }
        const faucetUrl = formState.faucetUrl as string // optional field
        if (faucetUrl !== '' && !faucetUrl.startsWith('http://') && !faucetUrl.startsWith('https://')) {
            errors.push('faucetUrl')
        }
        const insightsUrl = formState.insightsUrl as string // optional field
        if (insightsUrl !== '' && !insightsUrl.startsWith('http://') && !insightsUrl.startsWith('https://')) {
            errors.push('insightsUrl')
        }
        const tmWsUrl = formState.tmWsUrl as string
        if (!tmWsUrl.startsWith('wss://') && !tmWsUrl.startsWith('ws://')) {
            errors.push('tmWsUrl')
        }
        //TODO: Add form errors
        // setFormErrors(errors)
        if (errors.length > 0) {
            console.error(errors)
            throw new Error()
        }
    }

    const handleSave = () => {
        runSaveNode(async () => {
            validateForm(formState)

            const newNode: CustomNodeItem = {
                appBuild: network,
                nodeId: uuidv4(),
                ...formState,
            }
            const newNodes: CustomNodeItem[] = customNodes.map((node: CustomNodeItem) =>
                Object.assign({}, node)
            )
            if (nodeFormInfo.nodeInfo && customIndex !== -1) {
                newNodes[customIndex] = newNode
            } else {
                newNodes.push(newNode)
            }
            dispatch(updateCustomNodes(newNodes))
            if (selectedNodes[network]?.moniker === nodeFormInfo.nodeInfo?.moniker) {
                handleSelectNode(formState)
            }
            showNodeView()
        })
    }

    return (
        <div className='node-form-wrapper'>
            <div className="node-form-back-button" onClick={() => showNodeView()} onMouseOver={() => setHoveredBackButton(true)} onMouseLeave={() => setHoveredBackButton(false)}>
                <img src={hoveredBackButton ? ArrowLeftBranding : ArrowLeft} alt="Back Arrow" />
                <span>Back </span>
            </div>
            <div className="node-form-info">
                <div>
                    <p>Node Name</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.moniker : formState.moniker} onChange={handleFormChange("moniker")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
                <div>
                    <p>RPC URL</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.rpcUrl : formState.rpcUrl} onChange={handleFormChange("rpcUrl")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
                <div>
                    <p>Rest URL</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.restUrl : formState.restUrl} onChange={handleFormChange("restUrl")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
                <div>
                    <p>Websocket URL</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.wsUrl : formState.wsUrl} onChange={handleFormChange("wsUrl")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
                <div>
                    <p>Faucet URL</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.faucetUrl : formState.faucetUrl} onChange={handleFormChange("faucetUrl")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
                <div>
                    <p>Insights URL</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.insightsUrl : formState.insightsUrl} onChange={handleFormChange("insightsUrl")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
                <div>
                    <p>tmWebSocket URL</p>
                    <label className="node-form-input" style={!nodeFormInfo.viewOnly ? { border: "1px solid #BDCBD1" } : undefined}>
                        <input type="text" value={nodeFormInfo.viewOnly ? nodeFormInfo.nodeInfo?.tmWsUrl : formState.tmWsUrl} onChange={handleFormChange("tmWsUrl")} style={nodeFormInfo.viewOnly ? { backgroundColor: "#1770900D" } : undefined} disabled={nodeFormInfo.viewOnly} />
                    </label>
                </div>
            </div>
            {nodeFormInfo.viewOnly ? <></> : <div className="node-form-buttons">
                <button className="button-theme button-theme-secondary popup-button" style={{ backgroundColor: "transparent" }} onClick={showNodeView}>Cancel</button>
                <button className="button-theme button-theme-primary popup-button" onClick={handleSave}>Save</button>
            </div>}
        </div>
    )
}

export default NodeForm