import { useNavigate } from 'react-router-dom'

export const DelegationEmpty: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "24px", margin: "50px 0px" }}>
      <div style={{ textAlign: "center" }}>
        <p>You did not delegate to any validators.</p>
        <p>Delegate your SWTH to start earning rewards!</p>
      </div>
      <button className="button-theme button-theme-primary" style={{ width: "180px", height: "48px", fontSize: "14px" }} onClick={() => navigate("/stake/delegate")}>Delegate Now</button>
    </div>
  )
}

export const RedelegationEmpty: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "24px", margin: "50px 0px" }}>
      <div style={{ textAlign: "center" }}>
        <p>You did not redelegate any SWTH.</p>
      </div>
      <button className="button-theme button-theme-primary" style={{ width: "180px", height: "48px", fontSize: "14px" }} onClick={() => navigate("/stake/redelegate")}>Redelegate</button>
    </div>
  )
}

export const UndelegationEmpty: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "24px", margin: "50px 0px" }}>
      <div style={{ textAlign: "center" }}>
        <p>You did not undelegate any SWTH.</p>
      </div>
      <button className="button-theme button-theme-primary" style={{ width: "180px", height: "48px", fontSize: "14px" }} onClick={() => navigate("/stake/undelegate")}>Undelegate</button>
      <div>Consider <span className='underline' style={{ cursor: "pointer" }} onClick={() => navigate("/stake/redelegate")}>Redelegating your SWTH</span> to avoid a 30-day unstaking period!</div>
    </div>
  )
}