import React from "react";
import "./ToastMessages.css";
import Check from "../../assets/Success.svg";
import Error from "../../assets/Error_Thin.svg";
import Cross from "../../assets/Cross_Disabled.svg";
import ExternalLink from "../../assets/ExternalLink_Thin.svg";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { removeToastItem } from "../../store/Toast";
import { transactionToUrl } from "../../saga/Common";

const ToastErrorSubText: React.FC = () => {
  return (
    <span className="toast-error-text"><a href="https://t.me/demex_community" target="_blank" rel="noopener noreferrer">Let us know</a> if this problem persists.</span>
  )
}

const ToastMessages: React.FC = () => {
  const tokenDenom = useAppSelector(state => state.wallet.tokenDenom)
  const isCarbonEvmBridge = tokenDenom !== 'SWTH'
  const dispatch = useAppDispatch();
  const toastItems = useAppSelector(state => state.toast.toastItems);
  const network = useAppSelector(state => state.app.network);
  const processAddress = (address: string) => {
    return `${address.slice(0,4)}...${address.slice(-4)}`
  }
  return (
    <div className="toast-messages-wrapper">
      {toastItems.map((item) => {
        return (
        <div key={item.id} className={`toast-box ${item.error ? "toast-error" : "toast-success"}`}>
          <div className="toast-box-inner-wrapper">
            <div className="toast-box-contents">
              <img src={item.error ? Error : Check } alt={item.error ? "Error" : "Check"} height="26px"/>
              <div className="toast-box-message">
                <p>{item.error ? item.senderAddress === null ? "Wallet Connection Failed" : "Transaction Failed" : "Transaction Complete"}</p>
                {(item.senderAddress !== null && item.receiverAddress !== null) ? 
                <p>
                  From
                  <span> {processAddress(item.senderAddress)} </span>
                  To
                  <span> {processAddress(item.receiverAddress)} </span>
                </p> : <ToastErrorSubText />}
              </div>
              <div className="toast-icons">
                <img src={Cross} alt="Cross" height="18px" onClick={() => dispatch(removeToastItem(item.id))}/>
                {(!item.error && item.receiverChain !== null) && <a href={transactionToUrl(item.transactionHash!,item.receiverChain, network, isCarbonEvmBridge)} target="_blank" rel="noopener noreferrer">
                  <img src={ExternalLink} alt="External Link" height="18px"/>
                </a>}
              </div>
            </div>
            {((item.error) && <div className="toast-error-contents">
              {(item.senderAddress !== null && item.receiverAddress !== null) && <ToastErrorSubText />}
              <hr />
              <p>{item.error.message}</p>
            </div>)}
          </div>
          <div className="toast-loading-wrapper">
            <div className="toast-loading-bar" onAnimationEnd={() => dispatch(removeToastItem(item.id))}/>
          </div>
        </div>
      )})}
    </div>
  );
};

export default ToastMessages;
