import SWTH from "assets/SWTH-Blue-Small.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import BigNumber from "bignumber.js"
import { CarbonSDK } from "carbon-js-sdk"
import { SimpleMap } from "carbon-js-sdk/lib/util/type"
import TabBar from "components/Common/TabBar"
import { BN_ZERO } from "constants/math"
import dayjs, { Dayjs } from "dayjs"
import { useAppSelector } from "hooks"
import useMediaQuery from 'hooks/useMediaQuery'
import React, { useEffect, useMemo, useState } from "react"
import { ValPair } from "store/Stake"
import { parseNumber } from "utils"
import { formatDateWithTime } from 'utils/date'
import StakeRedelegation from "./Desktop/StakeRedelegation"
import StakeUndelegating from "./Desktop/StakeUndelegating"
import StakeYourDelegation from "./Desktop/StakeYourDelegation"
import RedelegationMobile from './Mobile/RedelegationMobile'
import StakeYourDelegationMobile from './Mobile/StakeYourDelegationMobile'
import UndelegationMobile from './Mobile/UndelegationMobile'
import "./StakeDelegationInfo.css"

export type DelegationsTableData = {
  validator: string
  validatorLink: string
  valAddr: string
  valID: string
  denom: string
  amount: BigNumber
  pendingRewards: BigNumber
}


export type RedelegationsTableData = {
  validatorFrom: string
  validatorFromLink: string
  valFromAddr: string
  valFromID: string
  validatorTo: string
  validatorToLink: string
  valToAddr: string
  valToID: string
  amount: BigNumber
  denom: string
  symbol: string
  startBlock: string
  completionTime: Dayjs
}

export type UndelegationsTableData = {
  validator: string
  validatorLink: string
  valAddr: string
  valID: string
  denom: string
  symbol: string
  slashedAmount: BigNumber
  undelegateAmount: BigNumber
  startBlock: string
  undelegateTime: string
  completionTime: Dayjs
}

interface renderTableProps {
  delegations: DelegationsTableData[]
  redelegations: RedelegationsTableData[]
  undelegations: UndelegationsTableData[]
}


const StakeDelegationInfo: React.FC = () => {
  const [chosenIndex, setChosenIndex] = useState<number>(0)
  const [tabNumbers, setTabNumbers] = useState<number[]>([0, 0, 0])
  const [expanded1, setExpanded1] = useState<boolean>(true)
  const [hovered1, setHovered1] = useState<boolean>(false)
  const [expanded2, setExpanded2] = useState<boolean>(false)
  const [hovered2, setHovered2] = useState<boolean>(false)
  const [expanded3, setExpanded3] = useState<boolean>(false)
  const [hovered3, setHovered3] = useState<boolean>(false)
  const isDesktop = useMediaQuery('(min-width: 900px)')

  // TODO: change number 
  const tabbarItems = [
    {
      text: "Your Delegation",
      number: tabNumbers[0],
      gap: 24
    },
    {
      text: "Redelegation",
      number: tabNumbers[1],
      gap: 33
    },
    {
      text: "Undelegating",
      number: tabNumbers[2],
    }
  ]

  const renderTable = ({ delegations, redelegations, undelegations }: renderTableProps) => {
    switch (chosenIndex) {
      case 0:
        return <StakeYourDelegation delegations={delegations} redelegations={redelegations} />

      case 1:
        return <StakeRedelegation redelegations={redelegations} />

      case 2:
        return <StakeUndelegating undelegations={undelegations} />

      default:
        return
    }
  }
  const userDelegations = useAppSelector(state => state.stake.userDelegations)
  const redelegationsArr = useAppSelector(state => state.stake.userRedelegations)
  const undelegationsArr = useAppSelector(state => state.stake.userUnbondingDelegations)
  const valMap = useAppSelector(state => state.stake.valAddrMap) as SimpleMap<ValPair>
  const sdk = useAppSelector<CarbonSDK | null>(state => state.app.carbonSDK)

  const delegations: DelegationsTableData[] = useMemo(() => {
    const results: DelegationsTableData[] = []

    for (const delegation of userDelegations) {
      const validator = valMap[delegation.validatorAddress]
      const decimals = sdk?.token.getDecimals(delegation.denom) ?? 0;
      const amount = delegation.balance.shiftedBy(-decimals) ?? BN_ZERO
      if (amount.lte(BN_ZERO)) continue

      const denom = delegation.denom ?? "";

      results.push({
        validator: validator?.carbonValidator.description?.moniker ?? "",
        validatorLink: `https://scan.carbon.network/validator/${validator?.carbonValidator.operatorAddress}` ?? "",
        valAddr: validator?.carbonValidator.operatorAddress,
        valID: validator?.carbonValidator.description?.identity ?? "",
        denom,
        amount,
        pendingRewards: delegation.rewardsUsdValue,
      })
    }
    return results
  }, [userDelegations, valMap, sdk?.token])

  const redelegations: RedelegationsTableData[] = useMemo(() => {
    const results: RedelegationsTableData[] = []

    for (const redelegation of redelegationsArr) {
      if (redelegation === undefined) continue
      const validatorFrom = valMap[redelegation.validatorSrcAddress]
      const validatorTo = valMap[redelegation.validatorDstAddress]
      const completionTime = redelegation.completionTime ? redelegation.completionTime.toString() : ''
      const denom = redelegation.denom
      const symbol = sdk?.token.tokenForDenom(denom)?.symbol ?? "";
      const decimals = sdk?.token.getDecimals(denom) ?? 0;
      const amount = redelegation.initialBalance.shiftedBy(-decimals) ?? BN_ZERO

      results.push({
        validatorFrom: validatorFrom?.carbonValidator.description?.moniker ?? "",
        validatorFromLink: `https://scan.carbon.network/validator/${validatorFrom?.carbonValidator.operatorAddress}` ?? "",
        valFromAddr: validatorFrom?.carbonValidator.operatorAddress,
        valFromID: validatorFrom?.carbonValidator.description?.identity ?? "",
        validatorTo: validatorTo?.carbonValidator.description?.moniker ?? "",
        validatorToLink: `https://scan.carbon.network/validator/${validatorTo?.carbonValidator.operatorAddress}` ?? "",
        valToAddr: validatorTo?.carbonValidator.operatorAddress,
        valToID: validatorTo?.carbonValidator.description?.identity ?? "",
        amount,
        startBlock: (redelegation.creationHeight)?.toString()!,
        completionTime: dayjs(completionTime),
        denom,
        symbol,
      })
    }
    return results
  }, [redelegationsArr, valMap, sdk?.token])

  const undelegations: UndelegationsTableData[] = useMemo(() => {
    const results: UndelegationsTableData[] = []

    for (const undelegation of undelegationsArr) {
      if (undelegation === undefined) continue
      const validator = valMap[undelegation?.validatorAddress]

      const slashedAmount = undelegation.initialBalance.minus(undelegation.balance)
      const completionTime = undelegation.completionTime ? undelegation.completionTime.toString() : ''
      const token = sdk?.token.tokenForDenom(undelegation.denom);
      const symbol = token?.symbol ?? "";
      const decimals = token?.decimals ?? 0;
      results.push({
        validator: validator?.carbonValidator.description?.moniker ?? "",
        validatorLink: `https://scan.carbon.network/validator/${validator?.carbonValidator.operatorAddress}` ?? "",
        valAddr: validator?.carbonValidator.operatorAddress,
        valID: validator?.carbonValidator.description?.identity ?? "",
        undelegateAmount: parseNumber(undelegation.initialBalance)?.shiftedBy(-decimals) ?? BN_ZERO,
        denom: undelegation.denom,
        symbol,
        slashedAmount: slashedAmount?.shiftedBy(-decimals) ?? BN_ZERO,
        startBlock: undelegation.creationHeight.toString(),
        undelegateTime: formatDateWithTime(completionTime),
        completionTime: dayjs(completionTime)
      })
    }
    return results
  }, [undelegationsArr, valMap, sdk?.token])

  useEffect(() => {
    let modifiedTabNumbers = tabNumbers
    modifiedTabNumbers[0] = userDelegations.filter(delegation => delegation.balance.gt(0)).length
    modifiedTabNumbers[1] = redelegations.length
    modifiedTabNumbers[2] = undelegations.length
    setTabNumbers(modifiedTabNumbers)
  }, [userDelegations, redelegations, undelegations, tabNumbers])

  return (
    <>
      {
        isDesktop
          ?
          sdk?.wallet && <div className="stake-delegation-info">
            <TabBar tabs={tabbarItems} onChoose={(index) => setChosenIndex(index)} style={{ marginBottom: "16px" }} />
            {renderTable({ delegations, redelegations, undelegations })}
          </div>
          :
          sdk?.wallet && <div className="delegations-cards-mobile-wrapper">
            <div>
              <div className='delegations-card-mobile-tab-wrapper' style={expanded1 ? { borderBottom: "2px solid #0A3F52" } : { borderBottom: "1px solid #BDCBD1" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <p className='bolded-700 delegations-card-tab-name'>Your Delegation</p>
                  <p className='bolded-700 num-of-delegations'>{tabNumbers[0]}</p>
                </div>
                <img
                  onMouseEnter={() => setHovered1(true)}
                  onMouseLeave={() => setHovered1(false)}
                  onClick={() => { setExpanded1(!expanded1) }}
                  src={hovered1 ? SWTHBranding : SWTH}
                  style={expanded1 ? undefined : { transform: "rotate(45deg)" }}
                  alt="Arrow"
                />
              </div>
              {expanded1 && <StakeYourDelegationMobile delegations={delegations} redelegations={redelegations} />}
            </div>
            <div>
              <div className='delegations-card-mobile-tab-wrapper' style={expanded2 ? { borderBottom: "2px solid #0A3F52" } : { borderBottom: "1px solid #BDCBD1" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <p className='bolded-700 delegations-card-tab-name'>Redelegation</p>
                  <p className='bolded-700 num-of-delegations'>{tabNumbers[1]}</p>
                </div>
                <img
                  onMouseEnter={() => setHovered2(true)}
                  onMouseLeave={() => setHovered2(false)}
                  onClick={() => { setExpanded2(!expanded2) }}
                  src={hovered2 ? SWTHBranding : SWTH}
                  style={expanded2 ? undefined : { transform: "rotate(45deg)" }}
                  alt="Arrow"
                />
              </div>
              {expanded2 && <RedelegationMobile redelegations={redelegations} />}
            </div>
            <div>
              <div className='delegations-card-mobile-tab-wrapper' style={expanded3 ? { borderBottom: "2px solid #0A3F52" } : { borderBottom: "1px solid #BDCBD1" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <p className='bolded-700 delegations-card-tab-name'>Undelegation</p>
                  <p className='bolded-700 num-of-delegations'>{tabNumbers[2]}</p>
                </div>
                <img
                  onMouseEnter={() => setHovered3(true)}
                  onMouseLeave={() => setHovered3(false)}
                  onClick={() => { setExpanded3(!expanded3) }}
                  src={hovered3 ? SWTHBranding : SWTH}
                  style={expanded3 ? undefined : { transform: "rotate(45deg)" }}
                  alt="Arrow"
                />
              </div>
              {expanded3 && <UndelegationMobile undelegations={undelegations} />}
            </div>
          </div>
      }
    </>
  )
}

export default StakeDelegationInfo
