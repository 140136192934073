import React from 'react';
import Map from 'assets/Map.svg';
import './Explore.css'
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer";

const Explore: React.FC = () => {
    const navigate = useNavigate();
    const { ref, inView } = useInView({
        /* Optional options */
        triggerOnce: true,
      });
    return (
        <div ref={ref} className={`explore-wrapper`}>
            <div className={`explore-inner-wrapper ${inView ? 'show' : undefined}`}>
            <div className='title-holder'>
                <p className='section-header'>Explore Carbonhub&nbsp;<img src={Map} alt="Map" className='title-image-holder'/></p>
                <p className='section-subheader'> Have more questions? We've got you covered!  </p>
            </div>
            <p className='section-subheader underline bolded-700 link' onClick={() => navigate('buy')}> Where can I buy SWTH from?  </p>
            <p className='section-subheader underline bolded-700 link' onClick={() => navigate('stake')}> How do I stake my SWTH tokens?  </p>
            <p className='section-subheader underline bolded-700 link' onClick={() => navigate('migrate')}> Where can I migrate my old SWTH token to the new one?  </p>
            </div>
        </div>
    );
};

export default Explore;