import React from 'react';
import Discord from 'assets/Discord.svg';
import Twitter from 'assets/Twitter.svg';
import Tele from 'assets/Tele.svg';
import DiscordWhite from 'assets/Discord-white.svg';
import TwitterWhite from 'assets/Twitter-white.svg';
import TeleWhite from 'assets/Tele-white.svg';

const Header: React.FC<{ color: string }> = ({ color }) => {
  const css = `
    .icon-set {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       width: 100%;
    }
    .social-media-icon:hover {
      filter: invert(45%) sepia(30%) saturate(840%) hue-rotate(167deg) brightness(91%) contrast(104%);
    }
    `
  return (
    <div className='icon-set'>
      <style>
        {css}
      </style>
      <a href={'https://t.me/demex_community'} target="_blank" rel="noopener noreferrer">
          <img src={color === 'white' ? TeleWhite : Tele} alt="tele" className='social-media-icon'/></a>
      <a href={'https://mobile.twitter.com/0xcarbon'} target="_blank" rel="noopener noreferrer">
          <img src={color === 'white' ? TwitterWhite : Twitter} alt="twitter" className='social-media-icon'/></a>
      <a href={'https://discord.gg/SPh62Hf'} target="_blank" rel="noopener noreferrer">
          <img src={color === 'white' ? DiscordWhite : Discord} alt="discord" className='social-media-icon'/></a>
    </div>
  );
};

export default Header;