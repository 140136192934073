import React, { useEffect, useState, useMemo } from "react";
import "./StakeFAQ.css";
import StakeFAQHeader from "./StakeFAQHeader";
import StakeFAQItem from "./StakeFAQItem";

interface StakeFAQItemSchema {
  header: string;
  textBlocks: string[];
  extra?: {
    index: number,
    element: JSX.Element
  }
}

const StakeFAQ: React.FC = () => {
  const [filteredStakeFAQContent, setFilteredStakeFAQContent] = useState<StakeFAQItemSchema[]>([]);
  const [inputText, setInputText] = useState<string>("");
  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputText(event.currentTarget.value);
  }
  const stakeFAQContent: StakeFAQItemSchema[] = useMemo(() => [
    {
      header: "How can I start staking?",
      textBlocks: [
        "Step 1. Select a trusted validator",
        "Stake your SWTH with active validators that are eligible for staking",
        "",
        "Step 2. Stake SWTH",
        "Enter the amount of SWTH tokens you want to stake. Buy or deposit SWTH into your wallet.",
        "",
        "Step 3. Start earning rewards",
        "You will receive rewards every second from your staked assets."
      ]
    },
    {
      header: "What does it mean by staking my SWTH?",
      textBlocks: [
        "Staking SWTH is the process of locking your Switcheo Tokens to support the security and operations of the Carbon protocol. While locking your SWTH, you also assign the intrinsic voting power of these tokens to one or more validators of your choice, who will act on your behalf.",
        "",
        "Since not everyone can run their own hardware, only validators run a full node and participate in consensus by voting on and committing new blocks to the Carbon side-chain.",
        "",
        "This process is sometimes also called bonding or delegating."
      ]
    },
    {
      header: 'What do the "Bonded" or “Unbonding” statuses mean?',
      textBlocks: [
        "Bonded refers to the state in which your SWTH are successfully staked and are earning block rewards.",
        "",
        "Unbonding refers to the state in which your SWTH are unstaked but not tradeable or transferable due to an earlier unstaking action performed. The unbonding duration for SWTH is 30 days. During those 30 days you will not earn any rewards.",
        "",
        "However when you transfer stake for your SWTH tokens (redelegate) to a different validator (use the transfer button), your bond is switched immediately and you continue receiving rewards.",
        "",
        "Please note that you will still incur slashing penalties if your previous validator commits a fault within the 30 days. Additionally, stake transfers can only be done once per target validator every 30 days! This means your tokens cannot be transferred from the new validator for 30 days after the last redelegation."
      ]
    },
    {
      header: "What are the rewards for staking?",
      textBlocks: [
        "Staking rewards consist:",
        " 1. “Block Rewards”, which are SWTH tokens generated by the network with each block through an inflation curve.",
        " 2. “Fee Rewards”, which are the fees that traders and other network participants pay for using the network.",
        "",
        "",
        "All rewards are distributed in proportion to the amount staked. For example, if 100,000 tokens are set to be distributed through block rewards, and 50% of SWTH tokens are bonded, all 100,000 tokens will go to the 50% of stakers evenly.",
        "",
        "In the simplest case where you are the only staker, all 100,000 tokens will go to you."
      ],
      extra: {
        index: 4,
        element: 
        <p>
          More details can be found ​
          <a className="dull-link underline" href="https://blog.switcheo.com/enhancing-the-switcheo-token/" target="_blank" rel="noopener noreferrer">here</a>
          .
        </p>
      }
    },
    {
      header: "Is there a minimum staking amount?",
      textBlocks: [
        "There is no minimum staking amount. However, there is a network fee of 1 SWTH per staking transaction."
      ]
    },
    {
      header: "Is there a minimum staking period?",
      textBlocks: [
        "There is no minimum staking period. However, there is a 30 day unbonding duration should you wish to unstake your tokens. During this period, rewards will not be accrued by you."
      ]
    },
    {
      header: "What are the risks of staking?",
      textBlocks: [
        "Validators have no control over your staked SWTH tokens and your SWTH tokens remains in your wallet.",
        "",
        'However, your staked tokens can be slashed (burnt) in the event the validator you delegated tokens to commits a "Byzantine fault".',
        "",
        "This can happen if the validator goes down for too long (>10% of blocks missed in 36000 blocks), or double-signs a block (signs and broadcasts two different blocks with the same height). The penalty is 0.1% and 5% respectively.",
        "",
        "Validators should therefore be chosen with care based on their validation history that can be viewed from the Carbon chain explorer and the Demex and CarbonHub staking interface."
      ]
    },
    {
      header: "What happens if a validator commits a fault?",
      textBlocks: [
        "Staked tokens will be slashed by:",
        "5% when a validator is faulted for a double sign.",
        "0.1% when a validator misses more than 10% of its blocks in a 36000 block window. ",
        "",
        "The validator is “jailed” and must can only rejoin the network after 1 hour to protect stakers from further losses. Rewards will not be earned when the validator is jailed.",
        "",
        "When the validator is unjailed, all existing staked tokens will remain staked to the validator unless the staker decides to transfer or unbond their tokens."
      ]
    },
    {
      header: "My staking rewards are not in my wallet!",
      textBlocks: [
        "Your staking rewards needs to be claimed via the Stake page on CarbonHub."
      ]
    },
    {
      header: "Does staking SWTH generate rewards from liquidity pools or insurance products?",
      textBlocks: [],
      extra: {
        index: 0,
        element: 
          <p>
            No, these are separate programs. To earn liquidity rewards on liquidity pools, you must add liquidity to the respective liquidity pools. Check out the liquidity pools ​
            <a className="dull-link underline" href="https://blog.switcheo.com/enhancing-the-switcheo-token/" target="_blank" rel="noopener noreferrer">here</a>
            .
          </p>
      }
    },
    {
      header: "What is the difference between staking on Demex or CarbonHub?",
      textBlocks: [
        "SWTH tokens are always staked on Carbon which is a blockchain network that matches and execute trades.",
        "",
        "Demex is a frontend web application that runs on the Carbon network.",
        "",
        "We are gradually moving the Staking function from Demex to CarbonHub, a cross-chain dApp for all things SWTH.",
        "",
        "Regardless of the frontend application used to stake, all tokens are always staked to the Carbon network."
      ]
    }
  ],[]);

  useEffect(() => {
    const filteredStakeFAQContent = stakeFAQContent.filter((stakeFAQItem) => {
      return stakeFAQItem.header.toLowerCase().includes(inputText.toLowerCase())
    });
    setFilteredStakeFAQContent(filteredStakeFAQContent);
  },[inputText, stakeFAQContent]);

  return (
    <div className="stake-faq-wrapper theme-color">
      <div className="stake-faq-inner-wrapper">
        <StakeFAQHeader onChange={onInputChange} />
        {
          filteredStakeFAQContent.map((stakeFAQItem, index) => 
            <StakeFAQItem key={index} header={stakeFAQItem.header} textBlocks={stakeFAQItem.textBlocks} extraIndex={stakeFAQItem.extra?.index} >
              {stakeFAQItem.extra?.element}
            </StakeFAQItem>
          )
        }
        <div className="stake-faq-horizontal-divider"/>
        <p className="stake-faq-bottom-text">
          Cannot find what you're looking for? Contact the admins on <a className="dull-link underline" href="https://t.me/switcheo" target="_blank" rel="noopener noreferrer">Telegram</a>
        </p>
      </div>
    </div>
  )
}

export default StakeFAQ;
