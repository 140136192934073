import ArrowLeft from "assets/ArrowLeft.svg"
import ArrowLeftBranding from "assets/ArrowLeft_Branding.svg"
import Img_Inf from 'assets/Demex_SWTH_Inf.png'
import Img_Leg from 'assets/Demex_SWTH_Leg.png'
import Loading_Dark from 'assets/Loading_Transparent.gif'
import Send from 'assets/Send.svg'
import BigNumber from "bignumber.js"
import { CarbonSDK, Models } from 'carbon-js-sdk'
import { ETHClient } from 'carbon-js-sdk/lib/clients'
import { stripHexPrefix } from "carbon-js-sdk/lib/util/generic"
import AddressLabel from 'components/Common/AddressLabel'
import DropDown, { DropdownItem } from "components/Common/DropDown"
import ExternalLink from "components/Common/ExternalLink"
import Notification, { WarningMessage } from 'components/Common/Notification'
import Popup from 'components/Common/Popups/Popup'
import ProviderPopup from 'components/Common/Popups/ProviderPopup'
import WalletInfoPopup from 'components/Common/Popups/WalletInfoPopup'
import SWTHBSC from 'components/Common/Tokens/SWTHBSC'
import SWTHNeo from 'components/Common/Tokens/SWTHNeo'
import { MigrationContractAddress, RpcUrl } from "constants/evm"
import { BN_ZERO } from "constants/math"
import { MigrateChoice, migrateChoiceToNames } from "constants/migrate"
import { ChainNames, Network } from "constants/types"
import { ethers } from "ethers"
import { useAppDispatch, useAppSelector, useAsyncTask } from 'hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { addressToUrl } from 'saga/Common'
import { updateMigrateUI } from 'store/UI'
import { approveAllowanceAction, disconnectWalletAction, initWalletAction, updateChain } from 'store/Wallet'
import { getETHClient, isEthAddress } from "utils/evm"
import { logger } from "utils/logger"
import { bnOrZero } from "utils/number"
import { ABIs } from "../../constants"
import './MigratePage.css'
import './TokenMigrateCard.css'
import CoinIcon from "components/Common/Tokens/CoinIcon"
import { EthLedgerAccount, EthLedgerSigner } from "utils"

const TokenMigrateCard: React.FC = () => {
  const dispatch = useAppDispatch()
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const network = useAppSelector(state => state.app.network)
  const walletState = useAppSelector(state => state.wallet)
  const asyncState = useAppSelector(state => state.async)
  const uiState = useAppSelector(state => state.ui.migrate)
  const popupState = useAppSelector(state => state.ui.popup)
  const sdk = useAppSelector(state => state.app.carbonSDK)
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices["swth"]

  const [runLoadBalance, , loadBalanceError] = useAsyncTask()
  const [runLoadAllowance, , loadAllowanceError] = useAsyncTask()
  const [runMigrate, loadingMigrate, migrateError] = useAsyncTask()

  const [legacyBalance, setLegacyBalance] = useState<BigNumber>(BN_ZERO)
  const [legacyAllowance, setLegacyAllowance] = useState<BigNumber>(BN_ZERO)

  const [success, setSuccess] = useState(false)
  const [walletInfoPopupSide, setWalletInfoPopupSide] = useState<"sender" | "receiver" | null>(null)
  const [statusMessage, setStatusMessage] = useState<WarningMessage | null>(null)
  const [providerPopupChain, setProviderPopupChain] = useState<ChainNames | null>(null)
  const [hoveredBackButton, setHoveredBackButton] = useState<boolean>(false)

  const location = useLocation()
  const navigate = useNavigate()
  const [migrateOption, setMigrateOption] = useState<MigrateChoice>(location.state === null ? MigrateChoice.BSC : location.state as MigrateChoice)

  const swapWalletProvider = useCallback(() => {
    dispatch(updateChain({
      senderChain: walletState.receiverChain,
      receiverChain: walletState.senderChain,
    }))
    dispatch(initWalletAction({ side: "receiver", chain: walletState.senderChain }))
    dispatch(initWalletAction({ side: "sender", chain: walletState.receiverChain }))

  }, [walletState.receiverChain, walletState.senderChain, dispatch])

  const changeDropdownHandler = (choice: number) => {
    setMigrateOption(choice)
  }

  const {
    v1Contract,
    v2Contract,
    fromToken,
  } = useMemo(() => {
    const fromNetwork = network === CarbonSDK.Network.MainNet ? Network.BSCMainnet : Network.BSCTestnet
    let v1Contract: ethers.Contract | undefined
    let v2Contract: ethers.Contract | undefined
    let fromToken: Models.Carbon.Coin.Token | undefined
    if ((fromNetwork === Network.BSCMainnet || fromNetwork === Network.BSCTestnet) && walletState.tokenInfo) {
      const provider = new ethers.providers.JsonRpcProvider(RpcUrl[fromNetwork])
      v1Contract = new ethers.Contract(MigrationContractAddress[fromNetwork].SWTHTokenV1, ABIs.SWTHBscV1, provider)
      v2Contract = new ethers.Contract(MigrationContractAddress[fromNetwork].SWTHTokenV2, ABIs.SWTHBscV2, provider)
      fromToken = Object.values(walletState.tokenInfo).filter(value => value.tokenAddress === stripHexPrefix(MigrationContractAddress[fromNetwork].SWTHTokenV1))[0]
    }
    return { v1Contract, v2Contract, fromToken }
  }, [network, walletState.tokenInfo])

  const reloadBalance = () => {
    runLoadBalance(async () => {
      if (!walletState.senderAddress || !v1Contract) {
        setLegacyBalance(BN_ZERO)
        return
      }
      const result = await v1Contract.balanceOf(walletState.senderAddress)
      const balance = bnOrZero(result.toString())
      logger("loaded balance", balance.toString(10))
      setLegacyBalance(balance)
    })
  }

  const reloadAllowance = () => {
    runLoadAllowance(async () => {
      if (!walletState.senderAddress || !v1Contract || !v2Contract) {
        setLegacyAllowance(BN_ZERO)
        return
      }
      const allowance = await v1Contract.allowance(walletState.senderAddress, v2Contract.address)
      const bnAllowance = new BigNumber(allowance.toString())
      logger("loaded allowance", bnAllowance.toString(10))
      setLegacyAllowance(bnAllowance)
    })
  }

  useEffect(() => {
    if (!walletState.senderAddress) {
      dispatch(updateMigrateUI({ button: { text: "Please connect wallet" } }))
      return
    };
    if (legacyBalance.gt(legacyAllowance)) {
      dispatch(updateMigrateUI({ button: { text: "Approve" } }))
    } else if (legacyAllowance.gte(BN_ZERO) && !success) {
      dispatch(updateMigrateUI({ button: { text: "Migrate" } }))
    }
  }, [walletState.senderAddress, legacyAllowance, legacyBalance, success, dispatch])

  useEffect(() => {
    if ((uiState.button.text === "Approve" || uiState.button.text === "Migrate") && legacyBalance && legacyBalance.gt(BN_ZERO)) {
      dispatch(updateMigrateUI({ button: { enabled: true } }))
    } else {
      dispatch(updateMigrateUI({ button: { enabled: false } }))
    }
  }, [uiState.button.text, legacyBalance, asyncState.approveAllowance.error, dispatch])

  useEffect(() => {
    const chainName = migrateChoiceToNames.get(migrateOption)
    if (chainName === walletState.receiverChain) {
      swapWalletProvider()
    } else if (chainName === ChainNames.Bsc) {
      dispatch(updateChain({ senderChain: chainName }))
      dispatch(initWalletAction({ side: "sender", chain: chainName }))
    }
  }, [migrateOption, dispatch, swapWalletProvider, walletState.receiverChain])

  useEffect(() => {
    if (!walletState.senderAddress) {
      setStatusMessage({
        category: "warning",
        message: "Connect your wallet to begin token migration.",
      })
      return
    };

    if (!success && legacyBalance.lte(0)) {
      setStatusMessage({
        category: "warning",
        message: "No BSC v1 SWTH tokens found in your wallet",
      })
      return
    }

    if (legacyBalance.gt(legacyAllowance)) {
      setStatusMessage({
        category: "warning",
        message: "Approve token for migration to v2.",
      })
      return
    }

    if (!success) {
      setStatusMessage({
        category: "neutral",
        message: "You can now migrate your tokens."
      })
      return
    }

    setStatusMessage({
      category: "success",
      message: "Successfully migrated SWTH tokens to v2!",
    })
  }, [legacyAllowance, legacyBalance, success, walletState.senderAddress])

  useEffect(() => {
    if (isEthAddress(walletState.senderAddress)) {
      reloadAllowance()
      reloadBalance()
    }
  }, [walletState.senderAddress]) // eslint-disable-line react-hooks/exhaustive-deps

  const connectWallet = () => {
    setProviderPopupChain(ChainNames.Bsc)
  }

  const approveAllowance = async () => {
    if (!v1Contract || !v2Contract || !fromToken) return
    if (walletState.senderChain === ChainNames.Bsc) {
      let walletProviderSender: ethers.providers.Web3Provider | EthLedgerAccount
      if (walletState.eth.provider) {
        walletProviderSender = walletState.eth.provider
      } else if (walletState.eth.ledger) {
        walletProviderSender = walletState.eth.ledger
      } else return

      dispatch(approveAllowanceAction({
        senderChain: walletState.senderChain,
        contractAddr: v2Contract.address,
        token: fromToken,
        purpose: "migrate",
        reloadAllowance,
        walletProviderSender
      }))
    }
  }

  const migrateTokens = async () => {
    runMigrate(async () => {

      if (walletState.senderChain === ChainNames.Bsc && sdk && v2Contract) {
        let signer: ethers.providers.JsonRpcSigner | EthLedgerSigner
        const ethClient: ETHClient = getETHClient(sdk, walletState.senderChain, network)
        let rpcProvider = ethClient.getProvider()
        if (walletState.eth.provider) {
          rpcProvider = walletState.eth.provider as ethers.providers.Web3Provider
          signer = rpcProvider.getSigner()
        } else if (walletState.eth.ledger) {
          const ledgerProvider = walletState.eth.ledger
          signer = new EthLedgerSigner(ethClient.getProvider(), ledgerProvider)
        } else return

        setStatusMessage({
          category: "warning",
          message: "Please accept the transaction on your wallet to proceed.",
        })

        const tx = await v2Contract.connect(signer).swapLegacy(walletState.senderAddress, legacyBalance.toString(10))
        // TODO: notify transaction sent

        setStatusMessage({
          category: "neutral",
          message: "Migrating your tokens…",
        })

        await rpcProvider.waitForTransaction(tx.hash)
        dispatch(updateMigrateUI({ button: { text: "Migrated" } }))
        reloadBalance()
        setSuccess(true)
      }
    })
  }

  const error = useMemo(() => {
    if (asyncState.initWallet.error?.message) return asyncState.initWallet.error.message
    if (asyncState.changeNetwork.error?.message) return asyncState.changeNetwork.error.message
    if (migrateError?.message) return migrateError.message
    if (asyncState.approveAllowance.error?.message) return asyncState.approveAllowance.error?.message
    if (loadAllowanceError?.message) return loadAllowanceError.message
    if (loadBalanceError?.message) return loadBalanceError.message
    return undefined
  }, [asyncState.initWallet.error?.message, asyncState.changeNetwork.error?.message, migrateError, asyncState.approveAllowance.error?.message, loadBalanceError, loadAllowanceError])

  const dropdownList: DropdownItem[] = [
    { img: <SWTHBSC />, content: "BSC v1 to BSC v2" },
    { img: <SWTHNeo />, content: "Neo (SWTH Inflationary) to Neo N3" },
    { img: <SWTHNeo />, content: "Neo (SWTH Legacy) to Neo N3" }
  ]

  let guide = <a className='underline dull-link' style={{ fontWeight: 'normal' }} href={'https://blog.switcheo.com/carbon-neo-n3-integration'} target="_blank" rel="noopener noreferrer">here</a>

  return (
    <div className="migrateCard-wrapper theme-color">
      <div className="migrate-back-button-wrapper bolded-700">
        <div className="migrate-back-button" onClick={() => navigate("/migrate")} onMouseOver={() => setHoveredBackButton(true)} onMouseLeave={() => setHoveredBackButton(false)}>
          <img src={hoveredBackButton ? ArrowLeftBranding : ArrowLeft} alt="Back Arrow" />
          <span>Back</span>
        </div>
      </div>
      <div className='token-migrate-card theme-color'>
        <div className="migrateCard-header theme-color">
          <p>Migrate SWTH</p>
          {(!asyncState.initWallet.loading)
            ? (!walletState.senderAddress || !walletState.senderWallet) ? <button className="button-theme button-theme-primary migrate-wallet-address-wrapper" onClick={connectWallet}> Connect Wallet </button>
              : <div className="migrate-wallet-address-holder migrate-wallet-address-wrapper" onClick={() => setWalletInfoPopupSide("sender")}>
                <AddressLabel logoName={walletState.senderWallet} text={walletState.senderAddress ?? ""} bolded={true} />
              </div>
            : <button className="button-theme button-theme-primary migrate-wallet-address-wrapper"> <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" />  </button>}
        </div>
        <div className="select-token-wrapper">
          <span className='token-migration-headers'> Select Migration </span>
          <DropDown selectOption={changeDropdownHandler} defaultOption={migrateOption} options={dropdownList} />
        </div>
        {
          migrateOption === MigrateChoice.BSC
            ? <div>
              <div className="token-balance-wrapper">
                <span className="token-balance-header">BSC v1 Balance</span>
                <div className="token-balance-main bolded-700">
                  <span>{legacyBalance.shiftedBy(-8).dp(8).toFormat(2)}</span>
                  <CoinIcon denom="SWTH" />
                </div>
                <span className="token-balance-secondary bolded-400">{`$${legacyBalance.shiftedBy(-8).multipliedBy(swthPrice).toFormat(2)}`}</span>
              </div>
              <div className='send-receive'>
                <div className="send-receive-column">
                  <span className='token-migration-headers'> Send </span>
                  <div className='read-only token-migration-token-box'>
                    <ExternalLink text="SWTH BSC v1" link={v1Contract?.address ? addressToUrl(v1Contract.address, walletState.senderChain, network) : ""} style={{ fontSize: "14px" }} gap={4} />
                  </div>
                </div>
                <img src={Send} className='migration-icon' alt='migration-icon' />
                <div className="send-receive-column">
                  <span className='token-migration-headers'> Receive </span>
                  <div className='read-only token-migration-token-box'>
                    <ExternalLink text="SWTH BSC v2" link={v2Contract?.address ? addressToUrl(v2Contract.address, walletState.senderChain, network) : ""} style={{ fontSize: "14px" }} gap={4} />
                  </div>
                </div>
              </div>
              <div className="notification-wrapper">
                {(!error && !!statusMessage) && (
                  <Notification category={statusMessage.category} message={<React.Fragment>{statusMessage.message}</React.Fragment>} />
                )}
                {!!error && (
                  <Notification category="error" message={<React.Fragment>{error}</React.Fragment>} />
                )}
              </div>
              <button id="bottom-button" className="button-theme button-theme-primary migrate-bottom-button" disabled={!uiState.button.enabled} onClick={() => { uiState.button.text === "Approve" ? approveAllowance() : migrateTokens() }}>
                {asyncState.approveAllowance.loading || loadingMigrate ? <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" /> : uiState.button.text}
              </button>
            </div>
            : <div className="migrate-on-demex">
              <img src={migrateOption === 1 ? Img_Inf : Img_Leg} alt='demex-img' className="demex-screenshot"></img>
              <span className="demex-caption">Demex Screenshot: Deposit SWTH (NEP-5) via Tokentransfer</span>
              <span>To migrate your old Neo Legacy SWTH tokens, follow the steps below, or view a more detailed guide {guide}.</span>
              <br />
              <span>1. Connect to Demex</span>
              <span>2. Deposit Neo Legacy SWTH token onto Carbon</span>
              <span>3. Withdraw upgraded Neo N3 SWTH token</span>
              <br />
              <span style={{ marginBottom: '8px' }}>If your SWTH token is already on Carbon, skip Step 2.</span>
              <button className='button-theme button-theme-primary migrate-bottom-button'>
                <a style={{ color: 'white' }} href={'https://app.dem.exchange/account/balance/withdraw/swth'} target="_blank" rel="noopener noreferrer">
                  Migrate on Demex
                </a>
              </button>
            </div>
        }
      </div>
      {providerPopupChain && <ProviderPopup providerPopupChain={providerPopupChain} setProviderPopupChain={setProviderPopupChain} providerSide="sender" />}
      {walletInfoPopupSide &&
        <WalletInfoPopup
          setWalletInfoPopupSide={setWalletInfoPopupSide}
          onDisconnect={() => dispatch(disconnectWalletAction({ side: walletInfoPopupSide }))}
          walletInfoPopupSide={walletInfoPopupSide}
          balance={legacyBalance.shiftedBy(-8).dp(8)}
        />}
      {popupState.purpose && <Popup purpose={popupState.purpose} />}
    </div>
  )
}

export default TokenMigrateCard
