import { TasksType } from 'utils'

export interface SubmitDelegationParams {
    amount: string
    validatorAddress: string
    validatorName: string
    feeDenom: string
}

export interface SubmitRedelegationParams {
    amount: string
    validatorAddressSource: string
    validatorAddressDestination: string
    feeDenom: string
}

export const StakeTasks: TasksType = {
    Alliances: 'alliances-staking',
    Validators: 'validators-staking',
    ValidatorImages: 'validator-images',
    ValidatorDelegations: 'validators-delegations-staking',
    ValidatorRedelegations: 'validators-redelegations-staking',
    ValidatorUndelegations: 'validators-undelegations-staking',
    AprStats: 'validators-apr-stats',
    TotalStaked: 'total-staked',
    DelegationRewards: 'delegation-rewards',
    AllianceRewards: 'alliance-rewards',
    TotalSupply: 'total-supply',
    ValidatorsParticipation: 'validators-participation',
}
