import ArrowLeft from "assets/ArrowLeft.svg"
import ArrowLeftBranding from "assets/ArrowLeft_Branding.svg"
import Loading_Dark from "assets/Loading_Transparent.gif"
import Refresh from "assets/Refresh.svg"
import RefreshBranding from "assets/Refresh_Branding.svg"
import BigNumber from 'bignumber.js'
import { CarbonTx } from "carbon-js-sdk"
import { CarbonSDK } from "carbon-js-sdk"
import { SimpleMap } from "carbon-js-sdk/lib/util/type"
import AvatarImage from 'components/Common/AvatarImage'
import DropDown, { DropdownItem } from 'components/Common/DropDown'
import { InputSlider } from 'components/Common/InputSlider'
import Notification from 'components/Common/Notification'
import UndelegateProcessPopup from 'components/Common/Popups/UndelegatePopup'
import CoinIcon from "components/Common/Tokens/CoinIcon"
import WalletButton from 'components/StakePage/DelegationInfo/Common/WalletButton'
import { BN_ZERO } from "constants/math"
import { ChainNames } from 'constants/types'
import { useAppDispatch, useAppSelector, useAsyncTask } from "hooks"
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateStakeAsync } from 'store/Async'
import { StakeActionTypes, ValPair, reloadUserDelegations } from "store/Stake"
import { addToastItem } from 'store/Toast'
import { bnOrZero, parseError, parseNumber, reduxAction, uuidv4 } from "utils"
import { ButtonState, FormState } from '../utils/createConfig'
import './UndelegateCard.css'

const initialFormState: FormState = {
  delegateAsset: "swth",
  delegateFrom: null,

  delegateFromAddr: null,

  input: '0',
  percentage: 0,
}

const initialButtonState: ButtonState = {
  enabled: true,
  text: "",
}


const UndelegateCard: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [hoveredBackButton, setHoveredBackButton] = useState<boolean>(false)
  const [inputError, setInputError] = useState<string>()
  const [hoverRefreshBalance, setHoverRefreshBalance] = useState<boolean>(false)
  const [clickedRefreshBalance, setClickedRefreshBalance] = useState<boolean>(false)
  const [runUnstake, loadingUnstake] = useAsyncTask();
  const [popup, setPopup] = useState<boolean>(false)
  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [buttonState, setButtonState] = useState<ButtonState>(initialButtonState)
  const valAddrMap = useAppSelector(state => state.stake.valAddrMap) as SimpleMap<ValPair>
  const sdk = useAppSelector<CarbonSDK | null>((state) => state.app.carbonSDK)
  const network = useAppSelector(state => state.app.network)
  const tokenPrices = useAppSelector(state => state.tokenPrices)
  const userDelegations = useAppSelector(state => state.stake.userDelegations)
  const chosenValidator = useAppSelector(state => state.ui.stake.chosenValidator)
  const allianceAssets = useAppSelector(state => state.stake.allianceAssets)

  const txFee = (sdk?.gasFee?.getFee(CarbonTx.TxGasCostTypeDefaultKey, "swth") ?? BN_ZERO)
  const adjustedFee = sdk?.token.toHuman("swth", txFee) ?? BN_ZERO
  const swthPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices.value["swth"]

  const resetFormState = () => setFormState(initialFormState)

  const isLedgerDisabled = useMemo(() => {
    const isLedger = sdk?.wallet?.isLedgerSigner()
    return formState.delegateAsset !== "swth" && isLedger
  }, [sdk?.wallet, formState.delegateAsset]);

  const notificationList = [
    <React.Fragment>Unstaked tokens will only be unlocked and transferred to your wallet balance <b>after 30 days</b>.</React.Fragment>,
    <React.Fragment>You will be liable for slashing if it is found that your validator had committed an infraction while your tokens were staked with them.</React.Fragment>,
    <React.Fragment>There is a limit of 6 concurrent undelegation/redelegations per validator.</React.Fragment>
  ]

  const defaultOption = useMemo(() => userDelegations.findIndex(d => chosenValidator?.operatorAddress === d.validatorAddress), [chosenValidator, userDelegations])

  const recomputeState = (newFormState: Partial<FormState>) => {
    // setSuccess(false)
    // setSuccessResponse(null)
    const newState = {
      ...formState,
      ...newFormState,
    }

    setFormState(newState)
  }

  useEffect(() => {
    if (sdk?.wallet?.bech32Address) {
      dispatch(reloadUserDelegations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, sdk])

  useEffect(() => {
    recomputeState({
      ...formState,
      delegateFrom: chosenValidator,
      delegateFromAddr: chosenValidator?.operatorAddress
    })
    // eslint-disable-next-line
  }, [chosenValidator])

  const { assetOptions, assets } = useMemo(() => {
    const options: DropdownItem[] = [{
      content: "SWTH",
      img: <CoinIcon denom="SWTH" />
    }];
    const assets = ["swth"];

    if (sdk === null)
      return {
        assetOptions: options,
        assets,
      }

    for (const asset of allianceAssets) {
      const token = sdk.token.tokenForDenom(asset.denom)
      if (!token) continue

      assets.push(token.denom);
      options.push({
        content: token.symbol,
        tag: <span className="dropdown-tag">Alliance</span>,
        img: <CoinIcon denom={token.symbol} />
      })
    }

    return {
      assetOptions: options,
      assets,
    }
  }, [allianceAssets, sdk])

  const assetInfo = useMemo(() => {
    const denom = formState.delegateAsset
    const token = sdk?.token.tokenForDenom(denom)
    return {
      decimals: token?.decimals.toNumber() ?? 0,
      symbol: token?.symbol ?? "",
      price: sdk?.token.getUSDValue(denom) ?? BN_ZERO,
    }
  }, [sdk?.token, formState.delegateAsset]);

  const delegatedOptions: DropdownItem[] = useMemo(() => {
    const valList: SimpleMap<DropdownItem> = {}

    for (const delegation of userDelegations) {
      const validator = valAddrMap[delegation.validatorAddress]?.carbonValidator
      valList[delegation.validatorAddress] = { img: <AvatarImage identity={validator?.description?.identity} />, content: validator?.description?.moniker ?? "" }
    }
    return Object.values(valList)
  }, [userDelegations, valAddrMap])

  const delegatedAmountMap: SimpleMap<SimpleMap<BigNumber>> = useMemo(() => {
    const returnMap: SimpleMap<SimpleMap<BigNumber>> = {}
    for (const delegation of userDelegations) {
      if (!returnMap[delegation.validatorAddress])
        returnMap[delegation.validatorAddress] = {}
      returnMap[delegation.validatorAddress][delegation.denom] = delegation.balance
    }
    return returnMap
  }, [userDelegations])

  const delegatedBalance = useMemo(() => {
    const delegatedBN = delegatedAmountMap[formState.delegateFromAddr ?? ""]?.[formState.delegateAsset] ?? BN_ZERO
    return delegatedBN.shiftedBy(-assetInfo.decimals) ?? BN_ZERO
  }, [formState.delegateFromAddr, formState.delegateAsset, delegatedAmountMap, assetInfo.decimals])

  const walletAddress = sdk?.wallet?.bech32Address

  const bnAmount = useMemo(() => {
    const parsedAmount = formState.input.replace(/,/g, "")
    return new BigNumber(parsedAmount)
  }, [formState.input])

  useEffect(() => {
    if (bnAmount.gt(delegatedBalance)) {
      setInputError("The amount entered exceeds your delegated balance")
    } else {
      setInputError("")
    }
    // eslint-disable-next-line 
  }, [bnAmount, delegatedBalance, adjustedFee])

  useEffect(() => {
    if (!walletAddress) {
      setButtonState({ enabled: false, text: "Please Connect Wallet" })
      return
    } else if (formState.input === "0" || formState.input === "") {
      setButtonState({ enabled: false, text: "Enter Amount" })
      return
    } else if (!formState.delegateFromAddr) {
      setButtonState({ enabled: false, text: "Select Validator" })
    } else {
      setButtonState({ enabled: true, text: "Undelegate" })
      return
    }
  }, [walletAddress, sdk, network, formState])

  const numbersClickHandler = (percentage: number) => {
    if (delegatedBalance !== BN_ZERO) {
      recomputeState({
        ...formState,
        input: delegatedBalance.times(percentage / 100).toString(),
        percentage: percentage,
      })
    }
  }

  const handleStakeSubmit = () => {
    if (!formState.delegateFromAddr || formState.input === "0" || formState.input === "") return
    // eslint-disable-next-line no-alert
    if (window.confirm(
      'Are you sure? Unstaked tokens will only be unlocked and transferred to your wallet balance after 30 days.',
    )) {
      runUnstake(async () => {
        if (!sdk?.wallet) return
        const params = {
          delegatorAddress: sdk.wallet.bech32Address,
          validatorAddress: formState.delegateFromAddr!,
          amount: bnOrZero(formState.input).shiftedBy(assetInfo.decimals),
        }
        try {
          if (formState.delegateAsset === "swth") {
            await sdk.staking.undelegateTokens(params, { feeDenom: "swth" })
          } else {
            await sdk.alliance.undelegateTokens({
              ...params,
              denom: formState.delegateAsset,
            }, { feeDenom: "swth" })
          }
          setPopup(true)
        } catch (err) {
          const error = parseError(err)
          dispatch(updateStakeAsync({ error }))
          dispatch(addToastItem({
            id: uuidv4(),
            senderAddress: walletAddress!,
            receiverAddress: null,
            senderChain: ChainNames.CarbonCore,
            receiverChain: null,
            transactionHash: null,
            error: error
          }))
        } finally {
          dispatch(reduxAction(StakeActionTypes.RELOAD_ALL_STAKING_INFO))
        }

        dispatch(reloadUserDelegations())
      })
    }
  }

  const inputChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const filteredTargetValue: string = e.currentTarget.value
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    if (Number.isNaN(parsedTargetValue)) {
      recomputeState({
        ...formState,
        input: "",
        percentage: 0,
      })
    } else if (filteredTargetValue.split(".").length === 2) {
      const [int, decimals] = filteredTargetValue.split(".")
      let percentage = new BigNumber(parsedTargetValue).dividedBy(delegatedBalance).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(percentage, 100) : 100
      recomputeState({
        ...formState,
        input: new BigNumber(int).toNumber() + `.${decimals}`,
        percentage: percentage,
      })
    } else {
      let percentage = new BigNumber(parsedTargetValue).dividedBy(delegatedBalance).times(100).toNumber()
      percentage = isFinite(percentage) ? Math.min(percentage, 100) : 100
      recomputeState({
        ...formState,
        input: new BigNumber(parsedTargetValue).toString(),
        percentage: percentage,
      })
    }
  }

  const inputSliderHandler = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const filteredTargetValue: string = e.currentTarget.value
    let parsedTargetValue: number = parseFloat(filteredTargetValue)
    recomputeState({
      ...formState,
      input: delegatedBalance.multipliedBy(parsedTargetValue / 100).toString(),
      percentage: parsedTargetValue,
    })
  }


  const changeUndelegationFrom = (valID: number) => {
    const delegatedVal = userDelegations[valID]
    recomputeState({
      ...formState,
      delegateFrom: valAddrMap[delegatedVal.validatorAddress!].carbonValidator,
      delegateFromAddr: delegatedVal.validatorAddress,
    })
  }

  const changeAssetHandler = (assetIdx: number) => {
    const asset = assets[assetIdx]
    recomputeState({
      ...formState,
      delegateAsset: asset,
      percentage: 0,
      input: "0",
    })
  }

  return (
    <div className="undelegate-card-wrapper theme-color">
      <div className={`undelegate-card-container + ${popup ? "no-padding" : ""}`}>
        <div className={`undelegate-card-back-button-wrapper bolded-700" + ${popup ? "active" : ""}`}>
          <div className="undelegate-card-back-button" onClick={() => navigate("/stake")} onMouseOver={() => setHoveredBackButton(true)} onMouseLeave={() => setHoveredBackButton(false)}>
            <img src={hoveredBackButton ? ArrowLeftBranding : ArrowLeft} alt="Back Arrow" />
            <span>Back</span>
          </div>
        </div>
        {!popup ?
          <div className='undelegate-card theme-color'>
            <div className="undelegate-card-header theme-color">
              <p>Undelegate </p>
              <div style={{ display: "flex", gap: "6px" }}>
                <WalletButton />
              </div>
            </div>
            <div className="undelegate-info-wrapper">
              <div className="validator-name-wrapper">
                <p>Undelegate From</p>
                <div className="validator-dropdown-wrapper" style={{ height: "48px" }}>
                  <DropDown selectOption={changeUndelegationFrom} defaultOption={defaultOption} options={delegatedOptions} />
                </div>
              </div>
            </div>
            <div className="stake-input-wrapper">
              <p>Amount</p>
              <label className="stake-amount-input" style={inputError ? { border: "1px solid #DC6D5E" } : undefined}>
                <input type="number" placeholder="0" min="0" id="amount" value={formState.input} onChange={inputChangeHandler} style={inputError ? { color: "#DC6D5E" } : undefined} disabled={delegatedBalance <= BN_ZERO} />
                <DropDown className="stake-asset-dropdown" selectOption={changeAssetHandler} defaultOption={formState.delegateAsset ? assets.findIndex(asset => asset === formState.delegateAsset) : ""} options={assetOptions} />
              </label>
              <InputSlider value={formState.percentage} disabled={delegatedBalance <= BN_ZERO} numbersClickHandler={numbersClickHandler} onChange={(e: React.FormEvent<HTMLInputElement>) => inputSliderHandler(e)} onFocus={() => { }}
              />
            </div>
            <div className="stake-balance-box">
              <img
                src={(hoverRefreshBalance && delegatedBalance !== BN_ZERO) ? RefreshBranding : Refresh}
                alt="Refresh"
                onClick={() => {
                  dispatch(reloadUserDelegations())
                  setClickedRefreshBalance(true)
                }}
                onMouseOver={() => {
                  setHoverRefreshBalance(true)
                }}
                onMouseLeave={() => setHoverRefreshBalance(false)}
                onAnimationEnd={() => {
                  setClickedRefreshBalance(false)
                }}
                className={`${clickedRefreshBalance ? "stake-refresh-rotate" : ""} ${!(delegatedBalance === BN_ZERO) ? "stake-refresh-pointer" : ""}`} />
              <div className="stake-balance">
                <span className="input-error">{inputError}</span>
                <span style={{ whiteSpace: "nowrap" }}>Balance:{" "}{delegatedBalance.toFormat()}</span>
              </div>
            </div>
            <div className="stake-details">
              <div><span>Undelegation Period </span><span>30 Days</span></div>
              <div><span>Estimated Fee </span><span>{adjustedFee.toString(10)} SWTH</span></div>
              <div><span id="usd">USD </span><span>${adjustedFee.multipliedBy(swthPrice).toString(10)}</span></div>
            </div>

            {!isLedgerDisabled && (
              <>
                <div className="stake-notification">
                  <Notification category="warning" header="Warning" list={notificationList} />
                </div>
                <div style={{ marginTop: "16px", visibility: formState.delegateAsset === "swth" ? "visible" : "hidden" }}>
                  <Notification category="neutral" message={<React.Fragment>Consider <b>&nbsp;redelegating&nbsp;</b> your SWTH instead.&nbsp;<span className='underline' style={{ cursor: "pointer" }} onClick={() => navigate('/stake/redelegate')}>Redelegate now</span></React.Fragment>} />
                </div>
              </>
            )}

            {isLedgerDisabled && (
              <div style={{ marginTop: "16px" }}>
                <Notification category="warning" header="Staking Alliance assets with Ledger is currently not supported, please connect with Keplr or Leap wallet. Alternatively, you can still stake SWTH with ledger." />
              </div>
            )}
            <button id="bottom-button" className="button-theme button-theme-primary" disabled={!buttonState.enabled || !!inputError || isLedgerDisabled} onClick={handleStakeSubmit}>
              {loadingUnstake ? <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" /> : buttonState.text}
            </button>
          </div>
          : <UndelegateProcessPopup val={formState.delegateFrom!} setPopup={setPopup} resetFormState={resetFormState} delegateAmount={parseNumber(formState.input)!.toFormat()} symbol={assetInfo.symbol} delegateUSD={parseNumber(formState.input)!.multipliedBy(assetInfo.price).toFormat(2)} />}
      </div>
    </div>
  )
}

export default UndelegateCard
