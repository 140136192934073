import Loading_Light from "assets/Loading_light.gif"
import { useAppSelector } from 'hooks'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React from "react"
import { StakeTasks } from 'store/Stake/types'
import "./Avatar.css"

type Props = {
  identity: string | undefined
}

const AvatarImage: React.FC<Props> = ({ identity }) => {
  const keyBaseURLs = useAppSelector(state => state.stake.keybaseURLs)
  const image = identity ? keyBaseURLs[identity.toLowerCase()] : undefined
  const [validatorsLoading] = useTaskSubscriber(StakeTasks.Validators)

  return (
    <>
      {
        validatorsLoading ?
          <img src={Loading_Light} className="loading-icon" alt="Loading" />
          :
          (image ?
            <img className="avatar" src={image} alt="Avatar" /> :
            <div className="avatar" />)
      }
    </>
  )
}

export default AvatarImage
