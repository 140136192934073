import React, { useState } from "react";
import Check from "assets/Check_Secondary.svg";

type Props = {
  checkboxHandler: () => void;
  text: string;
  style?: React.CSSProperties;
}

const Checkbox: React.FC<Props> = ({checkboxHandler, text, style}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const checkboxStyle = {
    color: "#6A8A96",
    display: "flex",
    alignItems: "center",
    gap: "7.5px",
    cursor: "pointer",
    ...style
  }

  const checkbox = {
    border: "2px solid #6A8A96",
    borderRadius: "3px",
    height: "15px",
    width: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }

  return (
    <div style={checkboxStyle} onClick={() => { 
      setChecked(!checked);
      checkboxHandler(); 
    }}>
      <div style={checkbox}>
        { checked && <img src={Check} alt="Check" /> }
      </div>
      <p className="bolded-700">{ text }</p>
    </div>
  )
}

export default Checkbox;
