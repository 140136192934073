import Success from "assets/Success.svg"
import SWTH from "assets/SWTH-Blue-Small.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import { Models } from 'carbon-js-sdk'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./UndelegatePopup.css"

type Props = {
  val: Models.Staking.Validator
  delegateAmount: string
  delegateUSD: string
  symbol: string
  setPopup: React.Dispatch<React.SetStateAction<boolean>>
  resetFormState: () => void
}


const UndelegateProcessPopup: React.FC<Props> = (props) => {
  const { val, delegateAmount, delegateUSD, symbol, setPopup, resetFormState } = props
  const [hovered, setHovered] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <div className="undelegate-popup-wrapper">
      <img src={Success} alt="success" className="success-img" />
      <div>Undelegation successful.</div>
      <div className="undelegate-details">
        <div><span>Undelegation Period</span><span>30 days</span></div>
        <div><span>Validator</span><a className="validator-name" onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)} href={`https://scan.carbon.network/validator/${val.operatorAddress}`} target="_blank" rel="noopener noreferrer">{val.description?.moniker}<img src={hovered ? SWTHBranding : SWTH} alt="External Link" className="avatar-icon" /></a></div>
        <div><span>Amount</span><span>{delegateAmount} {symbol}</span></div>
        <div><span id="usd">USD</span><span>${delegateUSD}</span></div>
      </div>
      <button className="button-theme button-theme-secondary popup-button" style={{ backgroundColor: "transparent", width: "100%", height: "48px", marginTop: "0px" }} onClick={() => { setPopup(false); resetFormState() }}>Undelegate Another</button>
      <button className="button-theme button-theme-primary popup-button" style={{ width: "100%", height: "48px", marginTop: "16px" }} onClick={() => { navigate("/stake") }}>Back to Stake</button>
    </div>
  )
}

export default UndelegateProcessPopup
