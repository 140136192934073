import { ReactComponent as CdpBadge } from 'assets/CdpBadge.svg'
import { ReactComponent as PlaceholderIcon } from 'assets/Placeholder.svg'
import { TokenIconPath } from 'constants/paths'
import { useState } from 'react'

const tokenNameMap: {
    [index: string]: string
} = {
    IUSD: 'USD',
    NNEO: 'Neo',
    BTCB: 'BTC',
    BCFX: 'CFX',
    EPLAY: 'PLAY',
    'LKT.BEP20': 'LKT',
    SWTHB: 'SWTH',
    LUNC: 'LUNA',
    USTC: 'UST',
    GAS: 'CGAS',
    CIBUSDC: 'USDC',
    AMPLUNA: 'ampLUNA',
    STLUNA: 'stLUNA',
    CUSDC: 'USDC',
    STEVMOS: 'stEVMOS',
    'cgt/1': 'cUSD',
    swth: 'SWTH',
}

type Props = {
    denom: string,
    size?: number,
}

function getTokenIconURL(tokenName: string, fileType: string = 'svg') {
    return TokenIconPath.replace(':token_name', tokenName).replace(':file_type', fileType)
  }

const CoinIcon: React.FC<Props> = ({ denom, size }) => {
    const [imageSrcError, setImageSrcError] = useState(false);
    const [imageSrcIndex, setImageSrcIndex] = useState(0);

    const isCibToken = denom.startsWith("cib")
    let symbolToFetch = denom.replace("cib", "")
    symbolToFetch = tokenNameMap[symbolToFetch] ?? symbolToFetch
    const imageSrcArray = symbolToFetch 
    ? [
      getTokenIconURL(symbolToFetch, 'svg'),
      getTokenIconURL(symbolToFetch, 'png'),
    ] : []

    const handleImgSrcError = () => {
        if (imageSrcIndex < imageSrcArray.length - 1) {
          setImageSrcIndex(imageSrcIndex + 1);
        } else {
          setImageSrcError(true);
        }
      }

    size = size ?? 24
    const cdpSize = size / 2

    let defaultIcon: React.ReactNode = <PlaceholderIcon height={size} width={size} />

    return (
      <div style={{ position: 'relative', display: 'flex' }}>
        { !imageSrcError && symbolToFetch 
            ? <img src={imageSrcArray[imageSrcIndex]} onError={handleImgSrcError} alt={symbolToFetch} height={size} width={size}/>
            : defaultIcon
        }
        { isCibToken && <CdpBadge style={{ position: "absolute", bottom: 6, right: -2, height: cdpSize, width: cdpSize }} /> }
      </div>
    )
}

export default CoinIcon
