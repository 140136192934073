import Loading_Dark from "assets/Loading_Transparent.gif"
import AddressLabel from 'components/Common/AddressLabel'
import ProviderPopup from 'components/Common/Popups/ProviderPopup'
import WalletInfoPopup from 'components/Common/Popups/WalletInfoPopup'
import { AddressSchema } from 'components/StakePage/StakePage'
import { ChainNames } from 'constants/types'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useMemo, useState } from 'react'
import { disconnectWalletAction, updateChain } from 'store/Wallet'

const WalletButton: React.FC = () => {
    const [walletInfoPopupSide, setWalletInfoPopupSide] = useState<"sender" | "receiver" | null>(null)
    const [connectProviderSide, setConnectProviderSide] = useState<"sender" | "receiver">("sender")
    const [providerPopupChain, setProviderPopupChain] = useState<ChainNames | null>(null)
    const dispatch = useAppDispatch()
    const walletState = useAppSelector(state => state.wallet)
    const asyncState = useAppSelector(state => state.async)


    const { carbonAddress, carbonWallet, walletSide }: AddressSchema = useMemo(() => {
        if (walletState.senderChain === ChainNames.CarbonCore) {
            return {
                carbonAddress: walletState.senderAddress,
                carbonWallet: walletState.senderWallet,
                walletSide: "sender",
            }
        } else if (walletState.receiverChain === ChainNames.CarbonCore) {
            return {
                carbonAddress: walletState.receiverAddress,
                carbonWallet: walletState.receiverWallet,
                walletSide: "receiver",
            }
        } else {
            return { carbonAddress: null, carbonWallet: null, walletSide: null }
        }
    }, [walletState])


    const connectWallet = () => {
        if (walletState.receiverChain === ChainNames.CarbonCore) {
            setConnectProviderSide("receiver")
        } else if (walletState.senderChain === ChainNames.CarbonCore) {
            setConnectProviderSide("sender")
        } else {
            dispatch(updateChain({ senderChain: ChainNames.CarbonCore }))
        }
        setProviderPopupChain(ChainNames.CarbonCore)
    }
    return (
        <>
            {(!asyncState.initWallet.loading && !asyncState.changeNetwork.loading)
                ? (!carbonAddress || !carbonWallet) ? <button className="stake-connect-button-theme button-theme-primary" onClick={() => connectWallet()}> Connect Wallet </button>
                    : <div className="stake-top-row-wallet" onClick={() => { setWalletInfoPopupSide(walletSide) }}>
                        <AddressLabel logoName={carbonWallet} text={carbonAddress} bolded={true} />
                    </div>
                : <button className="stake-connect-button-theme button-theme-primary"> <img src={Loading_Dark} className="loading-icon" alt="Loading_Dark" />  </button>}
            {walletInfoPopupSide &&
                <WalletInfoPopup
                    setWalletInfoPopupSide={setWalletInfoPopupSide}
                    onDisconnect={() => {
                        dispatch(disconnectWalletAction({ side: walletInfoPopupSide }))
                    }}
                    walletInfoPopupSide={walletInfoPopupSide}
                />}
            {providerPopupChain && <ProviderPopup providerPopupChain={providerPopupChain} setProviderPopupChain={setProviderPopupChain} providerSide={connectProviderSide} />}
        </>
    )
}

export default WalletButton