import React from 'react';
import './CarbonProtocol.css';
import Flash from 'assets/Flash.svg';
import Fees from 'assets/Fees.svg';
import Govern from 'assets/Govern.svg';
import SecureNetwork from 'assets/SecureNetwork.svg';
import { useInView } from "react-intersection-observer";

const CarbonProtocol: React.FC = () => {
    const { ref, inView } = useInView({
        /* Optional options */
        triggerOnce: true,
    });
    return (
        <div ref={ref} className={`wrapper ${inView ? 'show-carbon' : undefined}`}>
            <div className='carbon-inner-wrapper'>
                <div className='carbon-title-holder'>
                    <p className='section-header'>Supercharging the Carbon Protocol&nbsp;<img src={Flash} alt="Flash" className='title-image-holder' /></p>
                    <p className='section-subheader'> What is SWTH used for?</p>
                </div>
                <div className='carbon-section-holder'>
                    <div className='carbon-section'>
                        <img src={SecureNetwork} alt="SecureNetwork" className='carbon-image-holder' />
                        <p className='section-subpoint-header'>Securing Network</p>
                        <p className='section-text'>SWTH’s main utility is to secure the Carbon protocol via a proof-of-Stake (POS) system. To participate, validator nodes are required to stake SWTH and in return they are rewarded with epoch rewards and fees.</p>
                    </div>
                    <div className='carbon-section'>
                        <img src={Govern} alt="Group" className='carbon-image-holder' />
                        <p className='section-subpoint-header'>Stake &amp; Govern</p>
                        <p className='section-text'> Carbon is a fully decentralized protocol where governance is entirely on-chain. This means that SWTH holders have complete control over the protocol, through delegating (staking) their tokens to the validators.</p>
                    </div>
                    <div className='carbon-section'>
                        <img src={Fees} alt="Fees" className='carbon-image-holder' />
                        <p className='section-subpoint-header'>Network Fees &nbsp;&nbsp;</p>
                        <p className='section-text'> SWTH is used for network fees, such as transaction fees occurring on Carbon. These fees are returned to validators and delegators as their reward for verifying on-chain transactions.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarbonProtocol;