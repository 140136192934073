import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { CarbonSDK, Insights } from 'carbon-js-sdk'
import { getLocalStorageNetwork } from 'store/localStorage'
import { SimpleMap } from 'utils'
import { CustomNodeItem, RatingLatencyObj } from 'utils/nodes'
import { AppActionTypes } from './Actions'

interface UpdateSDKSchema {
  carbonSDK: CarbonSDK
}
interface AppSliceSchema {
  network: CarbonSDK.Network
  carbonSDK: null | CarbonSDK
  nodes: Insights.NodeItem[]
  selectedNodes: SimpleMap<any>
  latencies: RatingLatencyObj
  customNodes: CustomNodeItem[]
}

export interface NetworkPayload {
  network: CarbonSDK.Network
}

const initialState: AppSliceSchema = {
  network: getLocalStorageNetwork(),
  carbonSDK: null,
  nodes: [],
  selectedNodes: {},
  latencies: {},
  customNodes: [],
}

export const slice: Slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateCarbonSDK: (state, action: PayloadAction<UpdateSDKSchema>) => {
      state.carbonSDK = action.payload.carbonSDK
    },
    updateNetwork: (state, action: PayloadAction<NetworkPayload>) => {
      state.network = action.payload.network
      localStorage.setItem("network", action.payload.network)
    },
    updateNodes: (state, action) => {
      state.nodes = action.payload
    },
    updateSelectedNodes: (state, action) => {
      state.selectedNodes = action.payload
      localStorage.setItem(AppActionTypes.SET_SELECTED_NODES, JSON.stringify(action.payload))
    },
    updateLatency: (state, action) => {
      state.latencies = action.payload
    },
    updateCustomNodes: (state, action: PayloadAction<CustomNodeItem[]>) => {
      state.customNodes = action.payload
      localStorage.setItem(AppActionTypes.SET_CUSTOM_NODES, JSON.stringify(action.payload))
    },
  }
})

export const { updateCarbonSDK, updateNetwork, updateNodes, updateSelectedNodes, updateLatency, updateCustomNodes } = slice.actions

export default slice.reducer
