import { PopupPurposes } from "constants/bridge";
import WrongNetworkPopup from "./WrongNetworkPopup";
import BefSignPopup from "./BefSignPopup";
import AftSignPopupAllowance from "./AftSignPopupAllowance";
import React from "react";

type Props = {
  purpose: PopupPurposes
}

const Popup: React.FC<Props> = ({ purpose }) => {
  const switchPopup = (purpose: PopupPurposes) => {
    switch (purpose) {
      case PopupPurposes.WRONG_CHAIN: 
        return <WrongNetworkPopup />;
      case PopupPurposes.BEF_APPROVE:
        return <BefSignPopup />;
      case PopupPurposes.AFT_APPROVE_APPROVAL:
        return <AftSignPopupAllowance />;
      default:
        return undefined;
    }
  }
  return (
    <>
      {switchPopup(purpose)}
    </>
  );
};

export default Popup;
