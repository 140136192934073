import Avatar from "components/Common/Avatar"
import TableText from "components/Common/TableText"
import dayjs from 'dayjs'
import { useTaskSubscriber } from 'hooks/useTaskSubscriber'
import React from "react"
import { StakeTasks } from 'store/Stake/types'
import { DelegationLoading } from '../Common/DelegationLoading'
import { UndelegationEmpty } from '../Common/EmptyStates'
import { UndelegationsTableData } from '../StakeDelegationInfo'
import "./StakeUndelegating.css"


const StakeUndelegationEmpty: React.FC = () => {
  return (
    <tr>
      <td colSpan={100}>
        <UndelegationEmpty />
      </td>
    </tr>
  )
}

interface Props {
  undelegations: UndelegationsTableData[]
}

const StakeUndelegating: React.FC<Props> = ({ undelegations }) => {
  const [undelegationsLoading] = useTaskSubscriber(StakeTasks.ValidatorUndelegations)

  return (
    <div className="stake-undelegating-wrapper theme-color">
      <table className="stake-undelegating-inner-wrapper" style={{marginBottom: "0px"}}>
        <thead className='table-head'>
          <tr>
            <th style={{ paddingLeft: "24px" }}>Validator</th>
            <th align="right">Slashed Amount</th>
            <th align="right">Undelegate Amount</th>
            <th align="right">Start Block</th>
            <th style={{ paddingLeft: "67px" }}>Initiated On</th>
            <th style={{ paddingRight: "24px" }}>Time Left</th>
          </tr>
        </thead>
        <tbody>
          {undelegationsLoading ?
            <tr>
              <td colSpan={100} height="202px">
                <DelegationLoading />
              </td>
            </tr>
            :
            undelegations.length === 0 ?
              <StakeUndelegationEmpty />
              :
              undelegations.sort((a, b) => a.completionTime > b.completionTime ? -1 : 1).map((undelegation, index) => {
                var now = dayjs()
                const timeLeft = dayjs(undelegation.completionTime).diff(now, 'days')
                const timeDiff = timeLeft > 0 ? (30 - timeLeft) / 30 * 100 : 100

                return (
                  <tr key={index}>
                    <td style={{ paddingLeft: "24px" }}>
                      <Avatar name={undelegation.validator} valAddr={undelegation.valAddr} icon="link" identity={undelegation.valID} />
                    </td>
                    <td align="right">
                      <TableText content={undelegation.slashedAmount} type="token" denom={undelegation.denom} />
                    </td>
                    <td align="right">
                      <TableText content={undelegation.undelegateAmount} type="token" denom={undelegation.denom} />
                    </td>
                    <td align="right">
                      {undelegation.startBlock}
                    </td>
                    <td style={{ paddingLeft: "67px" }}>
                      <TableText content={undelegation.completionTime.subtract(30, "day")} type="date" />
                    </td>
                    <td style={{ paddingRight: "24px" }}>
                      <div className="stake-undelegating-time-box">
                        <div className="stake-undelegating-time-bar">
                          <div className="stake-undelegating-time-left" style={{ width: `${timeDiff}%` }} />
                        </div>
                        <p className="stake-secondary">{timeLeft >= 0 ? `${timeLeft} days left` : "Complete"}</p>
                      </div>
                    </td>
                  </tr>
                )
              })
          }
        </tbody>
      </table>
    </div>
  )
}

export default StakeUndelegating
