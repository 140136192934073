import { Validator } from '@cosmjs/tendermint-rpc'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { Models } from 'carbon-js-sdk'
import { BN_ZERO } from 'constants/math'
import { Dayjs } from 'dayjs'
import { SimpleMap, TokenAmount } from 'utils'

export interface ValPair {
  tmValidator?: Validator
  carbonValidator: Models.Staking.Validator
  consAddress?: string
}

export interface UserDelegation {
  balance: BigNumber
  shares: BigNumber
  denom: string
  validatorAddress: string
  delegatorAddress: string
  rewardsUsdValue: BigNumber
  pendingRewards: TokenAmount[]
}

export interface UserRedelegation {
  balance: BigNumber
  initialBalance: BigNumber
  newShares: BigNumber
  denom: string
  validatorSrcAddress: string
  validatorDstAddress: string
  delegatorAddress: string
  completionTime: Dayjs
  creationHeight: number
}

export interface UserUnbonding {
  balance: BigNumber
  initialBalance: BigNumber
  denom: string
  validatorAddress: string
  delegatorAddress: string
  completionTime: Dayjs
  creationHeight: number
}

export interface UpdateRewardAmount {
  validatorAddress: string
  delegatorAddress: string
  usdValue: BigNumber
  denom: string
  rewards: TokenAmount[]
}

export interface aprStats {
  totalBonded: BigNumber
  apr: BigNumber
}

export interface AppSliceSchema {
  aprStats: aprStats
  avgBlockTime: BigNumber
  totalStaked: BigNumber
  totalSupply: BigNumber
  userBalance: BigNumber

  allianceAssets: Models.Carbon.Alliance.AllianceAsset[]

  valAddrMap: SimpleMap<ValPair>
  delegatorsMap: SimpleMap<Models.Staking.DelegationResponse[]>
  keybaseURLs: SimpleMap<string>

  userDelegations: UserDelegation[]
  userUnbondingDelegations: UserUnbonding[],
  userRedelegations: UserRedelegation[],

  proposals:  Models.Gov.Proposal[],
  validatorParticipations: SimpleMap<number>[]
}

const initialState: AppSliceSchema = {
  aprStats: {
    totalBonded: BN_ZERO,
    apr: BN_ZERO,
  },
  avgBlockTime: BN_ZERO,
  totalStaked: BN_ZERO,
  totalSupply: BN_ZERO,
  userBalance: BN_ZERO,

  valAddrMap: {},
  delegatorsMap: {},
  keybaseURLs: {},

  allianceAssets: [],

  userDelegations: [],
  userUnbondingDelegations: [],
  userRedelegations: [],

  proposals: [],
  validatorParticipations: [],
}

export const appSlice = createSlice({
  name: 'stake',
  initialState,
  reducers: {
    updateAprStats: (state, action: PayloadAction<aprStats>) => {
      state.aprStats = action.payload
    },
    updateAvgBlockTime: (state, action: PayloadAction<BigNumber>) => {
      state.avgBlockTime = action.payload
    },
    updateTotalStaked: (state, action: PayloadAction<BigNumber>) => {
      state.totalStaked = action.payload
    },
    updateTotalSupply: (state, action: PayloadAction<BigNumber>) => {
      state.totalSupply = action.payload
    },
    updateUserBalance: (state, action: PayloadAction<BigNumber>) => {
      state.userBalance = action.payload
    },

    updateAllianceAssets: (state, action: PayloadAction<Models.Carbon.Alliance.AllianceAsset[]>) => {
      state.allianceAssets = action.payload
    },

    updateValAddrMap: (state, action: PayloadAction<SimpleMap<ValPair>>) => {
      state.valAddrMap = action.payload
    },
    updateDelegatorsMap: (state, action: PayloadAction<SimpleMap<Models.Staking.DelegationResponse[]>>) => {
      state.delegatorsMap = action.payload
    },
    updateAvatarImagesMap: (state, action: PayloadAction<SimpleMap<string>>) => {
      state.keybaseURLs = action.payload
    },

    updateUserDelegations: (state, action: PayloadAction<UserDelegation[]>) => {
      state.userDelegations = action.payload
    },
    updateUserUnbondingDelegations: (state, action: PayloadAction<UserUnbonding[]>) => {
      state.userUnbondingDelegations = action.payload
    },
    updateUserRedelegations: (state, action: PayloadAction<UserRedelegation[]>) => {
      state.userRedelegations = action.payload
    },
    updateUserDelegationRewards: (state, action: PayloadAction<UpdateRewardAmount[]>) => {
      for (const update of action.payload) {
        const delegation = state.userDelegations.find(delegation => (
          delegation.delegatorAddress === update.delegatorAddress
          && delegation.validatorAddress === update.validatorAddress
          && delegation.denom === update.denom
        ));
        if (!delegation) continue;

        delegation.pendingRewards = update.rewards
        delegation.rewardsUsdValue = update.usdValue
      }
    },
    updateProposals: (state, action: PayloadAction< Models.Gov.Proposal[]>) => {
      state.proposals = action.payload
    },
    updateValidatorParticipation: (state, action: PayloadAction<SimpleMap<number>[]>) => {
      state.validatorParticipations = action.payload
    },
    resetStakeState(state) {
      Object.assign(state, initialState)
    }
  }
})

export const {
  updateTotalStaked,
  updateTotalSupply,
  updateAllianceAssets,
  updateAprStats,
  updateAvgBlockTime,
  updateUserBalance,
  updateValAddrMap,
  updateDelegatorsMap,
  updateAvatarImagesMap,
  updateUserDelegations,
  updateUserRedelegations,
  updateUserUnbondingDelegations,
  updateUserDelegationRewards,
  updateProposals,
  updateValidatorParticipation,
  resetStakeState
} = appSlice.actions

export default appSlice.reducer
