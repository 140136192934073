import { CarbonSDK } from "carbon-js-sdk"
import { Network } from "carbon-js-sdk/lib/constant"
import dayjs from "dayjs"
import { select, SelectEffect, take } from "redux-saga/effects"
import { RootState } from "store"
import { ChainNames } from "../constants/types"

export const fetchData = async (url: string) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export function transactionToUrl(transaction: string, chainName: ChainNames, network: Network, isCarbonEvmBridge?: boolean) {
  if (chainName === ChainNames.CarbonCore && !isCarbonEvmBridge) { transaction = transaction.slice(2) }
  let ChainExplorerUrls;
  let end = new Map<ChainNames, string>();
  if (network === CarbonSDK.Network.MainNet) {
    ChainExplorerUrls = {
      [ChainNames.Bsc]: "https://bscscan.com/tx",
      [ChainNames.Neo]: "https://neotube.io/transaction",
      [ChainNames.Eth]: "https://etherscan.io/tx",
      [ChainNames.Neo3]: "https://neo3.neotube.io/transaction",
      [ChainNames.Zil]: "https://viewblock.io/zilliqa/tx",
      [ChainNames.CarbonCore]: "https://scan.carbon.network/transaction",
      [ChainNames.CarbonEVM]: "https://evm-scan.carbon.network/tx",
      [ChainNames.Arbitrum]: "https://arbiscan.io/tx"
    };
  } else {
    ChainExplorerUrls = {
      [ChainNames.Bsc]: "https://bscscan.com/tx",
      [ChainNames.Neo]: "https://neotube.io/transaction",
      [ChainNames.Eth]: "https://rinkeby.etherscan.io/tx",
      [ChainNames.Neo3]: "https://neo3.neotube.io/transaction",
      [ChainNames.Zil]: "https://viewblock.io/zilliqa/tx",
      [ChainNames.CarbonCore]: "https://scan.carbon.network/transaction",
      [ChainNames.CarbonEVM]: "https://evm-scan.carbon.network/tx",
      [ChainNames.Arbitrum]: "https://arbiscan.io/tx"
    }
    end.set(ChainNames.Zil, "?network=testnet");
  }
  return `${ChainExplorerUrls[chainName as ChainNames]}/${transaction}${end.get(chainName) ?? ""}`;
}

export function addressToUrl(address: string, chainName: ChainNames, network: Network) {
  let ChainExplorerUrls;
  let end;
  if (network === CarbonSDK.Network.MainNet) {
    ChainExplorerUrls = {
      [ChainNames.Bsc]: "https://bscscan.com/address",
      [ChainNames.Neo]: "https://neotube.io/address",
      [ChainNames.Eth]: "https://etherscan.io/address",
      [ChainNames.Neo3]: "https://neo3.neotube.io/address",
      [ChainNames.Zil]: "https://viewblock.io/zilliqa/address",
      [ChainNames.CarbonCore]: "https://scan.carbon.network/account",
      [ChainNames.CarbonEVM]: "https://evm-scan.carbon.network/address",
      [ChainNames.Arbitrum]: "https://arbiscan.io/address",
    };
    end = new Map([
      [ChainNames.Neo, "/page/1"]
    ]);
  } else {
    ChainExplorerUrls = {
      [ChainNames.Bsc]: "https://bscscan.com/address",
      [ChainNames.Neo]: "https://neotube.io/address",
      [ChainNames.Eth]: "https://rinkeby.etherscan.io/address",
      [ChainNames.Neo3]: "https://neo3.neotube.io/address",
      [ChainNames.Zil]: "https://viewblock.io/zilliqa/address",
      [ChainNames.CarbonCore]: "https://scan.carbon.network/account",
      [ChainNames.CarbonEVM]: "https://evm-scan.carbon.network/address",
      [ChainNames.Arbitrum]: "https://arbiscan.io/address",
    };
    end = new Map([
      [ChainNames.Neo, "/page/1"],
      [ChainNames.Zil, "?network=testnet"]
    ]);
  }
  return `${ChainExplorerUrls[chainName as ChainNames]}/${address}${end.get(chainName) ?? ""}`
}

export const getRefTime = (offset: number) => {
  const ref_time = dayjs().add(offset, "d").unix();
  const ref_time_result = ref_time - ref_time % 300;
  return ref_time_result;
}

export function selectState<T>(selector: (state: RootState) => T): SelectEffect {
  return select(selector);
}

export function* waitforSDK() {
  while (true) {
    const sdk: CarbonSDK | undefined = yield selectState(state => state.app.carbonSDK)
    if (sdk) {
      return sdk
    }
    yield take('*');
  }
}
