import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from "bignumber.js"
import { CarbonWallet, Models, NeoLedgerAccount, O3Wallet, Zilpay } from 'carbon-js-sdk'
import { BridgeLocalStorageKeys } from 'constants/bridge'
import { BN_ZERO } from 'constants/math'
import { ChainNames, WalletNames } from 'constants/types'
import { ethers } from "ethers"
import { EthLedgerAccount } from 'utils'
import { TokenPairsMap } from '../Erc20'

export interface BridgeSliceSchema {
  senderAddress: string | null;
  receiverAddress: string | null;
  senderWallet: WalletNames | null;
  receiverWallet: WalletNames | null;
  eth: { provider: ethers.providers.Web3Provider | null, ledger: EthLedgerAccount | null };
  arbitrum: { provider: ethers.providers.Web3Provider | null };
  zil: { zilPay: Zilpay | null, boltX: any };
  neo: { neoline: any, o3Wallet: O3Wallet | null, ledger: NeoLedgerAccount | null }
  senderBalance: BigNumber;
  receiverBalance: BigNumber;
  senderChain: ChainNames;
  receiverChain: ChainNames;
  tokenInfo: { [x: string]: Models.Carbon.Coin.Token } | null;
  transactions: TransactionsResponse | null;
  tokenPairsMap: TokenPairsMap | null
  tokenDenom: string
  senderCarbonWallet: CarbonWallet | null
  receiverCarbonWallet: CarbonWallet | null
}

export interface TransactionsResponse {
  data: Models.Carbon.Misc.Transaction[],
}

const initialState: BridgeSliceSchema = {
  senderAddress: null,
  receiverAddress: null,
  senderWallet: null,
  receiverWallet: null,
  eth: {
    provider: null,
    ledger: null
  },
  arbitrum: {
    provider: null,
  },
  zil: {
    zilPay: null,
    boltX: null
  },
  neo: {
    neoline: null,
    o3Wallet: null,
    ledger: null,
  },
  senderBalance: BN_ZERO,
  receiverBalance: BN_ZERO,
  senderChain: ChainNames.Eth,
  receiverChain: ChainNames.Bsc,
  tokenInfo: null,
  transactions: null,
  tokenPairsMap: null,
  tokenDenom: 'swth',
  // Store carbon wallets in store so when swapping Bridge From/To, able to retrieve addresses
  senderCarbonWallet: null,
  receiverCarbonWallet: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    updateAddress: (state, action: PayloadAction<{ sender?: string | null, receiver?: string | null, senderWallet?: WalletNames | null, receiverWallet?: WalletNames | null }>) => {
      const isSwth = state.tokenDenom === "swth"
      if (action.payload.sender !== undefined) { state.senderAddress = action.payload.sender }
      if (action.payload.receiver !== undefined) { state.receiverAddress = action.payload.receiver }
      if (action.payload.senderWallet !== undefined) {
        state.senderWallet = action.payload.senderWallet;
        const senderWallet: string = action.payload.senderWallet ?? "";
        if (isSwth) {
          localStorage.setItem(BridgeLocalStorageKeys.senderWallet, senderWallet);
        }
      }
      if (action.payload.receiverWallet !== undefined) {
        state.receiverWallet = action.payload.receiverWallet;
        const receiverWallet: string = action.payload.receiverWallet ?? "";
        if (isSwth) {
          localStorage.setItem(BridgeLocalStorageKeys.receiverWallet, receiverWallet);
        }
      }
    },
    updateEth: (state, action: PayloadAction<{ provider?: ethers.providers.Web3Provider | null, ledger?: EthLedgerAccount | null }>) => {
      if (action.payload.provider !== undefined) { state.eth.provider = action.payload.provider }
      if (action.payload.provider !== undefined) { state.arbitrum.provider = action.payload.provider }
      if (action.payload.ledger !== undefined) { state.eth.ledger = action.payload.ledger }
    },
    updateZil: (state, action: PayloadAction<{ zilPay?: Zilpay | null, boltX?: null }>) => {
      if (action.payload.zilPay !== undefined) { state.zil.zilPay = action.payload.zilPay }
      if (action.payload.boltX !== undefined) { state.zil.boltX = action.payload.boltX }
    },
    updateNeo: (state, action: PayloadAction<{ neoline?: any, o3Wallet?: O3Wallet | null, ledger?: NeoLedgerAccount | null }>) => {
      if (action.payload.neoline !== undefined) { state.neo.neoline = action.payload.neoline }
      if (action.payload.o3Wallet !== undefined) { state.neo.o3Wallet = action.payload.o3Wallet }
      if (action.payload.ledger !== undefined) { state.neo.ledger = action.payload.ledger }
    },
    updateBalance: (state, action: PayloadAction<{ senderBalance?: BigNumber, receiverBalance?: BigNumber }>) => {
      if (action.payload.senderBalance !== undefined) { state.senderBalance = action.payload.senderBalance }
      if (action.payload.receiverBalance !== undefined) { state.receiverBalance = action.payload.receiverBalance }
    },
    updateChain: (state, action: PayloadAction<{ senderChain?: ChainNames; receiverChain?: ChainNames }>) => {
      const isSwth = state.tokenDenom === "swth"
      if (action.payload.senderChain !== undefined) {
        state.senderChain = action.payload.senderChain;
        if (isSwth) {
          localStorage.setItem(BridgeLocalStorageKeys.senderChain, action.payload.senderChain);
        }
      }
      if (action.payload.receiverChain !== undefined) {
        state.receiverChain = action.payload.receiverChain;
        if (isSwth) {
          localStorage.setItem(BridgeLocalStorageKeys.receiverChain, action.payload.receiverChain);
        }
      }
    },
    updateTokenInfo: (state, action: PayloadAction<{ [x: string]: Models.Carbon.Coin.Token }>) => { state.tokenInfo = action.payload },
    updateTransactions: (state, action: PayloadAction<{ data: Models.Carbon.Misc.Transaction[] }>) => { state.transactions = action.payload },
    updateTokenPairsMap: (state, action: PayloadAction<TokenPairsMap>) => { state.tokenPairsMap = action.payload },
    updateTokenDenom: (state, action: PayloadAction<string>) => { state.tokenDenom = action.payload },
    updateCarbonWallet: (state, action: PayloadAction<{ carbonWallet: CarbonWallet | null, side: "sender" | "receiver" }>) => {
      const key = action.payload.side === "sender" ? "senderCarbonWallet" : "receiverCarbonWallet"
      state[key] = action.payload.carbonWallet
    },
  },
});

export const {
  updateAddress, updateEth, updateBalance, updateChain, updateTokenInfo,
  updateZil, updateNeo, updateTransactions, updateTokenPairsMap, updateTokenDenom,
  updateCarbonWallet,
} = walletSlice.actions;

export default walletSlice.reducer;
