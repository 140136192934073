import { CarbonSDK } from "carbon-js-sdk";

export const choiceToNetwork = new Map([
  [0, CarbonSDK.Network.MainNet],
  [1, CarbonSDK.Network.TestNet],
  [2, CarbonSDK.Network.DevNet],
  [3, CarbonSDK.Network.LocalHost]
]);

export const networkToChoice = new Map([
  [CarbonSDK.Network.MainNet, 0],
  [CarbonSDK.Network.TestNet, 1],
  [CarbonSDK.Network.DevNet, 2],
  [CarbonSDK.Network.LocalHost, 3]
]);

export const statusColourMap = new Map([
    [1, "#DC6D5E"],
    [2, "#D0A200"],
    [3, "#3C9D40"]
]);
