import React from 'react';
import Introduction from './Introduction';
import Banner from './Banner';
import CarbonProtocol from './CarbonProtocol';
import LiquidityPool from './LiquidityPool';
import Upcoming from './Upcoming';
import Explore from './Explore';
import './HomePage.css';


function App() {
  return (
    <div>
      <div className='home-page-wrapper theme-color'>
        <Introduction />
        <Banner />
        <CarbonProtocol />
        <LiquidityPool />
        <Upcoming/>
        <Explore/>
      </div>
    </div >
  );
}

export default App;