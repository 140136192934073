import Loading_Light from "assets/Loading_light.gif"
export const DelegationLoading: React.FC = () => {
    return (
        <div style={{
            height: "200px",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
        }} className="centralize-vertical">
            <img src={Loading_Light} className="loading-icon" alt="Loading" />
        </div>
    )
}