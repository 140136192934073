"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Protocol = void 0;
function compareStrings(current, other) {
    if (current.length !== other.length) {
        return false;
    }
    for (let i = 0; i < current.length; i++) {
        if (current[i] !== other[i]) {
            return false;
        }
    }
    return true;
}
/**
 * Model of the protocol configuration file used by the C# implementation.
 */
class Protocol {
    constructor(config = {}) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.magic = (_b = (_a = config.magic) !== null && _a !== void 0 ? _a : config.Magic) !== null && _b !== void 0 ? _b : 0;
        this.addressVersion = (_d = (_c = config.addressVersion) !== null && _c !== void 0 ? _c : config.AddressVersion) !== null && _d !== void 0 ? _d : 23;
        this.standbyValidators =
            (_f = (_e = config.standbyValidators) !== null && _e !== void 0 ? _e : config.StandbyValidators) !== null && _f !== void 0 ? _f : [];
        this.seedList = (_h = (_g = config.seedList) !== null && _g !== void 0 ? _g : config.SeedList) !== null && _h !== void 0 ? _h : [];
    }
    get [Symbol.toStringTag]() {
        return "Protocol";
    }
    export() {
        return {
            magic: this.magic,
            addressVersion: this.addressVersion,
            standbyValidators: this.standbyValidators,
            seedList: this.seedList,
        };
    }
    toConfiguration() {
        return {
            Magic: this.magic,
            AddressVersion: this.addressVersion,
            StandbyValidators: this.standbyValidators,
            SeedList: this.seedList,
        };
    }
    equals(other) {
        var _a, _b, _c, _d, _e, _f;
        return (this.magic === ((_a = other.magic) !== null && _a !== void 0 ? _a : other.Magic) &&
            this.addressVersion === ((_b = other.addressVersion) !== null && _b !== void 0 ? _b : other.AddressVersion) &&
            compareStrings(this.seedList, (_d = (_c = other.seedList) !== null && _c !== void 0 ? _c : other.SeedList) !== null && _d !== void 0 ? _d : []) &&
            compareStrings(this.standbyValidators, (_f = (_e = other.standbyValidators) !== null && _e !== void 0 ? _e : other.StandbyValidators) !== null && _f !== void 0 ? _f : []));
    }
}
exports.Protocol = Protocol;
exports.default = Protocol;
