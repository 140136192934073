import React from "react";
import "./InProgressDots.css";

type Props = {
    style?: React.CSSProperties;
}

const InProgressDots: React.FC<Props> = ({ style }) => {
    return (
        <div className="dots" style={style}>
            <div className="dot1"></div>
            <div className="dot2"></div>
            <div className="dot3"></div>
        </div>
    );
};

export default InProgressDots;
