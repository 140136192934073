import Success from "assets/Success.svg"
import InProgressDots from "components/Common/InProgressDots"
import { ChainNames } from "constants/types"
import { useAppDispatch, useAppSelector } from "hooks"
import React from "react"
import { updateBridgeUI } from "store/UI"
import "./BridgeProgressPopup.css"
import PopupWindow from "./PopupWindow"
import ChainIcon from "../ChainIcon"

type Props = {
    viewHistoryHandler: () => void;
}

const BridgeProcessPopup: React.FC<Props> = ({ viewHistoryHandler }) => {
  const dispatch = useAppDispatch();
  const walletState = useAppSelector(state => state.wallet);
  const uiState = useAppSelector(state => state.ui.bridge);
  const bridgeIconMap = new Map<ChainNames, JSX.Element>([
    [ChainNames.Eth, <ChainIcon chain="Ethereum" size={18} />],
    [ChainNames.Bsc, <ChainIcon chain="Binance Smart Chain" size={18} />],
    [ChainNames.Zil, <ChainIcon chain="Zilliqa" size={18} />],
    [ChainNames.CarbonCore, <ChainIcon chain="Carbon Core" size={18} />],
    [ChainNames.Neo, <ChainIcon chain="Neo" size={18} />],
    [ChainNames.Neo3, <ChainIcon chain="Neo3" size={18} />],
    [ChainNames.Arbitrum, <ChainIcon chain="Arbitrum" size={18} />],
    [ChainNames.CarbonEVM, <ChainIcon chain="Carbon EVM" size={18} />],
  ]);


  return (
    <PopupWindow headerText={uiState.bridgeProcess.success ? "Bridge Complete" : "Bridge in Progress"} style={{ fontSize:"16px" }} bottomButtonText="View Bridge History" topButtonText="Bridge Again" closeWindow={() => dispatch(updateBridgeUI({ bridgeProcess: { popup: false } }))}
      clickBottomButton={viewHistoryHandler}
      clickTopButton={() => dispatch(updateBridgeUI({ bridgeProcess: { popup: false } })) }
      message={uiState.bridgeProcess.success ? "Bridge successful!" : "Your tokens are now being bridged over! You can check the progress on Bridge History."}
      transactionLink={uiState.bridgeProcess.transactionLink}>
      <div className="bridge-progress-img-wrapper">
        {uiState.bridgeProcess.success ? <img src={Success} alt="success" style={{ height: "60px", width: "60px" }} /> 
        : <><div className="bridge-progress-token-container" style={{ backgroundColor: "#0A3F52" }}>
          <div>{bridgeIconMap.get(walletState.senderChain)}</div>
        </div>
          <InProgressDots />
          <div className="bridge-progress-token-container" style={{ backgroundColor: "#0A3F52" }}>
          <div id="token-inner-container">{bridgeIconMap.get(walletState.receiverChain)}</div>
          </div></>
        }
      </div>
    </PopupWindow>
  );
};

export default BridgeProcessPopup;
