import { ChainNames, WalletNames } from "constants/types"
import { useAppDispatch, useAppSelector } from "hooks"
import React, { useEffect, useMemo, useRef } from "react"
import { StakeActionTypes } from "store/Stake"
import { bnOrZero, reduxAction } from "utils"
import StakeDelegationInfo from "./DelegationInfo/StakeDelegationInfo"
import StakeInfo from "./StakeInfo/StakeInfo"
import "./StakePage.css"
import StakeTopRow from "./StakeTopRow"
import StakeValidatorsInfo from "./ValidatorsTable/StakeValidatorsInfo"
export interface AddressSchema {
  carbonAddress: string | null
  carbonWallet: WalletNames | null
  walletSide: "receiver" | "sender" | null
}

const StakePage: React.FC = () => {
  const validatorsInfoRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const walletState = useAppSelector(state => state.wallet)
  const valAddrMap = useAppSelector(state => state.stake.valAddrMap)
  const network = useAppSelector(state => state.app.network)
  const { carbonAddress, carbonWallet, walletSide }: AddressSchema = useMemo(() => {
    if (walletState.senderChain === ChainNames.CarbonCore) {
      return {
        carbonAddress: walletState.senderAddress,
        carbonWallet: walletState.senderWallet,
        walletSide: "sender",
      }
    } else if (walletState.receiverChain === ChainNames.CarbonCore) {
      return {
        carbonAddress: walletState.receiverAddress,
        carbonWallet: walletState.receiverWallet,
        walletSide: "receiver",
      }
    } else {
      return { carbonAddress: null, carbonWallet: null, walletSide: null }
    }
  }, [walletState])

  useEffect(() => {
    // loadBalance();
    // loadUserBalance();
    dispatch(reduxAction(StakeActionTypes.RELOAD_ALL_STAKING_INFO))
    dispatch(reduxAction(StakeActionTypes.QUERY_USER_DELEGATION_REWARDS))
  }, [carbonAddress, walletSide, network]) // eslint-disable-line react-hooks/exhaustive-deps

  const validators = useMemo(() => {
    const results = Object.values(valAddrMap).map(pair => pair.carbonValidator).sort((lhs, rhs) => bnOrZero(lhs.tokens).comparedTo(rhs.tokens))
    return results
  }, [valAddrMap])

  const onStakeNow = () => {
    const elTop = validatorsInfoRef.current?.getBoundingClientRect().top
    if (elTop) {
      const y = elTop + window.scrollY - 130
      window.scrollTo({ top: y, behavior: "smooth" })
    }
  }

  return (
    <div className="stakePage-wrapper theme-color">
      <div className="stakePage-inner-wrapper">
        <StakeTopRow carbonAddress={carbonAddress} carbonWallet={carbonWallet} walletSide={walletSide} />
        <StakeInfo onStakeNow={onStakeNow} />
        <StakeDelegationInfo />
        <StakeValidatorsInfo ref={validatorsInfoRef} validators={validators} />
      </div>
    </div>
  )
}

export default StakePage
