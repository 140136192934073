import Copy from "assets/Copy.svg"
import CopyBranding from "assets/Copy_Branding.svg"
import SWTHBranding from "assets/SWTH-Branding-Small.svg"
import SWTH from "assets/SWTH-Disabled-Small.svg"
import BigNumber from "bignumber.js"
import AddressLabel from "components/Common/AddressLabel"
import { BN_ZERO } from 'constants/math'
import { ChainNames } from 'constants/types'
import { useAppSelector, useAsyncTask } from "hooks"
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { addressToUrl } from "saga/Common"
import { getTokenDenoms, shiftDecimals } from 'utils'
import PopupWindow from "./PopupWindow"
import "./WalletInfoPopup.css"
import CoinIcon from "../Tokens/CoinIcon"

interface Props {
  setWalletInfoPopupSide: Dispatch<SetStateAction<"sender" | "receiver" | null>>
  walletInfoPopupSide: "sender" | "receiver"
  onDisconnect: () => void
  tokenDenom?: string
  balance?: BigNumber
}

const WalletInfoPopup: React.FC<Props> = (props: Props) => {
  const { setWalletInfoPopupSide, walletInfoPopupSide, balance, onDisconnect, tokenDenom = 'swth' } = props
  const tokenPrices = useAppSelector(state => state.tokenPrices.value)
  const tokenPrice = Object.entries(tokenPrices).length === 0 ? 0 : tokenPrices[tokenDenom]
  const network = useAppSelector(state => state.app.network)
  const sdk = useAppSelector(state => state.app.carbonSDK)
  const walletState = useAppSelector(state => state.wallet)
  const [hoverLink, setHoverLink] = useState<Boolean>(false)
  const [hoverCopy, setHoverCopy] = useState<Boolean>(false)
  const [swthBalance, setSwthBalance] = useState<BigNumber>(BN_ZERO)
  const [runLoadBalance] = useAsyncTask()
  const address = walletInfoPopupSide === "sender" ? walletState.senderAddress! : walletState.receiverAddress!
  const chain = walletInfoPopupSide === "sender" ? walletState.senderChain : walletState.receiverChain
  const wallet = walletInfoPopupSide === "sender" ? walletState.senderWallet! : walletState.receiverWallet!
  const link = addressToUrl(address, chain, network)
  const tokensInfoMap = walletState.tokenInfo

  const disconnectHandler = () => {
    onDisconnect()
    setWalletInfoPopupSide(null)
  }
  const copyHandler = () => {
    navigator.clipboard.writeText(address)
  }
  const reloadBalance = () => {
    runLoadBalance(async () => {
      if (sdk) {
        const rawBalance = await sdk?.query.bank.Balance({
          address: walletInfoPopupSide === "sender" ? walletState.senderAddress! : walletState.receiverAddress!,
          denom: "swth"
        })
        if (rawBalance && rawBalance.balance) {
          setSwthBalance(new BigNumber(rawBalance.balance.amount))
        }
      }
    })
  }

  useEffect(() => {
    reloadBalance()
    // eslint-disable-next-line
  }, [sdk, network, walletState])

  const swthTokenInfo = useMemo(() => {
    let tokenDenom = getTokenDenoms(network, ChainNames.CarbonCore)
    if (walletState.tokenInfo) {
      return Object.values(walletState.tokenInfo).filter(value => value.denom === tokenDenom)[0]
    } else {
      return null
    }
  }, [walletState.tokenInfo, network])

  const shiftedSwthBalance = useMemo(() => {
    return shiftDecimals(swthBalance, swthTokenInfo)
  }, [swthBalance, swthTokenInfo])

  return (
    <PopupWindow headerText='Connected Wallet' closeWindow={() => setWalletInfoPopupSide(null)}>
      <div className='bridge-wallet-disconnect'>
        <button className="button-theme button-theme-primary bridge-wallet-disconnect-button" onClick={disconnectHandler}>Disconnect</button>
        <div className='bridge-wallet-address'>
          <AddressLabel logoName={wallet} text={address} bolded={true} />
        </div>
      </div>
      <div className='bridge-wallet-utils'>
        <div className='bridge-wallet-utils-wrapper'>
          <div className='bridge-wallet-utils-item link' onMouseOver={() => setHoverCopy(true)} onMouseLeave={() => setHoverCopy(false)} onClick={copyHandler}>
            <span>Copy Wallet Address</span>
            <img src={hoverCopy ? CopyBranding : Copy} alt="Copy" />
          </div>
        </div>
        <div className='bridge-wallet-utils-wrapper'>
          <a href={link} target="_blank" rel="noopener noreferrer" onMouseOver={() => setHoverLink(true)} onMouseLeave={() => setHoverLink(false)}>
            <div className='bridge-wallet-utils-item link'>
              <span>View on Explorer</span>
              <img height="18px" src={hoverLink ? SWTHBranding : SWTH} alt="External Link" />
            </div>
          </a>
        </div>
      </div>
      <div className='bridge-wallet-info bolded-700'>
        <span className='bridge-wallet-info-header'>{tokensInfoMap?.[tokenDenom]?.symbol ?? tokenDenom} Balance</span>
        <div className='bridge-wallet-info-main'>
          <span>{balance ? (balance.toFormat(4)) : (shiftedSwthBalance.toFormat(4))}</span>
          <CoinIcon denom={tokenDenom} />
        </div>
        <span className='bridge-wallet-info-secondary bolded-400'>{`$${balance ? (balance.multipliedBy(tokenPrice).toFormat(4)) : (shiftedSwthBalance.multipliedBy(tokenPrice).toFormat(4))}`}</span>
      </div>
    </PopupWindow>
  )
}

export default WalletInfoPopup
