import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Models } from 'carbon-js-sdk'
import { PopupPurposes } from "constants/bridge"
import { NodeFormState } from 'utils/nodes'
import { WarningMessage } from "../components/Common/Notification"
import { BridgeHistoryTab } from 'components/BridgePage/types'
interface BridgeSchema {
  bridgeProcess: {
    success: boolean;
    popup: boolean;
    transactionLink: string;
  };
  button: {
    enabled: boolean;
    text: string;
  };
  input: {
    focus: boolean;
    error: string | null;
    amount: string;
    percentage: number;
  };
  statusMessage: WarningMessage | null;
  historyTab: BridgeHistoryTab;
};

interface BridgeInputSchema {
  bridgeProcess?: {
    success?: boolean;
    popup?: boolean;
    transactionLink?: string;
  };
  button?: {
    enabled?: boolean;
    text?: string;
  };
  input?: {
    focus?: boolean;
    error?: string | null;
    amount?: string;
    percentage?: number;
  };
  statusMessage?: WarningMessage | null;
  historyTab?: BridgeHistoryTab;
};

interface StakeSchema {
  chosenValidator: Models.Staking.Validator | null;
  chosenAsset: Models.Carbon.Alliance.AllianceAsset | null;
};

interface MigrateSchema {
  button: {
    enabled: boolean,
    text: string
  }
}

interface MigrateInputSchema {
  button: {
    enabled?: boolean,
    text?: string
  }
}

interface NodeFormSchema {
  viewOnly: boolean;
  nodeInfo?: NodeFormState;
  showNodeForm: boolean;
}

interface PopupSchema {
  purpose: PopupPurposes | null;
  buttonText: string;
  transactionLink: string;
}

interface UISliceSchema {
  bridge: BridgeSchema,
  stake: StakeSchema,
  popup: PopupSchema,
  migrate: MigrateSchema,
  node: NodeFormSchema,
}

const initialState: UISliceSchema = {
  bridge: {
    bridgeProcess: {
      success: false,
      popup: false,
      transactionLink: "",
    },
    button: {
      enabled: false,
      text: "",
    },
    input: {
      focus: false,
      error: null,
      amount: '',
      percentage: 0,
    },
    statusMessage: null,
    historyTab: BridgeHistoryTab.CrosschainTransfers,
  },
  stake: {
    chosenValidator: null,
    chosenAsset: null,
  },
  popup: {
    purpose: null,
    buttonText: "",
    transactionLink: "",
  },
  migrate: {
    button: {
      enabled: false,
      text: "",
    }
  },
  node: {
    viewOnly: true,
    showNodeForm: false,
  }
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
      updateBridgeUI: (state, action: PayloadAction<BridgeInputSchema>) => {
        if (action.payload.bridgeProcess?.popup !== undefined) { state.bridge.bridgeProcess.popup = action.payload.bridgeProcess.popup }
        if (action.payload.bridgeProcess?.success !== undefined) { state.bridge.bridgeProcess.success = action.payload.bridgeProcess.success }
        if (action.payload.bridgeProcess?.transactionLink !== undefined) { state.bridge.bridgeProcess.transactionLink = action.payload.bridgeProcess.transactionLink }
        if (action.payload.button?.enabled !== undefined) { state.bridge.button.enabled = action.payload.button.enabled }
        if (action.payload.button?.text !== undefined) { state.bridge.button.text = action.payload.button.text }
        if (action.payload.input?.error !== undefined) { state.bridge.input.error = action.payload.input.error }
        if (action.payload.input?.focus !== undefined) { state.bridge.input.focus = action.payload.input.focus }
        if (action.payload.input?.percentage !== undefined) { state.bridge.input.percentage = action.payload.input.percentage }
        if (action.payload.input?.amount !== undefined) { state.bridge.input.amount = action.payload.input.amount }
        if (action.payload.statusMessage !== undefined) { state.bridge.statusMessage = action.payload.statusMessage }
        if (action.payload.historyTab !== undefined) {state.bridge.historyTab = action.payload.historyTab }
      },
      updateStakeUI: (state, action: PayloadAction<Partial<StakeSchema>>) => {
        state.stake = {
          ...state.stake,
          ...action.payload,
        }
      },
      updatePopup: (state, action: PayloadAction<PopupSchema>) => {
        state.popup.buttonText = action.payload.buttonText;
        state.popup.purpose = action.payload.purpose;
        state.popup.transactionLink = action.payload.transactionLink;
      },
      updateMigrateUI: (state, action: PayloadAction<MigrateInputSchema>) => {
        if (action.payload.button.enabled !== undefined) state.migrate.button.enabled = action.payload.button.enabled;
        if (action.payload.button.text !== undefined) state.migrate.button.text = action.payload.button.text;
      },
      updateShowNodeForm: (state, action: PayloadAction<NodeFormSchema>) => {
        if (action.payload.nodeInfo !== undefined) state.node.nodeInfo = action.payload.nodeInfo;
        if (action.payload.viewOnly !== undefined) state.node.viewOnly = action.payload.viewOnly;
        if (action.payload.showNodeForm !== undefined) state.node.showNodeForm = action.payload.showNodeForm;
      }

    }
})

export const { updateBridgeUI, updatePopup, updateMigrateUI, updateStakeUI, updateShowNodeForm } = uiSlice.actions

export default uiSlice.reducer;
