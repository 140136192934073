import React from "react";
import PopupWindow from "./PopupWindow";
import { useAppSelector } from "hooks";
import Click from "assets/Click.svg";

const BefSignPopup: React.FC = () => {
  const popupState = useAppSelector(state => state.ui.popup);
  const bridgeCardImgWrapper = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center"
  }
  return (
    <PopupWindow
      bottomButtonText={popupState.buttonText}
      message="Please approve the transaction on your wallet"
      style={{ fontSize:"18px" }}
    >
      <div style={bridgeCardImgWrapper}>
      <img src={Click} alt="click" />
      </div>
    </PopupWindow>
  );
};

export default BefSignPopup;
