import { ReactComponent as PlaceholderIcon } from 'assets/Placeholder.svg'
import { BlockchainIconPath } from 'constants/paths'
import { useState } from 'react'

const tokenNameMap: {
    [index: string]: string
} = {
    IUSD: 'USD',
    NNEO: 'Neo',
    BTCB: 'BTC',
    BCFX: 'CFX',
    EPLAY: 'PLAY',
    'LKT.BEP20': 'LKT',
    SWTHB: 'SWTH',
    LUNC: 'LUNA',
    USTC: 'UST',
    GAS: 'CGAS',
    CIBUSDC: 'USDC',
    AMPLUNA: 'ampLUNA',
    STLUNA: 'stLUNA',
    CUSDC: 'USDC',
    STEVMOS: 'stEVMOS',
    BSC: 'Binance Smart Chain',
    'Arbitrum One': 'Arbitrum',
    'Neo (NEP-5)': 'Neo',
    'Neo N3 (NEP-17)': 'Neo3',
    'Carbon Core': 'Carbon-filled',
    'Carbon EVM': 'Carbon-EVM-filled',
}

type Props = {
    chain: string,
    size?: number,
}

function getChainIconURL(blockchainName: string, fileType: string = 'svg') {
    return BlockchainIconPath.replace(':blockchain_name', blockchainName).replace(':file_type', fileType)
  }

const ChainIcon: React.FC<Props> = ({ chain, size }) => {
    const [imageSrcError, setImageSrcError] = useState(false);
    const [imageSrcIndex, setImageSrcIndex] = useState(0);

    chain = tokenNameMap[chain] ?? chain
    const imageSrcArray = chain 
    ? [
      getChainIconURL(chain, 'svg'),
      getChainIconURL(chain, 'png'),
    ] : []

    const handleImgSrcError = () => {
        if (imageSrcIndex < imageSrcArray.length - 1) {
          setImageSrcIndex(imageSrcIndex + 1);
        } else {
          setImageSrcError(true);
        }
      }

    size = size ?? 24

    let defaultIcon: React.ReactNode = <PlaceholderIcon height={size} width={size} />

    return (
        <div style={{ display: 'flex' }}>
            {
            !imageSrcError && chain 
                ? <img src={imageSrcArray[imageSrcIndex]} onError={handleImgSrcError} alt={chain} height={size} width={size} />
                : defaultIcon
            }
        </div>
    )
}

export default ChainIcon
