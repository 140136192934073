import ExternalLink from 'components/Common/ExternalLink'
import React, { ReactFragment } from 'react'
import './MigrationCard.css'

interface Props {
  title: string;
  logo: string;
  description: ReactFragment;
  start: string;
  end: string;
  oldAddress: string;
  newAddress: string;
  oldLink: string;
  newLink: string;
  buttonText: string;
  setMigrate?: () => void;
};

const MigrationCard: React.FC<Props> = ({ title, logo, description, start, end, buttonText, oldAddress, newAddress, oldLink, newLink, setMigrate }) => {
  return (
    <div className={`card-wrapper ${buttonText === 'Ended' ? 'dashed' : 'solid'}`}>
      <p className='migrate-card-header'> <img src={logo} className='token-logo' alt="token-logo" />{title} </p>
      <p className='flexible card-description'> {description} </p>
      <div className='date-container'>
        <p className='card-description'> Start </p>
        <p className='card-description'> {start === '' ? 'To be updated' : start} </p>
      </div>
      <div className='date-container'>
        <p className='card-description'> End </p>
        <p className='card-description'> {end === '' ? 'To be updated' : end} </p>
      </div>
      <div className="migrate-contract-address-box">
        <span className='card-description' style={{ whiteSpace:"nowrap", minWidth: "165px" }}> Old Contract Address </span>
        <ExternalLink text={oldAddress} link={oldLink} style={{ fontFamily: "Heebo", fontWeight: "400" }} address={true} />
      </div>
      <div className="migrate-contract-address-box">
        <span className='card-description' style={{ whiteSpace:"nowrap", minWidth: "165px" }}> New Contract Address</span>
        <ExternalLink text={newAddress} link={newLink} style={{ fontFamily: "Heebo", fontWeight: "400" }} address={true} />
      </div>
      <div className='migrate-button-wrapper-desktop'>
        <button id="bottom-button" className='button-theme button-theme-primary button-migrate' disabled={buttonText !== 'Migrate'} onClick={setMigrate}> {buttonText} </button>
      </div>
      <div className="migrate-button-wrapper-mobile">
        <button id="bottom-button" className='button-theme button-theme-primary button-migrate' disabled onClick={setMigrate}> {buttonText === "Migrate" ? "Migrate on Desktop" : buttonText} </button>
      </div>
    </div>
  );
};

export default MigrationCard;
