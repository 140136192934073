import Error from 'assets/Error.svg'
import Neutral from 'assets/MoreInfo_NeutralThin.svg'
import ArrowNeutral from 'assets/Neutral.svg'
import Success from 'assets/Success.svg'
import Warning from 'assets/Warning.svg'
import React from 'react'
import './Notification.css'

interface Props {
  category: string
  message?: JSX.Element
  boldMessage?: JSX.Element
  header?: string
  link?: string
  list?: Array<JSX.Element>
};

export interface WarningMessage {
  message: string
  link?: string
  category: "success" | "warning" | "error" | "neutral"
}

const Notification: React.FC<Props> = ({ category, message, header, link, list, boldMessage }) => {
  if (!!header) {
    return (
      <div className='notification-max-width'>
        {
          category === "success" ?
            <div className='warning-message success' style={{ display: 'block' }}>
              <span style={{ display: 'flex' }}><img src={Success} alt='success' className="notification-img-wrapper" /><strong>{header}</strong></span>
              {boldMessage && <div>{boldMessage}</div>}
              {message && <div>{message}</div>}
              <ol className="list-wrapper">
                {list?.map((listMessage, index) => {
                  return (
                    <li key={index} style={{ marginBottom: "0px" }}>{listMessage}</li>
                  )
                })}
              </ol>
            </div>
            : category === 'warning' ?
              <div className='warning-message warning' style={{ display: 'block' }}>
                <span style={{ display: 'flex' }}><img src={Warning} alt='warning' className="notification-img-wrapper" /><strong>{header}</strong></span>
                {boldMessage && <div>{boldMessage}</div>}
                {message && <div>{message}</div>}
                <ol className="list-wrapper">
                  {list?.map((listMessage, index) => {
                    return (
                      <li key={index} style={{ marginBottom: "0px" }}>{listMessage}</li>
                    )
                  })}
                </ol>
              </div>
              : category === 'neutral' ?
                <div className='warning-message neutral' style={{ display: 'block' }}>
                  <span style={{ display: 'flex' }}> <img src={Neutral} alt='neutral' className="notification-img-wrapper" /><strong>{header}</strong></span>
                  {boldMessage && <div>{boldMessage}</div>}
                  {message && <div>{message}</div>}
                  <ol className="list-wrapper">
                    {list?.map((listMessage, index) => {
                      return (
                        <li key={index} style={{ marginBottom: "0px" }}>{listMessage}</li>
                      )
                    })}
                  </ol>
                </div>
                :
                <div className='warning-message error centralize-vertical' style={{ display: 'block' }}>
                  <span style={{ display: 'flex' }}><img src={Error} alt='error' className="notification-img-wrapper" /><strong>{header}</strong></span>
                  {boldMessage && <div>{boldMessage}</div>}
                  {message && <div>{message}</div>}
                  <ol className="list-wrapper">
                    {list?.map((listMessage, index) => {
                      return (
                        <li key={index} style={{ marginBottom: "0px" }}>{listMessage}</li>
                      )
                    })}
                  </ol>
                </div>
        }
      </div>
    )
  }
  return (
    <div className='notification-max-width'>
      {
        category === "success" ? <span className='warning-message success'><img src={Success} alt='success' className="notification-img-wrapper" />{message}</span>
          : category === 'warning' ? <span className='warning-message warning'><img src={Warning} alt='warning' className="notification-img-wrapper" />{message}</span>
            : category === 'neutral' ? <span className='warning-message neutral'> <img src={ArrowNeutral} alt='neutral' className="notification-img-wrapper" />{message}</span>
              : <span className='warning-message error centralize-vertical'><img src={Error} alt='error' className="notification-img-wrapper" />{message}</span>
      }
    </div>
  )
}

export default Notification
